import React, {useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Link} from 'react-router-dom';
import I18nMessage from '../../components/I18nMessage';
import beep from './voice_on.mp3';
import useSound from 'use-sound';
import './event.sass';

const colors = {
    green: '#00b105',
    red: '#f2392d',
};

const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: ${(props) => `1px solid ${colors.red}`};
    box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4);
  `;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
    span {
      color: ${colors.red};
      font-weight: bold;
      font-size: 0.8rem;
    }
    small {
    }
  `;

const Span = styled.span`
    color: ${colors.red};
    font-size: 1.5rem;
  `;

const Date = styled.span`
    color: ${colors.red};
    font-weight: 600;
    font-size: 0.8rem;
  `;

const LinkStyled = styled(Link)`
    color: inherit !important;
    text-decoration: inherit !important;
  `;


const getEventI18N = (type, attributes) => { // TODO should use from helper
    return attributes.alarm ?
        `alarm${String(attributes.alarm)
            .charAt(0)
            .toUpperCase()}${String(attributes.alarm).slice(1)}` :
        `event${String(type)
            .charAt(0)
            .toUpperCase()}${String(type).slice(1)}`;
};


const EventCard = ({
                       id,
                       deviceId,
                       type,
                       serverTime,
                       name,
                       attributes,
                       ...props
                   }) => {
    return (
        <LinkStyled to={`/admin/events/${id}`}>
            <Card {...props} className="mb-2 p-3">
                <Description>
                    <span>{name}</span>
                    <small>
                        <I18nMessage id={getEventI18N(type, attributes)}/>
                    </small>
                </Description>
                <Date className="mr-1 ml-1">{moment(serverTime).fromNow()}</Date>
                <Span className="fas fa-map-marker-alt ml-2"/>
            </Card>
        </LinkStyled>
    );
};


const EventList = (props) => {
    const [play] = useSound(beep);
    const [eventSize, setEventSize] = useState(props.events.length);

    const eventsList = props.events.filter(({type}) => (!props.hasFilter ? true : props.filters.includes(type)))
        .map((e, i) => <EventCard key={e.id} {...e} />);

    if (props.events.length > eventSize) {
        if (props.isSoundEnabled) {
            play();
        }
        setEventSize(props.events.length);
    }

    return (
        <div className="p-1 py-2">
            {eventsList}
        </div>
    );
};

export default EventList;
