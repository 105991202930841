import {flow, getSnapshot, types} from 'mobx-state-tree';
import AuthStore from './stores/auth-store';
import MapModel from './models/map-model';
import {getServer} from './services/serverService';
import AlarmTypesStore from './stores/alarm-types-store';
import AllNotificationTypesStore from './stores/all-notification-types-store';
import AllNotificatorStore from './stores/all-notificators-store';
import PositionAttributesStore from './stores/position-attributes-store';
import MaintenanceTypesStore from './stores/maintenance-types-store';
import SpeedUnitStore from './stores/speed-unit-store';
import DistanceUnitStore from './stores/distance-unit-store';
import HoursUnitStore from './stores/hours-unit-store';
import MapTypeStore from './stores/map-type-store';
import CoordinateFormatStore from './stores/coordinate-format-store';
import AllCommandTypesStore from './stores/all-command-types-store';
import KnownCommandsStore from './stores/known-commands-store';
import CategoryStore from './stores/category-store';
import DeviceStore from './stores/device-store';
import EventStore from './stores/event-store';
import EquipmentStore from './stores/equipment-store';
import AllTimezonesStore from './stores/all-timezones-store';
import DriverStore from './stores/driver-store';
import VolumeUnitStore from './stores/volume-unit-store';
import AzulPayStore from './stores/azulpay-store';
import FullControlStore from './stores/full-control-store';
import UserStore from './stores/user-store';
import CustomerStore from './stores/customer-store';
import SinisterStore from './stores/sinister-store';
import CompanyConfigurationModel from './stores/company-configuration-store';

const store = types

    .model('Stores', {
        auth: types.optional(AuthStore, {isLogged: false}),

        session: types.frozen({}),

        devices: types.optional(DeviceStore, {devices: []}),

        server: types.maybe(types.frozen({})),

        map: types.optional(MapModel, {selectedMap: 'osm'}),

        alarmTypes: types.optional(AlarmTypesStore, {}),

        allNotificationTypes: types.optional(AllNotificationTypesStore, {}),

        allNotificators: types.optional(AllNotificatorStore, {}),

        allCommandTypes: types.optional(AllCommandTypesStore, {}),

        categories: types.optional(CategoryStore, {}),

        drivers: types.optional(DriverStore, {drivers: []}),

        theme: types.optional(types.string, '#16A5DA'),

        positionAttributes: types.optional(PositionAttributesStore, {}),

        maintenanceTypes: types.optional(MaintenanceTypesStore, {}),

        knownCommands: types.optional(KnownCommandsStore, {}),

        allTimezonesStore: types.optional(AllTimezonesStore, {}),

        speedUnit: types.optional(SpeedUnitStore, {}),

        distanceUnit: types.optional(DistanceUnitStore, {}),

        hoursUnit: types.optional(HoursUnitStore, {}),

        volumeUnits: types.optional(VolumeUnitStore, {}),

        mapType: types.optional(MapTypeStore, {}),

        coordinateFormats: types.optional(CoordinateFormatStore, {}),

        events: types.optional(EventStore, {list: []}),

        equipment: types.optional(EquipmentStore, {}),

        azulPayStore: types.optional(AzulPayStore, {}),

        fullControlStore: types.optional(FullControlStore, {}),

        userStore: types.optional(UserStore, {}),

        customerStore: types.optional(CustomerStore, {}),

        sinisterStore: types.optional(SinisterStore, {}),

        companyConfigurationStore: types.optional(CompanyConfigurationModel, {}),
    })
    .actions((self) => ({
        afterCreate: flow(function* () {
            self.server = yield getServer();

            self.map.setInfoBase({
                zoom: self.server.zoom,
                center: self.server.center,
            });

            self.maintenanceTypes.setTypes(
                getSnapshot(self.positionAttributes).items.filter(
                    (p) => p.valueType === 'number',
                ),
            );
        }),
        setSession: (values) => (self.session = values),
        updateTheme: (newTheme) => {
            self.theme = newTheme;
        },
    }));

export default store;
