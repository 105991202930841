import React, {useRef, useState} from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import './device.sass';
import {FieldSet} from '../../components/fieldset';
import I18nMessage from '../../components/I18nMessage';
import {Input as UInput} from '../../components/input';
import {GroupButtonForm} from '../../components/group-button-form';
import ReactSelect from '../../components/react-select';
import ReactSelectCategory from '../../components/react-select-category';
import ToggleButton from '../../components/toggle-button';
import {kmhToKnots, knotsToKmH} from '../../helpers/conversions';


const DeviceAdminForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        groups,
        equipments,
        categories,
        loading,
        customers,
    } = props;

    const formRef = useRef(null);
    const [category, setCategory] = useState(data.category); // TODO: [MON-27] fix the react-select-category to bind the schema data

    const schema = Yup.object().shape({
        id: Yup.number().notRequired(),
        name: Yup.string().required('Nome é necessário'),
        uniqueId: Yup.string().required('Indentificador é necessário'),
        groupId: Yup.string().notRequired(),
        customerId: Yup.number(), // .required('Selecione um cliente'), // TODO: make it required again when asked
        phone: Yup.string().notRequired(),
        model: Yup.string().notRequired(),
        contact: Yup.string().notRequired(),
        category: Yup.string().notRequired(),
        disabled: Yup.boolean().notRequired(),
        attributes: Yup.object().shape({
            cc_email: Yup.string().notRequired(),
            m2mProvider: Yup.string().notRequired(),
            iccid: Yup.string().notRequired(),
            vehicleModel: Yup.string().notRequired(),
            plate: Yup.string().notRequired(),
            year: Yup.string().notRequired(),
            fuelType: Yup.string().notRequired(),
            fuelConsumption: Yup.string().notRequired(),
            manufacturer: Yup.string().notRequired(),
            color: Yup.string().notRequired(),
            renavam: Yup.string().notRequired(),
            chassi: Yup.string().notRequired(),
            devicePassword: Yup.string().notRequired(),
            speedLimit: Yup.string().notRequired(),
            installationLocation: Yup.string().notRequired(),
            technician: Yup.string().notRequired(),
            ignoreOdometer: Yup.boolean().default(false),
        }),
    });

    const cast = (src) => {
        const data = Object.assign({}, src);
        if (data.attributes) {
            const attributes = Object.assign({}, data.attributes);
            if (attributes.hasOwnProperty('speedLimit')) {
                attributes.speedLimit = knotsToKmH(attributes.speedLimit);
            }

            data.attributes = attributes;
        }
        return data;
    };

    const handleF8Key = () => {
        formRef.current.submitForm();
    };

    const handleSubmit = async (payload) => {
        try {
            payload.category = category;
            if (payload.attributes) {
                const attributes = Object.assign({}, payload.attributes);
                if (attributes.hasOwnProperty('speedLimit')) {
                    attributes.speedLimit = kmhToKnots(payload.attributes.speedLimit);
                }
                payload.attributes = attributes;
            }

            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            edit ? updateRegister(Object.assign(data, payload)) : newRegister(payload);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const onChange = (value) => {
        if (value !== null) {
            setCategory(value.id);
        } else {
            setCategory(data.category);
        }
    };

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            schema={schema}
            initialData={cast(data)}>

            {!!edit && <UInput hidden type="number" name="id" />}

            <FieldSet className="text-left border p-4" title={'Identificação'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedName" />*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="deviceIdentifier" />*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="uniqueId"
                                    id="uniqueId"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="category"><I18nMessage id="deviceCategory" /></Label>
                                <ReactSelectCategory
                                    id={'category'}
                                    name="category"
                                    options={categories}
                                    onChange={onChange} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="groupId"><I18nMessage id="groupParent" /></Label>
                                <ReactSelect
                                    id={'groupId'}
                                    name="groupId"
                                    options={groups} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedCustomer" /></Label>
                                <ReactSelect
                                    id={'customerId'}
                                    name="customerId"
                                    options={customers} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceContact" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="contact"
                                    id="contact"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="disabled"><I18nMessage id="sharedDisabled" /></Label>
                                <ToggleButton
                                    name={'disabled'}
                                    id={'disabled'} />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br />
            <FieldSet className="text-left border p-4" title={'Rastreador'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="model"><I18nMessage id="deviceModel" /></Label>
                                <ReactSelect id={'model'} name="model" options={equipments} />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceIccid" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.iccid"
                                    id="iccid"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="phone"><I18nMessage id="sharedPhone" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="phone"
                                    id="phone"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceM2mProvider" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.m2mProvider"
                                    id="m2mProvider"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br />
            <FieldSet className="text-left border p-4" title={'Veículo'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="devicePlate" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.plate"
                                    id="plate"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceVehicleModel" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.vehicleModel"
                                    id="vehicleModel"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceYear" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.year"
                                    id="year"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceChassi" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.chassi"
                                    id="chassi"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceInstallationLocation" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.installationLocation"
                                    id="installationLocation"
                                />
                            </FormGroup>

                        </Col>
                        <Col>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceManufacturer" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.manufacturer"
                                    id="manufacturer"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceColor" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.color"
                                    id="color"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceRenavam" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.renavam"
                                    id="renavam"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceFuelType" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.fuelType"
                                    id="fuelType"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceTechnician" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.technician"
                                    id="technician"
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br />
            <FieldSet className="text-left border p-4" title={'Configurações'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceFuelConsumption" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.fuelConsumption"
                                    id="fuelConsumption"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="devicePassword" /></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.devicePassword"
                                    id="devicePassword"
                                />
                            </FormGroup>

                        </Col>
                        <Col>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceSpeedLimit" /> (Km/h)</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.speedLimit"
                                    id="speedLimit"
                                />
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label for="contact"><I18nMessage id="deviceIgnoreOdometer" /></Label>
                                <ToggleButton name={'attributes.ignoreOdometer'} id={'ignoreOdometer'} />
                            </FormGroup>

                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br />
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={handleF8Key}
            />
        </Form>
    );
};

export default DeviceAdminForm;
