import React, {useContext, useEffect, useMemo, useState} from 'react';
import UserForm from '../../views/user/user-form';
import UserTable from '../../views/user/user-table';
import PermissionTabs from '../../views/permission';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useStore from '../../hooks/use-store';
import useServices from '../../hooks/use-services';
import {getSnapshot} from 'mobx-state-tree';
import useCustomers from '../../hooks/use-customers';
import useUsers from '../../hooks/use-users';
import {observer} from 'mobx-react';

const UserContainer = (props) => {
    const {store} = useStore();
    const {allTimezonesStore} = store;
    const {userService} = useServices();
    const {customers: customerStore} = useCustomers();
    const {auth, coordinateFormats, session} = store;
    const intl = useIntl();
    const {toast} = useAlerts();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('userList');
    const {users: userStore} = useUsers();
    const [coordFormats, setCoordinateFormats] = useState([]);
    const [data, setData] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);
    const [timezones, setTimezones] = useState([]);

    const users = useMemo(() => {
        return getSnapshot(userStore.users).map((user) => {
            const obj = Object.assign({}, user);
            const customer = customerStore.list.find((customer) => customer.id === user.customerId);
            if (customer) {
                obj.customerName = customer.name;
            }
            return obj;
        });
    }, [customerStore.list, userStore.list]);

    useEffect(() => {
        (async () => {
            const data = [];
            allTimezonesStore.timezones.map((timezone) => {
                data.push({id: timezone.key, title: timezone.key});
            });
            setTimezones(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const items = [];
            coordinateFormats.formats.map((type) => {
                items.push({id: type.key, title: type.name});
            });
            setCoordinateFormats(items);
        })();
    }, []);

    const userForm = async (data) => {
        const user = Object.keys(data).length ? data : {};
        setData(user);
        setView('userForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await userService.save(data);

            userStore.setUsers([...users, result]);
            setView('userList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'userList') => {
        try {
            showModalLoading(true);
            const index = users.findIndex(({id}) => id === data.id);
            const result = await userService.update({...users[index], ...data});
            const newUsers = [...getSnapshot(userStore.users)];
            newUsers.splice(index, 1, result);
            userStore.setUsers(newUsers);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await userService.remove(data.id);
            userStore.setUsers(users.filter(({id}) => id !== data.id));
            setView('userList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const showPermissionTabs = (data) => {
        setSelectedUser({userId: data.id});
        setView('userPermissions');
    };

    const getStates = () => {
        const data = [];
        const states = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

        states.map((state) => {
            data.push({id: state, title: state});
        });

        return data;
    };

    const formatCustomerList = () => customerStore.list.map((e) => {
        return {id: e.id, title: e.name};
    });

    return (
        <>
            {
                {
                    userList: (
                        <UserTable
                            list={users}
                            userForm={userForm}
                            removeRegister={removeRegister}
                            permissions={showPermissionTabs}
                            loading={isModalLoading}
                            intl={intl}
                        />
                    ),
                    userForm: (
                        <UserForm
                            cancel={() => setView('userList')}
                            data={Object.assign({}, data)}
                            session={session}
                            profile={auth.profile}
                            states={getStates()}
                            coordinateFormats={coordFormats}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            customers={formatCustomerList()}
                            loading={isModalLoading}
                            timezones={timezones}
                        />
                    ),
                    userPermissions: (
                        <PermissionTabs
                            options={[
                                'device',
                                'notification',
                                'geofence',
                                'group',
                                'calendar',
                                'driver',
                                'maintenance']}
                            data={selectedUser}
                            cancel={() => setView('userList')}/>
                    ),
                }[view]
            }
        </>
    );
};


export default observer(UserContainer);
