import styled, {createGlobalStyle} from 'styled-components';

export const AdminGlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // background: blue;    
  } 
  
  body {
    #root {
      padding-bottom: 0 !important;
    }
  }
`;

export const AdminStyle = styled.div`
  display: grid;
  grid-gap: 20px;
  min-height: 100%;
  grid-template-columns: min-content minmax(700px, 1fr) min-content;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "nav nav nav"
    "verticalNav children events";

  nav {
    grid-area: nav;
  }

  .navVertical {
    grid-area: verticalNav;
  }

  #children {
    grid-area: children;
    padding-bottom: 1rem;
  }

  #eventList {
    grid-area: events;
    padding-top: 1rem;
  }
`;
