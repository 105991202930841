import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListStyle = styled.div`
  position: absolute;
  bottom: 2rem;
  background: white;
  width: calc(100% - 3rem);
  border-radius: 12px;
  padding: 1rem;

  ${Header} {
    h5 {
      color: ${({theme}) => theme};
    }
  }

  // tbody {
  //   overflow-y: auto;
  //   max-height: 150px;
  // }

  // tr {
  //   display: flex;

  //   th,
  //   td {
  //     padding: 1.1rem 1.5rem;

  //     &:nth-child(1) {
  //       width: 40%;
  //     }

  //     &:nth-child(2) {
  //       width: 40%;
  //     }

  //     &:nth-child(3) {
  //       width: 20%;
  //     }
  //   }
  // }
`;
