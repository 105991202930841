import {inject, observer} from 'mobx-react';
import qs from 'qs';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {Container} from 'reactstrap';
import {from} from 'rxjs';
import {map as mapRx, mergeMap} from 'rxjs/operators';
import {MapContext} from '../../apiContexts';
import {dateToDate} from '../../helpers/date';
import {getRouteReport, getTripReport} from '../../services/reportService';
import TripList from '../../views/reports/trip-list';
import useServices from "../../hooks/use-services";

const TripReportContainer = (props) => {

    const {
        match,
        location,
        listOnly = false,
        mapRef,
    } = props;

    const [markers, setMarkers] = useState();
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const {driverService} = useServices();
    const {devices, server, auth} = props;
    const {timezone, distanceUnit, volumeUnit} = server.attributes;
    const search = useMemo(() => location.search, [location.search]);
    const {setData, setSelectedItem, setSelectedRow} = useContext(MapContext);
    const activeRef = useRef();

    const getReport = useCallback(async (params) => {
        setLoading(true);
        from(getTripReport(params))
            .pipe(
                mergeMap(
                    (_) => driverService.list(),
                    (trips, drivers) => {
                        drivers = drivers.reduce(
                            (acc, {id, name}) => ({...acc, [id]: name}),
                            {},
                        );

                        return trips.map((t) => ({
                            ...t,
                            driverUniqueId:
                                (drivers[t.driverUniqueId] ?
                                    `${t.driverUniqueId} (${drivers[t.driverUniqueId].name})` :
                                    t.driverUniqueId) || '-',
                        }));
                    },
                ),
                mapRx((data) => data.map((d, index) => ({id: index + 1, ...d}))),
            )
            .subscribe((data) => {
                setTrips(data);
                setLoading(false);
            });
    }, []);

    const onSelect = useCallback(
        async ({index, data: trip}) => {
            if (activeRef && activeRef.current === index) return;

            const item = trips[index];
            let itemActive = null;

            if (activeRef && activeRef.current >= 0) {
                itemActive = trips[activeRef.current];
            }

            const items = trips.concat();

            items[index] = {...item, isActive: true};
            itemActive &&
            (items[activeRef.current] = {...itemActive, isActive: false});

            activeRef.current = index;

            if (trip) {
                const {startTime, endTime, deviceId} = trip;
                const {from, to} = dateToDate(startTime, endTime);

                const positions = await getRouteReport(
                    qs.stringify({deviceId, from, to}, {indices: false}),
                );

                setData({
                    id: trip.id,
                    type: 'trip',
                    data: positions,
                    markers: markers === 'true',
                });
            }

            setSelectedRow({id: item.id});
            setSelectedItem(item);
            setTrips(items);
        },
        [trips],
    );

    useEffect(() => {
        if (search && devices.list.length && location.hash === '#trip') {
            const {markers, ...params} = qs.parse(search, {
                ignoreQueryPrefix: true,
                comma: true,
            });

            setMarkers(markers);

            getReport(qs.stringify(params, {indices: false}));
        }
    }, [search, devices.list]);

    return (
        <Container fluid className="px-4 position-relative h-100">
            <TripList
                data={trips}
                speedUnit={server.attributes && server.attributes.speedUnit}
                loading={loading}
                {...{timezone, onSelect, distanceUnit, volumeUnit}}
            />
        </Container>
    );
};

const mapping = ({store}) => ({
    auth: store.auth,
    session: store.session,
    map: store.map,
    server: store.server,
    devices: store.devices,
});

export default inject(mapping)(observer(TripReportContainer));
