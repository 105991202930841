import BaseService from './base-service';

/**
 * Customer Service
 */
export default class CompanyConfigurationService extends BaseService {
    /**
     * Default constructor from superclass.
     */
    constructor() {
        super('company/configurations');
    }

    get(label) {
        return this.__get(`company/configurations/${label}`);
    }

    create(data) {
        return super.__post('company/configurations', data);
    }

    update({label, ...data}) {
        return super.__put(`company/configurations/${label}`, data);
    }

    upload(data) {
        return super.__post('company/configurations/upload', data);
    }
}
