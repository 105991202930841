import moment from 'moment';


export const getColor = (sinister) => {
    const endTime = moment().utc();
    const startTime = moment(sinister.openingDate);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = Math.floor(duration.asHours());

    if (sinister.status !== 'opened') {
        if (sinister.closingType === 'vehicleNotFound') {
            return 'red';
        }
        return 'green';
    }
    if (sinister.id === 0) {
        return 'white';
    }

    if (hours <= 24) {
        return 'orange';
    }
    if (hours <= 48) {
        return 'purple';
    }
    if (hours <= 72) {
        return 'blue';
    }

    return 'gray';
};

export const getSinisterTypeI18N = (type) => {
    return `sinisterType${String(type)
        .charAt(0)
        .toUpperCase()}${String(type).slice(1)}`;
};


export const ellipseText = (text, max = 60) => {
   return text.length > max ? `${text.substring(0, max)}...`: text;
};
