import {types} from 'mobx-state-tree';

const CommandModel = types
    .model('CommandModel', {
      id: types.identifierNumber,
      attributes: types.maybe(types.frozen()),
      deviceId: types.maybe(types.integer),
      type: types.maybe(types.string),
      textChannel: types.maybe(types.boolean),
      description: types.maybe(types.string),
    })
    .actions((self) => ({
        setId: (id) => {
            self.id = id;
        },
        setAttributes: (attributes) => {
            self.attributes = attributes;
        },
        setDeviceId: (deviceId) => {
            self.deviceId = deviceId;
        },
        setType: (type) => {
            self.type = type;
        },
        setTextChannel: (textChannel) => {
            self.textChannel = textChannel;
        },
        setDescription: (description) => {
            self.description = description;
        },
    }));

export default CommandModel;
