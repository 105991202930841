import React, {useContext, useEffect, useState} from 'react';
import {Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import styled from 'styled-components';
import RoundButton from '../roundButton';
import {ThemeContext} from '../../apiContexts';
import I18nMessage from '../I18nMessage';
import moment from 'moment';
import {observer} from 'mobx-react';
import useStore from '../../hooks/use-store';
import {mergeAll, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import momentTimezone from 'moment-timezone';
import useTimezone from '../../hooks/use-timezone';
import useSocket from '../../hooks/use-socket';
import useSound from 'use-sound';
import beep from '../../views/event/voice_on.mp3';

const colors = {
    green: '#00b105',
    red: '#f2392d',
};


const DropdownToggleStyled = styled(DropdownToggle)`
    height: 3rem;
    position: relative;
    border: 0;
    padding: 0 0 0 0;
    margin: 0;
    background: transparent;
      
    :hover {
        color: #fff;
        background-color: transparent !important;
        border-color: transparent; !important
     }
     
     
`;

const AlertButton = styled(RoundButton)`
    position: relative;

    .badge {
      background-color: ${(props) => props.theme} !important;
      position: absolute;
      font-size: 0.8rem !important;
      bottom: -5px;
      top: auto;
      right: 0;
    }
  `;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 0;
  border: ${(props) => `1px solid ${colors.red}`};
  box-shadow: 0;
  margin-bottom: -1px !important;
  padding-right: 50px !important;
  

`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  span {
    color: ${colors.red};
    font-weight: bold;
    font-size: 0.8rem;
  }
  small {
  }
`;

const Span = styled.span`
  color: ${colors.red};
  font-size: 1.5rem;
`;

const Date = styled.span`
  color: ${colors.red};
  font-weight: 600;
  font-size: 0.8rem;
`;

const DropdownItemStyled = styled(DropdownItem)`
   
    padding: 0;
   
`;

const DropdownMenuStyled = styled(DropdownMenu)`
  padding: 0;
  width: 300px;
  left: 50px !important;
  z-index: -1;
  border-radius: 20px;
  
  button:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
 
`;

const TrashStyledButton = styled(Button)`
  height: auto;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: ${(props) => props.theme} !important;
  border: none;

  &.active {
    background-color: ${(props) => props.theme} !important;
    color: white !important;
  }

  :hover {
    color: white !important;
    background-color: ${(props) => props.theme} !important;

    & > span {
      color: white !important;
    }
  }

  > span {
    font-size: 1.5rem;
  }
`;


const getEventI18N = (type, attributes) => {
    return attributes.alarm ?
        `alarm${String(attributes.alarm)
            .charAt(0)
            .toUpperCase()}${String(attributes.alarm).slice(1)}` :
        attributes.return ?
            `return${String(attributes.return)
                .charAt(0)
                .toUpperCase()}${String(attributes.return).slice(1)}` :
            `event${String(type)
                .charAt(0)
                .toUpperCase()}${String(type).slice(1)}`;
};

const Item = ({id, deviceId, type, serverTime, name, attributes, onClick}) => {
    return (
        <DropdownItemStyled>
            <Card className={`mb-2 p-3`} style={{pointerEvents: 'all'}} onClick={() => onClick(deviceId, id)}>
                <Description>
                    <span>{name}</span>
                    <small><I18nMessage id={getEventI18N(type, attributes)}/></small>
                </Description>
                <Date className="ml-1">{moment(serverTime).fromNow()}</Date>
                <Span className="fas fa-map-marker-alt ml-2"/>
            </Card>
        </DropdownItemStyled>
    );
};

const EventButtonBox = (props) => {
    const [play] = useSound(beep);
    const {store} = useStore();
    const {events, devices} = store;
    const {events: eventsSocket} = useSocket();
    const {timezone} = useTimezone();
    const theme = useContext(ThemeContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleOnClick = (deviceId, eventId) => {
        props.setDeviceSelected(deviceId);
        events.remove(eventId);
    };

    useEffect(() => {
        eventsSocket
            .pipe(
                mergeAll(),
                mergeMap(
                    ({deviceId}) => of(devices.findById(deviceId)),
                    ({serverTime, ...e}, d) => ({
                        name: d ? d.name : '',
                        serverTime: momentTimezone(serverTime)
                            .tz(timezone)
                            .format(),
                        ...e,
                    }),
                ),
            )
            .subscribe((event) => {
                play();
                console.log('play');
                events.add(event);
            });
    }, []);


    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={props.className} direction="left">
            <DropdownToggleStyled>
                <AlertButton theme={theme}>
                    <span className="fal fa-bell"/>
                    <Badge color="danger" className="rounded-circle shadow">
                        {events.toList.length}
                    </Badge>
                </AlertButton>
            </DropdownToggleStyled>
            <DropdownMenuStyled
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: 600,
                                },
                            };
                        },
                    },
                }}>
                {events.toList.map((event, idx) => {
                    return <Item
                        key={idx}
                        {...event}
                        onClick={handleOnClick}
                        {...props}
                    />;
                })}
                {events.toList.length === 0 && <>
                    <DropdownItem>
                        <div
                            className={`d-flex flex-column justify-content-center align-items-center `}>
                            <span style={{color: colors.red}}><I18nMessage id={'sharedNoDataText'}/></span>
                        </div>
                    </DropdownItem>
                    <DropdownItem divider/></>
                }
                <DropdownItem tag={'div'}>
                    <div style={{pointerEvents: 'all'}}
                         className={`d-flex flex-column justify-content-end align-items-end padding-bottom: 10px`}>
                        <TrashStyledButton
                            outline
                            disabled={events.toList.length === 0}
                            className={``}
                            theme={theme}
                            withborder={0}
                            {...props}
                            onClick={() => events.removeAll()}>
                            <span className="fal fa-trash-alt"/>
                        </TrashStyledButton>
                    </div>
                </DropdownItem>
            </DropdownMenuStyled>
        </Dropdown>
    );
};

export default observer(EventButtonBox);
