import BaseService from './base-service';


/**
 *
 */
export default class SinisterService extends BaseService {
    constructor() {
        super('sinisters');
    }

    /**
     *
     * @return {Promise<*|undefined>}
     */
    fetch(all = false) {
        if (all) {
            return this.__get(`/sinisters?all=true`);
        }
        return this.__get(`/sinisters`);
    }

    /**
     * @param id
     * @return {Promise<*|undefined>}
     */
    create(data) {
        const  {
            deviceId,
            customerId,
            openingDate,
            openingType,
            openingAddress,
            openingDescription,
        } = data;
        return this.__post(`/sinisters/open`, {
            deviceId,
            customerId,
            openingDate,
            openingType,
            openingAddress,
            openingDescription,
        });
    }

    /**
     * @param id
     * @return {Promise<*|undefined>}
     */
    close(data) {
        const  {
            id,
            closingDate,
            closingType,
            vehicleDamaged,
            closingAddress,
            closingDescription,
        } = data;
        return this.__put(`/sinisters/${id}/close`, {
            id,
            closingDate,
            closingType,
            vehicleDamaged,
            closingAddress,
            closingDescription,
        });
    }

    /**
     * @param id
     * @return {Promise<*|undefined>}
     */
    occurrences(id) {
        return this.__get(`/sinisters/${id}occurrences`);
    }
x
    /**
     * @return {Promise<*|undefined>}
     * @param data
     */
    addOccurrences(data) {
        const {
            sinisterId,
            category,
            occurrenceDate,
            address,
            attributes,
            description,
        } = data;
        return this.__post(`/sinisters/${sinisterId}/occurrences`, {sinisterId,
            category,
            occurrenceDate,
            address,
            attributes,
            description});
    }
}
