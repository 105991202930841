import BaseService from "./base-service";

export default class PermissionService extends BaseService {

    constructor() {
        super('permissions');
    }

    addPermission(data){
        return this.__post(`permissions`, data);
    }

    removePermission(data){
        return this.__delete(`permissions`, data);
    }

    list(query) {
        throw new Error("method not implemented");
    }

    save(data) {
        throw new Error("method not implemented");
    }

    update(data) {
        throw new Error("method not implemented");
    }

    remove(id) {
        throw new Error("method not implemented");
    }

}
