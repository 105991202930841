import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../styleVariables';

const Style = styled.div`
  position: absolute;
  right: -2.3rem;
  height: 4.5rem;
  width: 4.5rem;
  min-width: 4.5rem;
  min-height: 4.5rem;
  max-width: 4.5rem;
  max-height: 4.5rem;

  .velocity {
    border: 3px solid ${colors.red1};
    height: 4.5rem;
    width: 4.5rem;
    min-width: 4.5rem;
    min-height: 4.5rem;
    max-width: 4.5rem;
    max-height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    position: absolute;
    line-height: 1.3rem;
    flex-direction: column;
    color: black !important;
    width: 100% !important;
    text-align: center !important;

    span {
      font-weight: bold;
      font-size: 1.35rem !important;
    }

    small {
      font-size: 0.8rem !important;
    }

    &.limit {
      right: 93px;
      bottom: 8px;
      height: 3.5rem;
      width: 3.5rem;
      min-width: 3.5rem;
      min-height: 3.5rem;
      max-width: 3.5rem;
      max-height: 3.5rem;
      z-index: 1;
      line-height: 1rem;
    }
    span {
      font-weight: bold;
      font-size: 1rem;
    }

    small {
      font-size: 0.7rem;
    }
  }
`;

const Velocity = ({speed, limit, unit}) => {
  return (
    <Style>
      {limit && (
        <div className="velocity limit rounded-circle shadow">
          <span>{Number(limit).toFixed(1)}</span>
          <small>{unit}</small>
        </div>
      )}

      <div className="velocity rounded-circle shadow">
        <span>{Number(speed).toFixed(1)}</span>
        <small>{unit}</small>
      </div>
    </Style>
  );
};

export default Velocity;
