import React, {useMemo, useRef, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {Tooltip} from 'reactstrap';
import styled from 'styled-components';
import uuid from 'uuid/v1';
import {colors} from '../../styleVariables';

const Progress = styled.div`
  background-color: ${(props) => props.color || 'blue'};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  width: 57px;
  height: ${(props) => props.value || 0}%;

  > div {
    :first-child {
      color: #666;
      margin-top: -21px;
    }
  }
`;

const BarItem = styled.div`
  border-bottom: 2px solid ${colors.gray2};

  > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  }

  ${Progress} {
    :not(:last-child) {
      margin-right: 4px;
    }
  }
`;

const ChartStyle = styled.div`
  display: flex;
`;

const Style = styled.div``;

const TooltipStyled = styled(Tooltip)`
  .tooltip-inner {
    background-color: ${colors.blue2} !important;
    color: ${'white'} !important;
    display: flex;
    flex-direction: column;
  }

  [x-placement="left"] {
    .arrow {
      &:before {
        border-left-color: ${colors.blue2} !important;
      }
    }
  }

  [x-placement="right"] {
    .arrow {
      &:before {
        border-right-color: ${colors.blue2} !important;
      }
    }
  }

  [x-placement="top"] {
    .arrow {
      &:before {
        border-top-color: ${colors.blue2} !important;
      }
    }
  }

  [x-placement="bottom"] {
    .arrow {
      &:before {
        border-bottom-color: ${colors.blue2} !important;
      }
    }
  }
`;

// VERTICALBAR

const VerticalBar = ({
  colors,
  data,
  labels,
  labelsName,
  height,
  onClick,
  ...props
}) => {
  const chartRef = useRef();

  const tooltipRef = useMemo(() => data.map((_) => '_' + uuid().split('-')[0]), [
    data,
  ]);

  const tooltips = useMemo(
      () =>
        data.map((d, idx) => (
          <TooltipItem key={idx} target={tooltipRef[idx]}>
            {d.map((v, i) => (
              <span key={`${i}_${idx}`}>
                {+v.toFixed(2)}% - <strong>{labelsName[i]}</strong>
              </span>
            ))}
          </TooltipItem>
        )),
      [data, tooltipRef,labelsName],
  );

  const barItems = useMemo(
      () =>
        data.map((d, i) => (
          <VerticalBarItem
            onClick={(idx) => onClick({index: i, status: labelsName[idx]})}
            id={tooltipRef[i]}
            key={i}
            colors={colors}
            data={d}
            label={labels[i]}
          />
        )),
      [data],
  );

  return (
    <Scrollbars
      style={{overflowY: 'hidden'}}
      autoHide={false}
      renderTrackVertical={(props) => (
        <div
          {...props}
          style={{display: 'none'}}
          className="track-vertical"
        />
      )}
    >
      <Style ref={chartRef}>
        {tooltips}
        <ChartStyle className="py-3 mb-4" style={{height: 200}}>
          {barItems}
        </ChartStyle>
        {/* <Legends>{legends}</Legends> */}
      </Style>
    </Scrollbars>
  );
};

// VERTICALBARITEM

const VerticalBarItem = ({colors, data, label, id, onClick = () => {}}) => {
  const progress = useMemo(
      () =>
        data.map((value, i) => (
          <Progress
            key={i}
            color={colors[i]}
            value={value}
            onClick={() => onClick(i)}
          >
            <div>{`${+(value || 0).toFixed(1)}%`}</div>
          </Progress>
        )),
      [data],
  );

  return (
    <BarItem className="px-3 flex-fill" id={id}>
      <div>{progress}</div>
      {label}
    </BarItem>
  );
};

// TOOLTIPITEM

const TooltipItem = ({children, target}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <TooltipStyled
      placement="auto"
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
    >
      {children}
    </TooltipStyled>
  );
};

VerticalBar.defaultProps = {
  data: [],
  height: '120px',
  onClick: () => {},
};

export default VerticalBar;
