import React, {useContext, useEffect, useState} from 'react';
import ServerForm from '../../views/server/server-form';
import i18n from '../../stores/translate-store';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from "../../hooks/use-services";
import useStore from "../../hooks/use-store";

const ServerContainer = (props) => {

    const intl = useIntl();
    const {toast} = useAlerts();
    const {store} = useStore();
    const {server, mapType, coordinateFormats: coordFormats} = store;
    const {serverService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('serverForm');
    const [currentServerConfig, setServerConfig] = useState({});
    const [coordinateFormats, setCoordinateFormats] = useState([]);
    const [mapTypes, setMapTypes] = useState([]);

    useEffect(() => {
        setServerConfig(server);
    }, []);

    useEffect(() => {
        (async () => {
            const items = [];
            coordFormats.formats.map((type) => {
                items.push({id: type.key, title: type.name});
            });
            setCoordinateFormats(items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const items = [];
            mapType.types.map((type) => {
                items.push({id: type.key, title: type.name});
            });
            setMapTypes(items);
        })();
    }, []);

    const updateRegister = async (data, afterTo = 'serverForm') => {
        try {
            showModalLoading(true);
            const result = await serverService.update({
                ...currentServerConfig,
                ...data,
            });
            setServerConfig(result);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    return (
        <>
            {
                {
                    serverForm: (
                        <ServerForm
                            data={Object.assign({}, currentServerConfig)}
                            mapTypes={mapTypes}
                            coordinateFormats={coordinateFormats}
                            strings={i18n.strings}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                        />
                    ),
                }[view]
            }
        </>
    );
};

export default ServerContainer;
