import React from 'react';
import DataTable from '../../components/dataTable';
import Button from "../../components/button";
import I18nMessage from '../../components/I18nMessage';

const CustomerTable = (props) => {
    const {
        customerForm,
        removeRegister,
        attributes,
        list,
        permissions,
        loading,
        showDeviceList
    } = props;

    return (
        <DataTable
            add={customerForm}
            edit={customerForm}
            remove={removeRegister}
            list={list}
            loading={loading}
            attributes={attributes}
            permissions={permissions}
            keyField={'id'}
            confirmText={`Você está removendo o cliente <strong><field>name</field></strong>! </br>Realmente deseja fazer isso? `}
            headers={[
                {title: 'sharedName', field: 'name', dataSort: true},
                {title: 'customerNotificationEmail', field: 'notificationEmail', dataSort: true},
                {title: 'customerPhone', field: 'phone', dataSort: true},
                {title: 'customerStatus', field: 'status', dataSort: true},
            ]}
            excelConfig={{
                fileName: 'sharedCustomers',
                columns: [
                    {label: 'customerCpfCnpj', field: 'cpfCnpj'},
                    {label: 'customerType', field: 'type'},
                    {label: 'customerName', field: 'name'},
                    {label: 'customerStreet', field: 'street'},
                    {label: 'customerNumber', field: 'number'},
                    {label: 'customerComplement', field: 'complement'},
                    {label: 'customerNeighborhood', field: 'neighborhood'},
                    {label: 'customerCity', field: 'city'},
                    {label: 'customerState', field: 'state'},
                    {label: 'customerPostalCode', field: 'postalCode'},
                    {label: 'customerPhone', field: 'phone'},
                    {label: 'customerCellphone', field: 'cellphone'},
                    {label: 'customerNotificationEmail', field: 'notificationEmail'},
                    {label: 'customerSecondaryEmail', field: 'secondaryEmail'},
                    {label: 'customerCategory', field: 'category'},
                    {label: 'customerStatus', field: 'status'},
                    {label: 'customerNotes', field: 'notes'},
                    // {label: 'customerDisabled', field: (col) => col.disabled ? intl.formatMessage({id: 'sharedYes'}) : intl.formatMessage({id: 'sharedNo'})},
                    // {label: 'customerRegistrationDate', field: (col) => formatDateTimeColumn(col, timezone)},
                    // {label: 'customerExpirationDate', field: (col) => formatDateTimeColumn(col, timezone)},
                    {label: 'customerInvoiceDay', field: 'invoiceDay'},
                    {label: 'customerAttributes', field: 'attributes'},
                ],
            }}
            extraHeaderEl={ (row, disabled) => <Button
                className={'outline'}
                disabled={disabled}
                color={'theme'}
                size={'sm'}
                onClick={() => showDeviceList(row)}
            >
                <i className="fas fa-car"/> <I18nMessage id='deviceTitle'/>
            </Button>}
        />);
};

export default CustomerTable;
