import React, {useEffect, useRef, useState} from 'react';
import {useField} from '@unform/core';
import {SketchPicker} from 'react-color';
import './styles.scss';


const ReactColor = ({name, type, onChangeComplete, ...rest}) => {
    const {fieldName, defaultValue, registerField, error} = useField(name);
    const [color, setColor] = useState();
    const detailsRef = useRef(null);
    const summaryRef = useRef(null);
    const colorPickerRef = useRef(null);

    const colorRef = useRef(null);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: colorRef,
            getValue: (ref) => {
                if (color) {
                    return ref.current.state[type];
                }
                return '';
            },
        });
    }, [fieldName, registerField, color]);

    useEffect(() => {
        if (defaultValue) {
            setColor(defaultValue);
        }
    }, [defaultValue]);

    const onChangeCompleteHandler = (evt) => {
        setColor(evt[type]);

        if (onChangeComplete) {
            onChangeComplete(evt);
        }
    };

    const handleClickOutsideDetails = (evt) => {
        if (colorPickerRef.current && !colorPickerRef.current.contains(evt.target)) {
            detailsRef.current.open = false;
        }
    };

    const onDetailsClick = (evt) => {
        if (evt.currentTarget.open && evt.target === summaryRef.current) {
            evt.preventDefault();
            evt.currentTarget.open = false;
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDetails);
        return () => {
            document.removeEventListener('click', handleClickOutsideDetails);
        };
    }, []);

    return (
        <>
            <div className='react-color-picker'>
                <details ref={detailsRef} onClick={onDetailsClick}>
                    <summary ref={summaryRef} style={{
                        backgroundColor: color,
                    }}>
                        {color ? color : `Selecione uma cor`}
                    </summary>
                    <div ref={colorPickerRef} className='sketch-picker-wrapper'>
                        <SketchPicker
                            ref={colorRef}
                            color={color}
                            onChangeComplete={onChangeCompleteHandler}
                            {...rest}
                        />
                    </div>
                </details>
            </div>
            {error && <span className="error">{error}</span>}
        </>
    );
};

ReactColor.defaultProps = {
    type: 'hex',
};

export {
    ReactColor,
};
