import {types} from 'mobx-state-tree';

const PositionModel = types.model('Position', {
  deviceId: types.number,
  accuracy: types.number,
  address: types.maybeNull(types.string),
  altitude: types.number,
  attributes: types.frozen({}),
  course: types.number,
  deviceTime: types.string,
  fixTime: types.string,
  id: types.number,
  latitude: types.number,
  longitude: types.number,
  network: types.frozen({}),
  outdated: types.boolean,
  protocol: types.string,
  serverTime: types.string,
  speed: types.number,
  type: types.maybeNull(types.string),
  valid: types.boolean,
});

export default PositionModel;
