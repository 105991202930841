import React, {useRef} from 'react';
import * as Yup from 'yup';
import {Form} from '@unform/web';
import {TextArea} from '../../../components/textarea';
import './style.sass';
import {FormGroup, Label} from 'reactstrap';
import I18nMessage from '../../../components/I18nMessage';
import {GroupButtonForm} from '../../../components/group-button-form';
import styled from 'styled-components';

const schema = Yup.object().shape({
    description: Yup.string().required('Tratamento de evento deve ser preenchido'),
});

const LabelStyled = styled.h2`
  color: ${(props) => props.theme};
  font-size: 1.2rem;
`;

const EventFormHandler = (props) => {
    const {
        submit,
        theme
    } = props;

    const loading = false;
    const cancel = () => console.log('cancel...');
    const formRef = useRef(null);

    const handleSubmit = async (data, {reset}) => {
        try {
            await schema.validate(data, {
                strict: true,
                abortEarly: false,
                stripUnknown: true,
            });

            submit(data.description);
            reset();
        } catch (err) {
            console.log(err);
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    console.log(error.path);
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    return (
        <div className={'event-form-handler-container'}>
            <Form ref={formRef} onSubmit={handleSubmit} schema={schema}>
                <FormGroup className="custom-formGroup">
                    <LabelStyled theme={theme}><I18nMessage id="sharedComment"/>*</LabelStyled>
                    <TextArea
                        name={'description'}
                        className={'textarea-input'}
                    />
                </FormGroup>

                <GroupButtonForm
                    loading={loading}
                    submit={() => formRef.current.submitForm()}
                />
            </Form>
        </div>
    );
};

export default EventFormHandler;
