import {types} from 'mobx-state-tree';
import CoordinateFormatModel from '../models/map-type-model';
import i18n from './translate-store';

const CoordinateFormatStore = types

    .model('CoordinateFormatStore', {
      formats: types.optional(types.array(CoordinateFormatModel), []),
    })
    .actions((self) => ({
      afterCreate() {
        self.setFormats([{
          key: 'dd',
          name: i18n.strings.sharedDecimalDegrees,
        }, {
          key: 'ddm',
          name: i18n.strings.sharedDegreesDecimalMinutes,
        }, {
          key: 'dms',
          name: i18n.strings.sharedDegreesMinutesSeconds,
        }]);
      },
      setFormats( data ) {
        self.formats = data;
      },
    }));

export default CoordinateFormatStore;
