import moment from 'moment';
import React from 'react';
import {filter, map, mapTo, mergeAll, reduce, tap, toArray} from 'rxjs/operators';
import {getDeviceIcon} from './deviceIcons';

export const communicationTime = [1800, 14400, 86400, 86401];

export const communicationLabel = [
  'Até 30 min',
  'Até 4h',
  'Até 24h',
  'Mais que 24h',
  'Nunca',
];

export const sinisterDurationLabel = [
    'Até 24h',
    'Até 48h',
    'Até 72h',
    '+ de 72h',
];

export const statusLabel = ['Online', 'Offline', 'Desconhecido'];

export const activeLabel = ['Ativo', 'Inativo'];

export const getCurrentCategories = (devices) => {
  const categories = new Set();
  devices.forEach(({category}) => categories.add(category || 'default'));
  return [...categories];
};

export const getGroupByCategoryStatus = (source$, category, status) => {
  return source$.pipe(
    filter(
      (d) =>
        d.status.toLowerCase() == status.toLowerCase() && d.category == category,
    ),
    toArray(),
  );
};

export const createLabelIcon = (
  category,
  width = 40,
  color = 'grey',
  position = 'right',
) => <img width={width} src={getDeviceIcon(category, position, color)} />;

export const calculeDateGroup = (data) => {
  const length = data.reduce((acc, cur) => (acc += cur.length), 0);
  data = data.map((d) => +((d.length / length || 0) * 100).toFixed(2));
  return data;
};

export const getDateGroup = (source$, groups) => {
  const now = moment().utc();

  // groups = groups.sort((a, b) => a - b);
  const lastGroup = groups[groups.length - 1];

  const group = new Map(['never', ...groups].map((g) => [g, []]));

  return source$.pipe(
    tap((device) => {
      const lastUpdate = device.lastUpdate;
      const active = !device.disabled;

      if (active) {
        if (!lastUpdate) {
          group.get('never').push(device);
          return;
        }

        const diff = Math.abs(now.diff(moment(lastUpdate).utc(), 'seconds'));
        const g = groups.find((g) => !(diff > g));

        if (!g) {
          group.get(lastGroup).push(device);
          return;
        }

        group.get(g).push(device);
      }
    }),
    toArray(),
    mapTo(
      [...group.entries()]
        .reverse()
        .reduce((acc, [k, v]) => ({[k]: v, ...acc}), {}),
    ),
  );
};

export const getSinisterGroup = (source$) => {
    const group = new Map([
        ['Até 24h', []],
        ['Até 48h', []],
        ['Até 72h', []],
        ['+ de 72h', []],
    ]);
    return source$.pipe(
        tap((sinister) => {
            const endTime = moment().utc();
            const startTime = moment(sinister.openingDate);
            const duration = moment.duration(endTime.diff(startTime));
            const hours = Math.floor(duration.asHours());

            if (hours <= 24) {
                group.get('Até 24h').push(sinister);
            } else if (hours <= 48) {
                group.get('Até 48h').push(sinister);
            } else if (hours <= 72) {
                group.get('Até 72h').push(sinister);
            } else {
                group.get('+ de 72h').push(sinister);
            }
        }),
        toArray(),
        mapTo(
            [...group.entries()]
                .reverse()
                .reduce((acc, [k, v]) => ({[k]: v, ...acc}), {}),
        ),
    );
};

export const getStatusGroup = (source$) => {
  const group = new Map([
    ['online', []],
    ['offline', []],
    ['unknown', []],
  ]);

  return source$.pipe(
    tap((device) => {
      const status = device.status;
      const active = !device.disabled;
      if (active) {
        if (!['online', 'offline'].includes(status)) {
          group.get('unknown').push(device);
          return;
        }
        group.get(status).push(device);
      }
    }),
    toArray(),
    mapTo(
      [...group.entries()]
        .reverse()
        .reduce((acc, [k, v]) => ({[k]: v, ...acc}), {}),
    ),
  );
};


export const getTypeGroupPercent = (source$) => {
  let length = 0;

  return source$.pipe(
    tap((_) => length++),
    reduce((acc, {category = 'default', status}) => {
      if (!acc[category]) {
        acc[category] = {online: 0, offline: 0, unknown: 0, total: 0};
      }
      acc[category][status || 'unknown']++;
      acc[category].total++;
      return acc;
    }, {}),
    map((data) => Object.entries(data)),
    mergeAll(),
    map(([k, {total, ...rest}]) => ({
      name: k,
      ...Object.entries(rest).reduce(
        (acc, [k, v]) => ({...acc, [k]: (v / total) * 100}),
        {},
      ),
    })),
    toArray(),
  );
};

export const getDeviceActiveGroup = (source$) => {
  const group = new Map([
    ['ativo', []],
    ['inativo', []],
  ]);

  return source$.pipe(
    tap((device) => {
      const active = !device.disabled;
      if (active) {
        group.get('ativo').push(device);
        return;
      } else {
        group.get('inativo').push(device);
      }
    }),
    toArray(),
    mapTo(
      [...group.entries()]
        .reverse()
        .reduce((acc, [k, v]) => ({[k]: v, ...acc}), {}),
    ),
  );
};

export const getUsersGroup = (source$) => {
  const group = new Map([
    ['ativo', []],
    ['inativo', []],
  ]);

  return source$.pipe(
    tap((user) => {
      const active = !user.disabled;
      if (active) {
        group.get('ativo').push(user);
        return;
      } else {
        group.get('inativo').push(user);
      }
    }),
    toArray(),
    mapTo(
      [...group.entries()]
        .reverse()
        .reduce((acc, [k, v]) => ({[k]: v, ...acc}), {}),
    ),
  );
};
