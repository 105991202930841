import axios from "axios";
import {isAuthenticated} from "../helpers/auth";
import config from "../config";

const {API_URL} = config;
const singleton = Symbol();
const singletonEnforcer = Symbol();

export default class HttpInstance {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error('Cannot construct singleton');
        }

        let request = axios.create({
            baseURL: API_URL,
            validateStatus: (status) => status >= 200 && status < 400,
        });
        request.interceptors.request.use(
            (config) => {
                const token = isAuthenticated();
                if (token) config.headers.Authorization = token;
                return config;
            },
            (error) => Promise.reject(error),
        );
        request.interceptors.response.use(
            (response) => {
                if (response.data.hasOwnProperty('errorcode')) {
                    return Promise.reject(response.data);
                }
                return response;
            },
            (error) => Promise.reject(error),
        );

        this.request = request;
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new HttpInstance(singletonEnforcer);
        }

        return this[singleton].request;
    }
}
