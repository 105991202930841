import React from 'react';
import {Button} from 'reactstrap';

const Navigation = ({type, ...props}) => {
  return (
    <Button
      className={`${type === 'next' ? 'next' : 'prev'} navigation`}
      {...props}
    >
      <span
        className={`${
          type === 'next' ? 'far fa-chevron-right' : 'far fa-chevron-left'
        }`}
      />
    </Button>
  );
};

export default Navigation;
