import {types} from 'mobx-state-tree';

const KnownNotificationModel = types

    .model('KnownNotificationModel', {
      type: types.string,
    })
    .views( (self) => {
      return {
        get name() {
          return 'event' + self.type.charAt(0).toUpperCase() + self.type.slice(1);
        },
      };
    });

export default KnownNotificationModel;
