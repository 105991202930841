import React, {useContext, useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {MapContext} from '../../apiContexts';
import DeviceList from '../../views/fleet-devices-container/device-list';
import DeviceListExpanded from '../../views/fleet-devices-container/device-list-expanded';
import '../../views/fleet-devices-container/fleet-devices-container.sass';
import Navigation from '../../views/fleet-devices-container/navigation';

const FleetDeviceListContainer = (props) => {
  const {
    setSelected,
    selectedDevice,
    devices,
    theme,
    updatedPositions
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState();
  const mapInstance = useContext(MapContext).map;

  useEffect(() => {
    if (devices.list.length) {
      (async () => {
        setPages(Math.ceil(devices.list.length / 4));
        setCurrentPage(0);
        setItems(devices.list);
      })();
    }
  }, [devices.list.length, updatedPositions]);

  useEffect(() => {
    if (selectedDevice) {
      (async () => {
        const {currentPosition} = selectedDevice;

        let longitude = 0;
        let latitude = 0;

        if (currentPosition) {
          longitude = currentPosition.longitude;
          latitude = currentPosition.latitude;
        }

        mapInstance.goTo([longitude, latitude], 18);
      })();
    }
  }, [selectedDevice]);

  const changePage = (page) => {
    if (page >= 0 && page < pages) {
      setCurrentPage(page);
    }
  };

  const getItems = () => {
    if (isOpen) {
      return items;
    } else {
      return items.concat().splice(currentPage * 4, 4);
    }
  };

  return (
    <div id="fleetDeviceListContainer" className="d-flex flex-column">
      <div id="strap">
        <Button
          color="light"
          size="sm"
          onClick={() => setIsOpen(!isOpen)}
          style={{color: theme}}
        >
          <strong>{isOpen ? 'Recolher ' : 'Ver todos '} os dispositivos</strong>{' '}
          ({devices.list.length})
        </Button>
      </div>
      <div className="d-flex">
        {!isOpen && (
          <Navigation
            style={{backgroundColor: theme, borderColor: theme}}
            type="prev"
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 0}
          />
        )}
        {!isOpen ? (
          <DeviceList
            list={getItems()}
            selected={selectedDevice}
            onSelect={(id) => setSelected(id)}
            currentPage={currentPage + 1}
            pages={pages}
          />
        ) : (
          <DeviceListExpanded
            list={getItems()}
            selected={selectedDevice}
            onSelect={(id) => setSelected(id)}
          />
        )}
        {!isOpen && (
          <Navigation
            style={{backgroundColor: theme, borderColor: theme}}
            type="next"
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === pages - 1}
          />
        )}
      </div>
    </div>
  );
};

export default FleetDeviceListContainer;
