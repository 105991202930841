import React, {useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import DeviceContainer from '../device-container';
import styled from 'styled-components';
import RoundButton from '../../components/roundButton';
import I18nMessage from '../../components/I18nMessage';
import AccountContainer from '../account-container';
import UserContainer from '../user-container';
import GroupContainer from '../group-container';
import GeofenceContainer from '../geofence-container';
import DriverContainer from '../driver-container';
import NotificationContainer from '../notification-container';
import ServerContainer from '../server-container';
import CalendarContainer from '../calendar-container';
import MaintenanceContainer from '../maintenance-container';
import CustomerContainer from '../customer-container';
import {ThemeContext} from '../../apiContexts';
import SinisterContainer from '../sinister-container';

const Content = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 12px;
  padding: 20px 40px 20px 40px;
  
  .react-bs-table-pagination {
    text-align: left;
  }
  
  .form-title {
    padding: 15px;
  }
  
  .rounded-group-button-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .rounded-group-button-form-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .custom-formGroup{
    text-align: left;
  }
`;

const H2Styled = styled.h2`
  color: ${(props) => props.theme};
`;

const AdminFormContainer = () => {
    const {view} = useParams();
    const theme = useContext(ThemeContext);

    const getFormName = () => {
        switch ( view ) {
            case 'device': return 'sharedDevice';
            case 'account': return 'settingsUser';
            case 'users': return 'settingsUsers';
            case 'groups': return 'groupDialog';
            case 'geofences': return 'sharedGeofence';
            case 'sinister': return 'sharedSinister';
            case 'drivers': return 'sharedDriver';
            case 'notifications': return 'sharedNotification';
            case 'calendars': return 'sharedCalendar';
            case 'server': return 'settingsServer';
            case 'maintenance': return 'sharedMaintenance';
            case 'customers': return 'sharedCustomers';
        }
    };

    return (
        <Content className="custom-shadow-2">
            <div id={'container'}>
                <div className={'float-left'}>
                    <H2Styled theme={theme} className={'form-title'}>
                        <I18nMessage id={getFormName()}/>
                    </H2Styled>
                </div>
                <RoundButton className="mt-3 mb-3 float-right" tag={Link} to={'/admin'}>
                    <span className="fas fa-times text-secondary" />
                </RoundButton>
                <div className={'clearfix'}>&nbsp;</div>
            </div>
            {
                {
                    device: (
                        <DeviceContainer />
                    ),
                    account: (
                        <AccountContainer />
                    ),
                    users: (
                        <UserContainer />
                    ),
                    groups: (
                        <GroupContainer />
                    ),
                    geofences: (
                        <GeofenceContainer />
                    ),
                    sinister: (
                        <SinisterContainer />
                    ),
                    drivers: (
                        <DriverContainer />
                    ),
                    notifications: (
                        <NotificationContainer />
                    ),
                    calendars: (
                        <CalendarContainer />
                    ),
                    maintenance: (
                        <MaintenanceContainer />
                    ),
                    server: (
                        <ServerContainer />
                    ),
                    customers: (
                        <CustomerContainer />
                    ),
                }[view]
            }
        </Content>
    );
};

export default AdminFormContainer;
