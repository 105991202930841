import history from '../routes/history';

export const isAuthenticated = () => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');

  if (token) return token;
  return false;
};

export const getSession = () => {
  return localStorage.getItem('token') ? localStorage : sessionStorage;
};

export const goMainRoute = (profile) => {
  let to = '';
  switch (profile) {
    case 'client':
      to = '/client';
      break;
    case 'fleet':
      to = '/fleet';
      break;
    case 'monitor':
      to = '/admin';
      break;
    case 'admin':
      to = '/admin';
      break;
    default:
      to = '/login';
  }
  history.push(to);
};

export const getStorageSnapshot = () => {
  const session =
    localStorage.getItem('snapshot') || sessionStorage.getItem('snapshot');
  return JSON.parse(session);
};

export const getProfile = ({ attributes, deviceLimit, administrator, devicesLength}) => {

  if (attributes.hasOwnProperty('profile') && attributes.profile === 'central') return 'monitor';
  if (administrator) return 'admin';
  if (deviceLimit !== 0) return 'monitor';
  if (devicesLength > 5) return 'fleet';

  return 'client';
};

const symbols =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateToken = () => {
  let i;
  let newToken = '';

  for (i = 0; i < 32; i++) {
    newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
  }

  return newToken;
};

// export function authHeader() {
//   const user = isAuthenticated();

//   if (user && user.token) return `Bearer ${user.token}`;
//   return {};
// }
