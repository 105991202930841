import axios from 'axios';
import config from '../../config';
import {isAuthenticated} from '../../helpers/auth';

const {API_URL} = config;

const instance = axios.create({
  baseURL: API_URL,
  validateStatus: (status) => status >= 200 && status < 400,
});

// instance.defaults.headers.common["Authorization"] = authHeader();

/**
 * REQUEST
 */
const request = async (config) => {
  try {
    const response = await instance(config);
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

/**
 * REQUEST INTERCEPTOR
 */
instance.interceptors.request.use(
    (config) => {
      const token = isAuthenticated();
      if (token && !config.noAuth) config.headers.Authorization = token;
      return config;
    },
    (error) => Promise.reject(error),
);

/**
 * RESPONSE INTERCEPTOR
 */
instance.interceptors.response.use(
    (response) => {
      if (response.data.hasOwnProperty('errorcode')) {
        return Promise.reject(response.data);
      }
      return response;
    },
    (error) => Promise.reject(error),
);

export default request;
