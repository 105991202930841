import {types} from 'mobx-state-tree';

const KnownAttributeModel = types

    .model('KnownAttributeModel', {
        key: types.maybe(types.string),
        name: types.maybe(types.string),
        valueType: types.maybe(types.string),
        dataType: types.maybe(types.string),
    })
    .views((self) => ({

        getAttributeName(key, capitalize) {
            if (self.key === key) {
                return self.name;
            } else if (capitalize) {
                return self.key.replace(/^./, function (match) {
                    return match.toUpperCase();
                });
            } else {
                return key;
            }
        },

        getAttributeDataType(key) {
            if (self.key === key && self.dataType) {
                return self.dataType;
            } else {
                return null;
            }
        }
    }));

export default KnownAttributeModel;
