export const colors = {
  gray: '#707070',
  gray1: '#b8b8b8',
  gray2: '#abaaac',
  gray3: '#404040',
  gray4: '#666666',
  gray5: '#f1f1f1',
  gray6: '#f7f7f7',
  gray7: '#f4f4f4',
  blue: '#2497e9',
  blue2: '#2e3192',
  blue3: '#0071BC',
  blue4: 'rgba(0, 113, 188, 0.5)',
  yellow: '#FFF433',
  yellow1: '#FFD800',
  green: '#389636',
  green1: '#00aa88',
  green2: '#00b923',
  green3: '#41eb61',
  green4: '#009018',
  red: '#CA0830',
  red1: '#ea242e',
  orange: '#FB7F2B',
  orange1: '#ff9900',
  black: '#2a2a2a',
  light: '#ffffff',
};

export const shadows = {
  // shadow: "0 0.125rem 0.3rem rgba(0, 0, 0, 0.4)",
  shadow: '0 0.125rem 0.3rem rgba(0, 0, 0, 0.2)',
  shadow2: '0 -0.09rem 0.3rem rgba(0, 0, 0, 0.4)',
  shadow3: '0 0.125rem 1rem rgba(0,0,0,0.1)',
};
