import memoize from 'memoize-one';
import React, {memo, useContext, useEffect, useRef} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {useAbsoluteLayout, useTable} from 'react-table';
import {areEqual, FixedSizeList} from 'react-window';
import {MapContext, ThemeContext} from '../../apiContexts';
import {ResultLength, Styles} from './style';

const createItemData = memoize((items, selectItem) => ({
  items,
  selectItem,
}));

const ReactTable = ({
  data,
  columns,
  selectItem,
  listHeight,
  innerRef,
  ...props
}) => {
  const {getTableProps, headerGroups, rows, prepareRow} = useTable(
      {columns, data},
      useAbsoluteLayout,
  );

  const listRef = useRef();
  const scrollRef = useRef();
  const headerRef = useRef();

  const theme = useContext(ThemeContext);

  const {selectedRow} = useContext(MapContext);

  const itemData = createItemData(rows, selectItem);

  const RenderRow = memo(({data, index, style}) => {
    const {items, selectItem} = data;
    const row = items[index];
    prepareRow(row);
    const {original} = row;

    return (
      <div
        {...row.getRowProps({style})}
        className={`tr ${original.isActive ? 'active' : ''}`}>
        {row.cells.map((cell) => {
          return (
            <div
              {...cell.getCellProps()}
              className="td"
              onClick={() => selectItem({index, data: original})}>
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    );
  }, areEqual);

  useEffect(() => {
    if (selectedRow && selectedRow.scroll) {
      const index = data.findIndex((e) => e.id == selectedRow.id);
      selectItem({index});
      scrollRef.current.scrollTop(index * 45);
    }
  }, [selectedRow]);

  const handleScroll = ({target}) => {
    const {scrollTop, scrollLeft} = target;
    listRef.current.scrollTo(scrollTop);
    headerRef.current.scrollTo({left: scrollLeft});
  };
  return (
    <Styles ref={innerRef} {...getTableProps()} className="table" theme={theme}>
      <div ref={headerRef} className="header">
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map((column) => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>
      <Scrollbars
        ref={scrollRef}
        autoHeight={true}
        autoHide={false}
        autoHeightMax={listHeight ? listHeight - 80 || 0 : 150}
        onScroll={handleScroll}>
        <FixedSizeList
          className="body"
          height={listHeight ? listHeight - 80 || 150 : 150}
          itemCount={data.length}
          itemData={itemData}
          itemSize={45}
          ref={listRef}
          style={{overflow: false}}
          width={'100%'}>
          {RenderRow}
        </FixedSizeList>
      </Scrollbars>
      <ResultLength>{data.length} Resultados</ResultLength>
    </Styles>
  );
};

export default ReactTable;
