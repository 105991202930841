import React, {useContext, useEffect, useState, useMemo} from 'react';
import {useIntl} from 'react-intl';
import DeviceTable from '../../views/device/device-table';
import PermissionTabs from '../../views/permission';
import useStore from '../../hooks/use-store';
import useTimezone from '../../hooks/use-timezone';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from '../../hooks/use-services';
import DeviceAdminForm from '../../views/device/device-admin-form';
import {getSnapshot} from 'mobx-state-tree';
import {observer} from 'mobx-react';
import AccumulatorForm from '../../views/device/accumulator-form';
import useCustomers from '../../hooks/use-customers';

const permissions = ['notification', 'driver', 'geofence', 'maintenance', 'attribute'];

const DeviceContainer = () => {
    const {store} = useStore();
    const {timezone} = useTimezone();
    const {toast} = useAlerts();
    const intl = useIntl();
    const {customers: customerStore} = useCustomers();
    const {deviceService, groupService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const {
        auth,
        devices: deviceStore,
        equipment: equipmentStore,
        categories: categoriesStore,
        session,
    } = store;

    const [view, setView] = useState('deviceList');
    const [selectDevices, setSelectDevices] = useState([]);
    const [groups, setGroups] = useState([]);
    const [categories, setCategories] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [devices, setDevices] = useState([]);
    const [data, setData] = useState({});
    const [selectedDevice, setSelectedDevice] = useState(null);

    const mappedCustomersObject = useMemo(() => {
        const result = customerStore.list.reduce((accu, _customer) => {
            accu[_customer.id] = {..._customer};
            return accu;
        }, {});
        return result;
    }, [customerStore.list]);

    useEffect(() => {
        (async () => {
            const devices = normalizeDeviceAndCustomer(getSnapshot(deviceStore.devices));
            const data = devices.map((device) => {
                return {id: device.id, title: device.name};
            });
            setDevices(devices);
            setSelectDevices(data);
        })();
    }, [deviceStore.list]);

    const normalizeDeviceAndCustomer = (devices) => {
        if (customerStore.list && customerStore.list.length > 0) {
            devices = devices.map( (device) => {
                const obj = Object.assign({}, device);
                const customer = mappedCustomersObject[device.customerId];
                if (customer) {
                    obj.customerName = customer.name;
                }
                return obj;
            });
        }

        return devices;
    };


    useEffect(() => {
        (async () => {
            const groups = await groupService.list();
            const data = [];
            groups.map((group) => {
                data.push({id: group.id, title: group.name});
            });
            setGroups(data);
        })();
    }, []);


    useEffect(() => {
        (async () => {
            const data = [];
            categoriesStore.types.map((category) => {
                data.push({id: category.key, title: category.name});
            });
            data.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
            setCategories(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const data = [];
            equipmentStore.types.map((e) => {
                data.push({id: e.name, title: e.name});
            });
            setEquipments(data);
        })();
    }, []);

    const deviceForm = (data) => {
        setData(Object.keys(data).length ? Object.assign({}, data) : {});
        setView('deviceForm');
    };

    const accumulatorForm = (data) => {
        setData(Object.keys(data).length ? Object.assign({}, data) : {});
        setView('accumulatorForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await deviceService.save(data);

            setDevices([...devices, result]);
            setDevices(normalizeDeviceAndCustomer([...devices, result]));

            deviceStore.add(result);
            setView('deviceList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            console.log(e);
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'deviceList') => {
        try {
            showModalLoading(true);
            const index = devices.findIndex(({id}) => id === data.id);
            const result = await deviceService.update({
                ...devices[index],
                ...data,
            });

            devices.splice(index, 1, result);
            setDevices(normalizeDeviceAndCustomer([...devices]));

            deviceStore.updateDevice(result);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            console.log(e);
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await deviceService.remove(data.id);
            deviceStore.removeDevice(data.id);
            setDevices(devices.filter(({id}) => id !== data.id));
            setView('deviceList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };


    const showPermissionTabs = (data) => {
        setSelectedDevice({deviceId: data.id});
        setView('devicePermissions');
    };

    const saveAccumulator = async (accumulator) => {
        const {
            deviceId,
            totalDistance,
            hours,
        } = accumulator;

        try {
            await deviceService.accumulator(deviceId, totalDistance, hours);
           // data.currentPosition = Object.assign({totalDistance, hours}, ...data.currentPosition.attributes);
           // deviceStore.updateDevice(data);
            setView('deviceList');
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            console.log(e);
            toast.errorStatus(e);
        }
    };

    const formatCustomerList = () => customerStore.list.map((e) => {
            return {id: e.id, title: e.name};
    });

    return (
        <>
            {
                {
                    deviceList: (
                        <DeviceTable
                            auth={auth}
                            list={devices}
                            deviceForm={deviceForm}
                            accumulatorForm={accumulatorForm}
                            removeRegister={removeRegister}
                            permissions={showPermissionTabs}
                            loading={isModalLoading}
                            timezone={timezone}
                            intl={intl}
                        />
                    ),
                    deviceForm: (
                        <DeviceAdminForm
                            cancel={() => setView('deviceList')}
                            data={Object.assign({}, data)}
                            groups={groups}
                            categories={categories}
                            equipments={equipments}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            devices={selectDevices}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                            customers={formatCustomerList()}
                            session={session}
                        />
                    ),
                    devicePermissions: (
                        <PermissionTabs
                            options={permissions}
                            data={selectedDevice}
                            cancel={() => setView('deviceList')}/>
                    ),
                    accumulatorForm: (
                        <AccumulatorForm
                            data={data}
                            saveAccumulator={saveAccumulator}
                            cancel={() => setView('deviceList')}/>
                    ),
                }[view]
            }
        </>
    );
};

export default observer(DeviceContainer);

