import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import momentTimezone from 'moment-timezone';
// eslint-disable-next-line max-len
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {of} from 'rxjs';
import {mergeAll, mergeMap, tap} from 'rxjs/operators';
import styled from 'styled-components';
import {MapContext, ThemeContext} from '../../apiContexts';
import {useMap} from '../../components/map/hooks';
import useMapAlarm from '../../components/map/hooks/useMapAlarm';
import useMapDevice from '../../components/map/hooks/useMapDevice';
import useMapGeofences from '../../components/map/hooks/useMapGeofences';
import useMapLabel from '../../components/map/hooks/useMapLabel';
import useMapPosition from '../../components/map/hooks/useMapPosition';
import useMapReport from '../../components/map/hooks/useMapReport';
import useMapTrace from '../../components/map/hooks/useMapTrace';
import {initSearchData} from '../../components/search-box';
import config from '../../config';
import useDevices from '../../hooks/use-devices';
import useSocket from '../../hooks/use-socket';
import useStore from '../../hooks/use-store';
import history from '../../routes/history';
import {logOut} from '../../services/authService';
import '../../views/big-fleet/big-fleet.sass';
import Bottom from '../../views/big-fleet/bottom';
import Top from '../../views/big-fleet/top';
import FloatingReport from '../../views/reports/floating-report';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import useServices from '../../hooks/use-services';
import useTimezone from '../../hooks/use-timezone';

let positionsCache;

const Map = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const BigFleetContainer = (props) => {
    const {
        location,
    } = props;

    const intl = useIntl();
    const {store} = useStore();
    const {positionService, geofenceService, driverService, permissionService, commandService} = useServices();
    const theme = useContext(ThemeContext);
    const {
        positions: positionsSocket,
        events: socketEvents,
        devices: socketDevices,
    } = useSocket();
    const mapRef = useRef();
    const {devices} = useDevices();
    const {addToast} = useToasts();
    const [report, setReport] = useState();
    const [isOpenModalCommand, toggleModalCommand] = useState(false);
    const [commandData, setCommandData] = useState({});
    const [searchData, setSearchData] = useState(initSearchData);
    const {timezone} = useTimezone();
    const {toast} = useAlerts();

    const query = useMemo(
        () => ({search: location.search, hash: location.hash}),
        [location.search],
    );

    const {
        instance,
        trace,
        setTrace,
        state,
        toogleTrace: traceToggleCurrentDevice,
        label,
        setLabel,
        selectedMap,
        setSelectedMap,
        setReportData,
        showDevices,
        setShowDevices,
        showGeofences,
        setShowGeofences,
        follow,
        setFollow,
        isAlarmsVisible,
        toggleAlarmsVisibility,
    } = useMap(
        {
            target: mapRef,
            center: [0, 0],
            zoom: 4,
            tiers: config.mapConfig,
            initAllDevices: true,
            cluster: {
                distance: 25,
            },
        },
        useMapPosition,
        useMapDevice,
        useMapTrace,
        useMapLabel,
        useMapReport,
        useMapGeofences,
        useMapAlarm,
    );

    // REPORTS
    const {
        selectedItem,
        data,
        setSelectedRow,
        selectedRow,
        setProps,
    } = useContext(MapContext);

    useEffect(() => {
        if (query.search) {
            setReport(query.hash.slice(1));
        } else {
            setReport(null);
        }
    }, [query]);

    useEffect(() => {
        (async () => {
            setSearchData({
                ...searchData,
                options: devices.list.map(({id, uniqueId, name}) => ({
                    id,
                    uniqueId: uniqueId,
                    name,
                })),
            });
        })();
    }, [devices.list]);

    useEffect(() => {
        driverService.list().then((drivers) => {
            store.drivers.setDrivers(drivers);
        });
    }, []);

    useEffect(() => {
        if (devices.list.length) {
            (async () => {
                if (!positionsCache && !devices.updatedPositions) {
                    positionsCache = await positionService.list();
                    devices.updatePositions([...positionsCache]);
                }
                positionsSocket
                    .pipe(
                        tap((positions) => {
                            if (positions.length > 0) {
                                devices.updatePositions(positions);
                                positionsCache = positions;
                            }
                        }),
                        mergeAll(),
                    )
                    .subscribe((position) => {
                        devices.updatePosition(position);
                    });

                socketDevices.pipe(mergeAll()).subscribe((device) => {
                    devices.updateDevice(device);
                });

                // new Event notification
                socketEvents
                    .pipe(
                        mergeAll(),
                        mergeMap(
                            ({deviceId}) => of(devices.findById(deviceId)),
                            ({serverTime, ...e}, d) => ({
                                name: d ? d.name : '',
                                serverTime: momentTimezone(serverTime)
                                    .tz(timezone)
                                    .format(),
                                ...e,
                            }),
                        ),
                    )
                    .subscribe((event) => {
                        store.events.add(event);
                    });
            })();
        }
    }, [devices.list, devices.updatedPositions]);

    // useEffect(() => {
    //     if (
    //         devices.updatedPositions &&
    //         devices.list.length &&
    //         state.initializedPositions
    //     ) {
    //         if (deviceId && devices.list.length) {
    //             devices.setSelected(deviceId);
    //             const {currentPosition} = devices.selectedDevice;
    //             if (currentPosition) {
    //                 const {longitude, latitude} = currentPosition;
    //                 instance.goTo([longitude, latitude], 18);
    //             }
    //         }
    //     }
    // }, [instance, deviceId, devices.list.length, state, devices.updatedPositions, setTrace, store.devices]);


    const setDeviceSelected = (deviceId) => {
        devices.setSelected(deviceId);
        const device = devices.selectedDevice;
        const currentPosition = device.currentPosition || {
            longitude: 0,
            latitude: 0,
        };
        const {longitude, latitude} = currentPosition;
        if (instance) {
            instance.goTo([longitude, latitude], 18);
        }
    };

    const searchDevice = useCallback((data) => {
        if (data.length) {
            const {id} = data[0];
            setDeviceSelected(id);
        }
    }, []);

    useEffect(() => {
        if (instance) {
            setProps({map: instance});
            const map = instance.instance;
            instance.setI18n(intl);
            setSelectedMap('street');
            map.on('click', (event) => {
                const [feature, layer] =
                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    return [feature, layer];
                }) || [];

                if (!layer) return;

                const layerName = layer.get('name');
                if (layerName === 'reportLayer') {
                    const featureName = feature.get('name');
                    if (featureName !== 'line') {
                        setSelectedRow({
                            id: feature.get('id') || feature.getId(),
                            scroll: true,
                        });
                    }
                }

                if (layerName === 'devicesLayer') {
                    const features = feature.get('features');
                    if (features.length === 1) devices.setSelected(features[0].getId());
                }
            });

            map.on('pointermove', (event) => {
                map.getTargetElement().style.cursor = map.hasFeatureAtPixel(event.pixel) ?
                    'pointer' :
                    '';
            });

            map.on('pointerdrag', (event) => {
                setFollow(false);
            });

            let zoomCache;
            map.on('movestart', (event) => {
                zoomCache = event.map.getView().getZoom();
            });

            map.on('moveend', (event) => {
                const zoom = event.map.getView().getZoom();
                zoom !== zoomCache && setFollow(false);
            });
        }
    }, [instance]);

    useEffect(() => {
        if (instance) {
            if (selectedRow) {
                instance.selectedReportPoint = selectedRow.id;
            }
        }
    }, [selectedRow]);

    useEffect(() => {
        if (instance) {
            if (selectedItem) {
                const {longitude, latitude} = selectedItem;
                if (longitude && latitude) {
                    instance.goTo([longitude, latitude], 18);
                } else {
                    instance.goTo(null, instance._instance.getView().getZoom() - 0.0001);
                }
            }
        }
    }, [selectedItem]);

    useEffect(() => {
        setReportData(data);
    }, [data]);

    // END REPORTS

    const followToogle = () => {
        const status = !follow;
        setFollow(status);
        if (status) {
            const {longitude, latitude} = devices.selectedDevice.currentPosition;
            instance.goTo([longitude, latitude]);
        }
    };

    const toggleTrace = () => {
        setTrace(prev => !prev);
    };

    const traceToggleCurrent = () => {
        traceToggleCurrentDevice(devices.selectedDevice);
    };

    const toggleLabel = () => {
        setLabel(!label);
    };

    const onChangeMap = (value) => {
        setSelectedMap(value);
    };

    const toggleDevice = () => {
        setShowDevices(!showDevices);
    };

    const toggleGeofences = () => {
        setShowGeofences(!showGeofences);
    };

    const close = () => {
        instance.removeReports();
        history.push('/fleet');
    };

    const createGeofence = async () => {
        const device = devices.selectedDevice;
        const currentPosition = device.currentPosition;

        if (currentPosition) {
            const area = `CIRCLE (${currentPosition.latitude} ${
                currentPosition.longitude
            }, ${20})`;
            const data = {name: `Cerca_${device.name}`, area};

            const response = await geofenceService.save(data);

            if (response) {
                const permission = {deviceId: device.id, geofenceId: response.id};
                await permissionService.addPermission(permission);
                instance.addGeofence(response);
                geoFenceToastSuccess('success');
            } else {
                geoFenceToastSuccess('error');
            }
        }
    };

    const geoFenceToastSuccess = useCallback((appearance) => {
        const msg =
            appearance === 'success' ? (
                <span>Cerca criada com sucesso</span>
            ) : (
                `Não foi possível realizar sua solicitação!`
            );
        addToast(msg, {appearance, autoDismiss: true});
    }, []);

    const block = (status) => {
        const {selectedDevice} = devices;
        const {id} = selectedDevice;
        const data = {deviceId: id, type: status ? 'engineStop' : 'engineResume'};
        setCommandData(data);
        toggleModalCommand(true);
    };

    const sendCommand = async () => {
        await commandService.send(commandData);
        toast.success(intl.formatMessage({id: 'commandSent'}));
        toggleModalCommand(false);
    };

    const closeDetail = () => {
        store.devices.setSelected(null);
    };

    return (
        <div id="bigFleet">
            <Map ref={mapRef}/>

            <Top
                logOut={logOut}
                events={store.events}
                searchData={{...searchData, onChange: searchDevice}}
                setDeviceSelected={setDeviceSelected}
            />
            <div className="content"/>

            <Bottom
                map={instance}
                devices={devices}
                theme={theme}
                toast={toast}
                intl={intl}
                auth={store.auth}
                server={store.server}
                drivers={store.drivers}
                setSelected={(id) => store.devices.setSelected(id)}
                selectedDevice={store.devices.selectedDevice}
                updatedPositions={store.devices.updatedPositions}
                isOpenModalCommand={isOpenModalCommand}
                toggleModalCommand={toggleModalCommand}
                sendCommand={sendCommand}
                commandData={commandData}
                block={block}
                close={closeDetail}
                {...{
                    traceToggleCurrent,
                    toggleTrace,
                    trace,
                    follow,
                    followToogle,
                    toggleLabel,
                    label,
                    toggleDevice,
                    showDevices,
                    onChangeMap,
                    selectedMap,
                    createGeofence,
                    showGeofences,
                    toggleGeofences,
                    isAlarmsVisible,
                    toggleAlarmsVisibility,
                }}
            />

            {report && (
                <FloatingReport
                    type={report}
                    location={location}
                    close={close}
                />
            )}
        </div>
    );
};

export default observer(BigFleetContainer);

BigFleetContainer.propTypes = {
    location: PropTypes.any,
};
