import config from '../config';

const {
  PATH: {device_icons},
} = config;

export const getDeviceIcon = (category = 'default', position, color) =>
  `${device_icons}/${category}/${category}_${position}_${color}.svg`;

export const getDeviceIconColor = ({status, ignition, alarm}) => {
  if (status === 'offline') return 'grey';
  if (status === 'unknown') return 'yellow';
  if (alarm) return 'red';
  if (status === 'online') return ignition ? 'blue' : 'green';
};

export const getCursor = (color) =>
  `data:image/svg+xml;base64,${btoa(`<svg height="25px" width="25px" viewBox="-53 0 511 511.36533" xmlns="http://www.w3.org/2000/svg">
      <path d="m394.652344 464.347656-182.402344-445.867187c-3.132812-7.617188-15.636719-7.617188-18.75 0l-182.398438 445.867187c-1.539062 3.773438-.683593 8.105469 2.21875 11.007813l20.265626 20.265625c2.941406 2.945312 7.402343 3.777344 11.21875 2.113281l158.058593-69.164063 158.082031 69.164063c1.300782.574219 2.6875.851563 4.054688.851563 2.621094 0 5.226562-1.023438 7.167969-2.964844l20.265625-20.265625c2.878906-2.902344 3.753906-7.234375 2.21875-11.007813zm0 0" 
        fill="${color}"/>
      <path fill="white" d="m373.53125 511.367188c-1.429688 0-2.902344-.300782-4.265625-.898438l-166.402344-72.789062-166.398437 72.789062c-4.011719 1.75-8.726563.855469-11.816406-2.21875l-21.335938-21.332031c-3.050781-3.050781-3.96875-7.59375-2.324219-11.585938l192-469.332031c3.285157-8 16.449219-8 19.753907 0l192 469.332031c1.621093 3.992188.703124 8.535157-2.324219 11.585938l-21.335938 21.332031c-2.046875 2.027344-4.777343 3.117188-7.550781 3.117188zm-170.667969-96c1.453125 0 2.925781.296874 4.269531.894531l164.007813 71.742187 11.160156-11.15625-179.4375-438.65625-179.433593 438.65625 11.15625 11.15625 164.011718-71.742187c1.34375-.597657 2.816406-.894531 4.265625-.894531zm0 0"/>
    </svg>
  `)}`;
