import BaseService from './base-service';

/** */
export default class LocationService extends BaseService {
    /** */
    constructor() {
        super('location');
    }

    /**
     * Receive a list of device uniqueId and return a token to be used with
     * getLocation.
     * @param {string[]} deviceUniqueIds
     * @return {Promise<string>}
     */
    shareLocation(deviceUniqueIds) {
        return this.__post('location/session', {
            deviceUniqueIds,
        });
    }

    /**
     * Receive a token from shareLocation and return a DeviceModel like.
     * @param {string} token
     * @return {any}
     */
    getLocation(token) {
        return this.__get(`location/${token}`);
    }
}
