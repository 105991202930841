import {useEffect} from 'react';
import {Subject} from 'rxjs';
import config from '../config';
import useStore from './use-store';
import {isObjectEmpty} from '../helpers/isObjectEmpty';

let socket = null;

const {socketURL} = config;

const subjects$ = {
    devices: new Subject(),
    events: new Subject(),
    positions: new Subject(),
    customers: new Subject(),
    sinisters: new Subject(),
};

const _init = (token) => {
    const url = `${socketURL}?b=${token}`;
    socket = new WebSocket(url);

    socket.onopen = () => console.log(`Conectando socket`);

    socket.onmessage = ({data}) => {
        try {
            const parsedData = JSON.parse(data);

            const isDataEmpty = isObjectEmpty(parsedData);
            if (isDataEmpty) return;

            const [event, values] = Object.entries(parsedData)[0];
            subjects$[event].next(values);
        } catch (error) {
            console.log('>> Error on handle socket message', error.message);
        }
    };

    socket.onclose = () => {
        console.log('Socket has disconnected!');
        setTimeout(() => _init(token), 60 * 1000);
    };

    socket.onerror = (err) => console.error('Socket error: ', err);
};

const useSocket = () => {
    const {store} = useStore();
    const {auth} = store;

    useEffect(() => {
        if (!socket) _init(auth.token);
    }, [auth.token]);

    return subjects$;
};

export default useSocket;
