import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isAuthenticated} from '../helpers/auth';

export const NoMatch = (props) => {
  return (
    <Redirect to={{pathname: '/login', state: {from: props.location}}} />
  );
};

export const PrivateRoute = (props) => {
  return isAuthenticated() ? (
    <Route {...props} />
  ) : (
    <Redirect to={{pathname: '/', state: {from: props.location}}} />
  );
};

export const ClientRoute = ({profile, ...rest}) => {
  return profile === 'client' ? (
    <PrivateRoute {...rest} />
  ) : (
    <Redirect to={{pathname: '/', state: {from: rest.location}}} />
  );
};

export const FleetRoute = ({profile, ...rest}) => {
  return profile === 'fleet' ? (
    <PrivateRoute {...rest} />
  ) : (
    <Redirect to={{pathname: '/', state: {from: rest.location}}} />
  );
};

export const AdminRoute = ({profile, ...rest}) => {
  return profile === 'admin' || profile === 'monitor' ? (
    <PrivateRoute {...rest} />
  ) : (
    <Redirect to={{pathname: '/', state: {from: rest.location}}} />
  );
};
