import React, {useState} from 'react';
import styled from 'styled-components';
import {colors} from '../../../styleVariables';
import {Col, ModalBody, ModalHeader, Row} from 'reactstrap';
import RoundButton from '../../../components/roundButton';
import {useIntl} from 'react-intl';
import {ModalStyled} from '../../../styles';
import CustomerView from '../../customer/customer-view';
import UserShortTable from '../../user/user-table-short';
import useServices from '../../../hooks/use-services';

const Style = styled.div`
  padding: 0 1rem;
  line-height: 1rem;
  font-size: 0.8rem !important;

  strong {
    color: ${(props) => props.theme};
  }

  > span {
    color: ${colors.gray} !important;
  }
`;

const Contact = ({contact, theme, customer}) => {
  const [modalCustomer, setModalCustomer] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalInfo, setModalInfo] = useState({title: 'Título', type: ''});
  const [users, setUsers] = useState();
  const intl = useIntl();
  const {userService} = useServices();

  const toggleModalCustomer = () => setModalCustomer(!modalCustomer);
  const toggleModalUser = () => setModalUser(!modalUser);

  const showCustomer = () => {
    setModalInfo({
      title: intl.formatMessage({id: 'sharedCustomer'}),
      type: 'group',
    });
    toggleModalCustomer();
  };

  const showUser = () => {
    try {
      userService.getUsersByCustomerId(customer.id).then((result) => {
        setUsers(result);
      });
    } catch (e) {
      console.log(e);
    }
    setModalInfo({
      title: intl.formatMessage({id: 'sharedUser'}),
      type: 'group',
    });
    toggleModalUser();
  };
  return (
    <Style className="mt-3 ml-3" theme={theme}>
      <Row>
        <Col className="mb-1" xs="6">
          <Row>
            <strong style={{color: theme}}>
              Contato
            </strong>
          </Row>
          <Row>
            <span>{contact}</span>
          </Row>
        </Col>
        <Col xs="3">
          <RoundButton
            withBorder
            noBgBadge
            onClick={() => showCustomer()}>
            <span className="fad fa-book-user" />
          </RoundButton>
        </Col>
        <Col xs="3">
          <RoundButton
            withBorder
            noBgBadge
            onClick={() => showUser()}>
            <span className="fad fa-users" />
          </RoundButton>
        </Col>
      </Row>
      <ModalStyled
        theme={theme}
        size="lg"
        centered
        unmountOnClose
        className={`customModal`}
        isOpen={modalCustomer}
        toggle={toggleModalCustomer}>
        <ModalHeader toggle={toggleModalCustomer}>{modalInfo.title}</ModalHeader>
        <ModalBody>
          <CustomerView customer={customer} theme={theme}></CustomerView>
        </ModalBody>
      </ModalStyled>
      <ModalStyled
        theme={theme}
        size="xl"
        centered
        unmountOnClose
        className={`customModal`}
        isOpen={modalUser}
        toggle={toggleModalUser}>
        <ModalHeader toggle={toggleModalUser}>{modalInfo.title}</ModalHeader>
        <ModalBody>
          <UserShortTable users={users} theme={theme} />
        </ModalBody>
      </ModalStyled>
    </Style>
  );
};

export default Contact;
