import {types} from 'mobx-state-tree';
import PositionModel from './position-model';
import config from '../config';

const DeviceModel = types
    .model('Device', {
      id: types.identifierNumber,
      uniqueId: types.string,
      name: types.string,
      attributes: types.frozen({}),
      category: types.maybeNull(types.string),
      contact: types.maybeNull(types.string),
      disabled: types.maybeNull(types.boolean),
      geofenceIds: types.maybeNull(types.array(types.frozen([]))),
      groupId: types.maybeNull(types.number),
      groupName: types.maybeNull(types.string),
      lastUpdate: types.maybeNull(types.string),
      model: types.maybeNull(types.string),
      phone: types.maybeNull(types.string),
      positionId: types.maybeNull(types.number),
      status: types.maybeNull(types.string),
      positionBuffer: types.array(PositionModel),
      currentPosition: types.maybe(types.maybeNull(PositionModel)),
      withTrace: types.optional(types.boolean, false),
      customerId: types.maybeNull(types.number),
      customerName: types.maybeNull(types.string),
      customerCpfCnpj: types.maybeNull(types.string),
      customTraceLimit: types.maybe(types.integer),
    })
    .actions((self) => ({
      update: (data) => Object.assign(self, data),
      updateAttributes: (attributes) => {
          self.attributes = attributes;
      },
      newPosition: (position) => {
        if (!self.positionBuffer.find(({id}) => id === position.id)) {
          const traceLimit = self.customTraceLimit ?? config.traceLimit - 1;
          self.positionBuffer = [
            position,
            ...self.positionBuffer.slice(0, self.customTraceLimit ?? config.traceLimit - 1),
          ];
        }
      },
      setBuffer: (positions) => self.positionBuffer.push(...positions),
      setCurrentPosition: (position) => (self.currentPosition = position),
      setTrace: (value) => (self.withTrace = value),
      setCustomTraceLimit: (value) => (self.customTraceLimit = value),
      setCustomerName: (value) => (self.customerName = value),
      setCustomerCpfCnpj: (value) => (self.customerCpfCnpj = value),
      clearBuffer: (_) => (self.positionBuffer = []),
    }));

export default DeviceModel;
