import React, {useContext, useMemo} from 'react';
import EventButtonBox from '../../components/event-button-box';
import RoundButtonDropdown from '../../components/roundButtonDropdown';
import SearchBox from '../../components/search-box';
import ModalEnum from '../modals/modalEnum';
import {modalDetail} from '../modals/modalList';
import {useIntl} from 'react-intl';
import {ModalContext} from '../../apiContexts';
import PropTypes from 'prop-types';
import {useCompany} from '../../providers/company';

const Top = (props) => {
    const {
        events,
        searchData,
        logOut,
        setDeviceSelected,
    } = props;

    const intl = useIntl();
    const {openModal} = useContext(ModalContext);
    const company = useCompany();

    const dropdownItems = useMemo(
        () => [
            ...[
                {icon: 'fas fa-user-tie', type: ModalEnum.ACCOUNT},
                {icon: 'fas fa-users', type: ModalEnum.USERS},
                {icon: 'fas fa-object-group', type: ModalEnum.GROUP},
                {icon: 'fas fa-car', type: ModalEnum.DEVICE},
                {icon: 'fas fa-location-circle', type: ModalEnum.GEOFENCE},
                {icon: 'fas fa-steering-wheel', type: ModalEnum.DRIVER},
                {icon: 'fas fa-envelope-open-text', type: ModalEnum.NOTIFICATION},
                {icon: 'far fa-calendar-alt', type: ModalEnum.CALENDAR},
                {icon: 'fas fa-tools', type: ModalEnum.MAINTENANCE},
                {icon: 'fas fa-file-alt', type: ModalEnum.NEW_REPORT},
            ].map((l) => {
                l.callback = () => openModal(l.type);
                l.label = intl.formatMessage({id: modalDetail[l.type].title});
                return l;
            }),
            {
                label: intl.formatMessage({id: 'sharedLogout'}),
                icon: 'fas fa-door-open',
                callback: logOut,
            },
        ],
        [],
    );

    return (
        <div id="top">
            <Logo src={company.logoUrl}/>

            <EventButtonBox
                className="ml-auto mb-1"
                events={events}
                setDeviceSelected={(deviceId) => setDeviceSelected(deviceId)}
            />

            <SearchBox
                {...searchData}
                onClick={() => console.log('clicked')}
                className="mx-2 mb-1"
            />

            <RoundButtonDropdown
                className="mb-1"
                icon="fal fa-bars"
                items={dropdownItems}
            />
        </div>
    );
};

const Logo = ({src}) => (
    <div id="logo">{src && <img alt="logo" src={src}/>}</div>
);

export default Top;

Top.propTypes = {
    searchData: PropTypes.func,
    logOut: PropTypes.func,
    setDeviceSelected: PropTypes.func,
    events: PropTypes.any,
};

Logo.propTypes = {
    src: PropTypes.string,
};

