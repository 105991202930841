import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {ThemeContext} from '../../apiContexts';
import styled from 'styled-components';

const LegendStyled = styled.legend`
  color: ${(props) => props.theme};
  font-weight: bolder;
`;

const FieldSet = (props) => {
    const theme = useContext(ThemeContext);

    return (
        <>
            <fieldset className={props.className}>
                <LegendStyled theme={theme} className="w-auto">{props.title}</LegendStyled>
                {props.children}
            </fieldset>
        </>
    );
};

FieldSet.propTypes = {
    children: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string,
};

export {
    FieldSet,
};
