import {useCallback, useEffect} from 'react';
import useStore from './use-store';
import useServices from './use-services';

let started = false;

const useDevices = () => {
    const {store} = useStore();
    const {deviceService} = useServices();

    const getDevices = useCallback(async () => {
        if (store.devices.list.length === 0 && !started) {
            started = true;
            const devices = await deviceService.list();
            devices.sort((a, b) => a.name.localeCompare(b.name));
            store.devices.addAll(devices);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await getDevices();
        })();
    }, []);

    return {devices: store.devices};
};

export default useDevices;
