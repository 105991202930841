import React, {useContext, useEffect, useState} from 'react';
import MaintenanceForm from '../../views/maintenance/maintenance-form';
import MaintenanceTable from '../../views/maintenance/maintenance-table';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from "../../hooks/use-services";
import useMaintenanceTypes from "../../hooks/use-maintenance-types";
import useFormatter from "../../hooks/user-formatter";
import useStore from "../../hooks/use-store";

const MaintenanceContainer = (props) => {

    const intl = useIntl();
    const {toast} = useAlerts();
    const {store} = useStore();
    const {maintenanceTypes: types} = store;
    const {renderAttribute, getConverter} = useFormatter();
    const {maintenanceService} = useServices();
    const {maintenanceTypes} = useMaintenanceTypes();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('maintenanceList');
    const [listOfMaintenance, setListOfMaintenance] = useState([]);
    const [data, setData] = useState({});


    const formatter = (arr) => {
        return arr.map(({id, name, period, start, type}) => (
            {
                id,
                name,
                period,
                start,
                type,
                typeFmt: types.getType(type).name,
                startFmt: renderAttribute(start, types.getType(type)),
                periodFmt: renderAttribute(period, types.getType(type))
            }))
    }

    useEffect(() => {
        (async () => {
            const maintenances = await maintenanceService.list();
            setListOfMaintenance(formatter(maintenances));
        })();
    }, []);

    const maintenanceForm = (data) => {
        setData(Object.keys(data).length ? data : {});
        setView('maintenanceForm');
    };

    const converterData = (src, back) => {

        const attribute = types.getType(src.type);

        if (attribute) {
            let unit = 'km';
            let converter = getConverter('distance');

            if (attribute.dataType === 'speed') {
                unit = 'kn';
                converter = getConverter(attribute.dataType);
            }

            if (attribute.dataType === 'hours') {
                unit = 'h';
                converter = getConverter(attribute.dataType);
            }

            src.start = converter(src.start, unit, back);
            src.period = converter(src.period, unit, back);
        }

        return src;
    }

    const preparePayload  = ( src ) => {
        delete src.startFmt;
        delete src.periodFmt;
        delete src.typeFmt;

        console.log(src);

        return src;
    }

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await maintenanceService.save(converterData(data, true));
            setListOfMaintenance([...listOfMaintenance, result]);
            setListOfMaintenance(formatter([...listOfMaintenance, result]));
            setView('maintenanceList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'maintenanceList') => {
        try {
            showModalLoading(true);
            const index = listOfMaintenance.findIndex(({id}) => id === parseInt(data.id));
            const result = await maintenanceService.update(preparePayload({...listOfMaintenance[index], ...converterData(data, true)}));
            listOfMaintenance.splice(index, 1, result);
            setListOfMaintenance(formatter([...listOfMaintenance]));
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
            setView(afterTo);
        } catch (e) {
            console.log(e);
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await maintenanceService.remove(data.id);
            setListOfMaintenance(listOfMaintenance.filter(({id}) => id !== parseInt(data.id)));
            setView('maintenanceList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    return (
        <>
            {
                {
                    maintenanceList: (
                        <MaintenanceTable
                            list={listOfMaintenance}
                            maintenanceForm={maintenanceForm}
                            removeRegister={removeRegister}
                            loading={isModalLoading}
                            renderAttribute={renderAttribute}
                        />
                    ),
                    maintenanceForm: (
                        <MaintenanceForm
                            cancel={() => setView('maintenanceList')}
                            data={Object.assign({}, data)}
                            types={maintenanceTypes}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                            converterData={converterData}
                        />
                    )
                }[view]
            }
        </>
    );
};

export default MaintenanceContainer;
