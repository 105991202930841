import {useEffect, useState} from 'react';
import {mergeAll} from 'rxjs/operators';
import {sharedMapSubjects$} from '../helpers/subjects';

let unsubscribe$ = null;

const useSharedMapLabel = (state, dispatch, instance) => {
  const [label, setLabel] = useState(false);
  const {positions} = sharedMapSubjects$;
  const {instance: map, deviceMap} = state;

  useEffect(() => {
        return () => unsubscribe$ && unsubscribe$.unsubscribe();
  });

  useEffect(() => {
    if (map && deviceMap) {
      if (label) {
        for (const [deviceId, {name, currentPosition}] of deviceMap) {
          if (currentPosition) {
            const {longitude, latitude} = currentPosition;
            map.addLabelOverlay(deviceId, name, [longitude, latitude]);
          }
        }

        map.instance.render();

        unsubscribe$ = positions
            .pipe(mergeAll())
            .subscribe(({deviceId, longitude, latitude}) => {
              map.updateOverlayPosition(`label-${deviceId}`, [
                longitude,
                latitude,
              ]);
            });
      } else {
        if (unsubscribe$) {
          unsubscribe$.unsubscribe();
          map.removeOverlays('label');
        }
      }
    }
  }, [map, deviceMap, label]);

  return {label, setLabel};
};

export default useSharedMapLabel;
