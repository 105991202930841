import useStore from './use-store';
import useConstants from './use-constants';
import moment from 'moment';


const useFormatter = () => {

    const {
        numberPrecision,
        dateTimeFormat12,
        dateTimeFormat24,
    } = useConstants();

    const {store} = useStore();

    const getPreference = (key, defaultValue) => {
        if (store.server.forceSettings) {
            return store.server[key] || store.auth.user[key] || defaultValue;
        } else {
            return store.auth.user[key] || store.server[key] || defaultValue;
        }
    };

    const getAttributePreference = (key, defaultValue) => {
        if (store.server.forceSettings) {
            return store.server.attributes[key] || store.auth.user['attributes'][key] || defaultValue;
        } else {
            return store.auth.user['attributes'][key] || store.server.attributes[key] || defaultValue;
        }
    };

    const numberFormatterFactory = (precision, suffix) => {
        return (value) => {
            if (value !== undefined) {
                return Number(value.toFixed(precision)) + ' ' + suffix;
            }
            return null;
        };
    };

    const coordinateFormatter = (key, value) => {
        return store.coordinateFormats.formatValue(key, value, getPreference('coordinateFormat'));
    };

    const speedFormatter = (value) => {
        return store.speedUnit.formatValue(value, getAttributePreference('speedUnit'));
    };

    const speedConverter = (value, defaultUnit, back) => {
        return store.speedUnit.convertValue(value, getAttributePreference('speedUnit', defaultUnit), back);
    };

    const courseFormatter = (value) => {
        const courseValues = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        return courseValues[Math.floor(value / 45)];
    };

    const distanceFormatter = (value) => {
        return store.distanceUnit.formatValue(value, getAttributePreference('distanceUnit'), true);
    };

    const distanceConverter = (value, defaultUnit, back) => {
        return store.distanceUnit.convertValue(value, getAttributePreference('distanceUnit', defaultUnit), back);
    };

    const volumeFormatter = (value) => {
        return store.volumeUnits.formatValue(value, getAttributePreference('volumeUnit'));
    };

    const volumeConverter = (value, defaultUnit) => {
        return store.volumeUnits.convertValue(value, getAttributePreference('volumeUnit', defaultUnit));
    };

    const hoursFormatter = (value) => {
        return store.hoursUnit.formatValue(value, 'h', true);
    };

    const hoursConverter = (value, defaultUnit, back) => {
        return store.hoursUnit.convertValue(value, defaultUnit || 'h', back);
    };

    const durationFormatter = (value) => {
        return store.hoursUnit.formatValue(value, 'h', true);
    };

    const defaultFormatter = (value) => {
        if (typeof value === 'number') {
            return Number(value.toFixed(numberPrecision));
        } else if (typeof value === 'boolean') {
            return value ? 'yes' : 'no';
        } else if (value instanceof Date) {
            if (getPreference('twelveHourFormat', false)) {
                return moment(value).format(dateTimeFormat12);
            } else {
                return moment(value).format(dateTimeFormat24);
            }
        }
        return value;
    };

    const getAttributeFormatter = (key) => {
        const dataType = store.positionAttributes.getAttributeDataType(key);

        switch (dataType) {
            case 'distance':
                return distanceFormatter;
            case 'speed':
                return speedFormatter;
            case 'volume':
                return volumeFormatter;
            case 'hours':
                return hoursFormatter;
            case 'voltage':
                return numberFormatterFactory(numberPrecision, 'V');
            case 'consumption':
                return numberFormatterFactory(numberPrecision, 'l/h');
            default:
                return defaultFormatter;
        }
    };

    const getFormatter = (key) => {
        switch (key) {
            case 'latitude':
            case 'longitude':
                return function (value) {
                    return coordinateFormatter(key, value);
                };
            case 'speed':
                return speedFormatter;
            case 'course':
                return courseFormatter;
            case 'distance':
            case 'accuracy':
                return distanceFormatter;
            case 'duration':
                return durationFormatter;
            case 'spentFuel':
                return volumeFormatter;
            default:
                return defaultFormatter;
        }
    };

    const getConverter = (key) => {
        switch (key) {
            case 'speed':
                return speedConverter;
            case 'distance':
            case 'accuracy':
                return distanceConverter;
            case 'spentFuel':
                return volumeConverter;
            default:
                return null;
        }
    };


    const getPositionConverter = (key) => {
        const dataType = store.positionAttributes.getAttributeDataType(key);

        switch (dataType) {
            case 'distance':
                return distanceConverter;
            case 'speed':
                return speedConverter;
            case 'volume':
                return volumeConverter;
            case 'hours':
                return hoursConverter;
            default:
                return null;
        }
    };

    const formatValue = (key, value) => {
        const formatter = getAttributeFormatter(key);
        return formatter(value);
    };

    const renderAttribute = (value, attribute) => {
        if (attribute && attribute.dataType === 'speed') {
            return store.speedUnit.formatValue(value, getAttributePreference('speedUnit', 'kn'), true);
        } else if (attribute && attribute.dataType === 'distance') {
            return store.distanceUnit.formatValue(value, getAttributePreference('distanceUnit', 'km'), true);
        } else if (attribute && attribute.dataType === 'hours') {
            return store.hoursUnit.formatValue(value, 'h', true);
        } else {
            return value;
        }
    }


    return {
        coordinateFormatter,
        speedFormatter,
        speedConverter,
        courseFormatter,
        distanceFormatter,
        distanceConverter,
        volumeFormatter,
        volumeConverter,
        hoursFormatter,
        hoursConverter,
        durationFormatter,
        getAttributeConverter: getPositionConverter,
        getConverter,
        getAttributeFormatter,
        getFormatter,
        formatValue,
        renderAttribute,
    };
};

export default useFormatter;
