import React, {useContext} from 'react';
import {Col, Progress, Row} from 'reactstrap';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';
import DeviceListItem from './device-list-item';

const StyledProgress = styled(Progress)`
  .progress-bar {
    background-color: ${(props) => props.theme};
  }
`;

const DeviceList = ({
  list = [],
  selected,
  onSelect,
  currentPage,
  pages,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div id="deviceList">
      <div className="shadow h-100 px-4 pt-2">
        <Row className="py-2">
          {list.map(({name, currentPosition, id, category, status}) => (
            <Col key={id} md="3">
              <DeviceListItem
                theme={theme}
                id={id}
                name={name}
                currentPosition={currentPosition}
                onSelect={onSelect}
                category={category}
                status={status}
                active={selected && selected.id === id}
              />
            </Col>
          ))}
        </Row>
        <StyledProgress
          theme={theme}
          value={currentPage}
          style={{height: '5px'}}
          className="mb-1"
          max={pages}
        />
      </div>
    </div>
  );
};

export default DeviceList;
