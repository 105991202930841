import {useContext, useEffect, useState} from 'react';
import {MapContext} from '../../../apiContexts';
import useDevices from '../../../hooks/use-devices';

let typeCache = '';

const useMapReport = (state, dispatch, instance) => {
  const [reportData, setReportData] = useState();
  const {devices} = useDevices();
  const {instance: map, deviceMap} = state;

  const {setSelectedItem, setSelectedRow} = useContext(MapContext);
  useEffect(() => {
    setSelectedItem(null);
    setSelectedRow(null);
  }, []);

  useEffect(() => {
    if (state.instance) {
      map.initReportLayer();
    }
  }, [state.instance]);

  useEffect(() => {
    if ((map, reportData)) {
      map.removeReports();
      map.instance.render();
      map.selectedReportPoint = null;
    }
  }, [map, reportData]);

  useEffect(() => {
    if (reportData) {
      const {type, data, markers} = reportData;

      if (type !== typeCache) {
        typeCache = type;
      }

      switch (type) {
        case 'route':
          const positions = data.map(({device, positions}) => {
            return positions.map(({id, longitude, latitude, course}) => ({
              id,
              lon: longitude,
              lat: latitude,
              course,
            }));
          });
          map.addReportRoute(positions, markers);
          break;
        case 'event':
        case 'stop':
          map.addReportPoint(data);
          break;
        case 'trip':
          map.addReportTrip(data, markers, reportData.id);
          break;
        default:
          map.removeReports();
          break;
      }
    }
  }, [state.instance, reportData]);

  return {setReportData};
};

export default useMapReport;
