export const getColumnWidth = (data, accessor, headerText) => {
  const cellLength = Math.max(
      ...data.map((row) => {
        let value = '';

        if (typeof accessor === 'string') {
          value = row[accessor];
        } else {
          value = accessor(row);
        }

        if (typeof value === 'number') return value.toString().length;
        return (value || '').length;
      }),
      headerText.length,
  );

  const magicSpacing = 10;
  return cellLength * magicSpacing;
};

export const memorizeReportForm = (args) => {
  const session = sessionStorage.getItem('reportForm');

  if (session) {
    sessionStorage.setItem(
        'reportForm',
        JSON.stringify({...JSON.parse(session), ...args}),
    );
  } else {
    sessionStorage.setItem('reportForm', JSON.stringify(args));
  }
};

export const getMemorizeReportForm = (...keys) => {
  let session = sessionStorage.getItem('reportForm');
  if (session) {
    session = JSON.parse(session);
    if (!keys.length) return session;
    return keys.reduce((acc, k) => ({...acc, [k]: session[k] || null}), {});
  }
  return null;
};

export const getDate = (date) => (date ? new Date(date) : null);
