import useDevices from "./use-devices";
import useServices from "./use-services";
import {useMap} from "../components/map/hooks";
import React, {useCallback} from "react";
import {useToasts} from "react-toast-notifications";

const useGeofences = () => {

    const { devices } = useDevices();
    const { geofenceService, permissionService} = useServices();
    const { instance } = useMap();
    const { addToast } = useToasts();

    const geoFenceToastSuccess = useCallback((appearance) => {
        const msg =
            appearance === 'success' ? (
                <span>Cerca criada com sucesso</span>
            ) : (
                `Não foi possível realizar sua solicitação!`
            );
        addToast(msg, {appearance, autoDismiss: true});
    }, []);

    const createGeofenceFromCurrentPosition = async () => {

        const device = devices.selectedDevice;
        const currentPosition = device.currentPosition;

        if (currentPosition) {
            const area = `CIRCLE (${currentPosition.latitude} ${
                currentPosition.longitude
            }, ${20})`;
            const data = {name: `Cerca_${device.name}`, area};

            const response = await geofenceService.save(data);

            if (response) {
                const permission = {deviceId: device.id, geofenceId: response.id};
                await permissionService.addPermission(permission);
                instance.addGeofence(response);
                geoFenceToastSuccess('success');
            } else {
                geoFenceToastSuccess('error');
            }
        }
    }
    return {
        createGeofenceFromCurrentPosition
    };
};

export default useGeofences;
