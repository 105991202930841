
export default class Cliente {

    /**
     *     {
     *        "id": 0,
     *        "cnpj_cpf": "string",
     *        "pessoa": 1,
     *        "rg": "string",
     *       "im": "string",
     *        "ie": "string",
     *        "nome": "string",
     *        "nomeFantasia": "string",
     *        "codigo": "string",
     *        "situacaoClienteId": 0,
     *        "dataCadastro": "2021-08-17T11:35:46.483Z",
     *        "enderecoPrincipal": {
     *          "cep": "string",
     *          "codigoIBGE": "string",
     *           "endereco": "string",
     *          "numero": "string",
     *          "bairro": "string",
     *          "complemento": "string",
     *          "cidade": "string",
     *          "uf": "string",
     *          "cidadeEstrangeiroId": 0
     *        },
     *        "contatoPrincipal": {
     *          "email": "string",
     *          "telefone": {
     *            "ddd": "string",
     *            "ddi": "string",
     *            "phone": "string"
     *          },
     *          "telefone2": {
     *            "ddd": "string",
     *            "ddi": "string",
     *            "phone": "string"
     *          },
     *          "celular": {
     *            "ddd": "string",
     *            "ddi": "string",
     *            "phone": "string"
     *          }
     *        }
     *      }
     * @param data
     * @param enderecoPrincipal
     * @param contatoPrincipal
     */
    constructor( data, enderecoPrincipal, contatoPrincipal ) {

        this.id = data.id;
        this.cnpj_cpf = data.cnpj_cpf;
        this.pessoa = data.pessoa;
        this.rg = data.rg;
        this.im = data.im;
        this.ie = data.ie;
        this.nome = data.nome;
        this.nomeFantasia = data.nomeFantasia;
        this.codigo = data.codigo;
        this.situacaoClienteId = data.situacaoClienteId;
        this.dataCadastro = data.dataCadastro;

        this.enderecoPrincipal = {
            cep : enderecoPrincipal.cep,
            codigoIBGE : enderecoPrincipal.codigoIBGE,
            endereco : enderecoPrincipal.endereco,
            numero : enderecoPrincipal.numero,
            bairro : enderecoPrincipal.bairro,
            complemento : enderecoPrincipal.complemento,
            cidade : enderecoPrincipal.cidade,
            uf : enderecoPrincipal.uf,
            cidadeEstrangeiroId : enderecoPrincipal.cidadeEstrangeiroId
        }

        this.contatoPrincipal = {
            email: contatoPrincipal.email,
            telefone: {
                ddd: contatoPrincipal.telefone.ddd,
                ddi: contatoPrincipal.telefone.ddi,
                phone: contatoPrincipal.telefone.phone,
            },
            telefone2: {
                ddd: contatoPrincipal.telefone2.ddd,
                ddi: contatoPrincipal.telefone2.ddi,
                phone: contatoPrincipal.telefone2.phone,
            },
            celular: {
                ddd: contatoPrincipal.celular.ddd,
                ddi: contatoPrincipal.celular.ddi,
                phone: contatoPrincipal.celular.phone,
            }
        }
    }
}
