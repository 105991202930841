import {types} from 'mobx-state-tree';
import UserModel from '../models/user-model';

const UserStore = types
    .model('UserStore', {
        users: types.optional(types.array(UserModel), []),
    })
    .actions((self) => ({
        setUsers(users) {
            self.users = users;
        },
        addAll: (users) => self.users.push(...users),
    }))
    .views((self) => ({
        getUserById: (id) => self.users.find((e) => e.id === id),
        getDrivers() {
            return self.users;
        },
        get list() {
            return self.users.toJSON();
        },
    }));

export default UserStore;

