import React, {useEffect, useMemo, useRef, forwardRef, useImperativeHandle} from 'react';
import {useField} from '@unform/core';
import Select, {components} from 'react-select';
import './react-select.sass';

const ReactSelectNotScoped = forwardRef((props, ref) => {
    const innerRef = useRef(null);

    const {
        name,
        label,
        options,
        multiple,
        onChange,
        isDisabled,
        value,
        ...rest
    } = props;

    useImperativeHandle(ref, () => ({
        setValue: innerRef.current?.select?.setValue,
        getValue: () => innerRef.current.state.value
    }), []);

    const _defaultValue = useMemo(() => {
        if (!value) return null;

        if (!multiple) {
            return options.find((option) => option.id === value);
        }

        return options.filter((option) => value.includes(option.id));
    }, []);

    return (<Select
            name={name}
            aria-label={name}
            options={options}
            isMulti={multiple}
            isDisabled={isDisabled}
            defaultValue={_defaultValue}
            ref={innerRef}
            onChange={onChange}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            components={{
                DropdownIndicator,
                IndicatorSeparator,
                Control,
            }}
            isClearable
            {...rest}
        />
    );
});

const ReactSelectScoped = forwardRef((props, ref) => {
    const innerRef = useRef(null);

    const {
        name,
        label,
        options,
        multiple,
        onChange,
        isDisabled,
        keyField,
        ...rest
    } = props;

    useImperativeHandle(ref, () => ({
        setValue: innerRef.current?.select?.setValue,
        getValue: () => innerRef.current.state.value
    }), []);

    const {fieldName, registerField, defaultValue, error} = useField(name);

    const parseSelectValue = (selectRef) => {
        const selectValue = selectRef.state.value;
        if (!multiple) {
            return selectValue ? selectValue.id : undefined;
        }

        return selectValue ? selectValue.map((option) => option.id) : [];
    };

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: innerRef.current,
            path: 'state.value',
            getValue: (ref) => {
                if (multiple) {
                    if (!ref.state.value) {
                        return undefined;
                    }
                    return ref.state.value.map((option) => option.id);
                }
                if (!ref.state.value) {
                    return undefined;
                }
                return ref.state.value[keyField];
            },
            parseValue: parseSelectValue,
            clearValue: (ref) => {
                ref.select.clearValue();
            },
        });
    }, [fieldName]);

    const _defaultValue = useMemo(() => {
        if (!defaultValue) return null;

        if (!multiple) {
            return options.find((option) => option.id === defaultValue);
        }

        return options.filter((option) => defaultValue.includes(option.id));
    }, []);

    return (
        <>
            {label && <label htmlFor={fieldName}>{label}</label>}

            <Select
                name={fieldName}
                aria-label={fieldName}
                options={options}
                isMulti={multiple}
                isDisabled={isDisabled}
                defaultValue={_defaultValue}
                ref={innerRef}
                onChange={onChange}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.title}
                components={{
                    DropdownIndicator,
                    IndicatorSeparator,
                    Control,
                }}
                isClearable
                {...rest}
            />

            {error && <span className="error">{error}</span>}
        </>
    );
});


const InnerReactSelect = forwardRef((props, ref) => {
    const {
        scoped,
        ...rest
    } = props;

    if (!scoped) {
        return <ReactSelectNotScoped {...rest} ref={ref} />;
    }

    return <ReactSelectScoped {...rest} ref={ref} />;
});
InnerReactSelect.defaultProps = {
    keyField: 'id',
    scoped: true,
};
export default InnerReactSelect;

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="far fa-chevron-down" style={{color: 'black'}}/>
        </components.DropdownIndicator>
    );
};

const IndicatorSeparator = () => null;

const Control = (props) => (
    <components.Control
        {...props}
        className="custom-shadow rounded-pill px-2 select"
    />
);
