import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import GroupForm from '../../views/group/group-form';
import GroupTable from '../../views/group/group-table';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from "../../hooks/use-services";
import PermissionTabs from "../../views/permission";

const permissions = ['notification', 'driver', 'geofence', 'maintenance', 'attribute'];

/**
 *
 * Group class container
 *
 * @param props
 * @return {*}
 * @constructor
 */
const GroupContainer = (props) => {

    const intl = useIntl();
    const {toast} = useAlerts();
    const {groupService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('groupList');
    const [groups, setGroups] = useState([]);
    const [data, setData] = useState({});
    const [selectedGroup, setSelectedGroup] = useState(null);


    useEffect(() => {
        (async () => {
            const data = await groupService.list();
            setGroups(data);
        })();
    }, []);

    const getGroupsSelect = () => {
        const data = [];
        groups.map((group) => {
            data.push({id: group.id, title: group.name});
        });
        return data;
    }

    const groupForm = (data) => {
        setData(Object.keys(data).length ? data : {});
        setView('groupForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await groupService.save(data);
            setGroups([...groups, result]);
            setView('groupList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            console.log(e);
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'groupList') => {
        try {
            showModalLoading(true);
            const index = groups.findIndex(({id}) => id === data.id);
            const result = await groupService.update({
                ...groups[index],
                ...data,
            });
            groups.splice(index, 1, result);
            setGroups([...groups]);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await groupService.remove(data.id);
            setGroups(groups.filter(({id}) => id !== data.id));
            setView('groupList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const showPermissionTabs = (data) => {
        setSelectedGroup({groupId: data.id});
        setView('groupPermissions');
    };


    return (
        <>
            {
                {
                    groupList: (
                        <GroupTable
                            list={groups}
                            groupForm={groupForm}
                            removeRegister={removeRegister}
                            permissions={showPermissionTabs}
                            loading={isModalLoading}
                        />
                    ),
                    groupForm: (
                        <GroupForm
                            cancel={() => setView('groupList')}
                            data={Object.assign({}, data)}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            groups={getGroupsSelect()}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                        />
                    ),
                    groupPermissions: (
                        <PermissionTabs
                            options={permissions}
                            data={selectedGroup}
                            cancel={() => setView('groupList')}/>
                    )
                }[view]
            }
        </>
    );
};

export default GroupContainer;
