import React, {useEffect, useRef} from 'react';
import {useField} from '@unform/core';

const TextArea = ({name, rows, cols, className, ...rest}) => {
    const textAreaRef = useRef(null);
    const {fieldName, defaultValue, registerField, error} = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: textAreaRef,
            getValue: (ref) => {
                return ref.current.value;
            },
            setValue: (ref, value) => {
                ref.current.value = value;
            },
            clearValue: (ref) => {
                ref.current.value = '';
            },
        });
    }, [fieldName, registerField]);


    return (
        <>
            <textarea
                rows={rows}
                cols={cols}
                ref={textAreaRef}
                className={`form-control ${className}`}
                defaultValue={defaultValue}
                {...rest} />

            {error && <span className="error">{error}</span>}
        </>
    );
};

TextArea.defaultProps = {
    rows: 4,
    cols: 50,
};

export {
    TextArea,
};
