import BaseService from './base-service';
import {serialize} from '../helpers/request';

/**
 *
 */
export default class UserService extends BaseService {
    /**
     *
     */
    constructor() {
        super('users');
    }

    /**
     * Save a User.
     * @param {Object} data
     * @return {Object} user created
     */
    save(data) {
        const user = this.removeUnnecessaryData(data);
        return super.save(user);
    }

    /**
     * Remove view attributes from object.
     * @param {Object} data
     * @return {Object} the User.
    */
    removeUnnecessaryData(data) {
        const user = Object.assign({}, data);
        delete user.customerName;
        return user;
    }

    /**
     * Updates a user.
     * @param {*} data
     * @return {Object} the User
     */
    update(data) {
        const user = this.removeUnnecessaryData(data);
        return super.update(user);
    }

    /**
     * Retrieves a list of users by customerId
     * @param {number} customerId
     * @return {Object[]} List of users
     */
    getUsersByCustomerId(customerId) {
        return this.__get(`${this.url}?${serialize(Object.assign({_dc: new Date().getTime()}, {customerId}))}`);
    }
}
