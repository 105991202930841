export const getOffsetTo = (elem, to) => {
  let distance = 0;

  if (elem && elem.offsetParent) {
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem && elem !== to);
  }
  return distance < 0 ? 0 : distance;
};
