import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import ReactSelect from '../../components/react-select';
import {Input as UInput} from '../../components/input';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import './maintenance.sass';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from '../../components/fieldset';
import {GroupButtonForm} from '../../components/group-button-form';

const MaintenanceForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        types,
        loading,
        converterData,
    } = props;

    const formRef = useRef(null);

    const schema = Yup.object().shape({
        name: Yup.string().required('Nome é necessário'),
        type: Yup.string().required('Tipo é necessário'),
        period: Yup.string().required('Período é necessário'),
        start: Yup.string().required('Início é necessário'),
        id: Yup.number(),
    });

    const handleSubmit = async (payload) => {
        try {
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            edit ? updateRegister(payload) : newRegister(payload);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const handleF8Key = () => {
        formRef.current.submitForm();
    };

    const cast = (src) => {
        return Object.assign({}, converterData(src, false));
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={cast(data)}>
            {!!edit && <UInput hidden type="number" name="id"/>}

            <FieldSet className="text-left border p-4" title={'Identificação'}>
                <div className="row">
                    <div className={'col'}>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="name"
                                id="name"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="types"><I18nMessage id="sharedType"/>*</Label>
                            <ReactSelect
                                id={'type'}
                                name="type"
                                options={types}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="maintenanceStart"/>*</Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name="start"
                                id="start"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="maintenancePeriod"/>*</Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name="period"
                                id="period"
                            />
                        </FormGroup>
                    </div>
                </div>
            </FieldSet>
            <br/>
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={handleF8Key}
            />

        </Form>
    );
};

export default MaintenanceForm;
