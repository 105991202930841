import React, {useContext, useEffect, useState} from 'react';
import DriverForm from '../../views/driver/driver-form';
import DriverTable from '../../views/driver/driver-table';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from "../../hooks/use-services";
import useStore from "../../hooks/use-store";

const DriverContainer = (props) => {

    const intl = useIntl();
    const {toast} = useAlerts();
    const {store} = useStore();
    const {driverService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('driverList');
    const [drivers, setDrivers] = useState([]);
    const [data, setData] = useState({});
    const {drivers: driverStore} = store;

    useEffect(() => {
        (async () => {
            const drivers = await driverService.list();
            setDrivers(drivers);
        })();
    }, []);

    const driverForm = (data) => {
        setData(Object.keys(data).length ? data : {});
        setView('driverForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await driverService.save(data);
            setDrivers([...drivers, result]);
            driverStore.setDrivers(drivers);
            setView('driverList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'driverList') => {
        try {
            showModalLoading(true);
            const index = drivers.findIndex(({id}) => id === data.id);
            const result = await driverService.update({
                ...drivers[index],
                ...data,
            });
            drivers.splice(index, 1, result);
            setDrivers([...drivers]);
            driverStore.setDrivers(drivers);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await driverService.remove(data.id);
            setDrivers(drivers.filter(({id}) => id !== data.id));
            driverStore.setDrivers(drivers);
            setView('driverList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };


    return (
        <>
            {
                {
                    driverList: (
                        <DriverTable
                            list={drivers}
                            driverForm={driverForm}
                            removeRegister={removeRegister}
                            loading={isModalLoading}
                        />
                    ),
                    driverForm: (
                        <DriverForm
                            cancel={() => setView('driverList')}
                            data={Object.assign({}, data)}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                        />
                    )
                }[view]
            }
        </>
    );
};

export default DriverContainer;
