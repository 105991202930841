import React, {useContext} from 'react';
import {ThemeContext} from '../../../../apiContexts';
import DeviceInfoAttributes from '../../../../components/device-info-attributes';

const DeviceFooter = (props) => {
  const theme = useContext(ThemeContext);
  const {currentPosition} = props;

  return (
    <DeviceInfoAttributes theme={theme} currentPosition={currentPosition} />
  );
};

export default DeviceFooter;
