import React, {useContext} from 'react';
import {Badge, Button} from 'reactstrap';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';

const StyledButton = styled(Button)`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4) !important;
  background-color: white;
  color: ${(props) => props.theme} !important;
  border: ${(props) =>
    props.withborder ? `1px solid ${props.theme} !important` : 'none'};

  &.active {
    background-color: ${(props) => props.theme} !important;
    color: white !important;
  }

  :hover {
    color: white !important;
    background-color: ${(props) => props.theme} !important;

    & > span {
      color: white !important;
    }
  }

  > span {
    font-size: 1.5rem;
  }
`;

const StyledBadge = styled(Badge)`
  font-size: 65%;
  font-weight: normal;
  background-color: ${(props) =>
    props.transparent ? 'transparent' : props.theme};
`;

const RoundButton = ({
  className,
  labelDirection = 'top',
  label,
  onClick,
  children,
  noBgBadge,
  withBorder,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      style={{pointerEvents: 'all'}}
      className={`${className} d-flex flex-column justify-content-center align-items-center ${labelDirection ===
        'bottom' && 'flex-column-reverse'}`}
    >
      {label && (
        <StyledBadge
          pill
          transparent={noBgBadge | 0}
          theme={theme}
          className={`${labelDirection === 'top' ? 'mb-1' : 'mt-1'}`}
        >
          {label}
        </StyledBadge>
      )}
      <StyledButton
        outline
        className={`rounded-circle shadow-sm`}
        onClick={onClick}
        theme={theme}
        withborder={withBorder | 0}
        {...props}
      >
        {children}
      </StyledButton>
    </div>
  );
};

export default RoundButton;
