import {types} from 'mobx-state-tree';


const SinisterModel = types.model('Sinister', {
    id: types.number,
    number: types.number,
    deviceId: types.number,
    customerId: types.number,
    devicePlate: types.maybeNull(types.string),
    customerName: types.maybeNull(types.string),
    openedBy: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    openingDate: types.maybeNull(types.string),
    openingType: types.maybeNull(types.string),
    openingAddress: types.maybeNull(types.string),
    openingLatitude: types.optional(types.number, 0.0),
    openingLongitude: types.optional(types.number, 0.0),
    openingDescription: types.maybeNull(types.string),
    closedAt: types.maybeNull(types.string),
    closingDate: types.maybeNull(types.string),
    closingType: types.maybeNull(types.string),
    closedBy: types.maybeNull(types.string),
    closingAddress: types.maybeNull(types.string),
    closingDescription: types.maybeNull(types.string),
    closingLatitude: types.optional(types.number, 0.0),
    closingLongitude: types.optional(types.number, 0.0),
    occurenceIds: types.maybeNull(types.string),
    vehicleDamaged: types.boolean,
    occurrences: types.frozen({}),
    attributes: types.frozen({}),
    device: types.optional(types.frozen({})),
    customer: types.optional(types.frozen({})),
});

export default SinisterModel;
