import moment from 'moment';
import React, {useContext} from 'react';
import {ThemeContext} from '../../apiContexts';
import RoundButton from '../../components/roundButton';
import {convertSpeedTo, nomenclatureSpeedOf} from '../../helpers/conversions';
import DeviceFooter from '../personal/device/device-detail/device-footer';
import Velocity from '../shared/velocity';
import './device-big-fleet.sass';
import I18nMessage from '../../components/I18nMessage';
import CommandConfirmationModal from '../modals/CommandConfirmationModal';


const DeviceBigFleet = (props) => {
    const {selectedDevice} = props.devices;
    return (
        <div id="deviceBigFleet">
            <Header {...selectedDevice} server={props.server}/>
            <div className={'location-info'}>
                <Location currentPosition={selectedDevice.currentPosition}/>
                <Driver currentPosition={selectedDevice.currentPosition} drivers={props.drivers}/>
            </div>

            {/* <Pictures /> */}
            <DeviceInfo {...selectedDevice} />
            <DeviceActions {...props} />
            <CommandConfirmationModal
                theme={props.theme}
                name={selectedDevice.name}
                isOpenModalCommand={props.isOpenModalCommand}
                toggleModalCommand={props.toggleModalCommand}
                block={props.block}
                sendCommand={props.sendCommand}
                commandData={props.commandData}
            />
        </div>
    );
};

const Header = ({
                    currentPosition = {},
                    name,
                    uniqueId,
                    server,
                }) => {
    const theme = useContext(ThemeContext);

    return (
        <div
            id="header"
            className="d-flex flex-column align-items-start justify-content-center"
            style={{backgroundColor: theme}}>
            <h4 className="text-truncate text-left">{name}</h4>
            <small>ID: {uniqueId}</small>
            <small>
                {currentPosition.latitude}º, {currentPosition.longitude}º
            </small>
            <Velocity
                speed={convertSpeedTo(
                    currentPosition.speed,
                    server.attributes && server.attributes.speedUnit,
                )}
                limit={
                    server &&
                    server.attributes &&
                    convertSpeedTo(server.attributes.speedLimit, server.attributes.speedUnit)
                }
                unit={nomenclatureSpeedOf(
                    server.attributes && server.attributes.speedUnit,
                )}
            />
        </div>
    );
};

const Location = ({currentPosition}) => {
    const theme = useContext(ThemeContext);

    return (
        <div id="location" className="mt-4">
            <strong className="mb-1" style={{color: theme}}>
                <I18nMessage id="deviceLastUpdate"/>
            </strong>
            <span className="text-gray">
        {currentPosition && currentPosition.fixTime ?
            moment(currentPosition.fixTime).format('YYYY-MM-DD HH:mm:ss') :
            ''}
      </span>
            <strong className="mt-3 mb-1" style={{color: theme}}>
                Endereço
            </strong>
            <span className="text-gray">
        {currentPosition && currentPosition.address ?
            currentPosition.address :
            ''}
      </span>
        </div>
    );
};

const Driver = ({currentPosition, drivers}) => {
    const theme = useContext(ThemeContext);

    const driver = currentPosition && currentPosition.attributes ?
        drivers.getDriverByUniqueId(currentPosition.attributes.driverUniqueId) : null;

    return (
        <div id="driver" className="mt-3">
            <div>
                <strong className="mb-1" style={{color: theme}}>
                    Motorista
                </strong>
                <span className="text-gray">{driver ? driver.name : ''}</span>
            </div>
            <img
                src={`${process.env.PUBLIC_URL}/images/default.jpg`}
                alt="profile"
                className="rounded-circle"
            />
        </div>
    );
};

// const Pictures = () => {
//   const theme = useContext(ThemeContext);

//   return (
//     <div id="picture" className="mt-3">
//       <strong className="mb-1" style={{color: theme}}>
//         Ver detalhes do dispositivo
//       </strong>
//       <UncontrolledCarousel
//         autoPlay={false}
//         items={[
//           {
//             src: `${process.env.PUBLIC_URL}/images/noImgDefault.jpg?1`,
//             caption: '',
//           },
//           {
//             src: `${process.env.PUBLIC_URL}/images/noImgDefault.jpg?2`,
//             caption: '',
//           },
//           {
//             src: `${process.env.PUBLIC_URL}/images/noImgDefault.jpg?3`,
//             caption: '',
//           },
//         ]}
//       />
//     </div>
//   );
// };

const DeviceInfo = (props) => (
    <div id="deviceInfo" className="mt-3">
        <span className="text-gray">Informações do Dispositivo</span>
        <DeviceFooter {...props} />
    </div>
);

const DeviceActions = (props) => {
    const {
        trace,
        traceToggle,
        followToogle,
        follow,
        createGeofence,
        block,
        close
    } = props;
    const theme = useContext(ThemeContext);

    return (
        <div id="deviceActions" className="pb-2">
            <strong className="" style={{color: theme}}>
                Ações para o Dispositivo
            </strong>
            <div className="row d-flex justify-content-center">
                <RoundButton
                    withBorder
                    noBgBadge
                    labelDirection="bottom"
                    label="Rota"
                    active={trace}
                    onClick={traceToggle}>
                    <span className="fal fa-route"/>
                </RoundButton>

                <RoundButton
                    withBorder
                    noBgBadge
                    labelDirection="bottom"
                    onClick={() => block(true)}
                    label="Bloquear">
                    <span className="fas fa-lock-alt text-danger"/>
                </RoundButton>

                <RoundButton
                    labelDirection="bottom"
                    withBorder
                    noBgBadge
                    onClick={() => block(false)}
                    label="Liberar">
                    <span className="fas fa-unlock-alt text-green"/>
                </RoundButton>

                <RoundButton
                    withBorder
                    noBgBadge
                    labelDirection="bottom"
                    label="Seguir"
                    active={follow}
                    onClick={followToogle}>
                    <span className="fas fa-location-arrow"/>
                </RoundButton>

                <RoundButton
                    withBorder
                    noBgBadge
                    labelDirection="bottom"
                    onClick={createGeofence}
                    label="Cerca">
                      <span style={{fontSize: '2rem'}} className="fal fa-location-circle fa-2x" />
                </RoundButton>

                <RoundButton
                    withBorder
                    noBgBadge
                    labelDirection="bottom"
                    label="Fechar"
                    onClick={close}>
                    <span className="fas fa-times text-secondary"/>
                </RoundButton>
            </div>
        </div>
    );
};


export default DeviceBigFleet;
