import {inject, observer} from 'mobx-react';
import React, {useContext} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../../apiContexts';
import {DeviceInfoStyle} from '../styles';
import Actions from './actions';
import Driver from './driver';
import Header from './header';
import Location from './location';
import Contact from './contact';
import DeviceInfoAttributes from '../../../components/device-info-attributes';
import useTimezone from '../../../hooks/use-timezone';
import I18nMessage from '../../../components/I18nMessage';
import {getSnapshot} from 'mobx-state-tree';
import {trigger} from '../../../helpers/events';

const Style = styled.div`
  background-color: white;
  box-shadow: $shadow;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Hr = styled.hr`
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SinisterCreateButton = styled.button` 
   color: #FDA700;
   border-color: #FDA700;
   font-weight: 700;
   font-size: 16px;
   line-height: 19px;
   border-radius: 8px;
   margin: 10px;
   height: 40px;
`;

const Container = styled.div`
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  height: 100%;
  position: absolute !important;
  z-index: 6;
  padding-bottom: 1.5rem;
`;

const SpanSinisterInfo = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #6B6B6B;
`;

const SpanSinister = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #FDA700;
`;

const DeviceDetail = ({
  selected = {},
  server,
  toggleTrace,
  trace,
  createGeofence,
  followToogle,
  follow,
  driver,
  customer,
  session,
  sinisterStore,
}) => {
  const {name, uniqueId, status, currentPosition = {}, attributes} = selected;
  const {timezone} = useTimezone();
  const theme = useContext(ThemeContext);
  const isOpenSinister = attributes.hasOwnProperty('sinisterId');

  const openSinister = () =>{
    trigger('openSinister:click');
    const currentPositionSnapshot = getSnapshot(currentPosition);
    const selectedSnapshot = getSnapshot(selected);
    const customerSnapshot = getSnapshot(customer);
    sinisterStore.newSinister(currentPositionSnapshot, selectedSnapshot, customerSnapshot, session);
  };

  return (
    <Container>
      <Style className="bg-white w-100 custom-shadow">
        <Header {...{...selected, theme}} server={server}/>
        <Contact contact={selected.contact} theme={theme} customer={customer}/>
        <Location
          theme={theme}
          currentPosition={currentPosition}
          timezone={timezone}
        />
        <Driver theme={theme} driver={driver}/>
        {/* <Pictures theme={theme}/> */}
        <Hr/>
        <DeviceInfo currentPosition={currentPosition} theme={theme} />
        <Hr/>
        <Actions
          traceToggle={toggleTrace}
          trace={trace}
          follow={follow}
          followToogle={followToogle}
          createGeofence={createGeofence}
          deviceUniqueId={uniqueId}
        />
        { !isOpenSinister ? <SinisterCreateButton
            className={'btn btn-sinister'}
            onClick={openSinister}>
          <i className="fas fa-shield-alt"/> <I18nMessage id="sinisterCreate"/>
        </SinisterCreateButton> :
        <SpanSinisterInfo><SpanSinister><i className={'fa fa-shield'}/> Sinistro Aberto</SpanSinister> 10/11/22 - 14:15</SpanSinisterInfo>}
      </Style>
    </Container>
  );
};

const DeviceInfo = ({theme, currentPosition}) => (
  <DeviceInfoStyle id="deviceInfo" theme={theme}>
    <span>Informações do Dispositivo</span>
    <DeviceInfoAttributes currentPosition={currentPosition} theme={theme} />
  </DeviceInfoStyle>
);

const mapping = ({store}) => ({
  selected: store.events.currentDevice,
  server: store.server,
  map: store.map,
  session: store.session,
  sinisterStore: store.sinisterStore,
});

export default inject(mapping)(observer(DeviceDetail));
