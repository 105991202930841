import React, {useContext, useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';
import {FieldSet} from '../../components/fieldset';
import {GroupButtonForm} from '../../components/group-button-form';
import {ReactColor} from '../../components/react-color';
import {useIntl} from 'react-intl';
import {ModalContext} from '../../apiContexts';
import useAlerts from '../../hooks/use-alerts';
import useServices from '../../hooks/use-services';
import {useCompany} from '../../providers/company';

const createSchema = Yup.object().shape({
    logoUrl: Yup.string().required().label('URL da Logomarca'),
    theme: Yup.string().required().label('Cor'),
    androidAppUrl: Yup.string().required().label('Android URL'),
    iosAppUrl: Yup.string().required().label('iOS URL'),
    label: Yup.string().required().label('Label'),
});

const editSchema = createSchema.clone().shape({
    id: Yup.number().required().label('ID'),
});

const LOGO_DIMENSION = 350;

const CompanyConfigurationForm = ({onCreateOrUpdateSuccess, onCancel}) => {
    const {data, companyDomain} = useCompany();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const {toast} = useAlerts();

    const companyConfigurationService = useServices().companyConfigurationService;

    const isEditing = !!data.id;

    const intl = useIntl();

    const formRef = useRef(null);
    const imageRef = useRef(null);
    const imageForm = useRef(null);
    const imageFile = useRef(null);

    const uploadLogo = async () => {
        if (imageForm.current === null) throw new Error('Ops! Houve um erro ao salvar a imagem!');
        return companyConfigurationService.upload(imageForm.current);
    };

    const handleCreate = async (payload) => {
        try {
            showModalLoading(true);
            await createSchema.validate(payload, {
                abortEarly: false,
                stripUnknown: true,
            });

            payload.logoUrl = await uploadLogo();

            const cleanedData = createSchema.cast(payload);

            const result = await companyConfigurationService.create(cleanedData);

            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
            onCreateOrUpdateSuccess(result);
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = {};
                err.inner.forEach((error) => {
                    errors[error.path] = error.message;
                });
                formRef.current.setErrors(errors);
            }
            toast.errorStatus(err);
        } finally {
            showModalLoading(false);
        }
    };

    const handleEdit = async (payload) => {
        try {
            showModalLoading(true);

            // means image input was not touched
            if (payload.logoUrl === '') {
                payload.logoUrl = data.logoUrl;
            }

            await editSchema.validate(payload, {
                abortEarly: false,
            });

            // means logoUrl was updated
            if (payload.logoUrl !== data.logoUrl) {
                payload.logoUrl = await uploadLogo();
            }

            const cleanedData = editSchema.cast(payload);

            const result = await companyConfigurationService.update(cleanedData);

            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
            onCreateOrUpdateSuccess(result);
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = {};
                err.inner.forEach((error) => {
                    errors[error.path] = error.message;
                });
                formRef.current.setErrors(errors);
            }
            toast.errorStatus(err);
        } finally {
            showModalLoading(false);
        }
    };

    const onSubmit = (payload) => {
        formRef.current.submitForm(payload);
    };

    const handleSubmit = isEditing ? handleEdit : handleCreate;

    const imageDimensionsFit = (imageSrc) => {
        return new Promise((resolve) => {
            const image = new Image();
            image.onload = function() {
                // if (image.width === LOGO_DIMENSION && image.height === LOGO_DIMENSION) {
                resolve(true);
                // }
                // resolve(false);
            };
            image.src = imageSrc;
        });
    };

    const persistImageLocally = (file) => {
        const reader = new FileReader();
        reader.onload = async function(event) {
            const imageSrc = event.target.result;
            if (!(await imageDimensionsFit(imageSrc))) {
                formRef.current.setFieldError('logoUrl', `Dimensões incorretas! O tamanho da imagem deve ser ${LOGO_DIMENSION} x ${LOGO_DIMENSION} pixels.`);
                return;
            }
            formRef.current.setFieldError('logoUrl', '');
            imageRef.current.src = imageSrc;
        };
        reader.readAsDataURL(file);
    };

    const onPickImage = (event) => {
        const _file = event.currentTarget.files[0];
        imageFile.current = imageFile;
        const ext = _file.name.slice(_file.name.lastIndexOf('.'));
        const form = new FormData();
        form.append('attachment', _file);
        form.append('name', `${companyDomain}-${Date.now()}${ext}`);
        imageForm.current = form;
        persistImageLocally(_file);
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={data}>
            {isEditing && <UInput hidden type="number" name="id"/>}
            <UInput hidden type="text" name="label"/>
            <FieldSet className="text-left border p-4" title={intl.formatMessage({id: 'sharedCompanyConfiguration'})}>
                <div className="row">
                <div className={'col-4'}>
                        <FormGroup className="custom-formGroup">
                            <Label for="name">Logomarca</Label>
                            <img
                                ref={imageRef}
                                src={data.logoUrl}
                                onClick={() => {
                                    const logoUrlRef = formRef.current.getFieldRef('logoUrl');
                                    logoUrlRef.current.click();
                                }}
                                className="rounded img-thumbnail"
                            />
                            <br />
                            <Input
                                tag={(props) => <UInput {...props} hidden />}
                                type='file'
                                name='logoUrl'
                                id='logoUrl'
                                defaultValue=''
                                accept='image/*'
                                onChange={onPickImage}
                            />
                        </FormGroup>
                    </div>

                    <div className={'col'}>
                        <FormGroup className="custom-formGroup">
                            <Label for="name">Android URL</Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="androidAppUrl"
                                id="androidAppUrl"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="phone">iOS  URL</Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="iosAppUrl"
                                id="iosAppUrl"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name">Cor</Label>
                            <ReactColor
                                name="theme"
                                id="theme"
                            />
                        </FormGroup>
                    </div>
                </div>
            </FieldSet>
            <br/>
            <GroupButtonForm
                edit={isEditing}
                loading={isModalLoading}
                cancel={onCancel}
                submit={onSubmit}
            />
        </Form>
    );
};

export default CompanyConfigurationForm;
