import i18n from '../i18n/pt-br';

export const getEventsList = () =>
  Object.entries(i18n).reduce(
      (acc, [id, title]) =>
      String(id).startsWith('event') ?
        [...acc, {id: id.charAt(5).toLowerCase() + id.slice(6), title}] :
        acc,
      [],
  );

export const getAlarmEventsList = () =>
  Object.entries(i18n).reduce(
      (acc, [id, title]) =>
      String(id).startsWith('alarm') ?
        [...acc, {id: id.charAt(5).toLowerCase() + id.slice(6), title}] :
        acc,
      [],
  );


export const on = (eventType, listener) =>{
  document.addEventListener(eventType, listener);
};

export const off = (eventType, listener) =>{
  document.removeEventListener(eventType, listener);
};

export const once = (eventType, listener) =>{
  on(eventType, handleEventOnce);

  const handleEventOnce = (event) =>{
    listener(event);
    off(eventType, handleEventOnce);
  };
};

export const trigger = (eventType, data) =>{
  const event = new CustomEvent(eventType, {detail: data});
  document.dispatchEvent(event);
};
