import React from 'react';
import {Button, FormGroup, Label, Input} from 'reactstrap';
import {Form, Input as UInput} from '@rocketseat/unform';
import * as Yup from 'yup';
import I18nMessage from '../../components/I18nMessage';

const AttributesForm = (props) => {
  const {
    cancel,
    data,
    edit,
    updateRegister,
  } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required('Nome é necessário'),
    value: Yup.string().required('Identificador é necessário'),
  });

  const handleSubmit = ({name, value}) => {
    const obj = Object.assign({}, data);
    const attrs = obj.attributes;
    if (edit) delete obj.attributes[edit];
    obj.attributes = {...attrs, [name]: value};
    updateRegister(obj, 'attributeList');
  };

  const getAttribute = () => {
    if (edit && edit.hasOwnProperty('value')) {
      return edit;
    } else {
      const attributes = Object.entries(data.attributes);
      const obj = {};

      obj['name'] = attributes[0][0];
      obj['value'] = attributes[0][1];

      return obj;
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} schema={schema} initialData={edit && edit.hasOwnProperty('value') && getAttribute()}>
        <div className={'rounded-group-button-form'}>
          <Button className={'btn-circle btn-lg'} onClick={cancel} type="button">
            <i className="fas fa-arrow-left"></i>
          </Button>
          <Button className={'btn-circle btn-lg'} type="submit">
            <i className="fas fa-save"></i>
          </Button>
        </div>
        <div className="mb-2">
          <span className="fas fa-info-circle"/>{' '}
          {edit ?
                        <I18nMessage id="sharedEditAttributeFrom" values={{name: data.name}}/> :
                        <I18nMessage id="sharedCreateAttributeFrom" values={{name: data.name}}/>}
        </div>

        <FormGroup className="custom-formGroup">
          <Label for="name"><I18nMessage id="sharedName"/>*</Label>
          <Input
            tag={UInput}
            type="text"
            name="name"
            id="name"
          />
        </FormGroup>
        <FormGroup className="custom-formGroup">
          <Label for="value"><I18nMessage id="stateValue"/>*</Label>
          <Input
            tag={UInput}
            type="text"
            name="value"
            id="value"
          />
        </FormGroup>
      </Form>
    </div>
  );
};

export default AttributesForm;
