import randomColor from 'randomcolor';
import {useCallback, useEffect, useState} from 'react';
import {mergeAll} from 'rxjs/operators';
import {sharedMapSubjects$} from '../helpers/subjects';
import useStore from '../../../hooks/use-store';

const TRACE_LIMIT = 30;

const colors = {};

let unsubscribe$ = null;

const useSharedMapTrace = (state, dispatch, instance) => {
    const [trace, setTrace] = useState(false);
    const {positions: positionsSocket} = sharedMapSubjects$;
    const {instance: map, deviceMap} = state;
    const {store} = useStore();
    const {events, devices} = store;

    const doBuffer = useCallback(
        (positions) =>
            positions.map(({longitude, latitude, course}) => [
                longitude,
                latitude,
                course,
            ]),
        [],
    );

    const toogleTrace = useCallback(
        (device) => {
            const status = !device.withTrace;
            device.setTrace(status);
            if (status) {
                device.setCustomTraceLimit(TRACE_LIMIT);
                map.addFeatureTrace(
                    device.id,
                    doBuffer(device.positionBuffer.toJSON()),
                    colors[+device.id],
                );
            } else {
                map.removeFeaturesTrace(+device.id);
                device.setCustomTraceLimit();
            }
        },
        [map],
    );

    const hasDeviceSelected = (deviceId) => {
        if (events.currentDevice || devices.selectedDevice) {
            const device = events.currentDevice || devices.selectedDevice;
            if (device.deviceId !== deviceId) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (deviceMap) {
            if (trace) {
                for (const [deviceId, device] of deviceMap) {
                    if (hasDeviceSelected(deviceId)) {
                        return;
                    }
                    if (device.withTrace) continue;
                    const buffer = doBuffer(device.positionBuffer.toJSON());
                    map.addFeatureTrace(deviceId, buffer, colors[deviceId]);
                    device.setTrace(true);
                }
            } else {
                if (unsubscribe$) {
                    // unsubscribe$.unsubscribe();
                    for (const device of deviceMap.values()) {
                        if (hasDeviceSelected(device.deviceId)) {
                            return;
                        }
                        if (!device.withTrace) continue;
                        device.setTrace(false);
                    }
                    map.removeFeaturesTrace();
                }
            }
        }
    }, [trace, deviceMap]);

    useEffect(() => {
        if (map && deviceMap) {
            map.initTraceLayer();

            const c = randomColor({
                luminosity: 'dark',
                count: deviceMap.size,
            });

            for (const deviceId of deviceMap.keys()) {
                colors[deviceId] = c.pop();
            }

            unsubscribe$ = positionsSocket
                .pipe(mergeAll())
                .subscribe(({deviceId}) => {
                    const device = deviceMap.get(+deviceId);
                    if (hasDeviceSelected(device.deviceId)) {
                        return;
                    }
                    if (device.withTrace) {
                        const buffer = doBuffer(device.positionBuffer.toJSON());
                        map.updateTrace(deviceId, buffer);
                    }
                });
        }
    }, [map, deviceMap]);

    useEffect(() => {
        return () => {
            unsubscribe$ && unsubscribe$.unsubscribe();
        };
    }, []);

    return {trace, setTrace, toogleTrace};
};

export default useSharedMapTrace;
