export default {
  'sharedLoading': 'Loading...',
  'sharedHide': 'Hide',
  'sharedSave': 'Save',
  'sharedSet': 'Set',
  'sharedCancel': 'Cancel',
  'sharedAdd': 'Add',
  'sharedEdit': 'Edit',
  'sharedRemove': 'Remove',
  'sharedRegister': 'Register',
  'sharedOf': 'of',
  'sharedLogout': 'Logout',
  'sharedYes': 'Yes',
  'sharedNo': 'No',
  'sharedLogin': 'Login',
  'sharedComment': 'Comment',
  'sharedNext': 'Next',
  'sharedPrev': 'Prev',
  'sharedAll': 'All',
  'sharedFirst': 'First',
  'sharedLast': 'Last',
  'sharedNoDataText': 'There is no data to display',
  'sharedEditAttributeFrom': 'Editing Attribute From {name}',
  'sharedCreateAttributeFrom': 'Creating Attribute At {name}',
  'sharedRemoveConfirm': 'Remove item?',
  'sharedKm': 'km',
  'sharedMi': 'mi',
  'sharedNmi': 'nmi',
  'sharedKn': 'kn',
  'sharedKmh': 'km/h',
  'sharedMph': 'mph',
  'sharedHour': 'Hour',
  'sharedMinute': 'Minute',
  'sharedSecond': 'Second',
  'sharedDays': 'days',
  'sharedHours': 'hours',
  'sharedMinutes': 'minutes',
  'sharedDecimalDegrees': 'Decimal Degrees',
  'sharedDegreesDecimalMinutes': 'Degrees Decimal Minutes',
  'sharedDegreesMinutesSeconds': 'Degrees Minutes Seconds',
  'sharedName': 'Name',
  'sharedDescription': 'Description',
  'sharedSearch': 'Search',
  'sharedGeofence': 'Geofence',
  'sharedGeofences': 'Geofences',
  'sharedSinister': 'Sinister',
  'sharedCompanyConfiguration': 'Company Configuration',
  'sinisterOpen': 'Sinister Open',
  'sinisterCreate': 'Sinister Create',
  'sinisterNumber': 'Number',

  'sinisterRobbery': 'Robbery',
  'sinisterTheft': 'Theft',
  'sinisterMisappropriation': 'Misappropriation',
  'sinisterAccident': 'Accident',
  'sinisterOther': 'Other',

  'sinisterTypeAttemptToTriggerPromptResponse': 'Attempt To Trigger Prompt Response',
  'sinisterTypeLimitExceededInSearchConditions': 'Limit Exceeded In Search Conditions',
  'sinisterTypePoliceArriveAtTheScene': 'Police Arrive At TheScene',
  'sinisterTypePromptResponseActivated': 'Prompt Response Activated',
  'sinisterTypeRegisterThePoliceReport': 'Register The Police Report',
  'sinisterTypeRequestedVehicleForVehicleRecovery': 'Requested Vehicle For Vehicle Recovery',
  'sinisterTypeSupportArrivesOnSite': 'Support Arrives On Site',
  'sinisterTypeSupportDoesOnSitePreservation': 'Support Does On Site Preservation',
  'sinisterTypeSupportOnTheWay': 'Support On TheWay',
  'sinisterTypeSupportStartsPatrolling': 'Support Starts Patrolling',
  'sinisterTypeUninstalledEquipmentFound': 'Uninstalled Equipment Found',
  'sinisterTypeUndefined': 'ERROR!',

  'sinisterClosingDate': 'Closing Date',
  'sinisterClosingType': 'Closing Type',
  'sinisterVehicleDamaged': 'Vehicle Damaged',
  'sinisterClosingAddress': 'Closing Address',
  'sinisterClosingDescription': 'Closing Description',
  'sinisterDevicePlate': 'Plate',
  'sinisterDeviceModel': 'Model',
  'sinisterOpeningDate': 'Opening Date',
  'sinisterStatus': 'Status',
  'sinisterActions': 'Actions',
  'sinisterStatusOpened': 'Opened',
  'sinisterStatusClosedWin': 'Closed',
  'sinisterStatusClosedLost': 'Closed',
  'sinisterTypeVehicleNotFound': 'Vehicle Not Found',
  'sinisterTypeVehicleFound': 'Vehicle Found',
  'sinisterTypeFalsePositive': 'False Positive',
  'sinisterTypeRobbery': 'Robbery',
  'sinisterTypeTheft': 'Theft',
  'sinisterTypeMisappropriation': 'Misappropriation',
  'sinisterTypeAccident': 'Accident',
  'sinisterTypeOther': 'Other',
  'sinisterOccurrenceDate': 'Occurrence Date',
  'sinisterOccurrenceShowMore': 'Show More',
  'sharedKeyword': 'keyword',
  'sharedNotifications': 'Notifications',
  'sharedNotification': 'Notification',
  'sharedAttributes': 'Attributes',
  'sharedAttribute': 'Attribute',
  'sharedDrivers': 'Drivers',
  'sharedDriver': 'Driver',
  'sharedArea': 'Area',
  'sharedSound': 'Notification Sound',
  'sharedType': 'Type',
  'sharedDistance': 'Distance',
  'sharedHourAbbreviation': 'h',
  'sharedMinuteAbbreviation': 'm',
  'sharedSecondAbbreviation': 's',
  'sharedVoltAbbreviation': 'V',
  'sharedLiterAbbreviation': 'l',
  'sharedGallonAbbreviation': 'gal',
  'sharedLiter': 'Liter',
  'sharedImpGallon': 'Imp. Gallon',
  'sharedUsGallon': 'U.S. Gallon',
  'sharedLiterPerHourAbbreviation': 'l/h',
  'sharedLabel': 'Label',
  'sharedGetMapState': 'Get Map State',
  'sharedComputedAttribute': 'Computed Attribute',
  'sharedComputedAttributes': 'Computed Attributes',
  'sharedCheckComputedAttribute': 'Check Computed Attribute',
  'sharedExpression': 'Expression',
  'sharedDevice': 'Device',
  'sharedTestNotification': 'Send Test Notification',
  'sharedCalendar': 'Calendar',
  'sharedCalendars': 'Calendars',
  'sharedFile': 'File',
  'sharedSelectFile': 'Select File',
  'sharedPhone': 'Phone',
  'sharedRequired': 'Required',
  'sharedPreferences': 'Preferences',
  'sharedPermissions': 'Permissions',
  'sharedExtra': 'Extra',
  'sharedTypeString': 'String',
  'sharedTypeNumber': 'Number',
  'sharedTypeBoolean': 'Boolean',
  'sharedTimezone': 'Timezone',
  'sharedInfoTitle': 'Info',
  'sharedSavedCommand': 'Saved Command',
  'sharedSavedCommands': 'Saved Commands',
  'sharedNew': 'New…',
  'sharedShowAddress': 'Show Address',
  'sharedDisabled': 'Disabled',
  'sharedMaintenance': 'Maintenance',
  'sharedMaintenances': 'Maintenances',
  'sharedDeviceAccumulators': 'Accumulators',
  'sharedAlarms': 'Alarms',
  'sharedSavedSuccess': 'Your data has been saved',
  'sharedRemovedSuccess': 'Your data has been removed',
  'sharedUpdatedSuccess': 'Your data has been updated',
  'sharedError': 'Ops! Internal Error',
  'sharedBadRequest': 'Unauthorized Operation',
  'sharedForbidden': 'Has no access rights',
  'sharedUnauthorized': 'Unauthenticated user',
  'sharedNotFound': 'Not Found',
  'sharedActivated': 'Activated',
  'sharedNotActivated': 'Not Activated',
  'sharedDate': 'Date',
  'sharedUserPaymentDetail': 'Payment Data',
  'sharedUser': 'User',
  'sharedCustomers': 'Customers',
  'sharedCustomer': 'Customer',
  'sharedAddress': 'Address',
  'attributeSpeedLimit': 'Speed Limit',
  'attributePolylineDistance': 'Polyline Distance',
  'attributeReportIgnoreOdometer': 'Report: Ignore Odometer',
  'attributeWebReportColor': 'Web: Report Color',
  'attributeDevicePassword': 'Device Password',
  'attributeProcessingCopyAttributes': 'Processing: Copy Attributes',
  'attributeColor': 'Color',
  'attributeWebLiveRouteLength': 'Web: Live Route Length',
  'attributeWebSelectZoom': 'Web: Zoom On Select',
  'attributeWebMaxZoom': 'Web: Maximum Zoom',
  'attributeMailSmtpHost': 'Mail: SMTP Host',
  'attributeMailSmtpPort': 'Mail: SMTP Port',
  'attributeMailSmtpStarttlsEnable': 'Mail: SMTP STARTTLS Enable',
  'attributeMailSmtpStarttlsRequired': 'Mail: SMTP STARTTLS Required',
  'attributeMailSmtpSslEnable': 'Mail: SMTP SSL Enable',
  'attributeMailSmtpSslTrust': 'Mail: SMTP SSL Trust',
  'attributeMailSmtpSslProtocols': 'Mail: SMTP SSL Protocols',
  'attributeMailSmtpFrom': 'Mail: SMTP From',
  'attributeMailSmtpAuth': 'Mail: SMTP Auth Enable',
  'attributeMailSmtpUsername': 'Mail: SMTP Username',
  'attributeMailSmtpPassword': 'Mail: SMTP Password',
  'attributeUiDisableReport': 'UI: Disable Report',
  'attributeUiDisableEvents': 'UI: Disable Events',
  'attributeUiDisableVehicleFetures': 'UI: Disable Vehicle Fetures',
  'attributeUiDisableDrivers': 'UI: Disable Drivers',
  'attributeUiDisableComputedAttributes': 'UI: Disable Computed Attributes',
  'attributeUiDisableCalendars': 'UI: Disable Calendars',
  'attributeUiDisableMaintenances': 'UI: Disable Maintenances',
  'attributeUiHidePositionAttributes': 'UI: Hide Position Attributes',
  'errorAuthorization': 'Invalid credential authorization, please check email and password.',
  'errorTitle': 'Error',
  'errorGeneral': 'Invalid parameters or constraints violation',
  'errorConnection': 'Connection error',
  'errorSocket': 'Web socket connection error',
  'errorZero': 'Can\'t be zero',
  'userEmail': 'Email',
  'userPassword': 'Password',
  'userAdmin': 'Admin',
  'userRemember': 'Remember',
  'userExpirationTime': 'Expiration',
  'userDeviceLimit': 'Device Limit',
  'userUserLimit': 'UserForm Limit',
  'userDeviceReadonly': 'Device Readonly',
  'userLimitCommands': 'Limit Commands',
  'userToken': 'Token',
  'userKeyword': 'Keyword',
  'loginTitle': 'Login',
  'loginLanguage': 'Language',
  'loginRegister': 'Register',
  'loginLogin': 'Login',
  'loginFailed': 'Incorrect email address or password',
  'loginCreated': 'New user has been registered',
  'loginLogout': 'Logout',
  'loginLogo': 'Logo',
  'devicesAndState': 'Devices and State',
  'deviceTitle': 'Devices',
  'deviceIdentifier': 'Identifier',
  'deviceModel': 'Model',
  'deviceContact': 'Contact',
  'deviceCategory': 'Category',
  'deviceLastUpdate': 'Last Update',
  'deviceLimit': 'Device Limit',
  'deviceCommand': 'Command',
  'deviceFollow': 'Follow',
  'deviceTotalDistance': 'Total Distance',
  'deviceTrail': 'Trail',
  'deviceStatus': 'Status',
  'deviceStatusOnline': 'Online',
  'deviceStatusOffline': 'Offline',
  'deviceStatusUnknown': 'Unknown',

  'deviceM2mProvider': 'M2M Provider',
  'deviceIccid': 'ICCID',
  'devicePlate': 'Plate',
  'deviceYear': 'Year',
  'deviceVehicleModel': 'Vehicle Model',
  'deviceFuelType': 'Fuel Type',
  'deviceFuelConsumption': 'Fuel Consumption',
  'deviceManufacturer': 'Manufacturer',
  'deviceColor': 'Color',
  'deviceRenavam': 'Renavam',
  'deviceChassi': 'Chassi',
  'deviceInstallationLocation': 'Installation Location',
  'deviceTechnician': 'Technician',
  'devicePassword': 'Password',
  'deviceSpeedLimit': 'Speed Limit',
  'deviceIgnoreOdometer': 'Ignore odometer in report',
  'deviceSelectedNeverPositioned': 'The selected device never positioned.',

  'groupDialog': 'Group',
  'groupParent': 'Group',
  'groupNoGroup': 'No Group',
  'settingsTitle': 'Settings',
  'settingsUser': 'Account',
  'settingsGroups': 'Groups',
  'settingsServer': 'Server',
  'settingsUsers': 'Users',
  'settingsDistanceUnit': 'Distance Unit',
  'settingsSpeedUnit': 'Speed Unit',
  'settingsVolumeUnit': 'Volume Unit',
  'settingsTwelveHourFormat': '12-hour Format',
  'settingsCoordinateFormat': 'Coordinates Format',
  'reportTitle': 'Reports',
  'reportDevice': 'Device',
  'reportGroup': 'Group',
  'reportFrom': 'From',
  'reportTo': 'To',
  'reportShow': 'Show',
  'reportClear': 'Clear',
  'positionFixTime': 'Time',
  'positionValid': 'Valid',
  'positionAccuracy': 'Accuracy',
  'positionLatitude': 'Latitude',
  'positionLongitude': 'Longitude',
  'positionAltitude': 'Altitude',
  'positionSpeed': 'Speed',
  'positionCourse': 'Course',
  'positionAddress': 'Address',
  'positionProtocol': 'Protocol',
  'positionDistance': 'Distance',
  'positionRpm': 'RPM',
  'positionFuel': 'Fuel',
  'positionPower': 'Power',
  'positionBattery': 'Battery',
  'positionRaw': 'Raw',
  'positionIndex': 'Index',
  'positionHdop': 'HDOP',
  'positionVdop': 'VDOP',
  'positionPdop': 'PDOP',
  'positionSat': 'Satellites',
  'positionSatVisible': 'Visible Satellites',
  'positionRssi': 'RSSI',
  'positionGps': 'GPS',
  'positionRoaming': 'Roaming',
  'positionEvent': 'Event',
  'positionAlarm': 'Alarm',
  'positionStatus': 'Status',
  'positionOdometer': 'Odometer',
  'positionServiceOdometer': 'Service Odometer',
  'positionTripOdometer': 'Trip Odometer',
  'positionHours': 'Hours',
  'positionSteps': 'Steps',
  'positionInput': 'Input',
  'positionOutput': 'Output',
  'positionBatteryLevel': 'Battery Level',
  'positionFuelConsumption': 'Fuel Consumption',
  'positionRfid': 'RFID',
  'positionVersionFw': 'Firmware Version',
  'positionVersionHw': 'Hardware Version',
  'positionIgnition': 'Ignition',
  'positionFlags': 'Flags',
  'positionCharge': 'Charge',
  'positionIp': 'IP',
  'positionArchive': 'Archive',
  'positionVin': 'VIN',
  'positionApproximate': 'Approximate',
  'positionThrottle': 'Throttle',
  'positionMotion': 'Motion',
  'positionArmed': 'Armed',
  'positionAcceleration': 'Acceleration',
  'positionDeviceTemp': 'Device Temperature',
  'positionOperator': 'Operator',
  'positionCommand': 'Command',
  'positionBlocked': 'Blocked',
  'positionDtcs': 'DTCs',
  'positionObdSpeed': 'OBD Speed',
  'positionObdOdometer': 'OBD Odometer',
  'positionDriverUniqueId': 'Driver Unique Id',
  'positionImage': 'Image',
  'positionAudio': 'Audio',
  'serverTitle': 'Server Settings',
  'serverZoom': 'Zoom',
  'serverRegistration': 'Registration',
  'serverReadonly': 'Readonly',
  'serverForceSettings': 'Force Settings',
  'mapTitle': 'Map',
  'mapLayer': 'Map Layer',
  'mapCustom': 'Custom Map',
  'mapCarto': 'Carto Basemaps',
  'mapOsm': 'Open Street Map',
  'mapBingKey': 'Bing Maps Key',
  'mapBingRoad': 'Bing Maps Road',
  'mapBingAerial': 'Bing Maps Aerial',
  'mapBingHybrid': 'Bing Maps Hybrid',
  'mapBaidu': 'Baidu',
  'mapYandexMap': 'Yandex Map',
  'mapYandexSat': 'Yandex Satellite',
  'mapWikimedia': 'Wikimedia',
  'mapShapePolygon': 'Polygon',
  'mapShapeCircle': 'Circle',
  'mapShapePolyline': 'Polyline',
  'mapLiveRoutes': 'Live Routes',
  'mapPoiLayer': 'POI Layer',
  'mapKindStreet': 'Street',
  'mapKindSatelite': 'Satellite',
  'stateTitle': 'State',
  'stateName': 'Attribute',
  'stateValue': 'Value',
  'commandTitle': 'Command',
  'commandSend': 'Send',
  'commandSent': 'Command sent',
  'commandQueued': 'Command queued',
  'commandUnit': 'Unit',
  'commandCustom': 'Custom command',
  'commandMotionInterval': 'Motion interval',
  'commandStopInterval': 'Stop Interval',
  'commandResetOdometer': 'Reset Odometer',
  'commandAlarmIdle': 'Idle Alarm',
  'commandAlarmRemove': 'Remove Alarm',
  'commandDeviceIdentification': 'Device Identification',
  'commandPositionSingle': 'Single Reporting',
  'commandPositionPeriodic': 'Periodic Reporting',
  'commandPositionStop': 'Stop Reporting',
  'commandEngineStop': 'Engine Stop',
  'commandEngineResume': 'Engine Resume',
  'commandAlarmArm': 'Arm Alarm',
  'commandAlarmDisarm': 'Disarm Alarm',
  'commandSetTimezone': 'Set Timezone',
  'commandRequestPhoto': 'Request Photo',
  'commandRebootDevice': 'Reboot Device',
  'commandSendSms': 'Send SMS',
  'commandSendUssd': 'Send USSD',
  'commandSosNumber': 'Set SOS Number',
  'commandSilenceTime': 'Set Silence Time',
  'commandSetPhonebook': 'Set Phonebook',
  'commandVoiceMessage': 'Voice Message',
  'commandOutputControl': 'Output Control',
  'commandVoiceMonitoring': 'Voice Monitoring',
  'commandSetAgps': 'Set AGPS',
  'commandSetIndicator': 'Set Indicator',
  'commandConfiguration': 'Configuration',
  'commandGetVersion': 'Get Version',
  'commandFirmwareUpdate': 'Update Firmware',
  'commandSetConnection': 'Set Connection',
  'commandSetOdometer': 'Set Odometer',
  'commandGetModemStatus': 'Get Modem Status',
  'commandGetDeviceStatus': 'Get Device Status',
  'commandModePowerSaving': 'Modify Power Saving',
  'commandModeDeepSleep': 'Modify Deep Sleep',
  'commandMovementAlarm': 'Movement Alarm',
  'commandFrequency': 'Frequency',
  'commandTimezone': 'Timezone Offset',
  'commandMessage': 'Message',
  'commandRadius': 'Radius',
  'commandEnable': 'Enable',
  'commandData': 'Data',
  'commandIndex': 'Index',
  'commandPhone': 'Phone Number',
  'commandServer': 'Server',
  'commandPort': 'Port',
  'eventAll': 'All Events',
  'eventDeviceOnline': 'Status online',
  'eventDeviceUnknown': 'Status unknown',
  'eventDeviceOffline': 'Status offline',
  'eventDeviceMoving': 'Device moving',
  'eventDeviceStopped': 'Device stopped',
  'eventDeviceInactive': 'Device Inactive',
  'eventDeviceOverspeed': 'Speed limit exceeded',
  'eventDeviceFuelDrop': 'Fuel drop',
  'eventCommandResult': 'Command result',
  'eventGeofenceEnter': 'Geofence entered',
  'eventGeofenceExit': 'Geofence exited',
  'eventAlarm': 'Alarm',
  'eventIgnitionOn': 'Ignition on',
  'eventIgnitionOff': 'Ignition off',
  'eventMaintenance': 'Maintenance required',
  'eventTextMessage': 'Text message received',
  'eventDriverChanged': 'Driver changed',
  'eventQueuedCommandSent': 'Queued Command Sent',
  'eventDeviceFuelIncrease': 'Device Fuel Increase',
  'eventMedia': 'Media',
  'eventsScrollToLast': 'Scroll To Last',
  'alarmGeneral': 'General',
  'alarmSos': 'SOS',
  'alarmVibration': 'Vibration',
  'alarmMovement': 'Movement',
  'alarmLowspeed': 'Low Speed',
  'alarmOverspeed': 'Overspeed',
  'alarmFallDown': 'Fall Down',
  'alarmLowPower': 'Low Power',
  'alarmLowBattery': 'Low Battery',
  'alarmFault': 'Fault',
  'alarmPowerOff': 'Power Off',
  'alarmPowerOn': 'Power On',
  'alarmDoor': 'Door',
  'alarmLock': 'Lock',
  'alarmUnlock': 'Unlock',
  'alarmGeofence': 'Geofence',
  'alarmGeofenceEnter': 'Geofence Enter',
  'alarmGeofenceExit': 'Geofence Exit',
  'alarmGpsAntennaCut': 'GPS Antenna Cut',
  'alarmAccident': 'Accident',
  'alarmTow': 'Tow',
  'alarmIdle': 'Idle',
  'alarmHighRpm': 'High RPM',
  'alarmHardAcceleration': 'Hard Acceleration',
  'alarmHardBraking': 'Hard Braking',
  'alarmHardCornering': 'Hard Cornering',
  'alarmLaneChange': 'Lane Change',
  'alarmFatigueDriving': 'Fatigue Driving',
  'alarmPowerCut': 'Power Cut',
  'alarmPowerRestored': 'Power Restored',
  'alarmJamming': 'Jamming',
  'alarmTemperature': 'Temperature',
  'alarmParking': 'Parking',
  'alarmShock': 'Shock',
  'alarmBonnet': 'Bonnet',
  'alarmFootBrake': 'Foot Brake',
  'alarmFuelLeak': 'Fuel Leak',
  'alarmTampering': 'Tampering',
  'alarmRemoving': 'Removing',
  'notificationType': 'Type of Notification',
  'notificationAlways': 'All Devices',
  'notificationNotificators': 'Channels',
  'notificatorWeb': 'Web',
  'notificatorMail': 'Mail',
  'notificatorSms': 'SMS',
  'notificatorFirebase': 'Mobile',
  'reportNew': 'New Report',
  'commandNew': 'New Command',
  'reportRoute': 'Route',
  'reportEvents': 'Events',
  'reportTrips': 'Trips',
  'reportStops': 'Stops',
  'reportSummary': 'Summary',
  'reportChart': 'Chart',
  'reportConfigure': 'Configure',
  'reportEventTypes': 'Event Types',
  'reportChartType': 'Chart Type',
  'reportShowMarkers': 'Show Markers',
  'reportExport': 'Export',
  'reportEmail': 'Email Report',
  'reportPeriod': 'Period',
  'reportCustom': 'Custom',
  'reportToday': 'Today',
  'reportYesterday': 'Yesterday',
  'reportThisWeek': 'This Week',
  'reportPreviousWeek': 'Previous Week',
  'reportThisMonth': 'This Month',
  'reportPreviousMonth': 'Previous Month',
  'reportDeviceName': 'Device Name',
  'reportAverageSpeed': 'Average Speed',
  'reportMaximumSpeed': 'Maximum Speed',
  'reportEngineHours': 'Engine Hours',
  'reportDuration': 'Duration',
  'reportStartTime': 'Start Time',
  'reportStartAddress': 'Start Address',
  'reportEndTime': 'End Time',
  'reportEndAddress': 'End Address',
  'reportSpentFuel': 'Spent Fuel',
  'reportStartOdometer': 'Odometer Start',
  'reportEndOdometer': 'Odometer End',
  'statisticsTitle': 'Statistics',
  'statisticsCaptureTime': 'Capture Time',
  'statisticsActiveUsers': 'Active Users',
  'statisticsActiveDevices': 'Active Devices',
  'statisticsRequests': 'Requests',
  'statisticsMessagesReceived': 'Messages Received',
  'statisticsMessagesStored': 'Messages Stored',
  'statisticsGeocoder': 'Geocoder Requests',
  'statisticsGeolocation': 'Geolocation Requests',

  'maintenanceStart': 'Start',
  'maintenancePeriod': 'Period',

  'customerCpfCnpj': 'Tax ID',
  'customerType': 'Type',
  'customerName': 'Name',
  'customerStreet': 'Street',
  'customerNumber': 'Number',
  'customerComplement': 'Complement',
  'customerNeighborhood': 'Neighborhood',
  'customerCity': 'City',
  'customerState': 'State',
  'customerPostalCode': 'CEP',
  'customerPhone': 'Phone',
  'customerCellphone': 'Cellphone',
  'customerNotificationEmail': 'Primary email',
  'customerSecondaryEmail': 'Secondary Email',
  'customerCategory': 'Category',
  'customerStatus': 'Status',
  'customerNotes': 'Notes',
  'customerDisabled': 'Disabled',
  'customerRegistrationDate': 'Registration Date',
  'customerExpirationDate': 'Expiration Date',
  'customerInvoiceDay': 'Invoice Day',
  'customerAttributes': 'Attributes',

  'categoryArrow': 'Arrow',
  'categoryDefault': 'Default',
  'categoryAnimal': 'Animal',
  'categoryBicycle': 'Bicycle',
  'categoryBoat': 'Boat',
  'categoryBus': 'Bus',
  'categoryCar': 'Car',
  'categoryCrane': 'Crane',
  'categoryHelicopter': 'Helicopter',
  'categoryMotorcycle': 'Motorcycle',
  'categoryOffroad': 'Offroad',
  'categoryPerson': 'Person',
  'categoryPickup': 'Pickup',
  'categoryPlane': 'Plane',
  'categoryShip': 'Ship',
  'categoryTractor': 'Tractor',
  'categoryTrain': 'Train',
  'categoryTram': 'Tram',
  'categoryTrolleybus': 'Trolleybus',
  'categoryTruck': 'Truck',
  'categoryVan': 'Van',
  'category_20ft_container': '20ft Container',
  'category_40ft_container': '40ft Container',
  'category_airliner': 'Airliner',
  'category_ambulance': 'Ambulance',
  'category_backhoe_loader': 'Backhoe Loader',
  'category_bicycle': 'Bicycle',
  'category_box_trailer': 'Box Trailer',
  'category_bulldozer': 'Bulldozer',
  'category_bus': 'Bus',
  'category_cabriolet': 'Cabriolet',
  'category_camper_van': 'Camper Van',
  'category_car': 'Car',
  'category_car_trailer': 'Car Trailer',
  'category_caravan': 'Caravan',
  'category_concrete_pump': 'Concrete Pump',
  'category_containerized_generator_trailer': 'Containerized Generator Trailer',
  'category_cruiser_motorcycle': 'Cruiser Motorcycle',
  'category_excavator': 'Excavator',
  'category_executive_car': 'Executive Car',
  'category_farm_tractor': 'Farm Tractor',
  'category_fire_truck': 'Fire Truck',
  'category_flatbed_trailer_bulkhead': 'Flatbed Trailer Bulkhead',
  'category_forklift_truck': 'Forklift Truck',
  'category_fuel_tank_truck': 'Fuel Tank Truck',
  'category_gully_emptier': 'Gully Emptier',
  'category_hatchback': 'Hatchback',
  'category_haul_truck': 'Haul Truck',
  'category_helicopter': 'Helicopter',
  'category_luxury_car': 'Luxury Car',
  'category_mixer_truck': 'Mixer Truck',
  'category_motorcycle': 'Motorcycle',
  'category_pedicab': 'Pedicab',
  'category_pickup': 'Pickup',
  'category_police': 'Police',
  'category_recovery_truck': 'Recovery Truck',
  'category_reefer_trailer': 'Reefer Trailer',
  'category_roadside_assistance_car': 'Roadside Assistance Car',
  'category_van': 'Van',
  'category_sewer_cleaning_truck': 'Sewer Cleaning Truck',
  'category_skip': 'Skip',
  'category_steamroller': 'Steamroller',
  'category_swap_tank_container': 'Swap Tank Container',
  'category_tanker_trailer': 'Tanker Trailer',
  'category_taxi': 'Taxi',
  'category_tow_rig': 'Tow Rig',
  'category_tow_truck': 'Tow Truck',
  'category_tractor_flatbed_trailer': 'Tractor Flatbed Trailer',
  'category_tractor_trailer': 'Tractor-trailer',
  'category_tractor_unit': 'Tractor Unit',
  'category_truck': 'Truck',
  'category_ultra_silent_generator_trailer': 'Ultra-silent Generator Trailer',
  'category_waste_tanker_trailer': 'Waste Tanker Trailer',
  'category_water_tank_truck': 'Water Tank Truck',
  'category_default': 'Default',
  'category_drone': 'Drone',
  'label': 'Label',
  'labels': 'Labels',
};

