import React from 'react';
import {IntlProvider} from 'react-intl';
import messages_pt_br from '../../i18n/pt-br';
import messages_en from '../../i18n/en';

const DEFAULT_LOCALE = navigator.language.split(/[-_]/)[0];

const messages = {
  'pt': messages_pt_br,
  'en': messages_en,
};

const LanguageProvider = (props) =>{
  return (
    <IntlProvider
      key={DEFAULT_LOCALE}
      locale={DEFAULT_LOCALE}
      messages={messages[DEFAULT_LOCALE]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};

export default LanguageProvider;
