import {types} from 'mobx-state-tree';
import CategoryModel from '../models/category-model';
import i18n from './translate-store';

// const Categories = [
//     'arrow', 'default', 'animal', 'bicycle', 'boat', 'bus', 'car', 'crane', 'helicopter', 'motorcycle',
//     'offroad', 'person', 'pickup', 'plane', 'ship', 'tractor', 'train', 'tram', 'trolleybus', 'truck', 'van'
// ];
const Categories = [
  '20ft_container',
  '40ft_container',
  'airliner',
  'ambulance',
  'backhoe_loader',
  'bicycle',
  'box_trailer',
  'bulldozer',
  'bus',
  'cabriolet',
  'camper_van',
  'car',
  'car_trailer',
  'caravan',
  'concrete_pump',
  'containerized_generator_trailer',
  'cruiser_motorcycle',
  'default',
  'drone',
  'excavator',
  'executive_car',
  'farm_tractor',
  'fire_truck',
  'flatbed_trailer_bulkhead',
  'forklift_truck',
  'fuel_tank_truck',
  'gully_emptier',
  'hatchback',
  'haul_truck',
  'helicopter',
  'luxury_car',
  'mixer_truck',
  'motorcycle',
  'pedicab',
  'pickup',
  'police',
  'recovery_truck',
  'reefer_trailer',
  'roadside_assistance_car',
  'sewer_cleaning_truck',
  'skip',
  'steamroller',
  'swap_tank_container',
  'tanker_trailer',
  'taxi',
  'tow_rig',
  'tow_truck',
  'tractor_flatbed_trailer',
  'tractor_trailer',
  'tractor_unit',
  'truck',
  'ultra_silent_generator_trailer',
  'van',
  'waste_tanker_trailer',
  'water_tank_truck',
];

const CategoryStore = types
    .model('CategoryStore', {
      types: types.optional(types.array(CategoryModel), []),
    })
    .actions((self) => ({
      afterCreate() {
        const items = [];

        Categories.map( (item) => {
          items.push({
            key: item,
            name: i18n.strings['category_' + item]});
        });

        self.setTypes(items);
      },
      setTypes( data ) {
        self.types = data;
      },
    }));

export default CategoryStore;

