import {types} from 'mobx-state-tree';

const SellerModel = types
    .model('SellerModel', {
      email: types.optional(types.string, ''),
      password: types.optional(types.string, ''),
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      cpfCnpj: types.optional(types.string, ''),
      businessDescription: types.optional(types.string, ''),
      birthDate: types.optional(types.string, ''),
      phoneNumber: types.optional(types.string, ''),
      postalCode: types.optional(types.string, ''),
      number: types.optional(types.string, ''),
      neighborhood: types.optional(types.string, ''),
      complement: types.optional(types.string, ''),
      city: types.optional(types.string, ''),
      state: types.optional(types.string, ''),
      taxpayerId: types.optional(types.string, ''),
      type: types.optional(types.string, ''),
      statementDescriptor: types.optional(types.string, ''),
    })
    .views( (self) => {
      return {
        getEmail() {
          return self.email;
        },
        getPassword() {
          return self.password;
        },
        getFirstName() {
          return self.firstName;
        },
        getLastName() {
          return self.lastName;
        },
        getCpfCnpj() {
          return self.cpfCnpj;
        },
        getBusinessDescription() {
          return self.businessDescription;
        },
        getBirthDate() {
          return self.birthDate;
        },
        getPhoneNumber() {
          return self.phoneNumber;
        },
        getPostalCode() {
          return self.postalCode;
        },
        getNumber() {
          return self.number;
        },
        getNeighborhood() {
          return self.neighborhood;
        },
        getComplement() {
          return self.complement;
        },
        getCity() {
          return self.city;
        },
        getState() {
          return self.state;
        },
        getTaxpayerId() {
          return self.taxpayerId;
        },
        getTypes() {
          return self.type;
        },
        getStatementDescriptor() {
          return self.statementDescriptor;
        },
      };
    })
    .actions((self) => ({
      setEmail(data) {
        self.email = data;
      },
      setPassword(data) {
        self.password = data;
      },
      setFirstName(data) {
        self.firstName = data;
      },
      setLastName(data) {
        self.lastName = data;
      },
      setCpfCnpj( data ) {
        self.cpfCnpj = data;
      },
      setBusinessDescription( data ) {
        self.businessDescription = data;
      },
      setBirthDate( data ) {
        self.birthDate = data;
      },
      setPhoneNumber( data ) {
        self.phoneNumber = data;
      },
      setPostalCode( data ) {
        self.postalCode = data;
      },
      setNumber( data ) {
        self.number = data;
      },
      setNeighborhood( data ) {
        self.neighborhood = data;
      },
      setComplement( data ) {
        self.complement = data;
      },
      setCity( data ) {
        self.city = data;
      },
      setState( data ) {
        self.state = data;
      },
      setTaxpayerId( data ) {
        self.taxpayerId = data;
      },
      setTypes( data ) {
        self.type = data;
      },
      setStatementDescriptor( data ) {
        self.statementDescriptor = data;
      },
    }));

export default SellerModel;
