import {types} from 'mobx-state-tree';
import i18n from './translate-store';
import VolumeUnitModel from '../models/volume-unit-model';

const VolumeUnitStore = types
    .model('VolumeUnitStore', {
        units: types.optional(types.array(VolumeUnitModel), []),
    })
    .actions((self) => ({
        afterCreate() {
            self.setUnits([{
                key: 'ltr',
                name: i18n.strings.sharedLiterAbbreviation,
                fullName: i18n.strings.sharedLiter,
                factor: 1,
            }, {
                key: 'impGal',
                name: i18n.strings.sharedGallonAbbreviation,
                fullName: i18n.strings.sharedImpGallon,
                factor: 4.546,
            }, {
                key: 'usGal',
                name: i18n.strings.sharedGallonAbbreviation,
                fullName: i18n.strings.sharedUsGallon,
                factor: 3.785,
            }]);
        },
        setUnits(data) {
            self.units = data;
        },
        findRecord(key, unit) {
            return self.units.find((u) => u[key] === unit);
        },
        convertValue(value, unit, back) {
            if (!unit) {
                unit = 'kn';
            }
            const model = self.findRecord('key', unit);
            return back ? value / model.factor : value * model.factor;
        },

        formatValue(value, unit, convert) {
            if (!unit) {
                unit = 'kn';
            }
            const model = self.findRecord('key', unit);
            return (convert ? self.convertValue(value, unit) : value).toFixed(1) + ' ' + model.name;
        },
    }));

export default VolumeUnitStore;

