import {getSession} from '../helpers/auth';
import {dataAsForm} from '../helpers/request';
import request from './http-request';

export const logIn = (data) =>
  request({
    method: 'post',
    url: 'session',
    ...dataAsForm(data),
  });

export const logOut = () => {
  getSession().clear();
  window.location.href = `${process.env.PUBLIC_URL}`;
};

export const getCurrentSession = (token) =>
  request({
    method: 'get',
    url: `session`,
    params: {token: sessionStorage.getItem('userToken')},
  });

export const setLocalSession = (token) => {
  sessionStorage.setItem('token', token);
};

export const setSnapshop = (snapshot) => {
  sessionStorage.setItem('snapshot', JSON.stringify(snapshot));
};
