import React, {useEffect} from 'react';
import styled from 'styled-components';
import MapRightActions from '../../views/map-right-actions';
import useDevices from '../../hooks/use-devices';

const Map = styled.div`
  #right-actions {
    position: absolute;
    right: 1rem;
    z-index: 10;
    top: 1rem;
  }
`;

const MapElement = ({mapRef, ...props}) => {
  const {
    trace,
    setTrace,
    label,
    setLabel,
    selectedMap,
    setSelectedMap,
    follow,
    setFollow,
    showDevices,
    setShowDevices,
    close,
    ...rest
  } = props;

  const {devices} = useDevices();

  useEffect(() => {}, []);

  const toggleLabel = () => {
    setLabel(!label);
  };

  const onChangeMap = (value) => {
    setSelectedMap(value);
  };

  const toggleDevice = () => {
    setShowDevices(!showDevices);
  };

  const toggleTrace = () => {
    setTrace(!trace, devices.selectedDevice);
  };

  return (
    <Map ref={mapRef} {...rest} className="map-element">
      <MapRightActions
        toggleTrace={toggleTrace}
        trace={trace}
        toggleLabel={toggleLabel}
        label={label}
        onChangeMap={onChangeMap}
        selectedMap={selectedMap}
        toggleDevice={toggleDevice}
        showDevices={showDevices}
        close={close}
      />
    </Map>
  );
};

export default MapElement;
