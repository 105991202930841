import React from 'react';
import {DeviceListItemStyle} from '../styles';

const DeviceListItem = ({id, name, status, uniqueId, onSelect}) => (
  <DeviceListItemStyle onClick={() => onSelect(id)}>
    <div className="text-left">
      <div>
        <strong>{name}</strong>
        <span
          className={`status fas fa-circle mr-auto ml-3 ${
            status === 'online' ? 'green' : 'gray'
          }`}
        />
      </div>
      <small>
        <strong>ID: </strong>
        {uniqueId}
      </small>
    </div>
    <span className="fas fa-angle-right" />
  </DeviceListItemStyle>
);

export default DeviceListItem;
