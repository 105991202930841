import {types} from 'mobx-state-tree';
import ENG from '../i18n/en';
import PT_BR from '../i18n/pt-br';


const TranslateStore = types
    .model('TranslateModel', {
        default: types.optional(types.string, 'pt_br'),
        eng: types.frozen(),
        ptBr: types.frozen(),
    })
    .views((self) => {
        return {
            get strings() {
                switch (self.default) {
                    case 'en':
                        return self.eng;
                    case 'pt_br':
                        return self.ptBr;
                    default:
                        return self.ptBr;
                }
            },
        };
    })
    .actions((self) => ({
        afterCreate(data) {
            self.eng = ENG;
            self.ptBr = PT_BR;
        },
        setDefault(data) {
            self.default = data;
        },
    }))
    .create();

export default TranslateStore;

