import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const I18nMessage = (props) =>{
  const {
    id,
    values,
  } = props;

  return <FormattedMessage
    id={id}
    values={values}/>;
};

I18nMessage.propTypes = {
  /**
     * The ID of the translation string.
     */
  id: PropTypes.string.isRequired,
  /**
     * Any variable substitution values.
     */
  values: PropTypes.object,
};

export default I18nMessage;
