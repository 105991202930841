import momentTimezone from 'moment-timezone';
import React, {memo, useContext, useMemo} from 'react';
import {ThemeContext} from '../../apiContexts';
import LoadingBar from '../../components/loadingBar';
import ReactTable from '../../components/reactTable';
import {getColumnWidth} from '../../helpers/report';
import {ButtomStyled} from '../../styles';
import {Header, ListStyle} from './styles';

const EventList = ({
                       className,
                       data,
                       timezone,
                       onSelect,
                       loading,
                       ...props
                   }) => {
    const theme = useContext(ThemeContext);

    const headers = useMemo(
        () => ['Tempo', 'Dispositivo', 'Tipo', 'Observação'],
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Tempo',
                accessor: 'eventTime',
                Cell: ({cell: {value}}) =>
                    momentTimezone(value)
                        .tz(timezone)
                        .format('DD/MM/YYYY [-] HH:mm:ss'),
                minWidth: 200,
            },
            {
                Header: 'Nome',
                accessor: 'name',
                minWidth: 200,
                width: getColumnWidth(data, 'name', 'Nome'),
            },
            {
                Header: 'Tipo',
                accessor: 'type',
                width: getColumnWidth(data, 'type', 'Tipo'),
            },
            {
                Header: 'Observação',
                accessor: 'detail',
                width: getColumnWidth(data, 'detail', 'Observação'),
            },
        ],
        [data],
    );

    const selectItem = (props) => onSelect(props);

    return (
        <ListStyle theme={theme} className="custom-shadow-2">
            <Header>
                <h5>Histório de Eventos</h5>
                <ButtomStyled
                    theme={theme}
                    outline
                    className="custom-shadow custom-border btn-theme mb-3"
                >
                    <span>Selecionar dispositivo</span>
                </ButtomStyled>
            </Header>
            {loading ? (
                <LoadingBar/>
            ) : (
                <ReactTable data={data} columns={columns} selectItem={selectItem}/>
            )}
        </ListStyle>
    );
};

export default memo(EventList);
