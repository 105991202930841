import React, {useContext, useState, Children} from 'react';
import {DropdownItem, DropdownToggle} from 'reactstrap';
import {ButtonStyled, DropdownMenuStyled, DropdownStyled} from './style';
import {ThemeContext} from '../../apiContexts';

const RoundButtonVerticalDropdown = ({
  className,
  icon,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useContext(ThemeContext);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      {!!Children.count(children) && (
        <DropdownStyled
          className={`all-event roundButtonVerticalDropdown ${className}`}
          direction="left"
          isOpen={isOpen}
          toggle={toggle}
          theme={theme}
        >
          <DropdownToggle
            tag={() => (
              <ButtonStyled
                className="rounded-circle"
                onClick={toggle}
                theme={theme}
              >
                <span className={icon} />
              </ButtonStyled>
            )}
            onClick={toggle}
          />

          <DropdownMenuStyled style={{}}>
            {Children.count(children) > 1 ? (
              children.map((e, i) => <DropdownItem tag={() => e} key={i} />)
            ) : (
              <DropdownItem tag={() => children} />
            )}
          </DropdownMenuStyled>
        </DropdownStyled>
      )}
    </>
  );
};

export default RoundButtonVerticalDropdown;
