/* eslint-disable require-jsdoc */
import {useMemo} from 'react';

export function useDeviceStatuses(devices) {
    function capitalizeLabel(label) {
        const lowerCaseLabel = label.toLowerCase();
        const firstCharUpperCase = label.charAt(0).toUpperCase();
        return firstCharUpperCase + lowerCaseLabel.slice(1);
    }

    return useMemo(() => devices.reduce((accu, device) => {
        const isStatusAlreadyMapped = accu.find((state) => state.id === device.status);

        if (isStatusAlreadyMapped) return accu;

        accu.push({
            id: device.status,
            title: capitalizeLabel(device.status),
        });

        return accu;
    }, []), [devices]);
}
