import FullControlService from "./full-control-service";
import {serialize} from '../../helpers/request';
import Cliente from "./model/cliente";

export default class FullControlClienteService extends FullControlService{

    constructor(clientId, secretId) {
        super(clientId, secretId);
    }

    /**
     *
     * @returns {Promise<any|undefined>}
     * @constructor
     */
    ObterSituacaoCliente() {
        return this.request({
            method: 'get',
            url: 'ObterSituacaoCliente'
        });
    }

    /**
     *
     * @param skip
     * @param take
     * @param search
     * @returns {Promise<any|undefined>}
     * @constructor
     */
    ObterClientes(skip, take, search) {
        console.log('here');
        console.log(this);
        return this.request({
            method: 'get',
            url: `ObterClientes?${serialize({skip, take, search })}`
        });
    }

    /**
     *
     * @param cliente Cliente
     * @returns {Promise<any|undefined>}
     * @constructor
     */
    CadastrarOuAtualizar( cliente ){

        if (!(cliente instanceof Cliente)) {
            throw new Error("Must to be a Cliente Instance");
        }

        return this.request({
            method: 'post',
            url: 'Cliente/CadastrarOuAtualizar',
            cliente,
        });
    }

}
