import React from 'react';
import {Input, InputGroup, InputGroupAddon} from 'reactstrap';
import './inputSearch.sass';

const InputSearchWithRef = ({inputRef, ...props}) => {
  return (
    <InputGroup id="search" size="sm" {...props}>
      <Input innerRef={inputRef} {...props} />
      <InputGroupAddon addonType="prepend">
        <span className="fal fa-search" />
      </InputGroupAddon>
    </InputGroup>
  );
};

export default InputSearchWithRef;
