import {inject, observer} from 'mobx-react';
import React from 'react';
import {ThemeContext} from '../../apiContexts';
import Routers from '../../routes';
import ModalContainer from '../modal-container';
import CompanyProvider from '../../providers/company';

const AppContainer = ({theme, ...props}) => {
    return (
        <CompanyProvider>
            <ThemeContext.Provider value={theme}>
                <ModalContainer>
                        <Routers {...props} />
                </ModalContainer>
            </ThemeContext.Provider>
        </CompanyProvider>
    );
};

const mapping = ({store}) => ({
    theme: store.theme,
});

export default inject(mapping)(observer(AppContainer));
