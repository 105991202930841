import {types} from 'mobx-state-tree';

const CustomerModel = types
    .model('CustomerModel', {
        id: types.number,
        groupId: types.maybe(types.number),
        cpfCnpj: types.string,
        type: types.maybeNull(types.string),
        name: types.string,
        street: types.maybeNull(types.string),
        number: types.maybeNull(types.string),
        complement: types.maybeNull(types.string),
        neighborhood: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        state: types.maybeNull(types.string),
        postalCode: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        cellphone: types.maybeNull(types.string),
        notificationEmail: types.maybeNull(types.string),
        secondaryEmail: types.maybeNull(types.string),
        category: types.string,
        status: types.string,
        notes: types.maybeNull(types.string),
        disabled: types.boolean,
        registrationDate: types.maybeNull(types.string), // "2019-10-31T03:00:00.000+0000"
        expirationDate: types.maybeNull(types.string), // "2019-10-31T03:00:00.000+0000"
        invoiceDay: types.maybeNull(types.number),
        attributes: types.frozen({}),
        expirationTime: types.maybeNull(types.string),
    });

export default CustomerModel;

