import {useCallback, useEffect, useState} from 'react';
import useServices from './use-services';

const CACHE_KEY = 'companyData';

export default function useFetchCompany() {
    const companyConfigurationService = useServices().companyConfigurationService;
    const [companyData, setCompanyData] = useState(JSON.parse(sessionStorage.getItem(CACHE_KEY)));

    const [companyDomain] = window.location.hostname?.split('.');

    const fetchCompanyData = async (companyData) => {
        return companyConfigurationService.get(companyData).catch((error) => {
            return null;
        });
    };

    const cacheCompanyData = useCallback((data) => {
        if (!data) return;

        const stringifiedCompanyData = JSON.stringify(data);
        sessionStorage.setItem(CACHE_KEY, stringifiedCompanyData);
    }, []);

    const fetchCompanyDataAndPersist = async (companyLabel) => {
        const _companyData = await fetchCompanyData(companyLabel);
        cacheCompanyData(_companyData);
        setCompanyData(_companyData);
    };

    const refetchCompanyData = useCallback(async () => {
        await fetchCompanyDataAndPersist(companyDomain);
    }, [companyDomain]);

    useEffect(() => {
        fetchCompanyDataAndPersist(companyDomain);
    }, []);

    return {
        companyData,
        refetchCompanyData,
        companyDomain,
    };
}
