import React from 'react';
import {Rnd} from 'react-rnd';
import styled from 'styled-components';
import RoundButton from '../../components/roundButton';
import EventReportContainer from '../../containers/report-container/EventReportContainer';
import RouteReportContainer from '../../containers/report-container/RouteReportContainer';
import StopReportContainer from '../../containers/report-container/StopReportContainer';
import SummaryReportContainer from '../../containers/report-container/SummaryReportContainer';
import TripReportContainer from '../../containers/report-container/TripReportContainer';

const Styles = styled.div`
  .container-fluid {
    padding: 0 !important;
  }
  .custom-shadow-2 {
    position: relative !important;
    width: 100% !important;
    bottom: 0 !important;
  }

  button {
    &.rounded-circle {
      position: absolute !important;
      align-self: flex-end !important;
      z-index: 1 !important;
      top: -0.9em !important;
      right: -1.6rem !important;
    }
  }
`;

const FloatingReport = ({type, location, close, mapRef}) => {
  return (
    <Rnd
      style={{zIndex: 9999}}
      default={{x: 100, y: 100, width: 600, height: 302}}
      minWidth={600}
      minHeight={302}
      maxHeight={302}
      bounds="window">
      <Styles>
        {type && (
          <RoundButton className="mt-3" onClick={close}>
            <span className="fas fa-times text-secondary" />
          </RoundButton>
        )}
        <div>
          {
            {
              route: (
                <RouteReportContainer
                  mapRef={mapRef}
                  location={location}
                  listOnly={true}
                />
              ),
              event: (
                <EventReportContainer
                  mapRef={mapRef}
                  location={location}
                  listOnly={true}
                />
              ),
              trip: (
                <TripReportContainer
                  mapRef={mapRef}
                  location={location}
                  listOnly={true}
                />
              ),
              stop: (
                <StopReportContainer
                  mapRef={mapRef}
                  location={location}
                  listOnly={true}
                />
              ),
              summary: (
                <SummaryReportContainer location={location} listOnly={true} />
              ),
            }[type]
          }
        </div>
      </Styles>
    </Rnd>
  );
};

export default FloatingReport;
