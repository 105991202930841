import React, {useEffect, useRef, useState} from 'react';
import {useField} from '@unform/core';
import InputMask from 'react-input-mask';

const ReactMaskPhone = (props) => {
    const {
        name,
        label,
        disabled
    } = props;
    const inputRef = useRef(null);
    const {fieldName, defaultValue, registerField, error} = useField(name);
    const [value] = useState(defaultValue);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [registerField, fieldName]);


    return (
        <>
            {label && <label htmlFor={fieldName}>{label}</label>}

            <InputMask
                ref={inputRef}
                name={name}
                disabled={disabled}
                className={'form-control'}
                mask={'(99) 99999-9999'}
                defaultValue={defaultValue}
            />

            {error && <span>{error}</span>}
        </>
    );
};

export default ReactMaskPhone;
