const ModalEnum = {
  MAP: 'map',
  NEW_REPORT: 'newReport',
  ACCOUNT: 'account',
  CUSTOMERS: 'customers',
  USERS: 'users',
  GROUP: 'group',
  GEOFENCE: 'geofence',
  SINISTER: 'sinister',
  DEVICE: 'device',
  DRIVER: 'driver',
  NOTIFICATION: 'notification',
  CALENDAR: 'calendar',
  MAINTENANCE: 'maintenance',
  SERVER: 'server',
  COMMAND: 'command',
};

export default ModalEnum;
