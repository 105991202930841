import React, {useEffect, useState} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {TabPane} from 'reactstrap';
import {useIntl} from 'react-intl';
import Switch from 'react-switch';
import useServices from "../../hooks/use-services";


const PermissionTab = ({name, table, auth, data}) => {

    const intl = useIntl();
    const {
        deviceService,
        notificationService,
        maintenanceService,
        groupService,
        driverService,
        userService,
        geofenceService,
        commandService,
        attributesComputedService,
        permissionService,
        calendarService
    } = useServices();
    const [items, setItems] = useState([]);
    const [itemIds, setItemIds] = useState([]);

    const getServiceData = async (query) => {
        switch (name) {
            case 'device':
                return deviceService.list(query);
            case 'notification':
                return notificationService.list(query);
            case 'geofence':
                return geofenceService.list(query);
            case 'group':
                return groupService.list(query);
            case 'calendar':
                return calendarService.list(query);
            case 'driver':
                return driverService.list(query);
            case 'command':
                return commandService.list(query);
            case 'maintenance':
                return maintenanceService.list(query);
            case 'user':
                return userService.list(query);
            case 'attribute':
                return attributesComputedService.list(query);
            default:
                return null;
        }
    };

    const formatItems = (items) => {
        const tmp = [];
        if (name === 'notification') {
            items.map((notification) => {
                const key = 'event' +
                    notification.type.charAt(0).toUpperCase() +
                    notification.type.slice(1);
                tmp.push({
                    id: notification.id,
                    title: intl.formatMessage({id: key}),
                    type: notification.type,
                });
            });

            return tmp;
        }

        return items;
    };

    useEffect(() => {
        (async () => {
            const items = await getServiceData();
            const itemsComp = await getServiceData(data);
            const itemsCompId = itemsComp.map((d) => d.id);
            const selectedItems = items.filter((d) => itemsCompId.includes(d.id));

            selectedItems.map((item) => {
                const index = items.findIndex((i) => i.id === item.id);
                if (index > 0) {
                    const element = items[index];
                    items.splice(index, 1);
                    items.unshift(element);
                }
            }); // code to bring the selected to the front positions

            setItems(formatItems(items));
            setItemIds(selectedItems.map((d) => d.id));
        })();
    }, []);

    const onRowSelect = (row, isSelected, {currentTarget}) => {
        const link = {};
        link[name + 'Id'] = row.id;

        const obj = Object.assign(data, link);

        if (currentTarget) {
            (async () => {
                await permissionService.addPermission(obj);
            })();
        } else {
            (async () => {
                await permissionService.removePermission(obj);
            })();
        }
    };

    const Toggle = (props) => {
        const {checked, disabled, onChange, rowIndex} = props;
        const [value, setValue] = useState(checked);

        if (rowIndex === 'Header') return null;

        const createEvent = (checked) => {
            return {
                currentTarget: checked,
            };
        };

        const handleEvent = (checked) => {
            setValue(checked);
            onChange(createEvent(checked), rowIndex);
        };

        return <Switch
            name={'checkbox' + rowIndex}
            id={'checkbox' + rowIndex}
            checked={value}
            disabled={disabled}
            onChange={(checked) => handleEvent(checked)}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="switch-button"/>;
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: itemIds,
        onSelect: onRowSelect,
        customComponent: Toggle,
        columnWidth: '80px',
    };

    const columnFormatter = (cell, row) => {
        if (typeof cell === 'boolean') {
            if (cell) {
                return intl.formatMessage({id: 'sharedYes'});
            }
            return intl.formatMessage({id: 'sharedNo'});
        }
        return cell;
    };

    const renderTableHeaderColumn = (headers) => {
        return headers.map((item, idx) => {
            return (
                <TableHeaderColumn
                    key={idx}
                    dataField={item.field}
                    dataSort={item.dataSort}
                    dataFormat={columnFormatter}>
                    {intl.formatMessage({id: item.title})}
                </TableHeaderColumn>
            );
        });
    };

    const options = {
        noDataText: intl.formatMessage({id: 'sharedNoDataText'}),
        sizePerPageList: [{
            text: '5', value: 5,
        }, {
            text: '10', value: 10,
        }],
        sizePerPage: 5, // which size per page you want to locate as default
        prePage: intl.formatMessage({id: 'sharedPrev'}), // Previous page button text
        nextPage: intl.formatMessage({id: 'sharedNext'}), // Next page button text
        firstPage: intl.formatMessage({id: 'sharedFirst'}), // First page button text
        lastPage: intl.formatMessage({id: 'sharedLast'}), // Last page button text
    };


    return (
        <TabPane tabId={name}>
            <br/>
            <BootstrapTable
                selectRow={selectRow}
                data={items}
                keyField={table.keyField}
                search={true}
                options={options}
                searchPlaceholder={intl.formatMessage({id: 'sharedSearch'})}
                striped hover pagination>
                {renderTableHeaderColumn(table.headers)}
            </BootstrapTable>
        </TabPane>
    );
};

export default PermissionTab;
