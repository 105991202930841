import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Container} from 'reactstrap';
import styled from 'styled-components';
import {MapContext} from '../../apiContexts';
import {useMap} from '../../components/map/hooks';
import useMapReport from '../../components/map/hooks/useMapReport';
import config from '../../config';
import history from '../../routes/history';
import FloatingReport from '../../views/reports/floating-report';
import MapElement from '../../components/map/MapElement';
import useMapLabel from '../../components/map/hooks/useMapLabel';
import useMapGeofences from '../../components/map/hooks/useMapGeofences';
import useMapTrace from '../../components/map/hooks/useMapTrace';
import {useIntl} from 'react-intl';

const Map = styled.div`
  height: 100%;
  width: 100%;

  .map-element {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 12px;

    .ol-viewport {
      border-radius: 12px;
    }
  }
`;

const AdminReportContainer = ({location, ...props}) => {
    const mapRef = useRef();
    const intl = useIntl();
    const [report, setReport] = useState();

    const query = useMemo(
        () => ({search: location.search, hash: location.hash}),
        [location.search],
    );

    useEffect(() => {
        if (query.search) {
            setReport(query.hash.slice(1));
        } else {
            setReport(null);
        }
    }, [query]);

    const {
        instance,
        setReportData,
        follow,
        setFollow,
        trace,
        setTrace,
        label,
        setLabel,
        selectedMap,
        setSelectedMap,
        showDevices,
        setShowDevices,
    } = useMap(
        {
            target: mapRef,
            center: [0, 0],
            zoom: 4,
            tiers: config.mapConfig,
        },
        useMapReport,
        useMapLabel,
        useMapGeofences,
        useMapTrace,
    );

    // REPORTS
    const {
        selectedItem,
        data,
        setSelectedRow,
        selectedRow,
        setProps,
    } = useContext(MapContext);

    useEffect(() => {
        if (instance && selectedRow && selectedRow !== undefined) {
            instance.selectedReportPoint = selectedRow.id;
        }
    }, [selectedRow, instance]);

    useEffect(() => {
        if (instance && selectedItem) {
            const {longitude, latitude} = selectedItem;

            if (longitude && latitude) {
                instance.goTo([longitude, latitude], 18);
            } else {
                instance.goTo(null, instance._instance.getView().getZoom() - 0.0001);
            }
        }
    }, [instance, selectedItem]);

    useEffect(() => {
        setReportData(data);
    }, [data]);

    const close = () => {
        history.push('/admin');
        instance.removeReports();
    };

    useEffect(() => {
        if (instance) {
            setProps({map: instance});
            const map = instance.instance;
            instance.setI18n(intl);
            map.on('click', (event) => {
                const [feature, layer] =
                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    return [feature, layer];
                }) || [];

                if (!layer) return;

                const layerName = layer.get('name');
                if (layerName === 'reportLayer') {
                    const featureName = feature.get('name');
                    if (featureName !== 'line') {
                        setSelectedRow({
                            id: feature.get('id') || feature.getId(),
                            scroll: true,
                        });
                    }
                }
            });

            map.on('pointermove', (event) => {
                map.getTargetElement().style.cursor = map.hasFeatureAtPixel(event.pixel) ?
                    'pointer' :
                    '';
            });
        }
    }, [instance]);

    return (
        <Container fluid className="px-4 pb-4 position-relative h-100">
            <Map>
                <MapElement
                    mapRef={mapRef}
                    className="custom-shadow-2"
                    {...{
                        trace,
                        setTrace,
                        label,
                        setLabel,
                        selectedMap,
                        setSelectedMap,
                        follow,
                        setFollow,
                        showDevices,
                        setShowDevices,
                    }}
                />
            </Map>
            {report && (
                <FloatingReport type={report} location={location} close={close}/>
            )}
        </Container>
    );
};

export default AdminReportContainer;
