import React, {useEffect, useRef, useState} from 'react';
import * as Sphere from 'ol/sphere';
import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import {fromLonLat, transform} from 'ol/proj';
import DigitizeButton from '@terrestris/react-geo/dist/Button/DigitizeButton/DigitizeButton';
import ToggleGroup from '@terrestris/react-geo/dist/Button/ToggleGroup/ToggleGroup';

import './geofence-map.sass';
import useDevices from '../../hooks/use-devices';
import {useMap} from '../../components/map/hooks';
import config from '../../config';

const MapDraw = (props) => {

    const {
        data,
        setData,
    } = props;

    const mapRef = useRef();
    const [map, setMap] = useState(null);
    const [mapId] = useState(`map-${Math.random()}`);
    const devices = useDevices();

    const {latitude, longitude} = devices.selectedDevice &&
    devices.selectedDevice.currentPosition ? devices.selectedDevice.currentPosition : {latitude: 0.0, longitude: 0.0};

    const {
        instance,
    } = useMap(
        {
            target: mapRef,
            center: fromLonLat([latitude, longitude]),
            zoom: 4,
            tiers: config.mapConfig,
        },
    );

    useEffect(() => {
        if (instance) {
            setMap(instance.instance);
            if (data.area) {
                instance.addGeofence(data, true);
            }
        }
    }, [instance]);

    const onDrawStart = (evt) => {
        clear();
    };

    const onDrawEnd = (evt) => {

        try {
            const geometry = evt.feature.getGeometry();
            const projection = map.getView().getProjection();
            const area = geometryToWkt(projection, geometry);

            setData(area);
        } catch (e) {
            console.log(e);
        }
    };

    const clear = () => {
        map.getLayers().forEach((layer) => {
            layer.getSource().clear(true);
        });
    };

    const onClick = (evt) => {
        clear();
    };

    const geometryToWkt = (projection, geometry) => {
        let result;
        let i;
        let center;
        let radius;
        let edgeCoordinate;
        let points;
        if (geometry instanceof Circle) {
            center = geometry.getCenter();
            radius = geometry.getRadius();
            edgeCoordinate = [center[0] + radius, center[1]];
            center = transform(center, projection, 'EPSG:4326');
            const distance = Sphere.getDistance(center, edgeCoordinate, radius);
            result = 'CIRCLE (';
            result += center[1] + ' ' + center[0] + ', ';
            result += distance.toFixed(1) + ')';
        } else if (geometry instanceof Polygon) {
            points = geometry.getCoordinates();
            result = 'POLYGON((';
            for (i = 0; i < points[0].length; i += 1) {
                const point = transform(points[0][i], projection, 'EPSG:4326');
                result += point[1] + ' ' + point[0] + ', ';
            }
            result = result.substring(0, result.length - 2) + '))';
        } else if (geometry instanceof LineString) {
            points = geometry.getCoordinates();
            result = 'LINESTRING (';
            for (i = 0; i < points.length; i += 1) {
                const point = transform(points[i], projection, 'EPSG:4326');
                result += point[1] + ' ' + point[0] + ', ';
            }
            result = result.substring(0, result.length - 2) + ')';
        }

        return result;
    };

    return (
        <>
            <div ref={mapRef} id={mapId} style={{height: '400px'}}/>
            {map && <div className={'react-geo-actions'}>
                <div style={{paddingTop: 20}}>
                    <ToggleGroup onChange={(evt) => onClick(evt)}>
                        <DigitizeButton
                            className={'btn btn-outline-info btn-sm'}
                            onDrawStart={(evt) => onDrawStart(evt)}
                            onDrawEnd={(evt) => onDrawEnd(evt)}
                            name="drawPolygon"
                            map={map}
                            drawType="Polygon"
                        >
                            <i className="fas fa-draw-polygon"/> Polígono
                        </DigitizeButton>
                        <DigitizeButton
                            className={'btn btn-outline-info btn-sm'}
                            onDrawStart={(evt) => onDrawStart(evt)}
                            onDrawEnd={(evt) => onDrawEnd(evt)}
                            name="drawCircle"
                            map={map}
                            drawType="Circle"
                        >
                            <i className="fas fa-draw-circle"/> Circulo
                        </DigitizeButton>
                        <DigitizeButton
                            className={'btn btn-outline-info btn-sm'}
                            onDrawStart={(evt) => onDrawStart(evt)}
                            onDrawEnd={(evt) => onDrawEnd(evt)}
                            name="drawLine"
                            map={map}
                            drawType="LineString"
                        >
                            <i className="fas fa-horizontal-rule"/> Linha
                        </DigitizeButton>

                        <DigitizeButton
                            className={'btn btn-outline-danger btn-sm'}
                            name="deleteFeature"
                            map={map}
                            onClick={clear}
                            editType="Delete"
                        >
                            <i className="fas fa-trash"/> Limpar
                        </DigitizeButton>
                    </ToggleGroup>

                </div>
            </div>}
        </>
    );
};

export default MapDraw;
