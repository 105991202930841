import {types} from 'mobx-state-tree';
import FCVehicleModel from "./vehicle-model";
import FCSimCardModel from "./simcard-model";

const FCEquipmentModel = types
    .model('FCEquipmentModel', {
        id: types.maybeNull(types.number),
        modeloEquipamentoId: types.maybeNull(types.number),
        modeloEquipamento: types.maybeNull(types.string),
        equipamento: types.maybeNull(types.string),
        simCardId: types.maybeNull(types.number),
        simCard: types.maybeNull(FCSimCardModel),
        numeroChip: types.maybeNull(types.string),
        numeroLinha: types.maybeNull(types.string),
        veiculoId: types.maybeNull(types.number),
        placa: types.maybeNull(types.string),
        veiculo: types.maybeNull(FCVehicleModel),
    })
    .views((self) => {
        return {
            getId() {
                return self.id;
            },
            getModeloEquipamentoId() {
                return self.modeloEquipamentoId;
            },
            getModeloEquipamento() {
                return self.modeloEquipamento;
            },
            getEquipamento() {
                return self.equipamento;
            },
            getSimCardId() {
                return self.simCardId;
            },
            getNumeroChip() {
                return self.numeroChip;
            },
            getNumeroLinha() {
                return self.numeroLinha;
            },
            getVeiculoId() {
                return self.veiculoId;
            },
            getPlaca() {
                return self.placa;
            },
            getVeiculo() {
                return self.veiculo;
            },
            getSimCard() {
                return self.simCard;
            }
        };
    })
    .actions((self) => ({
        setId(data) {
            self.id = data;
        },
        setModeloEquipamentoId( data ) {
            self.modeloEquipamentoId  = data;
        },
        setModeloEquipamento( data ) {
            self.modeloEquipamento  = data;
        },
        setEquipamento( data ) {
            self.equipamento = data;
        },
        setSimCardId( data ) {
            self.simCardId  = data;
        },
        setNumeroChip( data ) {
            self.numeroChip  = data;
        },
        setNumeroLinha( data ) {
            self.numeroLinha  = data;
        },
        setVeiculoId( data ) {
            self.veiculoId  = data;
        },
        setPlaca( data ) {
            self.placa  = data;
        },
        setVeiculo( data ) {
            self.veiculo  = data;
        },
        setSimCard( data ) {
            self.simCard = data;
        }
    }));

export default FCEquipmentModel;
