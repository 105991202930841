import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import styled from 'styled-components';
import {convertSpeedTo, nomenclatureSpeedOf} from '../../../helpers/conversions';
import Velocity from './velocity';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({theme}) => theme};
  color: white;
  line-height: 0.9rem;
  padding: 0.4rem 1rem;
  width: 83%;
  position: relative;

  > h5 {
    font-weight: bold;
    margin-bottom: 0.1rem;
    max-width: 90%;
  }
`;

const Header = ({
  currentPosition = {},
  name,
  uniqueId,
  attributes,
  server,
  theme,
}) => {
  return (
    <Style id="header" theme={theme}>
      <h5 className="text-truncate text-left">{name}</h5>
      <small>ID: {uniqueId}</small>
      <small className="text-truncate w-75 pointer text-left" id="lonlat">
        {currentPosition.latitude}º, {currentPosition.longitude}º
      </small>
      <UncontrolledTooltip placement="right" target="lonlat">
        {currentPosition.latitude}º, {currentPosition.longitude}º
      </UncontrolledTooltip>
      <Velocity
        speed={convertSpeedTo(
            currentPosition.speed,
            server.attributes && server.attributes.speedUnit,
        )}
        // limit={
        //   attributes &&
        //   server.attributes &&
        //   convertSpeedTo(attributes.speedLimit, server.attributes.speedUnit)
        // }
        unit={nomenclatureSpeedOf(
            server.attributes && server.attributes.speedUnit,
        )}
      />
    </Style>
  );
};

export default Header;
