import React, {useEffect, useRef} from 'react';
import Select, {components} from 'react-select';
import './react-select.sass';
import {useField} from '@unform/core';
import {getDeviceIcon} from '../../helpers/deviceIcons';

const ReactSelectCategory = (props) => {
  const {name, label, options, onChange} = props;

  const ref = useRef(null);
  const {fieldName, registerField, defaultValue, error} = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      parseValue: parseSelectValue,
      clearValue: (selectRef) => {
        selectRef.select.clearValue();
      },
    });
  }, [ref.current, fieldName]);

  const parseSelectValue = (selectValue) => {
    return selectValue ? selectValue.id : '';
  };

  const getDefaultValue = () => {
    if (!defaultValue) return null;

    return options.find((option) => option.id === defaultValue);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="far fa-chevron-down" style={{color: 'black'}} />
      </components.DropdownIndicator>
    );
  };

  const IndicatorSeparator = () => null;

  const Control = (props) => (
    <components.Control
      {...props}
      className="custom-shadow rounded-pill px-2"
    />
  );

  const {Option} = components;

  const IconOption = (props) => (
    <Option {...props}>
      <img src={getDeviceIcon(props.data.id, 'right', 'grey')} width={'40px'} />
      &nbsp;{props.data.title}
    </Option>
  );

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Select
        name={fieldName}
        aria-label={fieldName}
        options={options}
        isMulti={false}
        defaultValue={getDefaultValue()}
        ref={ref}
        onChange={onChange}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.title}
        components={{
          DropdownIndicator,
          IndicatorSeparator,
          Control,
          Option: IconOption,
        }}
        isClearable
        {...props}
      />

      {error && <span className="error">{error}</span>}
    </>
  );
};

export default ReactSelectCategory;
