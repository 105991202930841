import React from 'react';

const Velocity = (props) => {
  return (
    <div id="velocity">
      {props.limit && (
        <div className="velocity limit rounded-circle shadow">
          <span>{Number(props.limit ? props.limit : 0).toFixed(1)}</span>
          <small>{props.unit ? props.unit : 'km/h'}</small>
        </div>
      )}
      <div className="velocity rounded-circle shadow">
        <span>{Number(props.speed ? props.speed : 0).toFixed(1)}</span>
        <small>{props.unit ? props.unit : 'km/h'}</small>
      </div>
    </div>
  );
};

export default Velocity;
