import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import SendCommandForm from '../../views/command/send-command-form';
import useStore from "../../hooks/use-store";
import useServices from "../../hooks/use-services";

const CommandContainer = (props) => {
    const {
        toggle,
    } = props;

    const intl = useIntl();
    const {store} = useStore();
    const {commandService} = useServices();
    const {events, knownCommands, allTimezonesStore} = store;
    const [timezones, setTimezones] = useState([]);
    const [commandTypes, setCommandTypes] = useState([]);

    useEffect(() => {
        (async () => {
            const data = [];
            const currentDevice = events.currentDevice;
            const commands = await commandService.getCommandTypes({deviceId: currentDevice.id});

            commands.map((command) => {
                const name = 'command' + command.type.charAt(0).toUpperCase() + command.type.slice(1);
                data.push({id: command.type, title: intl.formatMessage({id: name})});
            });

            setCommandTypes(data);
        })();
    }, [events.currentDevice]);

    useEffect(() => {
        (async () => {
            const data = [];
            allTimezonesStore.timezones.map((timezone) => {
                data.push({id: timezone.key, title: timezone.key});
            });
            setTimezones(data);
        })();
    }, []);


    const send = async (data) => {
        await commandService.send(data);
        toggle();
    };

    return (
        <SendCommandForm
            commandTypes={commandTypes}
            knownCommands={knownCommands}
            selectedDevice={events.currentDevice}
            timezones={timezones}
            sendCommand={send}
        />
    );
};

export default CommandContainer;
