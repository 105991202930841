import momentTimezone from 'moment-timezone';
import React, {memo, useContext, useMemo} from 'react';
import {ThemeContext} from '../../apiContexts';
import LoadingBar from '../../components/loadingBar';
import ReactTable from '../../components/reactTable';
import {convertSpeedTo, nomenclatureSpeedOf} from '../../helpers/conversions';
import {getColumnWidth} from '../../helpers/report';
import {ButtomStyled} from '../../styles';
import {Header, ListStyle} from './styles';

const RouteList = ({
  className,
  data,
  timezone,
  onSelect,
  speedUnit,
  loading,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const columns = useMemo(
      () => [
        {
          Header: 'Nome',
          accessor: 'name',
          minWidth: 200,
          width: getColumnWidth(data, 'name', 'Nome'),
        },
        {
          Header: 'Válido',
          accessor: 'valid',
          Cell: ({cell: {value}}) => (value ? 'Sim' : 'Não'),
        },
        {
          Header: 'Data e Hora',
          accessor: 'fixTime',
          Cell: ({cell: {value}}) =>
            momentTimezone(value)
                .tz(timezone)
                .format('DD/MM/YYYY [-] HH:mm:ss'),
          minWidth: 200,
        },
        {
          Header: 'Latitude',
          accessor: 'latitude',
          Cell: ({cell: {value}}) => `${value}º`,
        },
        {
          Header: 'Longitude',
          accessor: 'longitude',
          Cell: ({cell: {value}}) => `${value}º`,
        },
        {
          Header: 'Altitude',
          accessor: 'altitude',
        },
        {
          Header: 'Velocidade',
          accessor: 'speed',
          Cell: ({cell: {value}}) =>
            `${Number(convertSpeedTo(value, speedUnit)).toFixed(1) ||
            0} ${nomenclatureSpeedOf(speedUnit)}`,
        },
        {
          Header: 'Endereço',
          accessor: 'address',
          Cell: ({cell: {value}}) => value || '-',
          minWidth: 150,
          width: getColumnWidth(data, 'address', 'Endereço'),
        },
      ],
      [data],
  );

  const selectItem = (props) => onSelect(props);

  return (
    <ListStyle theme={theme} className="custom-shadow-2">
      <Header>
        <h5>Histório de Rotas</h5>
        <ButtomStyled
          theme={theme}
          outline
          className="custom-shadow custom-border btn-theme mb-3">
          <span>Selecionar dispositivo</span>
        </ButtomStyled>
      </Header>
      {loading ? (
        <LoadingBar />
      ) : (
        <ReactTable data={data} columns={columns} selectItem={selectItem} />
      )}
    </ListStyle>
  );
};

export default memo(RouteList);
