import {types} from 'mobx-state-tree';

const CustomerModel = types
    .model('CustomerModel', {
        id: types.maybeNull(types.number),
        cc_email: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        password: types.maybeNull(types.string),
        full_name: types.maybeNull(types.string),
        cpf_cnpj: types.maybeNull(types.string),
        phone_number: types.maybeNull(types.string),
        postal_code: types.maybeNull(types.string),
        number: types.maybeNull(types.string),
        neighborhood: types.maybeNull(types.string),
        complement: types.maybeNull(types.string),
        street: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        state: types.maybeNull(types.string),
        resource_uri: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        notes: types.maybeNull(types.string),
        created_at: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
    })
    .views((self) => {
        return {
            getEmail() {
                return self.email;
            },
            getFullName() {
                return self.full_name;
            },
            getCpfCnpj() {
                return self.cpf_cnpj;
            },
            getPhoneNumber() {
                return self.phone_number;
            },
            getPostalCode() {
                return self.postal_code;
            },
            getNumber() {
                return self.number;
            },
            getNeighborhood() {
                return self.neighborhood;
            },
            getComplement() {
                return self.complement;
            },
            getCity() {
                return self.city;
            },
            getStreet() {
                return self.street;
            },
            getState() {
                return self.state;
            },
            getTypes() {
                return self.type;
            },
        };
    })
    .actions((self) => ({
        setEmail(data) {
            self.email = data;
        },
        setCCEmail(data) {
            self.cc_email = data;
        },
        setFullName(data) {
            self.full_name = data;
        },
        setCpfCnpj(data) {
            self.cpf_cnpj = data;
        },
        setPhoneNumber(data) {
            self.phone_number = data;
        },
        setPostalCode(data) {
            self.postal_code = data;
        },
        setNumber(data) {
            self.number = data;
        },
        setNeighborhood(data) {
            self.neighborhood = data;
        },
        setComplement(data) {
            self.complement = data;
        },
        setCity(data) {
            self.city = data;
        },
        setStreet(data) {
            self.street = data;
        },
        setState(data) {
            self.state = data;
        },
        setTypes(data) {
            self.type = data;
        },
    }));

export default CustomerModel;
