import './device-detail.scss';
import React from 'react';
import moment from 'moment';
import {getExternalMapURL} from '../../../helpers/externalResources';

const formatTime = (tz, time) => time
                                  ? moment(time)
                                    .tz(tz)
                                    .format('YYYY-MM-DD HH:mm:ss')
                                  : '';

const Location = ({currentPosition, theme, timezone}) => {
  return (
    <>
      <div className="section-container mt-3">
        <strong className="mb-1" style={{color: theme}}>
          Última atualização
        </strong>
        <span className="text-gray">
          GPS: {formatTime(timezone, currentPosition?.fixTime)}
        </span>
        <span className="text-gray">
          Dispositivo: {formatTime(timezone, currentPosition?.deviceTime)}
        </span>
        <span className="text-gray">
          Servidor: {formatTime(timezone, currentPosition?.serverTime)}
        </span>
      </div>
      <div className='section-container mt-3'>
        <div className='item-row item-space-between'>
          <div className='item-column'>
            <strong className="mb-1" style={{color: theme}}>Endereço</strong>
            <span>{currentPosition.address}</span>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/street.png`}
            alt="street view"
            className="rounded-circle"
            onClick={() => window.open(getExternalMapURL('street', currentPosition), '_blank')}
          />
        </div>
      </div>
    </>
  );
};

export default Location;
