import React, {useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';

const NAVIGATE_TO_LOGIN_TIMEOUT = 5000;

/** */
export default function HashNotValidView() {
    const timeout = useRef(null);
    const history = useHistory();

    useEffect(() => {
        timeout.current = setTimeout(() => {
            history.push('/login');
        }, [NAVIGATE_TO_LOGIN_TIMEOUT]);

        return () => {
            timeout.current && clearTimeout(timeout.current);
        };
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <h1>Link inválido ou expirado!</h1>
            <h5>Você será redirecionado para a página de login em alguns instantes.</h5>
            <Link to='/login'>
                <h6>Voltar à página de login.</h6>
            </Link>
        </div>
    );
}
