import moment from 'moment';
import React, {useContext} from 'react';
import {CardBody, CardHeader, Progress, Card} from 'reactstrap';
import {ThemeContext} from '../../apiContexts';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HeaderTitle = styled.h5`
  font-weight: bold;
  margin-bottom: 0;
`;

const StyledCard = styled(Card)`
  border: none;
  border-radius: 10px;
  box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.1);
  .card-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
  }
  .card-body {
    text-align: center;
    padding-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .card-header,
  .card-body {
    background-color: white;
  }

  ${HeaderTitle} {
    color: ${(props) => props.theme};
    text-align: left;
  }
`;

const ChartCard = ({header, children, ...props}) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledCard theme={theme} {...props}>
      {header && (
        <CardHeader className="py-4">
          <HeaderTitle>{header}</HeaderTitle>
        </CardHeader>
      )}
      <CardBody>{children}</CardBody>
    </StyledCard>
  );
};

ChartCard.defaultProps = {};

export default ChartCard;
