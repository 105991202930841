import {ModalStyled} from '../../styles';
import {Button, ModalBody, ModalFooter} from 'reactstrap';
import I18nMessage from '../../components/I18nMessage';
import React from 'react';
import PropTypes from 'prop-types';


const CommandConfirmationModal = (props) => {
  const msg = props.commandData.type === 'engineStop' ?
        `Tem certeza que deseja bloquear o veículo ${props.name} ?` :
        `Tem certeza que deseja desbloquear o veículo ${props.name} ?`;
  return (
    <ModalStyled
      theme={props.theme}
      isOpen={props.isOpenModalCommand}
      toggle={props.toggleModalCommand}
      className="customModal"
    >
      <ModalBody
        className="d-flex flex-column justify-content-center align-items-center">
        {props.commandData.type === 'engineResume' ?
            <span className="fas fa-unlock-alt fa-5x text-green mb-3" /> :
           <span className="fas fa-lock-alt fa-5x text-danger mb-3" />
        }
        <span dangerouslySetInnerHTML={{__html: msg}} />
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => props.toggleModalCommand()}
          className=" custom-border"
        >
          <I18nMessage id="sharedCancel" />
        </Button>
        <Button
          color="danger"
          onClick={() => props.sendCommand()}
          className="custom-shadow custom-border"
        >
          <I18nMessage id="commandSend" />
        </Button>
      </ModalFooter>
    </ModalStyled>
  );
};

export default CommandConfirmationModal;

CommandConfirmationModal.propTypes = {
  name: PropTypes.string,
  theme: PropTypes.any,
  commandData: PropTypes.any,
  isOpenModalCommand: PropTypes.bool,
  toggleModalCommand: PropTypes.func,
  sendCommand: PropTypes.func,
};
