import {types} from 'mobx-state-tree';

const UserModel = types
    .model('UserModel', {
        id: types.number,
        name: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        login: types.maybeNull(types.string),
        password: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        map: types.maybeNull(types.string),
        latitude: types.maybeNull(types.number),
        longitude: types.maybeNull(types.number),
        zoom: types.maybeNull(types.number),
        twelveHourFormat: types.maybeNull(types.boolean),
        coordinateFormat: types.maybeNull(types.string),
        poiLayer: types.maybeNull(types.string),
        disabled: types.maybeNull(types.boolean),
        administrator: types.maybeNull(types.boolean),
        readonly: types.maybeNull(types.boolean),
        deviceReadonly: types.maybeNull(types.boolean),
        limitCommands: types.maybeNull(types.boolean),
        expirationTime: types.maybeNull(types.string), // "2019-10-31T03:00:00.000+0000"
        deviceLimit: types.maybeNull(types.number),
        userLimit: types.maybeNull(types.number),
        token: types.maybeNull(types.string),
        customerId: types.maybeNull(types.number),
        attributes: types.frozen({}),
    });

export default UserModel;

