import React, {createContext, useEffect, useContext, useRef} from 'react';
import {observer} from 'mobx-react';
import useFetchCompany from '../../hooks/use-fetch-company';
import useCompanyConfigurationStore from "../../hooks/use-company-configuration-store";
import useStore from "../../hooks/use-store";

export const CompanyContext = createContext({});

const CompanyProvider = ({children}) => {
    const logoPreload = useRef(new Image());
    const timeoutRef = useRef();
    const {companyData: company, companyDomain, refetchCompanyData} = useFetchCompany();
    const companyStore = useCompanyConfigurationStore();
    const {store} = useStore();

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            if(companyStore.logoUrl === '') {
                companyStore.setLogoUrl(`${process.env.PUBLIC_URL}/images/thumbnail.svg`)
            }
        }, 5000);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    useEffect(() => {
        if (!company) return;

        const {logoUrl, ..._company} = company;

        logoPreload.current.onload = function() {
            companyStore.setLogoUrl(logoUrl);
        }
        logoPreload.current.onerror = function() {
            companyStore.setLogoUrl(logoUrl);
        }
        logoPreload.current.src = logoUrl;

        companyStore.persist(_company);
        store.updateTheme(_company?.theme); // only for retrocompatibility
    }, [company]);

    return (
        <CompanyContext.Provider value={Object.assign(companyStore, { companyDomain, refetchCompanyData })}>
            {children}
        </CompanyContext.Provider>
    );
}

export function useCompany() {
    return useContext(CompanyContext);
}

export default observer(CompanyProvider);
