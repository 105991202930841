import request from './http-request';

export const getRouteReport = (params) => {
  return request({
    method: 'get',
    url: `reports/route${params ? '?' + params : ''}`,
  });
};

export const getEventReport = (params) => {
  return request({
    method: 'get',
    url: `reports/events${params ? '?' + params : ''}`,
  });
};

export const getTripReport = (params) => {
  return request({
    method: 'get',
    url: `reports/trips${params ? '?' + params : ''}`,
  });
};

export const getStopReport = (params) => {
  return request({
    method: 'get',
    url: `reports/stops${params ? '?' + params : ''}`,
  });
};

export const getSummaryReport = (params) => {
  return request({
    method: 'get',
    url: `reports/summary${params ? '?' + params : ''}`,
  });
};

export const externalReport = (type, params, asExport) => {
  const options = {
    method: 'get',
    url: `reports/${type}?${params}`,
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  };

  if (asExport) options.responseType = 'blob';

  return request(options);
};

export const sinisterReport = (params) => {
  const options = {
    method: 'get',
    url: `reports/sinister?sinisterId=${params}`,
    headers: {
      Accept:
        'application/pdf',
    },
  };

  options.responseType = 'blob';

  return request(options);
};
