import {types} from 'mobx-state-tree';
import axios from 'axios';
import config from '../config';
import SellerModel from '../models/azul-pay/SellerModel';
import CustomerModel from '../models/azul-pay/CustomerModel';

const AzulPayStore = types
    .model('AzulPayStore', {
        apiKeySeller: types.optional(types.string, ''),
        sellers: types.optional(types.array(SellerModel), []),
        customers: types.optional(types.array(CustomerModel), []),
    })
    .views((self) => {
        return {
            getCustomers() {
                return self.customers;
            },
            getCustomerById(id) {

                return axios({
                    method: 'get',
                    url: `${config.azulPay.url}/customers/${id}`,
                    headers: {
                        Authorization: `ApiKey ${self.getApiKeySeller()}`,
                    },
                }).then((response) => {

                    return response.data;
                });
            },
            getApiKeySeller() {
                return self.apiKeySeller;
            },
        };
    })
    .actions((self) => ({
        setSellers(data) {
            self.sellers = data;
        },
        setCustomers(data) {
            self.customers = data;
        },
        addCustomer(data) {
            self.customers.push(data);
        },
        setApiKeySelle(data) {
            self.apiKeySeller = data;
        },
        loadCustomers() {
            return new Promise(async (resolve, reject) => {

                try {
                    let customers = [];

                    let response = await axios({
                        method: 'get',
                        url: `${config.azulPay.url}/customers/?limit=100`,
                        headers: {
                            Authorization: `ApiKey ${self.getApiKeySeller()}`,
                        },
                    });

                    let next = response.data.meta.next;
                    customers = response.data.objects;

                    if (next) {
                        while (next) {

                            response = await axios({
                                method: 'get',
                                url: `${config.azulPay.base}${next}`,
                                headers: {
                                    Authorization: `ApiKey ${self.getApiKeySeller()}`,
                                },
                            });

                            next = response.data.meta.next;
                            customers = customers.concat(response.data.objects);
                        }
                    }

                    self.setCustomers(customers);
                    resolve(customers);
                } catch (e) {
                    reject(e);
                }

            });
        },
        async createCustomer(data) {
            const body = {
                'cc_email': data.cc_email ? data.cc_email : '',
                'email': data.email,
                'full_name': data.full_name,
                'cpf_cnpj': data.cpf_cnpj,
                'phone_number': data.phone_number,
                'postal_code': data.postal_code,
                'number': data.number,
                'neighborhood': data.neighborhood,
                'complement': data.complement,
                'street': data.street,
                'city': data.city,
                'state': data.state,
                'type': data.type,
                'notes': data.notes ? data.notes : '',
            };

            return new Promise((resolve, reject) => {
                fetch(`${config.azulPay.url}/customers/`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `ApiKey ${self.getApiKeySeller()}`,
                    },
                    body: JSON.stringify(body)
                })
                    .then((response) => {
                        response.json().then((data) => {
                            if (response.status === 400) {
                                reject({azulPayErrors: data});
                            } else {
                                self.addCustomer(data);
                                resolve(data);
                            }
                        })
                    });
            });
        },
        updateCustomer(data) {
            const body = {
                'cc_email': data.cc_email ? data.cc_email : '',
                'email': data.email,
                'full_name': data.full_name,
                'cpf_cnpj': data.cpf_cnpj,
                'phone_number': data.phone_number,
                'postal_code': data.postal_code,
                'number': data.number,
                'neighborhood': data.neighborhood,
                'complement': data.complement,
                'street': data.street,
                'city': data.city,
                'state': data.state,
                'type': data.type,
                'notes': data.notes ? data.notes : '',
            };

            return new Promise((resolve, reject) => {
                fetch(`${config.azulPay.url}/customers/${data.id}`, {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `ApiKey ${self.getApiKeySeller()}`,
                    },
                    body: JSON.stringify(body)
                })
                    .then((response) => {
                        response.json().then((data) => {
                            if (response.status === 400) {
                                reject({azulPayErrors: data});
                            } else {
                                self.loadCustomers();
                                resolve(data);
                            }
                        })
                    });
            });
        },
        createSeller() {
            return axios({
                method: 'post',
                url: `${config.azulPay.url}/sellers/`,
                headers: {
                    Authorization: `ApiKey ${self.getApiKeySeller()}`,
                },
                data: {
                    email: self.seller.email,
                    password: self.seller.password,
                    first_name: self.seller.firstName,
                    last_name: self.seller.lastName,
                    cpf_cnpj: self.seller.cpfCnpj,
                    business_description: self.seller.businessDescription,
                    birthdate: self.seller.birthDate,
                    phone_number: self.seller.phoneNumber,
                    postal_code: self.seller.postalCode,
                    number: self.seller.number,
                    neighborhood: self.seller.neighborhood,
                    complement: self.seller.complement,
                    city: self.seller.city,
                    state: self.seller.state,
                    taxpayer_id: self.seller.taxpayerId,
                    types: self.seller.type,
                    statement_descriptor: self.seller.statementDescriptor,
                },
            });
        },
    }));

export default AzulPayStore;
