import {types} from 'mobx-state-tree';

const FCVehicleModel = types
    .model('FCVehicleModel', {
        id: types.maybeNull(types.number),
        veiculo: types.maybeNull(types.string),
        placa: types.maybeNull(types.string),
        cor: types.maybeNull(types.string),
        ano: types.maybeNull(types.number),
        numeroChassi: types.maybeNull(types.string),
        modelo: types.maybeNull(types.string),
        combustivel: types.maybeNull(types.string),
        consumo: types.maybeNull(types.string),
        odometro: types.maybeNull(types.number),
        tipo: types.maybeNull(types.string),
        marca: types.maybeNull(types.string),
        clienteId: types.maybeNull(types.number),

    })
    .views((self) => {
        return {
            getId() {
                return self.id;
            },
            getPlaca() {
                return self.placa;
            },
            getVeiculo() {
                return self.veiculo;
            },
            getCor() {
                return self.cor;
            },
            getAno() {
                return self.ano;
            },
            getNumeroChassi() {
                return self.numeroChassi;
            },
            getModelo() {
                return self.modelo;
            },
            getCombustivel() {
                return self.combustivel;
            },
            getConsumo() {
                return self.consumo;
            },
            getOdometro() {
                return self.odometro;
            },
            getTipo() {
                return self.tipo;
            },
            getMarca() {
                return self.marca;
            },
            getClienteId() {
                return self.clienteId;
            }

        };
    })
    .actions((self) => ({
        setId(data) {
            self.id = data;
        },
        setPlaca( data ) {
            self.placa  = data;
        },
        setVeiculo( data ) {
            self.veiculo  = data;
        },
        setCor( data ) {
            self.cor = data;
        },
        setAno( data ) {
            self.ano = data;
        },
        setNumeroChassi( data ) {
            self.numeroChassi = data;
        },
        setModelo( data ) {
            self.modelo = data;
        },
        setCombustivel( data ) {
            self.combustivel = data;
        },
        setConsumo( data ) {
            self.consumo = data;
        },
        setOdometro( data ) {
            self.odometro = data;
        },
        setTipo( data ) {
            self.tipo = data;
        },
        setMarca( data ) {
            self.marca = data;
        },
        setClienteId( data ) {
            self.clienteId = data;
        }

    }));

export default FCVehicleModel;
