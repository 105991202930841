import {types} from 'mobx-state-tree';
import EquipmentModel from '../models/equipment-model';

const models = [
    'ACP',
    'AT4 2G',
    'AT6 3G',
    'BWS E3+',
    'CalAmp',
    'Castel',
    'Coban',
    'Concox At4',
    'Concox CRX1 New',
    'Concox CRX1',
    'Concox CRX3',
    'Concox Qbit',
    'Continental Compact',
    'Continental Evolution',
    'Continental ZGO',
    'Continental',
    'CR2000/3000',
    'CRX1 NEW',
    'CRX3 MINI',
    'CRX3 NEW',
    'CRX7 3G',
    'E3 Plus',
    'E3',
    'E3+',
    'E4 MINI',
    'E4',
    'F1',
    'FMB920',
    'G02',
    'G05',
    'G9001N',
    'Generico',
    'GENÉRICO',
    'Getrak GTK110',
    'Getrak NR01',
    'GetrakFox',
    'GL300N',
    'GoSafe',
    'Great Will TR05',
    'GT06',
    'Guardiancar',
    'GV300N',
    'GV50',
    'GV500 OBDII',
    'GV50P',
    'GV55',
    'GV55N',
    'GV75',
    'GV75N',
    'iMXT',
    'Iter ITR120',
    'Iter ITR150',
    'Iter ITR155',
    'Jointech',
    'Karitec',
    'LairdTech LT100',
    'LKGPS LK710C',
    'M1',
    'Marelli TBox',
    'Maxtrack MTC400/MTC500',
    'Maxtrack MXT 160',
    'Maxtrack MXT130',
    'Maxtrack MXT140/MXT150',
    'Maxtrack MXT160',
    'MicroControl',
    'Mobilogix MT2000',
    'MXT-100',
    'MXT-100N',
    'MXT-101',
    'MXT-101N',
    'MXT-120',
    'MXT-130D',
    'MXT-140',
    'MXT-141',
    'MXT-142',
    'MXT-150',
    'MXT-151',
    'MXT-160',
    'MXT-162',
    'MXT-G100',
    'Niagara',
    'NR01',
    'NT20',
    'Orbisat',
    'Positron GSM',
    'Positron RT305',
    'Positron RT325',
    'Quanta Baby',
    'Quanta V2',
    'Quanta',
    'Queclink 500',
    'Queclink GV 50',
    'Queclink GV 55',
    'Queclink GV 75',
    'Queclink GV50',
    'Queclink GV50M',
    'Queclink GV55',
    'Queclink GV57',
    'Queclink GV75',
    'RGCOM',
    'RST Absolut',
    'RST-ABS',
    'RST-CARGA',
    'RST-DTMF',
    'RST-LCv2',
    'RST-MINI',
    'RST-VT (Sertech)',
    'RST-VT-EVO',
    'RST-VT-PLUS',
    'RST-VT',
    'RST-VTv2',
    'RT305',
    'RT325',
    'RT345',
    'Sigfox',
    'SkyPatrol',
    'Smart One C',
    'Smartcar',
    'SMini',
    'SpotTrace',
    'SR411 Mini',
    'SR411',
    'ST200',
    'ST210',
    'ST215',
    'ST215E',
    'ST215I',
    'ST230',
    'ST240',
    'ST300HD',
    'ST310U',
    'ST340',
    'ST340RB',
    'ST340UR',
    'ST380',
    'ST390',
    'ST500',
    'ST900',
    'ST910',
    'ST940',
    'STG T10',
    'STG T100',
    'STGT100',
    'Suntech (linha 200)',
    'Suntech (linha 300)',
    'Suntech (linha 900)',
    'Suntech ST200M',
    'Suntech ST340U',
    'SVATech',
    'SVias',
    'TC68S',
    'Teltonika FMB001',
    'Teltonika FMB920',
    'Teltonika MTB100',
    'TK102B',
    'TK103',
    'TK103A 3G',
    'TK103A',
    'TK103B 3G',
    'TK103B',
    'TK105A',
    'TK105B',
    'TK303F 3G',
    'TK303G 3G',
    'TK303G',
    'TK303H',
    'TK303I',
    'TK306',
    'TK311A',
    'TK311B',
    'TK311C',
    'TK403A 4G LTE',
    'TK403B 4G LTE',
    'TopFlyTec',
    'TopTen',
    'VT200',
    'Webtech',
    'X3 Tech NT20',
];

const EquipmentStore = types
    .model('EquipmentStore', {
        types: types.optional(types.array(EquipmentModel), []),
    })
    .actions((self) => ({
        afterCreate() {
            const types = [];
            models.map((m) => {
                types.push({name: m});
            });
            self.setTypes(types);
        },
        setTypes(data) {
            self.types = data;
        },
    }));

export default EquipmentStore;

