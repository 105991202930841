import React, {useState} from 'react';
import {getProfile, goMainRoute} from '../../helpers/auth';
import {setLocalSession} from '../../services/authService';
import '../../views/login/login.sass';
import LoginForm from '../../views/login/loginForm';
import * as Yup from 'yup';
import useStore from '../../hooks/use-store';
import useServices from '../../hooks/use-services';
import usePayProvider from '../../hooks/use-pay-provider';
import useRemoveMinWidthRestrictionWhileMounted from 'src/hooks/use-remove-min-width-restriction-while-mounted';

const LoginContainer = () => {
    const {store} = useStore();
    const {deviceService, positionService} = useServices();
    const {syncExternalApi} = usePayProvider();
    const {auth, devices, setSession} = store;

    const schema = Yup.object().shape({
        email: Yup.string()
            .required('Por favor informe o seu usuário ou e-mail'),
        password: Yup.string()
            .min(4)
            .required('Por favor informe a senha'),
    });

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const clear = () => {
        setError(false);
    };

    const submitLogin = async (data) => {
        setError(false);
        setLoading(true);
        try {
            const session = await auth.signIn(data);
            auth.setAuthInfo(data.email, data.password);

            setSession(session);
            setLocalSession(auth.token);

            const positions = await positionService.list(data);
            const devicesData = await deviceService.list();

            devices.addAll(devicesData);
            devices.updatePositions(positions);

            await syncExternalApi();

            auth.setProfile(
                getProfile({...session, devicesLength: devicesData.length, auth: data}),
            );

            auth.setLogged(true);
            setLoading(false);
            goMainRoute(auth.profile);
        } catch (e) {
            console.log(e);
            setLoading(false);
            setError(true);
        }
    };

    useRemoveMinWidthRestrictionWhileMounted();

    return <LoginForm
              loading={loading}
              schema={schema}
              onLogin={submitLogin}
              error={error}
              clear={clear}
           />;
};


export default LoginContainer;
