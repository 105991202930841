import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Form} from '@unform/web'
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';
import ReactSelect from '../../components/react-select';
import ToggleButton from '../../components/toggle-button';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from "../../components/fieldset";
import {GroupButtonForm} from "../../components/group-button-form";

const ServerForm = (props) => {
    const {
        data,
        updateRegister,
        mapTypes,
        coordinateFormats,
        loading,
    } = props;

    const formRef = useRef(null);

    const schema = Yup.object().shape({
        id: Yup.number(),
        map: Yup.string().notRequired,
        bingKey: Yup.string().notRequired(),
        mapUrl: Yup.string().notRequired(),
        latitude: Yup.number().notRequired(),
        longitude: Yup.number().notRequired(),
        zoom: Yup.number().notRequired(),
        twelveHourFormat: Yup.boolean().notRequired(),
        forceSettings: Yup.boolean().notRequired(),
        coordinateFormat: Yup.string().notRequired(),
        poiLayer: Yup.string().notRequired(),
        registration: Yup.boolean().notRequired(),
        administrator: Yup.boolean().notRequired(),
        readonly: Yup.boolean().notRequired(),
        deviceReadonly: Yup.boolean().notRequired(),
        limitCommands: Yup.boolean().notRequired(),
        deviceLimit: Yup.number().notRequired(),
    });

    const handleSubmit = async (payload) => {

        try {
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            updateRegister(payload);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={data}>
            <UInput hidden type="number" name="id"/>
            <FieldSet className="text-left border p-4" title={'Identificação'}>
                <div className={'row'}>
                    <div className={'col'}>

                        <FormGroup className="custom-formGroup">
                            <Label for={'map'}><I18nMessage id="mapLayer"/></Label>
                            <ReactSelect name="map" options={mapTypes}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="bingKey"><I18nMessage id="mapBingKey"/></Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="bingKey"
                                id="bingKey"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="mapUrl"><I18nMessage id="mapCustom"/></Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="mapUrl"
                                id="mapUrl"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="positionLatitude"/></Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name="latitude"
                                id="latitude"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="positionLongitude"/></Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name="longitude"
                                id="longitude"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="coordinateFormat"><I18nMessage id="settingsCoordinateFormat"/></Label>
                            <ReactSelect id={'coordinateFormat'} name="coordinateFormat" options={coordinateFormats}/>
                        </FormGroup>
                    </div>
                    <div className={'col'}>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="serverZoom"/></Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name="zoom"
                                id="zoom"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="deviceLimit"/></Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name="deviceLimit"
                                id="deviceLimit"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="mapPoiLayer"/></Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="poiLayer"
                                id="poiLayer"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label><I18nMessage id="settingsTwelveHourFormat"/></Label>
                            <ToggleButton name={'twelveHourFormat'} id={'twelveHourFormat'}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label><I18nMessage id="serverForceSettings"/></Label>
                            <ToggleButton name={'forceSettings'} id={'forceSettings'}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label><I18nMessage id="serverRegistration"/></Label>
                            <ToggleButton name={'registration'} id={'registration'}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label><I18nMessage id="serverReadonly"/></Label>
                            <ToggleButton name={'readonly'} id={'readonly'}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label><I18nMessage id="userDeviceReadonly"/></Label>
                            <ToggleButton name={'deviceReadonly'} id={'deviceReadonly'}/>
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label><I18nMessage id="userLimitCommands"/></Label>
                            <ToggleButton name={'limitCommands'} id={'limitCommands'}/>
                        </FormGroup>
                    </div>
                </div>
            </FieldSet>
            <br/>

            <GroupButtonForm
                loading={loading}
                submit={() => formRef.current.submitForm()}
            />
        </Form>
    );
};

export default ServerForm;
