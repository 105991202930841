import {types} from 'mobx-state-tree';

const UnitModel = types
    .model('UnitModel', {
      key: types.string,
      name: types.string,
      factor: types.number,
    });

export default UnitModel;

