import {types} from 'mobx-state-tree';
import request from '../services/http-request';
import KnownCommandModel from '../models/known-command-model';

const AllCommandTypesStore = types

    .model('AllCommandTypesStore', {
        types: types.optional(types.array(KnownCommandModel), []),
    })
    .actions((self) => ({
        setTypes(data) {
            self.types = data;
        },
        init({email, password}) {
            (async () => {
                const response = await request({
                    method: 'get',
                    url: `commands/types`,
                    noAuth: true,
                    auth: {username: email, password},
                });

                self.setTypes(response);
            })();
        },
    }));

export default AllCommandTypesStore;

