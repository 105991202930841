import React, {useContext, useEffect, useRef, useState} from 'react';
import AccountForm from '../../views/account/account-form';
import {inject, observer} from 'mobx-react';
import AttributesTable from '../../views/attributes/attributes-table';
import AttributesForm from '../../views/attributes/attributes-form';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from '../../hooks/use-services';
import CompanyConfigurationForm from '../../views/company-configuration';
import {useCompany} from '../../providers/company';

const AccountContainer = (props) => {
    const intl = useIntl();
    const {toast} = useAlerts();
    const {userService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const {persist, refetchCompanyData} = useCompany();
    const [view, setView] = useState('accountForm');
    const [editingAttr, setEditingAttr] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [coordinateFormats, setCoordinateFormats] = useState([]);
    const lastCurrentUser = useRef();

    useEffect(() => {
        lastCurrentUser.current = currentUser;
    }, [currentUser]);

    useEffect(() => {
        setCurrentUser(props.auth.user);
    }, []);

    useEffect(() => {
        (async () => {
            const items = [];
            props.coordinateFormats.formats.map((type) => {
                items.push({id: type.key, title: type.name});
            });
            setCoordinateFormats(items);
        })();
    }, []);

    const updateRegister = async (data, afterTo = 'accountForm') => {
        try {
            showModalLoading(true);
            const result = await userService.update({
                ...currentUser,
                ...data,
            });
            setCurrentUser(result);
            props.auth.setUser(result);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const showAttributes = (data) => {
        setCurrentUser(data);
        setView('attributeList');
    };

    const showCompanyConfigurationForm = (data) => {
        setView('companyConfiguration');
    };

    const attributeForm = (data) => {
        setEditingAttr(data);
        setView('attributeForm');
    };

    const removeAttribute = async (data, key) => {
        delete data.attributes[key];
        await updateRegister(data, 'accountForm');
    };

    const backToAccount = (data) => {
        setCurrentUser(data);
        setView('groupForm');
    };

    const companyConfigurationFormCancel = () => {
        setView('accountForm');
    };

    const onCompanyConfigurationCreateOrUpdateSuccess = (data) => {
        persist(data);
        setView('accountForm');
        refetchCompanyData();
    };

    return (
        <>
            {
                {
                    accountForm: (
                        <AccountForm
                            data={currentUser}
                            profile={props.auth.profile}
                            coordinateFormats={coordinateFormats}
                            attributes={showAttributes}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                            showCompanyConfigurationForm={showCompanyConfigurationForm}
                        />
                    ),
                    attributeList: (
                        <AttributesTable
                            cancel={backToAccount}
                            attributeForm={attributeForm}
                            removeAttribute={removeAttribute}
                            data={currentUser}
                        />
                    ),
                    attributeForm: (
                        <AttributesForm
                            cancel={() => setView('attributeList')}
                            data={Object.assign({}, currentUser)}
                            edit={editingAttr}
                            updateRegister={updateRegister}
                        />
                    ),
                    companyConfiguration: (
                        <CompanyConfigurationForm
                            onCreateOrUpdateSuccess={onCompanyConfigurationCreateOrUpdateSuccess}
                            onCancel={companyConfigurationFormCancel}
                        />
                    ),
                }[view]
            }
        </>
    );
};

const mapping = ({store}) => ({
    auth: store.auth,
    mapType: store.mapType,
    coordinateFormats: store.coordinateFormats,
});

export default inject(mapping)(observer(AccountContainer));
