import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Button, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler} from 'reactstrap';
import {inject, observer} from 'mobx-react';
import styled from 'styled-components';
import {ModalContext, ThemeContext} from '../../apiContexts';
import SearchAutocomplete from '../../components/searchAutocomplete';
import ModalEnum from '../modals/modalEnum';
import {useCompany} from '../../providers/company';
import RoundButton from '../../components/roundButton';
import {useIntl} from 'react-intl';
import {Skeleton} from '@mui/material';

const initSearchData = {
    emptyLabel: 'Nada encontrado!',
    minLength: 2,
    align: 'right',
    caseSensitive: false,
    ignoreDiacritics: false,
    labelKey: 'name',
    filterBy: ['uniqueId', 'name', 'groupName', 'customerName', 'customerCpfCnpj'],
    options: [],
    renderMenuItemChildren: ({uniqueId, name, groupName, customerName, customerCpfCnpj}) => (
        <div>
            {name}
            <div>
                <small>ID: {uniqueId} Group: {groupName}</small>
                <br/>
                <small>CPF/CNPJ: {customerCpfCnpj} Cliente: {customerName}</small>
            </div>
        </div>
    ),
};

const StyledNavbar = styled(Navbar)``;

const NewReportStyled = styled(Button)`
  color: ${(props) => props.theme} !important;

  &:hover,
  &:active {
    > span {
      color: white;
    }
  }
`;

const NavBar = (props) => {
    const {devices} = props;
    const [isOpen, setIsOpen] = useState(false);
    const theme = useContext(ThemeContext);
    const {openModal} = useContext(ModalContext);
    const history = useHistory();
    const match = useRouteMatch();
    const company = useCompany();
    const intl = useIntl();

    const toggle = () => setIsOpen(!isOpen);

    const searchDevice = useCallback(
        (data) => {
            if (data.length) {
                const {id} = data[0];
                history.push(`${match.url}/events/devices/${id}`);
            }
        },
        [match.url],
    );

    const options = useMemo(
        () =>
            devices.map(({id, uniqueId, name, groupName, customerName, customerCpfCnpj}) => ({
                id,
                uniqueId: uniqueId,
                name,
                type: 'devices',
                groupName: groupName ? groupName : '',
                customerName: customerName ? customerName : '',
                customerCpfCnpj: customerCpfCnpj ? customerCpfCnpj : '',
            })),
        [devices],
    );

    return (
        <StyledNavbar
            color="white"
            light
            expand="md"
            className="shadow px-5"
            theme={theme}
        >
            <NavbarBrand href="/">
                <Logo src={company.logoUrl}/>
            </NavbarBrand>
            <NavbarToggler onClick={toggle}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <SearchAutocomplete
                        className="mr-4"
                        id="searchDevice"
                        bsSize="large"
                        placeholder="Digite para pesquisar"
                        onChange={searchDevice}
                        {...initSearchData}
                        options={options}
                    />

                    <NewReportStyled
                        theme={theme}
                        outline
                        className="custom-shadow custom-border btn-theme"
                        size="lg"
                        onClick={() => openModal(ModalEnum.NEW_REPORT)}
                    >
                        <span>
                        {intl.formatMessage({id: 'reportNew'})} <span className="fal fa-plus"/>
                        </span>
                    </NewReportStyled>

                    <div className='mx-2' />

                    <NewReportStyled
                        theme={theme}
                        outline
                        className="custom-shadow custom-border btn-theme"
                        size="lg"
                        onClick={() => openModal(ModalEnum.COMMAND)}
                    >
                        <span>
                        {intl.formatMessage({id: 'commandNew'})} <span className="fal fa-plus"/>
                        </span>
                    </NewReportStyled>
                </Nav>
            </Collapse>
            <RoundButton className={'ml-3'} onClick={() => history.push('/admin/manage/account')}>
                <span className="fad fa-user-tie"/>
            </RoundButton>
        </StyledNavbar>
    );
};

const Logo = ({src}) => {
    return (
    <div id="logo">
        {src ? <img alt="logo" src={src} height="58"/> :
            <Skeleton
                variant='rectangular'
                width={58}
                height={58}
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderRadius: '10px',
                }}
            />
        }
    </div>
    );
};

const mapping = ({store}) => ({
    companyConfigurationStore: store.companyConfigurationStore,
});

export default inject(mapping)(observer(NavBar));
