import React, {useRef, useState} from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {Form} from '@unform/web';
import I18nMessage from '../../components/I18nMessage';
import * as Yup from 'yup';
import ReactSelect from '../../components/react-select';
import {TextArea} from '../../components/textarea';
import {Input as UInput} from '../../components/input';
import Button from '../../components/button';
import useStore from '../../hooks/use-store';
import {trigger} from '../../helpers/events';


const EssentialSinisterForm = (props) => {
    const {
        sinister,
        close,
        creating,
        loadIncidents,
        setSinister,
    } = props;

    const schema = Yup.object().shape({
        deviceId: Yup.number().required(),
        customerId: Yup.number().required(),
        openingType: Yup.string().required(),
        openingDescription: Yup.string().required(),
    });
    const {store} = useStore();
    const {sinisterStore} = store;
    const [loading] = useState(false);
    const formRef = useRef(null);


    const handleSubmit = async (payload) => {
        try {
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            sinisterStore.save(payload).then((data) => {
                console.log('.....NOO');
                console.log(sinister);
                setSinister(data);
                loadIncidents();
                close();
            });
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                console.log(err);
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            props.dispatchAccordionResize();
        }
    };

    const cancel = () => {
        loadIncidents();
        trigger('openEventTab:click');
    };

    return (
        <Form
            ref={formRef}
            initialData={{
                id: sinister.id,
                deviceId: sinister.deviceId,
                customerId: sinister.customerId,
                openingDate: sinister.openingDate,
                openingType: sinister.openingType,
                openingAddress: sinister.openingAddress,
                openingLatitude: sinister.openingLatitude,
                openingLongitude: sinister.openingLongitude,
                openingDescription: sinister.openingDescription,
            }}
            onSubmit={handleSubmit}
            schema={schema}>


            <UInput hidden type="number" name="deviceId" />
            <UInput hidden type="number" name="customerId" />
            {creating && <>
                <UInput hidden type="string" name="openingAddress" />
                <UInput hidden type="number" name="openingLatitude" />
                <UInput hidden type="number" name="openingLongitude" />
            </>}

            <Label><I18nMessage id="sharedDevice" /></Label>
            <Row>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label for="groupId"><I18nMessage id="devicePlate" /></Label>
                        <Input
                            plaintext
                            value={sinister.device.attributes.plate}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label for="groupId"><I18nMessage id="deviceModel" /></Label>
                        <Input
                            plaintext
                            value={sinister.device.attributes.vehicleModel}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label><I18nMessage id="positionAddress" /></Label>
                        <Input
                            plaintext
                            value={sinister.openingAddress}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label><I18nMessage id="positionLatitude" /></Label>
                        <Input
                            plaintext
                            value={sinister.openingLatitude}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label><I18nMessage id="positionLongitude" /></Label>
                        <Input
                            plaintext
                            value={sinister.openingLongitude}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Label><I18nMessage id="sharedCustomer" /></Label>
            <Row>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label><I18nMessage id="customerName" /></Label>
                        <Input
                            plaintext
                            value={sinister.customer.name}
                        />
                    </FormGroup>
                    <FormGroup className="custom-formGroup">
                        <Label><I18nMessage id="customerPhone" /></Label>
                        <Input
                            plaintext
                            value={sinister.customer.phone}
                        />
                    </FormGroup>
                    <FormGroup className="custom-formGroup">
                        <Label><I18nMessage id="userEmail" /></Label>
                        <Input
                            plaintext
                            value={sinister.customer.email}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup className="custom-formGroup">
                <Label for="name">Do que se trata o sinistro*</Label>
                <ReactSelect
                    id={'openingType'}
                    name="openingType"
                    options={[
                        {id: 'robbery', title: 'Roubo'},
                        {id: 'theft', title: 'Furto'},
                        {id: 'misappropriation', title: 'Apropriação Indevida'},
                        {id: 'accident', title: 'Acidente'},
                        {id: 'other', title: 'Outro'},
                    ]} />
            </FormGroup>

            <FormGroup className="custom-formGroup">
                <Label for="name">Faça uma descrição do evento ocorrido</Label>
                <TextArea
                    type="text"
                    name="openingDescription"
                    id="openingDescription"
                />
            </FormGroup>

            {creating && <Row>
                <Col> <Button
                    color={'warning'}
                    disabled={loading}
                    className={'outline'}
                    size={'sm'}
                    onClick={() => cancel()}>
                    Cancelar
                </Button></Col>
                <Col>
                    <Button
                        color={'success'}
                        disabled={loading}
                        className={'outline'}
                        size={'sm'}
                        onClick={() => formRef.current.submitForm()}>
                        Salvar
                    </Button>
                </Col>
            </Row>}
        </Form>

    );
};

export default EssentialSinisterForm;
