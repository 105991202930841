import React, {useContext, useEffect, useState} from 'react';
import CalendarForm from '../../views/calendar/calendar-form';
import CalendarTable from '../../views/calendar/calendar-table';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from '../../hooks/use-services';

const CalendarContainer = (props) => {
    const intl = useIntl();
    const {toast} = useAlerts();
    const {calendarService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('calendarList');
    const [calendars, setCalendars] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        (async () => {
            const calendars = await calendarService.list();
            setCalendars(calendars);
        })();
    }, []);

    const calendarForm = (data) => {
        setData(Object.keys(data).length ? data : {});
        setView('calendarForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await calendarService.save(data);
            setCalendars([...calendars, result]);
            setView('calendarList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'calendarList') => {
        try {
            showModalLoading(true);
            const index = calendars.findIndex(({id}) => id === data.id);
            const result = await calendarService.update({
                ...calendars[index],
                ...data,
            });
            calendars.splice(index, 1, result);
            setCalendars([...calendars]);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await calendarService.remove(data.id);
            setCalendars(calendars.filter(({id}) => id !== data.id));
            setView('calendarList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };


    return (
        <>
            {
                {
                    calendarList: (
                        <CalendarTable
                            list={calendars}
                            calendarForm={calendarForm}
                            removeRegister={removeRegister}
                            loading={isModalLoading}
                        />
                    ),
                    calendarForm: (
                        <CalendarForm
                            cancel={() => setView('calendarList')}
                            data={Object.assign({}, data)}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                        />
                    ),
                }[view]
            }
        </>
    );
};

export default CalendarContainer;
