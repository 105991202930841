import styled from 'styled-components';
import {Button, CustomInput, Modal, Table} from 'reactstrap';
import ReactSelect from './components/react-select';
import {colors} from './styleVariables';

export const ModalStyled = styled(Modal)`
  .modal-header {
    background-color: ${(props) => props.theme} !important;
  }
`;

export const EventMapStyled = styled.div`
  border-radius: 12px;
  position: relative !important;
  min-width: 604.66px;

  width: 100%
  height: 100%
  
  .ol-viewport {
    border-radius: 12px;
  }
`;


export const ModalXD = styled(Modal)`
  .modal-content {
    border: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 0 1rem;

    .modal-header {
      align-items: center !important;
      border: 0;

      .modal-title {
        font-size: 1.6rem;
        color: ${({theme}) => theme};
      }

      .close {
        font-size: 2.5rem;
      }
    }
  }
`;

export const ReactSelectStyled = styled(ReactSelect)`
  .select {
    border-radius: ${(props) => props.radius} !important;
  }

  + small {
    color: ${colors.red};
  }
`;

export const ButtomStyled = styled(Button)`
  color: ${(props) => props.theme} !important;
  &:hover,
  &:active {
    > span {
      color: white;
    }
  }
`;

export const CustomTable = styled(Table)`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 -1px 0.25rem rgba(0, 0, 0, 0.075);

  tr {
    display: flex;

    th,
    td {
      padding: 1.1rem 1.5rem;
    }
  }

  thead {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    tr {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      th {
        border-top: 1px solid $gray-5;
        font-weight: bold;
        border-bottom: 0;
        color: ${({theme}) => theme}

        &:first-child {
          border-left: 1px solid $gray-5;
          border-top-left-radius: 15px;
        }

        &:last-child {
          border-right: 1px solid $gray-5;
          border-top-right-radius: 15px;
        }
      }
    }
  }

  tbody {
    tr {
      margin-bottom: 2px;

      &:first-child {
        -webkit-box-shadow: inset 0px 44px 23px -32px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: inset 0px 44px 23px -32px rgba(0, 0, 0, 0.15);
        box-shadow: inset 0px 44px 23px -32px rgba(0, 0, 0, 0.15);
      }

      &:not(:first-child) {
        td {
          border-top-color: white;
        }
      }

      &:nth-of-type(odd) {
        background-color: $gray-5 !important;
      }

      &:nth-of-type(even) {
        background-color: $gray-6 !important;
      }
    }
  }
`;

export const CustomCheckbox = styled(CustomInput)`
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:before {
          color: #fff;
          border-color: ${({theme}) => theme};
          background-color: ${({theme}) => theme};
        }
      }
    }
  }
  .custom-control-label {
    padding-left: 5px;
    cursor: pointer;

    &:before {
      width: 1.5rem !important;
      height: 1.5rem !important;
      top: 0;
    }

    &:after {
      width: 1.5rem !important;
      height: 1.5rem !important;
      top: 0;
    }
  }
`;
