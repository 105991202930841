import React, {useContext, memo} from 'react';
import {Nav, NavLink} from 'reactstrap';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';

const StyledNavLink = styled(NavLink)`
  text-align: center;
  width: 80px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  line-height: 1.2rem !important;
  border-radius: 6px;
  color: #8d8d8d;
  .label {
    display: none;
  }
  span {
    &:first-child {
      font-size: 1.6rem;
    }
  }
`;

const StyledNav = styled(Nav)`
  width: 80px;
  background-color: white;
  border-radius: 12px;
  height: max-content;
  ${StyledNavLink} {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const NavLinkWrapper = styled.div`
  :hover {
    margin-bottom: 1px;
    ${StyledNavLink} {
      margin-left: 20.5px !important;
      display: flex;
      align-items: center;
      width: max-content;
      background-color: ${(props) => props.theme};
      color: white;
      padding-left: 8px;
      box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4);

      .label {
        display: block;
      }
    }
  }
`;

const NavVertical = ({links = [], ...props}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className="pl-3" style={{zIndex: 10}}>
      <StyledNav
        vertical
        pills
        color="dark"
        className="navVertical custom-shadow-2 py-3">
        {links.map(({label, icon, fn}, i) => (
          <NavLinkWrapper key={i} theme={theme}>
            <StyledNavLink href="#" theme={theme} onClick={fn || null}>
              <span className={icon} />
              <span className="label ml-3">{label}</span>
            </StyledNavLink>
          </NavLinkWrapper>
        ))}
      </StyledNav>
    </div>
  );
};

export default memo(NavVertical);
