import {Button, Dropdown, DropdownItem, DropdownMenu} from 'reactstrap';
import styled from 'styled-components';
import {shadows} from '../../styleVariables';

export const ButtonStyled = styled(Button)`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${shadows.shadow} !important;
  background-color: white;
  border-color: ${(props) => props.theme} !important;
  color: ${(props) => props.theme};

  :active,
  :hover {
    background-color: ${(props) => props.theme} !important;
  }
  span {
    font-size: 1.5rem;
  }
`;

export const DropdownItemStyled = styled(DropdownItem)`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme};

  :active,
  :focus,
  :hover {
    background-color: ${(props) => props.theme};
    color: white;
  }

  span {
    &:first-child {
      display: none;
    }

    &:last-child {
      font-size: 1.5rem;
    }
  }

  :hover {
    width: auto;
    border-radius: 4px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2px;
    padding-right: 14px;

    span:first-child {
      display: block;
    }
  }
`;

export const DropdownMenuStyled = styled(DropdownMenu)`
  z-index: -1;
  opacity: inherit !important;
  pointer-events: all !important;
  transform: translate3d(0, 19px, 0px) !important;
  min-width: unset;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 2rem;
  flex-wrap: wrap-reverse;
  border-bottom-left-radius: 50px 50px;
  border-bottom-right-radius: 50px 50px;
  padding-bottom: 1.2rem;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 48px, 0px);

  &.show {
    display: flex;
    flex-direction: column;
  }

  &[x-placement^="bottom"] {
    right: 0 !important;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  pointer-events: all !important;

  &.show {
    ${ButtonStyled} {
      color: white;
      background-color: ${(props) => props.theme} !important;
    }
  }
`;
