
export default class Rastreador {

    constructor(data) {
        this.id = data.id;
        this.modeloEquipamentoId = data.modeloEquipamentoId;
        this.modeloEquipamento = data.modeloEquipamento;
        this.equipamento = data.equipamento;
        this.simCardId = data.simCardId;
        this.numeroChip = data.numeroChip;
        this.numeroLinha = data.numeroLinha;
        this.veiculoId = data.veiculoId;
        this.placa = data.placa;
        this.veiculo = data.veiculo;
    }
}
