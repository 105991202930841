import React, {memo, useContext} from 'react';
import BarLoader from 'react-spinners/BarLoader';
import {ThemeContext} from '../../apiContexts';
import {css} from '@emotion/react';

const LoadingBar = (props) => {
  const theme = useContext(ThemeContext);
  return <BarLoader {...props} color={theme} />;
};

LoadingBar.defaultProps = {
  height: 4,
  loading: true,
  css: css`
    width: 100%;
  `,
};

export default memo(LoadingBar);
