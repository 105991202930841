export const defaultReportValidation = (Yup) =>
    Yup.object().shape({
        device: Yup.array().test(
            'is-device-required',
            '${path} is required',
            async (value, context) => {
                const hasGroup = context.parent.group ? context.parent.group.length > 0 : false;
                console.log(hasGroup);
                return hasGroup || value.length > 0;
            }),
        group: Yup.array().test(
            'is-group-required',
            '${path} is required',
            async (value, context) => {
                const hasDevice  = context.parent.device ? context.parent.device.length > 0 : false;
                return hasDevice || value.length > 0
            }),
        period: Yup.string().required(),
        endDate: Yup.date().when('period', {
            is: 'dateToDate',
            then: Yup.date().required(),
            otherwise: Yup.date().notRequired(),
        }),
        startDate: Yup.date().when('period', {
            is: 'dateToDate',
            then: Yup.date().required(),
            otherwise: Yup.date().notRequired(),
        }),
    });

export const eventReportValidation = (Yup) =>
    Yup.object().shape({
        device: Yup.array().test(
            'is-device-required',
            '${path} is required',
            async (value, context) => {
                const hasGroup = context.parent.group ? context.parent.group.length > 0 : false;
                return hasGroup || value.length > 0
            }),
        group: Yup.array().test(
            'is-group-required',
            '${path} is required',
            async (value, context) => {
                const hasDevice  = context.parent.device ? context.parent.device.length > 0 : false;
                return hasDevice || value.length > 0
            }),
        event: Yup.array().required(),
        period: Yup.string().required(),
        endDate: Yup.date().when('period', {
            is: 'dateToDate',
            then: Yup.date().required(),
            otherwise: Yup.date().notRequired(),
        }),
        startDate: Yup.date().when('period', {
            is: 'dateToDate',
            then: Yup.date().required(),
            otherwise: Yup.date().notRequired(),
        }),
    });
