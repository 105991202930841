import React, {useRef, useState} from 'react';
import {Button, FormGroup, Input, Label} from 'reactstrap';
import ReactSelect from '../../components/react-select';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';
import I18nMessage from '../../components/I18nMessage';
import ToggleButton from '../../components/toggle-button';
import {useIntl} from 'react-intl';

const SendCommandForm = (props) => {
    const {
        commandTypes,
        knownCommands,
        timezones,
        selectedDevice,
        sendCommand,
        loading,
    } = props;

    const formRef = useRef(null);
    const intl = useIntl();
    const [type, setType] = useState('1');
    const [attributes, setAttributes] = useState({});

    const schema = Yup.object().shape({
        id: Yup.number().notRequired(),
        type: Yup.string().required('Type é necessário'),
        textChannel: Yup.string().notRequired(),
    });

    const handleSubmit = (data) => {
        data.id = 0;
        data.deviceId = selectedDevice.id;
        data.textChannel = false;
        data.description = intl.formatMessage({id: 'sharedNew'});
        data.attributes = attributes;

        sendCommand(data);
    };

    const handleChange = (selectedOption) => {
        setType(selectedOption.id);
    };

    const renderParameterField = () => {
        let defaultFieldConfig = null;
        const command = knownCommands.getCommand(type);
        const parameters = command !== undefined ? command.parameters : [];

        parameters.map((parameter) => {
            switch (parameter.valueType) {
                case 'number':
                    defaultFieldConfig =
                        <FormGroup className="custom-formGroup">
                            <Label for="name">
                                <I18nMessage id={parameter.name}/>*
                            </Label>
                            <Input
                                tag={UInput}
                                type="number"
                                name={parameter.name}
                                id={parameter.key}
                                onChange={(evt) => setAttributes({[parameter.name]: evt.target.value})}
                            />
                        </FormGroup>;
                    break;
                case 'boolean':
                    defaultFieldConfig =
                        <FormGroup className="custom-formGroup">
                            <Label for="textChannel"><I18nMessage id={parameter.name}/></Label>
                            <ToggleButton
                                onChange={(checked) => setAttributes({[parameter.name]: checked})}
                                name={parameter.name}
                                id={parameter.key}/>
                        </FormGroup>;
                    break;
                default:
                    if (parameter.dataType === 'timezone') {
                        defaultFieldConfig = <FormGroup className="custom-formGroup">
                            <Label for={parameter.name}><I18nMessage id="commandSendSms"/></Label>
                            <ReactSelect
                                onChange={(opt) => {
                                    if (opt !== null) {
                                        setAttributes({[parameter.name]: opt.id});
                                    } else {
                                        setAttributes({});
                                    }
                                }}
                                id={parameter.key}
                                name={parameter.name}
                                options={timezones}/>
                        </FormGroup>;
                        break;
                    } else {
                        defaultFieldConfig = <FormGroup className="custom-formGroup">
                            <Label for="name">
                                <I18nMessage id={parameter.name}/>*
                            </Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name={parameter.name}
                                id={parameter.key}
                                onChange={(evt) => setAttributes({[parameter.key]: evt.target.value})}
                            />
                        </FormGroup>;
                    }
            }
        });
        return (defaultFieldConfig);
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={{}}>
            <FormGroup>
                <Label for="type"><I18nMessage id="sharedType"/></Label>
                <ReactSelect id={'type'} name="type" options={commandTypes} onChange={(value) => {
                    if (value != null) {
                        handleChange(value);
                    } else {
                        setType('1');
                    }
                }}/>
            </FormGroup>
            {renderParameterField()}
            <FormGroup>
                <Button color="danger" type="submit" disabled={loading} block style={{borderRadius: 20}}>
                    <i className="fal fa-upload"/>
                </Button>
            </FormGroup>
        </Form>
    );
};

export default SendCommandForm;
