import {types} from 'mobx-state-tree';

const CompanyConfigurationModel = types.model('CompanyConfigurationModel', {
    id: types.maybe(types.number),
    label: types.optional(types.string, window.location.hostname?.split('.')[0]),
    logoUrl: types.optional(types.string, ''),
    theme: types.optional(types.string, '#1293d1'),
    androidAppUrl: types.optional(types.string, ''),
    iosAppUrl: types.optional(types.string, ''),
}).actions((self) => ({
    persist(values) {
        Object.assign(self, values);
    },
    setLogoUrl(value) {
        self.logoUrl = value;
    },
    setTheme(value) {
        self.theme = value;
    },
    setAndroidAppUrl(value) {
        self.androidAppUrl = value;
    },
    setIosAppUrl(value) {
        self.iosAppUrl = value;
    },
    isLogoUrlDefault(value) {
        return value === `${process.env.PUBLIC_URL}/images/thumbnail.svg`;
    },
})).views((self) => ({
    get data() {
        return {
            id: self.id,
            label: self.label,
            logoUrl: self.logoUrl,
            theme: self.theme,
            androidAppUrl: self.androidAppUrl,
            iosAppUrl: self.iosAppUrl,
        };
    },
}));

export default CompanyConfigurationModel;
