import FullControlService from "./full-control-service";
import Rastreador from "./model/rastreador";

export default class FullControlRastreadorService extends FullControlService {

    constructor(clientId, secretId) {
        super(clientId, secretId);
    }

    ObterRastreadores() {
        return this.request({
            method: 'post',
            url: `Rastreador/ObterRastreadores`,
            data: {}
        });
    }

    Incluir(rastreador) {

        if (!(rastreador instanceof Rastreador)) {
            throw new Error("Must to be a Rastreador Instance");
        }

        return this.request({
            method: 'post',
            url: `Rastreador/Incluir`,
            data: rastreador
        });
    }

    Atualizar(rastreador) {

        if (!(rastreador instanceof Rastreador)) {
            throw new Error("Must to be a Rastreador Instance");
        }

        return this.request({
            method: 'put',
            url: `Rastreador/Atualizar`,
            data: rastreador
        });
    }

}
