import {observer} from 'mobx-react';
import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {Container} from 'reactstrap';
import styled from 'styled-components';
import {MapContext} from '../../apiContexts';
import {useMap} from '../../components/map/hooks';
import useMapDevice from '../../components/map/hooks/useMapDevice';
import useMapAlarm from '../../components/map/hooks/useMapAlarm';
import useMapGeofences from '../../components/map/hooks/useMapGeofences';
import useMapLabel from '../../components/map/hooks/useMapLabel';
import useMapPosition from '../../components/map/hooks/useMapPosition';
import useMapReport from '../../components/map/hooks/useMapReport';
import useMapTrace from '../../components/map/hooks/useMapTrace';
import config from '../../config';
import useDevices from '../../hooks/use-devices';
import useStore from '../../hooks/use-store';
import DeviceDetail from '../../views/event-map/device-detail';
import PopupEvent from '../../views/map/popupEvent';
import MapRightActions from '../../views/map-right-actions';
import {useIntl} from 'react-intl';
import useServices from '../../hooks/use-services';
import MapLegend from '../../components/map-legend';

const Map = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 12px;

  .ol-viewport {
    border-radius: 12px;
  }

  #right-actions {
    position: absolute;
    right: 1rem;
    z-index: 10;
    top: 1rem;
  }
`;

const AdminMapContainer = (props) => {
    const {store} = useStore();
    const {events, customerStore} = store;
    const {geofenceService, permissionService} = useServices();
    const {eventId} = useParams();
    const {devices} = useDevices();
    const {addToast} = useToasts();
    const mapRef = useRef();
    const intl = useIntl();

    const {
        setSelectedRow,
    } = useContext(MapContext);

    const {
        instance,
        trace,
        setTrace,
        toogleTrace: traceToggleCurrentDevice,
        label,
        setLabel,
        selectedMap,
        setSelectedMap,
        showDevices,
        setShowDevices,
        showGeofences,
        setShowGeofences,
        follow,
        setFollow,
        isAlarmsVisible,
        toggleAlarmsVisibility,
    } = useMap(
        {
            target: mapRef,
            center: [0, 0],
            zoom: 8,
            tiers: config.mapConfig,
            initAllDevices: true,
            centerOnCluster: true,
            cluster: {
                distance: 25,
            },
        },
        useMapPosition,
        useMapDevice,
        useMapTrace,
        useMapLabel,
        useMapReport,
        useMapGeofences,
        useMapAlarm,
    );

    const followToggle = () => {
        const status = !follow;
        setFollow(status);
        if (status) {
            devices.setSelected(events.currentDevice.id);
            const {longitude, latitude} = events.currentDevice.currentPosition;
            instance.goTo([longitude, latitude]);
        }
    };

    useEffect(() => {
        if (instance && devices.list.length) {
            const map = instance.instance;
            instance.setI18n(intl);
            setSelectedMap('osm');

            map.on('click', (event) => {
                if (events.currentDevice !== null) {
                    devices.setSelected(null);
                    events.setCurrentDevice(null);

                    return;
                }

                const [feature, layer] = map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    return [feature, layer];
                }) || [];

                if (!layer) return;

                const layerName = layer.get('name');

                if (layerName === 'reportLayer') {
                    const featureName = feature.get('name');
                    if (featureName !== 'line') {
                        setSelectedRow({
                            id: feature.get('id') || feature.getId(),
                            scroll: true,
                        });
                    }
                }

                if (layerName === 'devicesLayer') {
                    const features = feature.get('features');
                    if (features.length === 1) {
                        const deviceId = features[0].getId();
                        const device = store.devices.findById(+deviceId);
                        devices.setSelected(deviceId);
                        events.setCurrentDevice(+deviceId);

                        (async () => {
                            const position = device.currentPosition;

                            if (!position) return;

                            const {longitude, latitude} = position;
                            instance.goTo([longitude, latitude], 18);
                        })();
                    }
                }
            });

            map.on('pointermove', (event) => {
                map.getTargetElement().style.cursor = map.hasFeatureAtPixel(event.pixel) ?
                    'pointer' :
                    '';
            });

            map.on('pointerdrag', (event) => {
                setFollow(false);
            });

            let zoomCache;
            map.on('movestart', (event) => {
                zoomCache = event.map.getView().getZoom();
            });

            map.on('moveend', (event) => {
                const zoom = event.map.getView().getZoom();
                zoom !== zoomCache && setFollow(false);
            });
        }
    }, [instance, devices.list.length]);

    const createGeofence = async () => {
        const currentPosition =
            events.currentDevice && events.currentDevice.currentPosition;

        const device = events.currentDevice;

        if (currentPosition) {
            const area = `CIRCLE (${currentPosition.latitude} ${
                currentPosition.longitude
            }, ${20})`;

            const data = {name: `Cerca_${device.name}`, area};

            const response = await geofenceService.save(data);

            if (response) {
                const permission = {deviceId: device.id, geofenceId: response.id};
                await permissionService.addPermission(permission);
                instance.addGeofence(response);
                geoFenceToastSuccess('success');
            } else {
                geoFenceToastSuccess('error');
            }
        }
    };

    const geoFenceToastSuccess = useCallback((appearance) => {
        const msg =
            appearance === 'success' ? (
                <span>Cerca criada com sucesso</span>
            ) : (
                `Não foi possível realizar sua solicitação!`
            );
        addToast(msg, {appearance, autoDismiss: true});
    }, []);

    const toggleTrace = () => {
        setTrace(prev => !prev);
    };

    const traceToggleCurrent = () => {
        traceToggleCurrentDevice(devices.selectedDevice);
    };

    const toggleLabel = () => {
        setLabel(!label);
    };

    const toggleGeofences = () => {
        setShowGeofences(!showGeofences);
    };

    const onChangeMap = (value) => {
        setSelectedMap(value);
    };

    const toggleDevice = () => {
        setShowDevices(!showDevices);
    };

    const getDrive = () => {
        return events.currentDevice.currentPosition && events.currentDevice.currentPosition.attributes ?
            store.drivers.getDriverByUniqueId(events.currentDevice.currentPosition.attributes.driverUniqueId) : null;
    };

    const getCustomer = () => {
        return customerStore.findById(events.currentDevice.customerId);
    };

    return (
        <Container fluid className="px-4 pb-4 position-relative h-100">
            <MapLegend />
            <>
                { devices.list.length > 0 && <PopupEvent isEvent={!!eventId}/> }

                {devices.list.length > 0 && events.currentDevice && events.currentDevice.currentPosition && (
                    <DeviceDetail
                        toggleTrace={traceToggleCurrent}
                        trace={!!devices?.selectedDevice?.withTrace}
                        createGeofence={createGeofence}
                        followToogle={followToggle}
                        follow={follow}
                        driver={getDrive()}
                        customer={getCustomer()}
                    />
                )}
                <Map ref={mapRef} className="custom-shadow-2">
                    <MapRightActions
                        map={instance}
                        toggleTrace={toggleTrace}
                        trace={trace}
                        toggleLabel={toggleLabel}
                        isAlarmsVisible={isAlarmsVisible}
                        toggleAlarmsVisibility={toggleAlarmsVisibility}
                        label={label}
                        onChangeMap={onChangeMap}
                        selectedMap={selectedMap}
                        toggleDevice={toggleDevice}
                        showDevices={showDevices}
                        toggleGeofences={toggleGeofences}
                        showGeofences={showGeofences}
                        close={'/admin'}
                    />
                </Map>
            </>
        </Container>
    );
};

export default observer(AdminMapContainer);
