import {Map as OlMap, Overlay} from 'ol';
import Feature from 'ol/Feature';
import {Circle, LineString, Point, Polygon} from 'ol/geom';
import {Cluster, Vector as VectorSource} from 'ol/source';
import {createEmpty, extend} from 'ol/extent';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {fromLonLat, getPointResolution, METERS_PER_UNIT, transform} from 'ol/proj';
import XYZ from 'ol/source/XYZ';
import {Circle as CircleStyle, Fill, Icon, Stroke, Style, Text as TextStyle} from 'ol/style';
import {getCursor, getDeviceIcon, getDeviceIconColor} from '../../helpers/deviceIcons';


/**
 * Map Class Instance
 */
class MapInstance {
  constructor(options) {
    this._instance = new OlMap(options);

    this._devicesLayer = null;
    this._devices = new Map();

    this._traceEventLayer = null;

    this._traceLayer = null;
    this._traces = new Map();

    this._geofenceLayer = null;

    this._reportLayer = null;
    this.selectedReportPoint = null;

    this._cacheImg = {};

    this._i18n = null;
  }

  get instance() {
    return this._instance;
  }

  get devicesLayer() {
    return this._devicesLayer;
  }

  get devices() {
    return this._devices;
  }

  get zoom() {
    return this._instance.getView().getZoom();
  }

  get i18n() {
    return this._i18n;
  }

  setI18n(translateService) {
    this._i18n = translateService;
  }

  static createTileXYZ(url, name, visible = false) {
    return new TileLayer({
      source: new XYZ({url}),
      visible,
      name,
    });
  }

  increaseZoom() {
    this._instance.getView().setZoom(this._instance.getView().getZoom() + 1);
  }

  decreaseZoom( value ) {
    if (value) {
      this._instance.getView().setZoom(this._instance.getView().getZoom() - value);
    } else {
      this._instance.getView().setZoom(this._instance.getView().getZoom() - 1);
    }
  }

  goTo(coord, zoom) {
    const options = {};
    if (coord) options.center = fromLonLat(coord);
    if (zoom) options.zoom = zoom;
    this._instance.getView().animate(options);
  }

  setMap(name) {
    this._instance
        .getLayers()
        .getArray()
        .forEach(
            (t) => t instanceof TileLayer && t.setVisible(t.get('name') === name),
        );
  }

  initDevicesLayer() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      name: 'devicesLayer',
      zIndex: 10,
    });

    this._devicesLayer = layer;

    this.instance.addLayer(layer);
  }

  initReportLayer() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      name: 'reportLayer',
      zIndex: 11,
    });

    this._reportLayer = layer;

    this.instance.addLayer(layer);
  }

  initEventLayer() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      name: 'eventLayer',
    });

    this.instance.addLayer(layer);
    this._eventLayer = layer;
  }

  initTraceLayer() {
    const cacheCursor = {};

    const styleFunction = (feature) => {
      const geometry = feature.getGeometry();
      const {color, courses} = feature.get('data');

      if (!cacheCursor[color]) cacheCursor[color] = getCursor(color);

      // linestring
      const styles = [
        new Style({
          stroke: new Stroke({color: 'white', width: 8}),
        }),
        new Style({
          stroke: new Stroke({color, width: 6}),
        }),
      ];

      let lastDistance = 0;
      let idx = 0;
      geometry.forEachSegment((start, end) => {
        const distance = this._getDistance(start, end);

        if (idx > 0 && distance + lastDistance > 20) {
          lastDistance = 0;

          styles.push(
              new Style({
                geometry: new Point(start),
                image: new Icon({
                  src: cacheCursor[color],
                  rotation: (courses[idx] * Math.PI) / 180,
                }),
              }),
          );
        } else {
          lastDistance += distance;
        }
        idx++;
      });

      if (geometry.getCoordinates().length > 1) {
        styles.push(
            new Style({
              geometry: new Point(geometry.getLastCoordinate()),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({color}),
                stroke: new Stroke({color: 'white', width: 2}),
              }),
            }),
        );
      }

      return styles;
    };

    const layer = new VectorLayer({
      source: new VectorSource(),
      style: styleFunction,
      name: 'traceLayer',
    });

    this._traceLayer = layer;

    this.instance.addLayer(layer);
  }

  initTraceEventLayer() {
    const styleFunction = (feature) => {
      const geometry = feature.getGeometry();
      const {color, courses} = feature.get('data');

      const cursor = getCursor('red');

      // linestring
      const styles = [
        new Style({
          stroke: new Stroke({color: 'white', width: 8}),
        }),
        new Style({
          stroke: new Stroke({color, width: 6}),
        }),
      ];

      if (geometry.getCoordinates().length > 1) {
        styles.push(
            new Style({
              geometry: new Point(geometry.getLastCoordinate()),
              image: new Icon({
                scale: 1.5,
                src: cursor,
                rotation: (courses[courses.length - 1] * Math.PI) / 180,
              }),
            }),
        );
      }

      return styles;
    };

    const layer = new VectorLayer({
      source: new VectorSource(),
      style: styleFunction,
      name: 'traceLayer',
    });

    this._traceEventLayer = layer;

    this.instance.addLayer(layer);
  }

  initGeofencesLayer() {
    const layer = new VectorLayer({
      source: new VectorSource(),
      name: 'geofenceLayer',
    });

    this._geofenceLayer = layer;

    this.instance.addLayer(layer);
  }

  defineDeviceCluster(distance, zoom) {
    const clusterSource = new Cluster({
      source: this._devicesLayer.getSource(),
      distance,
    });

    this._devicesLayer.setSource(clusterSource);

    const styleCache = {};

    this._devicesLayer.setStyle((feature) => {
      const size = feature.get('features').length;
      if (size === 1) {
        const f = feature.get('features')[0];
        return feature.get('features')[0].getStyle()(f);
      } else {
        let style = styleCache[size];

        const radius = Math.max(15, Math.min(size * 2, 20));
        let dash = (2 * Math.PI * radius) / 6;
        dash = [0, dash, dash, dash, dash, dash, dash];
        const color = size > 25 ? '49,27,146' : size > 8 ? '81,45,168' : '126,87,194';

        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: radius,
              stroke: new Stroke({
                color: 'rgba(' + color + ',0.5)',
                width: 15,
                lineDash: dash,
                lineCap: 'butt',
              }),
              fill: new Fill({color: 'rgba(' + color + ',1)'}),
            }),
            text: new TextStyle({
              font: '15px sans-serif',
              text: size.toString(),
              fill: new Fill({color: '#fff'}),
            }),
          });
          styleCache[size] = style;
        }
        return style;
      }
    });

    if (zoom) {
      this._devicesLayer.once('change', (evt) => {
        console.log(evt);
        setTimeout(() => {
          this.centerOnCluster();
        }, 1000);
      });
    }
  }

  addFeatureEvent() {
  }

  centerOnCluster() {
    const source = this._devicesLayer.getSource();
    const features = source.getFeatures();
    const extent = new createEmpty();

    features.forEach((f, index, array) => {
      extend(extent, f.getGeometry().getExtent());
    });

    const validIndexValue = extent.findIndex((el) => el == Number.POSITIVE_INFINITY || el == Number.NEGATIVE_INFINITY);
    if (validIndexValue < 0) {
      this._instance.getView().fit(extent, this._instance.getSize());
      this.decreaseZoom( 12 );
    }
  }

  addFeatureDevice(device) {
    if (!device.currentPosition) return;
    const {category, status, id} = device;

    const isDeviceFeatureAlreadyExists = !!this.getDeviceFeatureByDeviceId(id);
    if(isDeviceFeatureAlreadyExists) return;

    const {
      longitude,
      latitude,
      course,
      attributes = {},
    } = device.currentPosition;

    const img = new Image(50, 50);

    const ignition = !!attributes.ignition;
    const alarm = !!attributes.alarm;

    img.src = getDeviceIcon(
        category,
        'top',
        getDeviceIconColor({status, ignition, alarm}),
    );

    this._cacheImg[`${category}_${status}_${ignition.toString()}`] = img;

    img.onerror = (err) => {
      img.src = getDeviceIcon(
          'default',
          'top',
          getDeviceIconColor({status, ignition}),
      );
      this._cacheImg[`${category}_${status}_${ignition.toString()}`] = img;
    };

    const feature = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude])),
    });

    feature.set('data', {img, course});
    feature.setId(+id);
    feature.setStyle((feature) => {
      const {img, course} = feature.get('data');

      return new Style({
        image: new Icon({
          img: img,
          anchor: [0.5, 0.5],
          crossOrigin: 'anonymous',
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          imgSize: [img.width, img.height],
          rotation: (course * Math.PI) / 180,
        }),
      });
    });

    this._devices.set(id, feature);
    this._devicesLayer.getSource().addFeature(feature);
  }

  addFeatureTrace(deviceId, coords, color) {
    const courses = [];

    coords = coords.map(([lon, lat, course]) => {
      courses.push(course);
      return fromLonLat([lon, lat]);
    });

    const feature = new Feature({
      geometry: new LineString(coords),
      name: 'trace',
    });

    feature.set('data', {courses, color});
    feature.setId(+deviceId);

    this._traceLayer.getSource().addFeature(feature);
    this._traces.set(deviceId, feature);
  }

  addFeatureEventTrace(deviceId, coords, color) {
    const courses = [];

    coords = coords.map(([lon, lat, course]) => {
      courses.push(course);
      return fromLonLat([lon, lat]);
    });

    const feature = new Feature({
      geometry: new LineString(coords),
      name: 'trace',
    });

    feature.set('data', {courses, color});
    feature.setId(+deviceId);

    this._traceEventLayer.getSource().addFeature(feature);
    this._traces.set(deviceId, feature);
  }

  addLabelOverlay(deviceId, name, coord) {
    const element = document.createElement('div');
    element.className = 'label-overlay rounded-pill';
    element.innerHTML = name;

    const overlay = new Overlay({
      id: `label-${deviceId}`,
      element,
      offset: [0, -30],
      positioning: 'bottom-center',
      position: fromLonLat(coord),
    });

    overlay.set('name', 'label');
    this._instance.addOverlay(overlay);
  }

  addAlarmOverlay(deviceId, alarmName, coord) {
    const element = document.createElement('div');
    element.className = 'label-overlay-alarm rounded-pill';
    const alarm = this._i18n.formatMessage({
      id: `alarm${String(alarmName)
          .charAt(0)
          .toUpperCase()}${String(alarmName).slice(1)}`,
    });
    element.innerHTML = `<span name="alarm-icon" class="far fa-siren-on"></span> <span name="alarm-name" class="alarm-name">${alarm}</span>`;

    const overlay = new Overlay({
      id: `alarm-${deviceId}`,
      element,
      offset: [0, 27],
      positioning: 'top-left',
      position: fromLonLat(coord),
    });

    overlay.set('name', 'alarm');
    this._instance.addOverlay(overlay);
  }

  addEventOverlay(elementId, coord) {
    const container = document.getElementById(elementId);

    const overlay = new Overlay({
      id: 'overlayEvent',
      positioning: 'bottom-left',
      element: container,
      autoPan: true,
      position: fromLonLat(coord),
      stopEvent: false,
      autoPanAnimation: {
        duration: 250,
      },
    });

    overlay.set('name', 'event');
    this._instance.addOverlay(overlay);
  }

  addReportRoute(data, markers = false) {
    this.removeReports();

    const color = 'rgb(22, 165, 218)';
    const cursor = getCursor(color);
    const activeCursor = getCursor('#FC0000');

    const features = data.reduce((features, positions) => {
      const courses = [];
      const ids = [];

      const coords = positions.map(({id, lon, lat, course}) => {
        courses.push(course);
        ids.push(id);
        return fromLonLat([lon, lat]);
      });

      const lineFeature = new Feature({
        geometry: new LineString(coords),
        name: 'line',
      });

      lineFeature.setStyle(
          new Style({stroke: new Stroke({color, width: 6})}),
      );

      const length = coords.length;
      const positionsFeature = coords.reduce((features, coord, index) => {
        if (!markers && index > 0 && index < length - 1) return features;

        const f = new Feature({
          geometry: new Point(coord),
        });

        f.setId(ids[index]);

        const styleFunction = (f) => {
          const active = f.getId() === this.selectedReportPoint;

          return index === 0 ?
              new Style({
                image: new CircleStyle({
                  radius: 8,
                  fill: new Fill({color: 'white'}),
                  stroke: new Stroke({
                    color: active ? '#FC0000' : color,
                    width: 3,
                  }),
                }),
                zIndex: 1,
              }) :
              index === length - 1 ?
                  [
                    new Style({
                      image: new CircleStyle({
                        radius: 10,
                        fill: new Fill({color: 'white'}),
                        stroke: new Stroke({
                          color: active ? '#FC0000' : color,
                          width: 3,
                        }),
                      }),
                      zIndex: 1,
                    }),
                    new Style({
                      image: new CircleStyle({
                        radius: 3,
                        fill: new Fill({color: active ? '#FC0000' : color}),
                      }),
                      zIndex: 1,
                    }),
                  ] :
                  new Style({
                    image: new Icon({
                      src: active ? activeCursor : cursor,
                      rotation: (courses[index] * Math.PI) / 180,
                    }),
                  });
        };

        f.setStyle(styleFunction);
        return [...features, f];
      }, []);

      return [...features, lineFeature, ...positionsFeature];
    }, []);

    this._reportLayer.getSource().addFeatures(features);
  }

  addReportTrip(data, markers = false, id) {
    this.removeReports();

    const color = 'rgb(22, 165, 218)';
    const cursor = getCursor(color);

    const courses = [];

    const coords = data.map(({longitude, latitude, course}) => {
      courses.push(course);
      return fromLonLat([longitude, latitude]);
    });

    const lineFeature = new Feature({
      geometry: new LineString(coords),
      name: 'line',
    });

    lineFeature.setStyle(
        new Style({stroke: new Stroke({color, width: 6})}),
    );

    const length = coords.length;
    const features = coords.reduce((features, coord, index) => {
      if (!markers && index > 0 && index < length - 1) return features;

      const f = new Feature({geometry: new Point(coord)});
      f.set('id', id);
      f.setStyle(
          index === 0 ?
              new Style({
                image: new CircleStyle({
                  radius: 8,
                  fill: new Fill({color: 'white'}),
                  stroke: new Stroke({
                    color,
                    width: 3,
                  }),
                }),
                zIndex: 1,
              }) :
              index === length - 1 ?
                  [
                    new Style({
                      image: new CircleStyle({
                        radius: 10,
                        fill: new Fill({color: 'white'}),
                        stroke: new Stroke({
                          color,
                          width: 3,
                        }),
                      }),
                      zIndex: 1,
                    }),
                    new Style({
                      image: new CircleStyle({
                        radius: 3,
                        fill: new Fill({color}),
                      }),
                      zIndex: 1,
                    }),
                  ] :
                  new Style({
                    image: new Icon({
                      src: cursor,
                      rotation: (courses[index] * Math.PI) / 180,
                    }),
                  }),
      );

      return [...features, f];
    }, []);

    this._reportLayer.getSource().addFeatures([lineFeature, ...features]);
  }

  addReportPoint(data) {
    if (!data) {
      this.removeReports();
      return;
    }

    if (this._reportLayer.getSource().getFeatureById(String(data.id))) return;

    const color = 'rgb(22, 165, 218)';
    const cursor = getCursor(color);
    const activeCursor = getCursor('#FC0000');

    const {id, course, longitude, latitude} = data;

    const feature = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude])),
    });
    feature.setId(id);
    feature.setStyle((f) => {
      const active = f.getId() === this.selectedReportPoint;
      return new Style({
        image: new Icon({
          src: active ? activeCursor : cursor,
          rotation: (course * Math.PI) / 180,
        }),
      });
    });

    this._reportLayer.getSource().addFeature(feature);
  }

  addGeofence(geofence, zoom) {
    const {area, id} = geofence;
    const feature = new Feature(this.wktToGeometry(area));

    feature.setId(+id);
    feature.setStyle([
      new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
    ]);

    if (this._geofenceLayer === null) {
      this.initGeofencesLayer();
    }

    this._geofenceLayer.getSource().addFeature(feature);

    if (zoom) {
      const point = feature.getGeometry();
      this._instance.getView().fit(point);
    }
  }

  removeGeofence(geofenceId) {
    if (!this._geofenceLayer) return;

    const source = this._geofenceLayer.getSource();
    if (geofenceId) {
      const found = source.getFeatures().find((f) => f.getId() === geofenceId);
      found && source.removeFeature(found);
      return;
    }
    source.getFeatures().forEach((f) => source.removeFeature(f));
  }

  wktToGeometry(area) {
    const mapView = this._instance.getView();
    let geometry;
    let projection;
    let resolutionAtEquator;
    let pointResolution;
    let resolutionFactor;
    const points = [];
    let center;
    let radius;
    let content;
    let i;
    let lat;
    let lon;
    let coordinates;

    if (area.lastIndexOf('POLYGON', 0) === 0) {
      content = area.match(/\([^()]+\)/);
      if (content !== null) {
        coordinates = content[0].match(/-?\d+\.?\d*/g);
        if (coordinates !== null) {
          projection = mapView.getProjection();
          for (i = 0; i < coordinates.length; i += 2) {
            lat = Number(coordinates[i]);
            lon = Number(coordinates[i + 1]);
            points.push(transform([lon, lat], 'EPSG:4326', projection));
          }
          geometry = new Polygon([points]);
        }
      }
    } else if (area.lastIndexOf('CIRCLE', 0) === 0) {
      content = area.match(/\([^()]+\)/);
      if (content !== null) {
        coordinates = content[0].match(/-?\d+\.?\d*/g);
        if (coordinates !== null) {
          projection = mapView.getProjection();
          center = transform([Number(coordinates[1]), Number(coordinates[0])], 'EPSG:4326', projection);
          resolutionAtEquator = mapView.getResolution();
          pointResolution = getPointResolution(projection, resolutionAtEquator, center);
          resolutionFactor = resolutionAtEquator / pointResolution;
          radius = Number(coordinates[2]) / METERS_PER_UNIT.m * resolutionFactor;
          geometry = new Circle(center, radius);
        }
      }
    } else if (area.lastIndexOf('LINESTRING', 0) === 0) {
      content = area.match(/\([^()]+\)/);
      if (content !== null) {
        coordinates = content[0].match(/-?\d+\.?\d*/g);
        if (coordinates !== null) {
          projection = mapView.getProjection();
          for (i = 0; i < coordinates.length; i += 2) {
            lat = Number(coordinates[i]);
            lon = Number(coordinates[i + 1]);
            points.push(transform([lon, lat], 'EPSG:4326', projection));
          }
          geometry = new LineString(points);
        }
      }
    }
    return geometry;
  }

  removeLayer(layer) {
    this._instance.removeLayer(layer);
    layer = null;
  }

  removeReports() {
    const source = this._reportLayer.getSource();
    source.getFeatures().forEach((f) => source.removeFeature(f));
  }

  removeGeofences() {
    if (!this._geofenceLayer) return;
    const source = this._geofenceLayer.getSource();
    source.getFeatures().forEach((f) => source.removeFeature(f));
  }

  removeDevices() {
    if (!this._devicesLayer) return;
    const source = this._devicesLayer.getSource();
    source.getFeatures().forEach((f) => source.removeFeature(f));
  }

  removeOverlayById(overlayId) {
    const found = this._instance.getOverlayById(overlayId);

    if (found) {
      this._instance.removeOverlay(found);
    }
  }

  removeOverlays(typeName) {
    this._instance
        .getOverlays()
        .getArray()
        .filter((o) => (!typeName ? true : o.get('name') === typeName))
        .forEach((o) => this._instance.removeOverlay(o));
  }

  removeFeaturesTrace(deviceId) {
    if (!this._traceLayer) return;

    const source = this._traceLayer.getSource();
    if (deviceId) {
      const found = source.getFeatures().find((f) => f.getId() === deviceId);
      found && source.removeFeature(found);
      return;
    }
    source.getFeatures().forEach((f) => source.removeFeature(f));
  }

  removeFeaturesEventTrace(deviceId) {
    const source = this._traceEventLayer.getSource();
    if (deviceId) {
      const found = source.getFeatures().find((f) => f.getId() === deviceId);
      found && source.removeFeature(found);
      return;
    }
    source.getFeatures().forEach((f) => source.removeFeature(f));
  }

  updateAlarmOverlay(overlayId, overlay, { name, coord }) {
    const _overlay = overlay ?? this._instance.getOverlayById(overlayId);
    if(!_overlay) return;

    name && this.updateAlarmOverlayName(name, null, overlay);
    coord && this.updateOverlayPosition(null, coord, overlay);
  }

  updateAlarmOverlayName(name, overlayId, overlay) {
    let element;

    if(overlay) {
      element = overlay.getElement();
    } else {
      element = this._instance.getOverlayById(overlayId).getElement();
    }
    if(!element) return;

    element.children.namedItem("alarm-name").innerText = this._i18n.formatMessage({
      id: `alarm${String(name)
          .charAt(0)
          .toUpperCase()}${String(name).slice(1)}`,
    });
  }

  updateOverlayPosition(OverlayId, coord, overlay) {
    if (overlay) {
      overlay.setPosition(fromLonLat(coord));
      return;
    }

    this._instance.getOverlayById(OverlayId).setPosition(fromLonLat(coord));
  }

  updateTrace(deviceId, coords) {
    const feature = this._traces.get(deviceId);
    const courses = [];

    coords = coords.map(([lon, lat, course]) => {
      courses.push(course);
      return fromLonLat([lon, lat]);
    });

    feature.get('data').courses = courses;

    feature.getGeometry().setCoordinates(coords);
  }

  updateEventTrace(deviceId, coords) {

    const feature = this._traces.get(deviceId);
    const courses = [];

    coords = coords.map(([lon, lat, course]) => {
      courses.push(course);
      return fromLonLat([lon, lat]);
    });

    if (feature) {
      feature.get('data').courses = courses;
      feature.getGeometry().setCoordinates(coords);
    }
  }

  updateDevicePosition(device) {
    const {longitude, latitude, course} = device.currentPosition;
    const feature = this._devices.get(device.id);
    feature.get('data').course = course;
    feature.getGeometry().setCoordinates(fromLonLat([longitude, latitude]));
  }

  updateDevice(device) {
    if (this._devices.has(+device.id)) {
      const {category, status, id} = device;
      const {attributes = {}} = device.currentPosition || {};
      const ignition = attributes.ignition || false;

      let img = this._cacheImg[`${category}_${status}_${ignition.toString()}`];

      if (!img) {
        img = new Image(50, 50);
        img.src = getDeviceIcon(
            category,
            'top',
            getDeviceIconColor({status, ignition}),
        );
        this._cacheImg[`${category}_${status}_${ignition.toString()}`] = img;
      }

      const f = this._devices.get(+id);
      f.get('data').img = img;
      f.getStyle()(f);
    }
  }

  findLayerById(id) {
    return this.instance
        .getLayers()
        .getArray()
        .find((l) => l.get('id') === id);
  }

  findLayerByProperties(prop, value) {
    return this.instance
        .getLayers()
        .getArray()
        .find((l) => l.get(prop) == value);
  }

  _getDistance(start, end) {
    return Math.abs(
        this._instance.getPixelFromCoordinate(start)[0] -
        this._instance.getPixelFromCoordinate(end)[0],
    );
  }

  getDeviceFeatureByDeviceId(deviceId) {
    return this._devices.get(deviceId) ?? null;
  }
}

export default MapInstance;
