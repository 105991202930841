import {inject, observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import AdminContainer from '../containers/admin-container/admin-container';
import AdminDashboardContainer from '../containers/admin-container/admin-dashboard-container';
import AdminDetailChartContainer from '../containers/admin-container/admin-detail-chart-container';
import AdminMapContainer from '../containers/admin-container/admin-map-container';
import AdminEventMapContainer from '../containers/admin-container/admin-event-map-container';
import AdminReportContainer from '../containers/admin-container/admin-report-container';
import BigFleetContainer from '../containers/big-fleet-container';
import LoginContainer from '../containers/login-container';
import ClientContainer from '../containers/personal-container';
import {goMainRoute} from '../helpers/auth';
import {AdminRoute, ClientRoute, FleetRoute, NoMatch} from './buildRoutes';
import history from './history';
import AdminFormContainer from '../containers/admin-container/admin-form-container';
import SharedMap from '../views/shared-map';

const Routes = ({
                    auth,
                    allNotificationTypes,
                    allNotificators,
                    allCommandTypes,
                }) => {
    const profile = auth.profile;

    useEffect(() => {
        if (profile) {
            allNotificationTypes.init(auth);
            allNotificators.init(auth);
            allCommandTypes.init(auth);
        }
    }, [profile]);

    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" render={() => goMainRoute(auth.profile)}/>

                <ClientRoute exact path="/client" component={ClientContainer} profile={profile}/>
                {/* <ClientRoute exact path="/client/:deviceId" component={ClientContainer} profile={profile}/>*/}

                <FleetRoute exact path="/fleet" component={BigFleetContainer} profile={profile}/>
                {/* <FleetRoute exact path="/fleet/:deviceId" component={BigFleetContainer} profile={profile}/>*/}

                <AdminRoute
                    path="/admin"
                    profile={profile}
                    render={(props) => (
                        <AdminContainer {...props}>
                            <Route exact path={`${props.match.url}/`} component={AdminDashboardContainer}/>
                            <Route exact path={[
                                `${props.match.url}/events/:eventId`,
                                `${props.match.url}/events/devices/:deviceId`,
                            ]}
                                   component={AdminEventMapContainer}
                            />
                            <Route exact path={`${props.match.url}/devices`} component={AdminMapContainer}/>
                            <Route exact path={`${props.match.url}/manage/:view`} component={AdminFormContainer}/>
                            <Route exact path={`${props.match.url}/reports`} component={AdminReportContainer}/>
                            <Route exact path={`${props.match.url}/chart/:type`} component={AdminDetailChartContainer}/>
                        </AdminContainer>
                    )}
                />

                <Route exact path="/login" component={LoginContainer}/>

                <Route path="/shared/:sharedId" component={SharedMap} />

                <Route component={NoMatch}/>
            </Switch>
        </Router>
    );
};

const mapping = ({store}) => ({
    auth: store.auth,
    allNotificationTypes: store.allNotificationTypes,
    allNotificators: store.allNotificators,
    allCommandTypes: store.allCommandTypes,
});

export default inject(mapping)(observer(Routes));
