import {useField} from '@unform/core';
// eslint-disable-next-line camelcase
import pt_br from 'date-fns/locale/pt-BR';
import * as moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DEFAULT_LOCALE = navigator.language.split(/[-_]/)[0];

const DatePicker = ({name, displayFormat, dateFormat, ...props}) => {
    const ref = useRef(null);
    const {fieldName, registerField, defaultValue, error} = useField(name);
    const initialState = defaultValue ? moment(defaultValue).toDate() : null;
    const [selected, setSelected] = useState(initialState);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: ref.current,
            path: 'props.selected',
            clearValue: (pickerRef) => {
                pickerRef.clear();
            },
        });
    }, [ref.current, fieldName]); // eslint-disable-line

    const formatDate = (date) => {
        return date;
    };

    if (DEFAULT_LOCALE === 'pt') {
        registerLocale('pt-BR', pt_br);
    }

    return (
        <>
            <ReactDatePicker
                name={fieldName}
                selected={selected}
                locale={DEFAULT_LOCALE}
                dateFormat={displayFormat || 'Y-m-d'}
                onChange={(date) => setSelected(formatDate(date))}
                ref={ref}
                {...props}
            />
            {error && <span>{error}</span>}
        </>
    );
};

export default DatePicker;
