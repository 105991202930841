import useStore from './use-store';

const useTimezone = () => {
    const {store} = useStore();
    const {server, session} = store;
    const attributesSesssion = session.attributes;
    const attributesServer = server.attributes;

    let serverTimezone;

    if (attributesServer) {
        serverTimezone = attributesServer?.hasOwnProperty('timezone') ? attributesServer?.timezone : 'America/Fortaleza';
    } else {
        serverTimezone = 'America/Fortaleza';
    }

    const timezone = attributesSesssion?.hasOwnProperty('timezone') ? attributesSesssion?.timezone : serverTimezone;

    return {timezone};
};

export default useTimezone;
