import {types} from 'mobx-state-tree';
import KnownAttributeModel from '../models/known-attribute-model';

const MaintenanceTypesStore = types
    .model('MaintenanceTypesStore', {
        types: types.optional(types.array(KnownAttributeModel), []),
    })
    .actions((self) => ({
        setTypes(data) {
            const items = [];
            data.map((x) => items.push(x));
            self.types = items;
        },
        getType(type) {
            return self.types.find((t) => t['key'] === type);
        },
    }));

export default MaintenanceTypesStore;

