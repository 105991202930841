import React from 'react';
import styles from './Header.module.sass';
import ImageComponent from 'src/components/image/image';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    padding: 0 2rem 0 2rem;
    pointer-events: none;
    width: 100%;
`;

const Header = ({children, ...rest}) => {
    return (
        <Container {...rest}>
            {children}
        </Container>
    );
};

const Logo = ({src}) => {
    return (
        <div className={styles.logo__container}>
            <ImageComponent
                className={styles.logo}
                src={src}
                skeletonProps={{
                    className: styles.logo__skeleton,
                }}
            />
        </div>
    );
};

export default {
    Root: Header,
    Logo,
};
