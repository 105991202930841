import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import I18nMessage from '../../components/I18nMessage';
import './sinister-event.sass';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Accordion from '../../components/accordion';
import Chevron from './chevron';
import OccurrencesForm from './occurrences-form';
import SinisterCloseForm from './sinister-close-form';
import EssentialSinisterForm from './sinister-essential-form';
import {Timeline, TimelineItem} from 'vertical-timeline-component-for-react';
import {ellipseText} from '../../helpers/sinister-help';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';

const colors = {
    green: '#00b105',
    red: '#f2392d',
};

const SinisterCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
 `;

const SinisterCard = styled.div`
    background-color: white;
    border-radius: 10px;
    border: ${(props) => `1px solid ${colors.red}`};
    box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4);
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
    span {
      color: ${colors.red};
      font-weight: bold;
      font-size: 0.8rem;
    }
    small {
    }
  `;

const SinisterDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
  `;

const Span = styled.span`
    color: ${colors.red};
    font-size: 1.5rem;
  `;

const Date = styled.span`
    color: ${colors.red};
    font-weight: 600;
    font-size: 0.8rem;
  `;

const H3SinisterStyled = styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FDA700;
    text-align: left;
  `;

const PSinisterStyled = styled.h3`
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
   color: #6B6B6B;
   text-align: left;
  `;

const ModalStyled = styled(Modal)`
   top: 20%;
   .modal-dialog{
      overflow:visible;
      min-height:100%!important;
      height:600px !important;
   }
   .modal-content{
        background: #FFFFFF;
        height: 60%;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
    }
`;

const ModalHeaderStyled = styled(ModalHeader)`
    background: #FFFFFF;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #15A6DA;
    font-size: 16px;
`;

const ModalBodyStyled = styled(ModalBody)`
    height:100%
`;

const ChevronButtonStyled = styled.button`
    border: 0;
    background-color: transparent;
    color:  ${(props) => `${props.color}`};
`;

const LinkStyled = styled(Link)`
    color: inherit !important;
    text-decoration: inherit !important;
  `;

const getSinisterTypeI18N = (type) => { // TODO should use from helper
    return `sinisterType${String(type)
        .charAt(0)
        .toUpperCase()}${String(type).slice(1)}`;
};

const SinisterCardEvent = (props) => {
    const [hideClass, setHideClass] = useState('none');
    const [sinister, setSinister] = useState(props.sinister ? props.sinister : {});
    const [showDetail, openDetail] = useState(sinister ? sinister.id === 0 : false);
    const [occurrences, setOccurrences] = useState(sinister ? sinister.occurrences : []);

    const infoAccordionRef = useRef();
    const occurrenceAccordionRef = useRef();
    const closeAccordionRef = useRef();

    const getColor = () => {
        const endTime = moment().utc();
        const startTime = sinister ? moment(sinister.openingDate) : moment();
        const duration = moment.duration(endTime.diff(startTime));
        const hours = Math.floor(duration.asHours());

        if (sinister && sinister.id === 0) { // TODO should use from helper
            return 'white';
        }

        if (hours <= 24) {
            return 'orange';
        }
        if (hours <= 48) {
            return 'purple';
        }
        if (hours <= 72) {
            return 'blue';
        }

        return 'gray';
    };

    const showOccurrenceModal = (occurrence) => {
        props.setSelectedOccurrence(occurrence);
        props.toggleOccurrenceModal();
    };


    return (
        <LinkStyled to={`/admin/events/devices/${sinister.deviceId}`}>
            <SinisterCard {...props} className={`mb-2 p-3 sinister-color-${getColor()} ${hideClass}`}>
                {sinister && sinister.id === 0 && <>
                    <H3SinisterStyled><i className={'fas fa-shield'}/>Abrir Sinistro</H3SinisterStyled>
                    <PSinisterStyled>Preencha as informações essências para cadastrar o novo sinistro!</PSinisterStyled>
                </>}

                {sinister && sinister.id !== 0 && <SinisterCardHeader>
                    <Description>
                        <span>{sinister.devicePlate}</span>
                        <small>
                            <I18nMessage id={'sinisterOpen'}/>
                        </small>
                        <small>
                            <I18nMessage id={getSinisterTypeI18N(sinister.openingType)}/>
                            <ChevronButtonStyled onClick={() => openDetail(!showDetail)} color={getColor()}>
                                <i className={'fa fa-chevron-down'}/>
                            </ChevronButtonStyled>
                        </small>
                    </Description>
                    <Date
                        className={`mr-1 ml-1 sinister-color-${getColor()}`}>{moment(sinister.openingDate).fromNow()}</Date>
                    <Span className={`fas fa-shield ml-2 sinister-color-${getColor()}`}/>
                </SinisterCardHeader>}
                {showDetail && <SinisterDetails>
                    {sinister && sinister.id === 0 ? <EssentialSinisterForm
                            creating={true}
                            setSinister={setSinister}
                            sinister={sinister}
                            loadIncidents={props.loadIncidents}
                            close={() => infoAccordionRef.current.toggleAccordion()}/> :
                        <Accordion
                            ref={infoAccordionRef}
                            chevron={(className, width, fill) => <Chevron className={className} width={10}
                                                                          fill={fill}/>}
                            title="Informações Essências"
                            component={<EssentialSinisterForm
                                creating={false}
                                sinister={sinister}
                                loadIncidents={props.loadIncidents}
                                dispatchAccordionResize={() => closeAccordionRef.current.dispatchAccordionResize()}
                                close={() => infoAccordionRef.current.toggleAccordion()}/>}
                        />}
                    {sinister && sinister.id !== 0 && <Accordion
                        ref={occurrenceAccordionRef}
                        chevron={(className, width, fill) => <Chevron className={className} width={10} fill={fill}/>}
                        title="+ Adicionar Ocorrência"
                        component={<OccurrencesForm
                            sinister={sinister}
                            loadIncidents={props.loadIncidents}
                            setSinister={setSinister}
                            setOccurrences={setOccurrences}
                            dispatchAccordionResize={() => closeAccordionRef.current.dispatchAccordionResize()}
                            close={() => occurrenceAccordionRef.current.toggleAccordion()}/>}
                    />}
                    {sinister && sinister.id !== 0 && <Accordion
                        ref={closeAccordionRef}
                        chevron={(className, width, fill) => <Chevron className={className} width={10} fill={fill}/>}
                        title="Fechar Sinistro"
                        component={<SinisterCloseForm
                            sinister={sinister}
                            loadIncidents={props.loadIncidents}
                            setHideClass={setHideClass}
                            dispatchAccordionResize={() => closeAccordionRef.current.dispatchAccordionResize()}
                            close={() => closeAccordionRef.current.toggleAccordion()}/>}
                    />}
                    {sinister && sinister.id !== 0 && occurrences.length > 0 && <>
                        <br/>
                        <H3SinisterStyled>Histórico</H3SinisterStyled>
                        <Timeline lineColor={'#FDA700'}>
                            {occurrences.map((occurrence) => <TimelineItem
                                key={occurrence.id}
                                dateText={`${moment(occurrence.occurrenceDate).format('DD/MM/YY')} - ${moment(occurrence.occurrenceDate).format('hh:mm')}`}
                                dateStyle={{background: '#FDA700'}}
                                bodyContainerStyle={{
                                    background: '#FFFFFF',
                                    boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '12px',
                                }}
                            >
                                <p className={'timeline-text'}>
                                    {ellipseText(occurrence.description, 20)}
                                    <a className={'occurrence-show-more'} onClick={() => showOccurrenceModal(occurrence)}>
                                        <I18nMessage id="sinisterOccurrenceShowMore"/>
                                    </a>
                                </p>
                            </TimelineItem>)
                            }
                        </Timeline></>}
                </SinisterDetails>}
            </SinisterCard>
        </LinkStyled>
    );
};

const SinisterEventList = (props) => {
    const intl = useIntl();
    const [selectedOccurrence, setSelectedOccurrence] = useState({});
    const [modalOccurrence, setModalOccurrence] = useState(false);
    const toggleOccurrenceModal = () => setModalOccurrence(!modalOccurrence);

    const incidents = props.incidents.filter((s) => s.status === 'opened');

    const incidentsList = incidents.filter(({type}) => (!props.hasFilter ? true : props.filters.includes(type)))
        .map((e, i) => <SinisterCardEvent
            key={e.id} sinister={e}
            loadIncidents={props.loadIncidents}
            setSelectedOccurrence={setSelectedOccurrence}
            toggleOccurrenceModal={toggleOccurrenceModal}/>);

    return (
        <>
            <ModalStyled isOpen={modalOccurrence} toggle={toggleOccurrenceModal} className={'className'}>
                <ModalHeaderStyled toggle={toggleOccurrenceModal}>Ocorrência</ModalHeaderStyled>
                <ModalBodyStyled>
                    <span className={'occurrence-modal-item'}>
                        <span
                            className={'occurrence-modal-label'}>Data: </span> {moment(selectedOccurrence.occurrenceDate).format('MM.DD.YYYY')}
                    </span>
                    <span className={'occurrence-modal-item'}>
                        <span
                            className={'occurrence-modal-label'}>Horário: </span> {moment(selectedOccurrence.occurrenceDate).format('hh:mm')}
                    </span>
                    <span className={'occurrence-modal-item'}>
                        <span
                            className={'occurrence-modal-label'}>Categoria: </span> {intl.formatMessage({id: getSinisterTypeI18N(selectedOccurrence.category)})}
                    </span>
                    <span className={'occurrence-modal-item'}>
                        <span className={'occurrence-modal-label'}>Endereço: </span> {selectedOccurrence.address}
                    </span>
                    <span className={'occurrence-modal-item'}>
                        <span className={'occurrence-modal-label'}>Ocorrência: </span> {selectedOccurrence.description}
                    </span>
                </ModalBodyStyled>
                <ModalFooter>
                    <span
                        className={'occurrence-modal-footer-label'}>Relatado Por: </span> {selectedOccurrence.reportedBy}
                </ModalFooter>
            </ModalStyled>
            <div className="p-1 py-2">
                {incidentsList}
            </div>
        </>
    );
};

export default SinisterEventList;
