import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';
import * as moment from 'moment';
import ReactSelect from '../../components/react-select';

import './user.sass';
import ToggleButton from '../../components/toggle-button';
import {generateToken} from '../../helpers/auth';
import I18nMessage from '../../components/I18nMessage';
import DatePicker from '../../components/react-datepicker';
import {FieldSet} from '../../components/fieldset';
import {GroupButtonForm} from '../../components/group-button-form';
import ReactMaskPhone from '../../components/react-mask-phone';

const schema = Yup.object().shape({
    id: Yup.number().notRequired(),
    customerId: Yup.number().notRequired(),
    name: Yup.string().required(),
    email: Yup.string().required(),
    login: Yup.string().nullable().notRequired(),
    password: Yup.string().nullable().notRequired(),
    phone: Yup.string().notRequired(),
    map: Yup.string().nullable().notRequired(),
    latitude: Yup.number().notRequired(),
    longitude: Yup.number().notRequired(),
    zoom: Yup.number().notRequired(),
    twelveHourFormat: Yup.boolean().notRequired(),
    coordinateFormat: Yup.string().nullable().notRequired(),
    poiLayer: Yup.string().nullable().notRequired(),
    disabled: Yup.boolean().notRequired(),
    administrator: Yup.boolean().notRequired(),
    readonly: Yup.boolean().notRequired(),
    deviceReadonly: Yup.boolean().notRequired(),
    limitCommands: Yup.boolean().notRequired(),
    expirationTime: Yup.string().notRequired().nullable(), // "2019-10-31T03:00:00.000+0000"
    deviceLimit: Yup.number().notRequired(),
    userLimit: Yup.number().notRequired(),
    token: Yup.string().nullable().notRequired(),
    attributes: Yup.object().shape({
        keyword: Yup.string().nullable().notRequired(),
        timezone: Yup.string().nullable().notRequired(),
    }),
});

const cast = (src) => {
    const data = Object.assign({}, src);

    if (data.attributes) {
        data.attributes = Object.assign({}, data.attributes);
    }

    if (!data.userLimit) {
        data.userLimit = 0;
    }
    if (!data.zoom) {
        data.zoom = 0;
    }
    if (!data.expirationTime) {
        data.expirationTime = undefined;
    }
    if (!data.deviceLimit) {
        data.deviceLimit = 0;
    }
    if (!data.latitude) {
        data.latitude = 0;
    }
    if (!data.longitude) {
        data.longitude = 0;
    }

    return data;
};

const UserForm = ({
    cancel,
    data,
    edit,
    newRegister,
    updateRegister,
    coordinateFormats,
    profile,
    loading,
    customers,
    timezones
}) => {
    const formRef = useRef(null);

    const handleSubmit = async (formData) => {
        const userData = {...formData};
        if (data.attributes) {
            userData.attributes = {
                ...data.attributes,
                ...userData.attributes
            }
        }
        let user = {
            ...data,
            ...userData
        }

        try {
            if (user.expirationTime) {
                user.expirationTime = moment(user.expirationTime).toISOString();
            }

            user = schema.cast(user);

            await schema.validate(user, {
                strict: true,
                abortEarly: false,
                stripUnknown: true,
            });

            edit ? updateRegister(user) : newRegister(user);
        } catch (err) {
            console.log(err);
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const setToken = () => {
        formRef.current.setFieldValue('token', generateToken());
    };

    return (
        <>
            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={cast(data)}>

                {!!edit && <UInput hidden type="number" name="id"/>}

                <FieldSet className="text-left border p-4" title={'Identificação'}>

                    <div className="row">
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userEmail"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="email"
                                    name="email"
                                    id="email"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="login"><I18nMessage id="sharedLogin"/></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="login"
                                    id="login"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="login"><I18nMessage id="sharedKeyword"/></Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.keyword"
                                    id="attributes.keyword"
                                />
                            </FormGroup>
                        </div>

                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="phone"><I18nMessage id="sharedPhone"/></Label>
                                <ReactMaskPhone
                                    tag={UInput}
                                    type="number"
                                    name="phone"
                                    id="phone"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userPassword"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="password"
                                    name="password"
                                    id="password"
                                    autoComplete={'new-password'}
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedCustomer" />*</Label>
                                <ReactSelect
                                    id={'customerId'}
                                    name="customerId"
                                    options={customers} />
                            </FormGroup>
                                <FormGroup className="custom-formGroup">
                                    <Label for="name"><I18nMessage id="sharedTimezone" /></Label>
                                    <ReactSelect
                                        id={'attributes.timezone'}
                                        name="attributes.timezone"
                                        options={timezones} />
                                </FormGroup>
                        </div>
                    </div>
                </FieldSet>
                <br/>
                <FieldSet className="text-left border p-4" title={'Permissões'}>
                    <div className="row">
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="sharedDisabled"/></Label>
                                <ToggleButton name={'disabled'} id={'disabled'}/>
                            </FormGroup>
                            {profile === 'admin' &&
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="userAdmin"/></Label>
                                <ToggleButton name={'administrator'} id={'administrator'}/>
                            </FormGroup>
                            }
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="serverReadonly"/></Label>
                                <ToggleButton name={'readonly'} id={'readonly'}/>
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="userDeviceReadonly"/></Label>
                                <ToggleButton name={'deviceReadonly'} id={'deviceReadonly'}/>
                            </FormGroup>
                        </div>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="userLimitCommands"/></Label>
                                <ToggleButton name={'limitCommands'} id={'limitCommands'}/>
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="settingsTwelveHourFormat"/></Label>
                                <ToggleButton name={'twelveHourFormat'} id={'twelveHourFormat'}/>
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>
                <br/>
                <FieldSet className="text-left border p-4" title={'Configurações'}>
                    <div className="row">
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="positionLatitude"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="latitude"
                                    id="latitude"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="positionLongitude"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="longitude"
                                    id="longitude"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="serverZoom"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="zoom"
                                    id="zoom"
                                />
                            </FormGroup>
                        </div>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="settingsCoordinateFormat"/></Label>
                                <ReactSelect
                                    id={'coordinateFormat'}
                                    name="coordinateFormat"
                                    options={coordinateFormats}/>
                            </FormGroup>
                            {profile === 'admin' &&
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userDeviceLimit"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="deviceLimit"
                                    id="deviceLimit"
                                />
                            </FormGroup>
                            }
                            {profile === 'admin' &&
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userUserLimit"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="userLimit"
                                    id="userLimit"
                                />
                            </FormGroup>
                            }
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userExpirationTime"/></Label>
                                <DatePicker
                                    name={'expirationTime'}
                                    displayFormat={'dd/MM/yyyy'}
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="token"><I18nMessage id="userToken"/></Label>
                                <div className="input-group mb-2">
                                    <Input
                                        tag={UInput}
                                        type="text"
                                        name="token"
                                        id="token"
                                    />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text token">
                                                <span color="primary" onClick={() => setToken()}>
                                                    <i className="fas fa-sync-alt"/>
                                                </span>
                                            </div>
                                        </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>
                <br/>
                <GroupButtonForm
                    loading={loading}
                    cancel={cancel}
                    submit={() => formRef.current.submitForm()}
                />
            </Form>
        </>
    );
};

export default UserForm;
