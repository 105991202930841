import momentTimezone from 'moment-timezone';
import React, {memo, useContext, useMemo} from 'react';
import {ThemeContext} from '../../apiContexts';
import ReactTable from '../../components/reactTable';
import {convertDistanceTo, convertVolumeTo, nomenclatureDistance, nomenclatureVolume} from '../../helpers/conversions';
import {durationFormat} from '../../helpers/date';
import {getColumnWidth} from '../../helpers/report';
import {ButtomStyled} from '../../styles';
import {Header, ListStyle} from './styles';
import LoadingBar from '../../components/loadingBar';

const StopList = ({
  className,
  data,
  timezone,
  onSelect,
  speedUnit,
  distanceUnit,
  volumeUnit,
  loading,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const columns = useMemo(
      () => [
        {
          Header: 'Nome',
          accessor: 'deviceName',
          minWidth: 200,
          width: getColumnWidth(data, 'deviceName', 'Nome'),
        },
        {
          Header: 'Hora inicial',
          accessor: 'startTime',
          Cell: ({cell: {value}}) =>
            momentTimezone(value)
                .tz(timezone)
                .format('DD/MM/YYYY [-] HH:mm:ss'),
          minWidth: 200,
        },
        {
          Header: 'Odômetero',
          accessor: 'startOdometer',
          minWidth: 200,
          Cell: ({cell: {value}}) =>
            `${Number(convertDistanceTo(value, distanceUnit)).toFixed(2) ||
            0} ${nomenclatureDistance(distanceUnit)}`,
        },
        {
          Header: 'Endereço',
          accessor: 'address',
          Cell: ({cell: {value}}) => value || '-',
          minWidth: 150,
          width: getColumnWidth(data, 'address', 'Endereço'),
        },
        {
          Header: 'Hora final',
          accessor: 'endTime',
          Cell: ({cell: {value}}) =>
            momentTimezone(value)
                .tz(timezone)
                .format('DD/MM/YYYY [-] HH:mm:ss'),
          minWidth: 200,
        },
        {
          Header: 'Duração',
          accessor: 'duration',
          Cell: ({cell: {value}}) => durationFormat(value, 'H [h] mm [m]'),
        },
        {
          Header: 'Horas ligado',
          accessor: 'engineHours',
          Cell: ({cell: {value}}) => durationFormat(value, 'H [h] mm [m]'),
        },
        {
          Header: 'Gasto de Combustível',
          accessor: 'spentFuel',
          minWidth: 200,
          Cell: ({cell: {value}}) =>
            `${Number(convertVolumeTo(value, volumeUnit)).toFixed(1) ||
            0} ${nomenclatureVolume(volumeUnit)}`,
        },
      ],
      [data],
  );

  const selectItem = (props) => onSelect(props);

  return (
    <ListStyle theme={theme} className="custom-shadow-2">
      <Header>
        <h5>Histório de Paradas</h5>
        <ButtomStyled
          theme={theme}
          outline
          className="custom-shadow custom-border btn-theme mb-3"
        >
          <span>Selecionar dispositivo</span>
        </ButtomStyled>
      </Header>
      {loading ? (
        <LoadingBar />
      ) : (
        <ReactTable data={data} columns={columns} selectItem={selectItem} />
      )}
    </ListStyle>
  );
};

export default memo(StopList);
