import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import RoundButton from '../../components/roundButton';
import RoundButtonVerticalDropdown from '../../components/roundButtonVerticalDropdown';
import ZoomButton from '../../components/zoomButton';

const RightStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const MapRightActions = ({
                             label,
                             trace,
                             toggleLabel,
                             toggleTrace,
                             close,
                             onChangeMap,
                             selectedMap,
                             map,
                             toggleDevice,
                             toggleGeofences,
                             showDevices,
                             showGeofences,
                             showVerticalDevices = true,
                             isAlarmsVisible,
                             toggleAlarmsVisibility,
                             ...props
                         }) => {
    return (
        <RightStyle id="right-actions" {...props}>
            {close && (
                <RoundButton className="mt-3 mb-3" tag={Link} to={close}>
                    <span className="fas fa-times text-secondary"/>
                </RoundButton>
            )}
            <RoundButtonVerticalDropdown icon="fas fa-layer-group">

            <RoundButton
                    label="Alarmes"
                    withBorder
                    labelDirection="bottom"
                    onClick={toggleAlarmsVisibility}
                    active={isAlarmsVisible}
                >
                    <span className="fal fa-comment-alt"/>
                </RoundButton>

                <RoundButton
                    label="Dispositivos"
                    withBorder
                    labelDirection="bottom"
                    active={showDevices}
                    onClick={toggleDevice}
                >
                    <span className="fal fa-car"/>
                </RoundButton>

                <RoundButton
                    label="Labels"
                    withBorder
                    labelDirection="bottom"
                    onClick={toggleLabel}
                    active={label}
                >
                    <span className="fal fa-comment-alt"/>
                </RoundButton>

                <RoundButton
                    label="Cercas"
                    withBorder
                    className="ml-2"
                    labelDirection="bottom"
                    active={showGeofences}
                    onClick={toggleGeofences}
                >
                    <span className="fal fa-location-circle"/>
                </RoundButton>

                <RoundButton
                    label="Rastro"
                    withBorder
                    labelDirection="bottom"
                    onClick={toggleTrace}
                    className="ml-2"
                    active={trace}
                >
                    <span className="fal fa-route"/>
                </RoundButton>
            </RoundButtonVerticalDropdown>

            <RoundButtonVerticalDropdown icon="fal fa-map" className="my-4">
                <RoundButton
                    label="Ruas"
                    withBorder
                    labelDirection="bottom"
                    onClick={() => onChangeMap('street')}
                    active={selectedMap === 'street'}
                >
                    <span className="fas fa-globe-americas"/>
                </RoundButton>

                <RoundButton
                    withBorder
                    className="mx-2"
                    active={selectedMap === 'satellite'}
                    label="Satélite"
                    labelDirection="bottom"
                    onClick={() => onChangeMap('satellite')}
                >
                    <span className="fal fa-satellite"/>
                </RoundButton>

                <RoundButton
                    withBorder
                    active={selectedMap === 'osm'}
                    label="OSM"
                    labelDirection="bottom"
                    onClick={() => onChangeMap('osm')}
                >
                    <strong>OSM</strong>
                </RoundButton>
            </RoundButtonVerticalDropdown>
            <ZoomButton map={map}/>
        </RightStyle>
    );
};

export default MapRightActions;
