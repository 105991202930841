import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Input as UInput} from '../../components/input';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import './device.sass';
import I18nMessage from '../../components/I18nMessage';
import {GroupButtonForm} from '../../components/group-button-form';
import {FieldSet} from '../../components/fieldset';
import {knotsToKmH} from '../../helpers/conversions';
import useFormatter from '../../hooks/user-formatter';

const AccumulatorForm = (props) => {
    const {
        data,
        loading,
        cancel,
        saveAccumulator,
    } = props;

    const formRef = useRef(null);
    const {distanceConverter, hoursConverter} = useFormatter();

    const schema = Yup.object().shape({
        deviceId: Yup.number(),
        totalDistance: Yup.number().required('Nome é necessário'),
        hours: Yup.number().required('Nome é necessário'),
    });

    const handleF8Key = () => {
        formRef.current.submitForm();
    };

    const handleSubmit = async (data) => {
        try {
            await schema.validate(data, {
                abortEarly: false,
            });
            saveAccumulator(data);
        } catch (err) {
            console.log(err);
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const cast = (data) => {
        if (data.currentPosition) {
            const {totalDistance = 0, hours = 0} = data.currentPosition.attributes;
            return {
                deviceId: data.id,
                totalDistance: distanceConverter(totalDistance),
                hours: hoursConverter(hours),
            };
        }

        return {
            deviceId: data.id,
            totalDistance: 0,
            hours: 0,
        };
    };

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            schema={schema}
            initialData={cast(data)}>
            <UInput hidden type="number" name="deviceId"/>
            <FieldSet className="text-left border p-4">
                <div className="row">
                    <div className={'col'}>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="deviceTotalDistance"/>* (km)</Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="totalDistance"
                                id="name"
                            />
                        </FormGroup>

                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="positionHours"/>* (h)</Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="hours"
                                id="name"
                            />
                        </FormGroup>
                    </div>
                </div>
            </FieldSet>
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={handleF8Key}
            />
        </Form>
    );
};

export default AccumulatorForm;
