import React from 'react';
import styled from 'styled-components';
import RoundButton from '../../../../components/roundButton';
import RoundButtonVerticalDropdown from '../../../../components/roundButtonVerticalDropdown';
import ZoomButton from '../../../../components/zoomButton';

const Container = styled.div.attrs({id: 'right-actions'})`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  position: absolute;
  z-index: 9999999999;
  right: 1rem;
  top: 1rem;
`;

const ActionGroup = ({
    icon,
    children,
}) => {
    return (
        <RoundButtonVerticalDropdown
            icon={icon}
        >
            {children}
        </RoundButtonVerticalDropdown>
    );
};

const ActionItem = ({
    label,
    onPress,
    active,
    iconClassName,
    children,
}) => {
    return (
        <RoundButton
            withBorder
            label={label}
            labelDirection='bottom'
            onClick={onPress}
            active={active}
        >
            {!!iconClassName ? (
                <span className={iconClassName} />
            ) : children}
        </RoundButton>
    );
};

const ZoomControls = ({map}) => <ZoomButton map={map} />;

export default {
    Root: Container,
    ActionGroup,
    ActionItem,
    ZoomControls,
};
