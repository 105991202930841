export default class Veiculo {
    constructor(data) {
        this.id = 0;
        this.veiculo = data.veiculo;
        this.placa = data.placa;
        this.cor = data.cor;
        this.ano = data.ano;
        this.numeroChassi = data.numeroChassi;
        this.modelo = data.modelo;
        this.combustivel = data.combustivel;
        this.consumo = data.consumo;
        this.limiteVelocidade = data.limiteVelocidade;
        this.odometro = data.odometro;
        this.tipo = data.tipo;
        this.marca = data.marca;
        this.clienteId = data.clienteId;
    }
}
