/**
 * Returns true for any kind of data different from an object(even for object
 * likes eg.: array or null) and true for {}.
 * @param {object} data
 * @return {boolean}
 */
export function isObjectEmpty(data) {
    if (!(typeof data === 'object')) return true;

    if (data === null) return true;

    if (Array.isArray(data)) return true;

    return Object.keys(data).length === 0;
}
