export const actions = {
  SET_INSTANCE: 'set_instance',
  SET_CLUSTER: 'set_cluster',
  SET_DEVICE_MAP: 'set_device_map',
  SET_INITIALIZED_POSITIONS: 'set_initialized_positions',
};

const _reducerActions = {
  set_instance: (state, payload) => ({
    ...state,
    instance: payload,
  }),

  set_cluster: (state, payload) => ({
    ...state,
    cluster: {...state.cluster, ...payload},
  }),

  set_device_map: (state, payload) => ({
    ...state,
    deviceMap: payload,
  }),

  set_initialized_positions: (state, payload) => ({
    ...state,
    initializedPositions: payload,
  }),
};

export const initialState = {
  instance: null,
  deviceMap: null,
  initializedPositions: false,
};

export const reducer = (state, action) => {
  const fn = _reducerActions[action.type];

  if (fn) return fn(state, action.payload);

  console.log('[WARNING] Action without reducer:', action);
  return state;
};
