import React, {useState, useContext} from 'react';
import {ModalBody, ModalHeader, Spinner} from 'reactstrap';
import {ModalContext, ThemeContext} from '../../apiContexts';
import styled from 'styled-components';
import {ModalXD} from '../../styles';
import {modalList, modalDetail} from '../../views/modals/modalList';
import I18nMessage from '../../components/I18nMessage';

const ModalContainer = ({children, ...props}) => {
  const theme = useContext(ThemeContext);
  const [modalInfo, setModalInfo] = useState({title: 'Título', type: ''});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = (modal) => {
    setModalInfo(modalDetail[modal]);
    setModal(true);
  };

  const toggle = () => setModal(!modal);

  const showModalLoading = (value) => setLoading(value);

  return (
    <ModalContext.Provider value={{openModal, toggle, showModalLoading, isModalLoading: loading}}>
      <>
        {children}

        <ModalXD
          size="lg"
          centered
          isOpen={modal}
          toggle={toggle}
          theme={theme}
          unmountOnClose={true}
        >
          <ModalHeader toggle={toggle}>
            {loading && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={'spinner-modal'}
              aria-hidden="true"
            /> }
            <I18nMessage id={modalInfo.title}/>
          </ModalHeader>
          <ModalBody>
            {
              {
                ...modalList,
              }[modalInfo.type]
            }
          </ModalBody>
        </ModalXD>
      </>
    </ModalContext.Provider>
  );
};

export default ModalContainer;
