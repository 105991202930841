import BaseService from './base-service';

/**
 * Customer Service
 */
export default class CustomerService extends BaseService {
    /**
     * Default constructor from superclass.
     */
    constructor() {
        super('customers');
    }
}
