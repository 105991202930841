import React, {useRef, useState} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import {InputFile} from '../../components/input-file';
import * as Yup from 'yup';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from '../../components/fieldset';
import {GroupButtonForm} from '../../components/group-button-form';

const CalendarForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        loading,
    } = props;

    const formRef = useRef(null);

    const [file, setFile] = useState(null);

    const schema = Yup.object().shape({
        name: Yup.string().required('Nome é necessário'),
        id: Yup.number(),
        data: Yup.string().notRequired(),
    });

    const handleSubmit = async (payload) => {
        try {
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            payload.data = file;
            edit ? updateRegister(payload) : newRegister(payload);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const handleProgress = (event) => {
        const file = event.currentTarget.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            setFile(reader.result);
        };
        reader.onerror = function(error) {
            console.log('Error: ', error);
        };
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={data}>
            {!!edit && <UInput hidden type="number" name="id"/>}
            <FieldSet className="text-left border p-4" title={'Identificação'}>
                <>
                    <FormGroup className="custom-formGroup">
                        <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                        <Input
                            tag={UInput}
                            type="text"
                            name="name"
                            id="name"
                        />
                    </FormGroup>
                    <FormGroup className="custom-formGroup">
                        <Label for="name"><I18nMessage id="sharedSelectFile"/></Label>
                        <InputFile name="data" onChange={handleProgress}/>
                    </FormGroup>
                </>

            </FieldSet>
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={() => formRef.current.submitForm()}
            />

        </Form>
    );
};

export default CalendarForm;
