import {types} from 'mobx-state-tree';
import KnownNotificatorModel from '../models/known-notificator-model';
import request from '../services/http-request';

const AllNotificatorStore = types
    .model('AllNotificatorStore', {
      types: types.optional(types.array(KnownNotificatorModel), []),
    })
    .actions((self) => ({
      init({email, password}) {
        (async () => {
          const response = await request({
            method: 'get',
            url: `notifications/notificators`,
            noAuth: true,
            auth: {username: email, password},
          });

          self.setTypes(response);
        })();
      },
      setTypes( data ) {
        self.types = data;
      },
    }));

export default AllNotificatorStore;

