import React, {useContext, useEffect, useState} from 'react';
import NotificationForm from '../../views/notification/notification-form';
import NotificationTable from '../../views/notification/notification-table';
import {inject, observer} from 'mobx-react';
import i18n from '../../stores/translate-store';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from "../../hooks/use-services";
import useNotificationType from "../../hooks/use-notification-type";
import useAlarmType from "../../hooks/use-alarm-types";
import useNotificatiorType from "../../hooks/use-notificatior-type";

const NotificationContainer = (props) => {

    const intl = useIntl();
    const {toast} = useAlerts();
    const {types: allNotificationTypes} = useNotificationType();
    const {alarmTypes} = useAlarmType();
    const {allNotificator} = useNotificatiorType();
    const {calendarService, notificationService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('notificationList');
    const [notifications, setNotifications] = useState([]);
    const [calendars, setCalendars] = useState([]);
    const [data, setData] = useState({});

    const formatter = (arr) => {
        return arr.map(({ id, always, attributes, notificators, type, calendarId }) => (
            {
                id,
                always,
                attributes,
                notificators,
                title: intl.formatMessage({id: 'event' + type.charAt(0).toUpperCase() + type.slice(1)}),
                type,
                calendarId,
                alarms: attributes ? attributes['alarms'] : undefined,
            })
        );
    }

    useEffect(() => {
        (async () => {
            const response = await notificationService.list();
            const notifications = formatter(response);
            setNotifications(notifications);

            const items = [];
            const calendars = await calendarService.list();
            calendars.map((calendar) => {
                items.push({id: calendar.id, title: calendar.name});
            });
            setCalendars(items);
        })();
    }, []);


    const notificationForm = (data) => {
        setData(Object.keys(data).length ? data : {});
        setView('notificationForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            const result = await notificationService.save(data);
            setNotifications(formatter([...notifications, result]));
            setView('notificationList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'notificationList') => {
        try {
            showModalLoading(true);
            const index = notifications.findIndex(({id}) => id === parseInt(data.id));
            const notification = {...notifications[index], ...data};

            delete notification.title;
            delete notification.alarms;

            const result = await notificationService.update(notification);

            notifications.splice(index, 1, result);
            setNotifications(formatter([...notifications]));

            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await notificationService.remove(data.id);
            setNotifications(notifications.filter(({id}) => id !== data.id));
            setView('notificationList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };


    return (
        <>
            {
                {
                    notificationList: (
                        <NotificationTable
                            i18n={i18n.strings}
                            list={notifications}
                            notificationForm={notificationForm}
                            removeRegister={removeRegister}
                            loading={isModalLoading}
                        />
                    ),
                    notificationForm: (
                        <NotificationForm
                            i18n={i18n.strings}
                            cancel={() => setView('notificationList')}
                            data={Object.assign({}, data)}
                            calendars={calendars}
                            alarmTypes={alarmTypes}
                            allNotificator={allNotificator}
                            allNotificationTypes={allNotificationTypes}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                        />
                    )
                }[view]
            }
        </>
    );
};

const mapping = ({store}) => ({
    auth: store.auth,
    alarmTypes: store.alarmTypes,
    allNotificationTypes: store.allNotificationTypes,
    allNotificators: store.allNotificators,
    i18n: store.i18n,
});

export default inject(mapping)(observer(NotificationContainer));
