import {useEffect, useRef, useState} from 'react';
import {mergeAll} from 'rxjs/operators';
import useSocket from '../../../hooks/use-socket';
import useDevices from 'src/hooks/use-devices';

const useMapAlarm = (state, _, __) => {
  const {instance: map, initializedPositions} = state;
  const {positions} = useSocket();
  const {devices} = useDevices();
  const [isAlarmsVisible, setIsAlarmsVisible] = useState(true);
  const positionsSubscription = useRef(null);

  const showAlarms = () => {
    positionsSubscription.current = positions
            .pipe(mergeAll())
            .subscribe((position) => {
              if (!isAlarmsVisible) return;
              if (!position.attributes.alarm) {
                map.removeOverlayById(`alarm-${position.deviceId}`);
              } else {
                upsertAlarmOverlay(position.deviceId, position);
              }
            });
            return true;
  };

  const hideAlarms = () => {
    positionsSubscription.current && positionsSubscription.current.unsubscribe();
    map && map.removeOverlays('alarm');
    return false;
  };

  const toggleAlarmsVisibility = () => {
    setIsAlarmsVisible((value) => !value);
  };

  const upsertAlarmOverlay = (deviceId, currentPosition) => {
    const overlay = map.instance.getOverlayById(`alarm-${deviceId}`);

    if (!overlay) {
      map.addAlarmOverlay(
        deviceId,
        currentPosition.attributes.alarm,
        [currentPosition.longitude, currentPosition.latitude],
      );
    } else {
        map.updateAlarmOverlay(
          null,
          overlay,
          {
            name: currentPosition.attributes.alarm,
            coord: [currentPosition.longitude, currentPosition.latitude],
          },
        );
    }
  };

  useEffect(() => {
    if (!isAlarmsVisible) return;

    if (map && initializedPositions) {
      const alarmedDevices = devices.findDevicesWithAlarm();
      for (const alarmedDevice of alarmedDevices) {
        if (!alarmedDevice) continue;
        const {
          id,
          currentPosition,
        } = alarmedDevice;
        upsertAlarmOverlay(id, currentPosition);
      }
      showAlarms();
    }

    return () => {
      hideAlarms();
    };
  }, [map, initializedPositions, isAlarmsVisible]);

  return {isAlarmsVisible, toggleAlarmsVisibility};
};

export default useMapAlarm;
