import {types} from 'mobx-state-tree';

const MapTypeModel = types
    .model('MapTypeModel', {
      key: types.string,
      name: types.string,
    });

export default MapTypeModel;

