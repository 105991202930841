import {types} from 'mobx-state-tree';
import DeviceModel from '../models/device-model';
import PositionModel from '../models/position-model';
import SinisterModel from '../models/sinister-model';
import SinisterService from '../services/sinister-service';

const sinisterService = new SinisterService();

const SinisterStore = types
    .model('SinisterStore', {
        device: types.maybeNull(
            types.reference(types.late(() => DeviceModel)),
        ),
        position: types.maybeNull(
            types.reference(types.late(() => PositionModel)),
        ),
        openingSinister: types.optional(types.boolean, false),
        incidents: types.optional(types.array(SinisterModel), []),
    })
    .views((self) => ({
        get list() {
            return self.incidents;
        },
        getOpeningSinister() {
            return self.openingSinister;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.fetch();
        },
        setDevice(data) {
            self.device = data;
        },
        setPosition(data) {
            self.position = data;
        },
        setIncidents(data) {
            self.incidents = data;
        },
        openNewSinister() {
            self.openingSinister = true;
        },
        rollbackNewSinister() {
            self.openingSinister = false;
            self.fetch();
        },
        fetch() {
            sinisterService.fetch(true).then((incidents) => self.setIncidents(incidents));
        },
        newSinister(position, device, customer, session) {
            const hasNew = self.incidents.filter((i) => i.id === 0);
            if (hasNew.length === 0) {
                const sinister = {
                    id: 0,
                    number: 0,
                    deviceId: device.id,
                    customerId: device.customerId,
                    devicePlate: device.attributes.hasOwnProperty('plate') ? device.attributes.plate : '',
                    customerName: customer.name,
                    openedBy: session.email,
                    openingAddress: position.address,
                    openingLatitude: position.latitude,
                    openingLongitude: position.longitude,
                    status: 'opened',
                    vehicleDamaged: false,
                    device: device,
                    customer: customer,
                };
                self.incidents.unshift(sinister);
            }
        },
        cancelNewSinister() {
            self.openingSinister = false;
            self.fetch();
        },
        save(payload) {
            const
                {
                    deviceId,
                    customerId,
                    openingType,
                    openingAddress,
                    openingDescription,
                } = payload;

            return sinisterService.create({
                deviceId,
                customerId,
                openingDate: new Date().toISOString(),
                openingType,
                openingAddress,
                openingDescription,
            }).then((sinister) => {
                self.fetch();
                return sinister;
            });
        },
    }));

export default SinisterStore;

