import React from 'react';
import './notification.sass';
import DataTable from '../../components/dataTable';


const NotificationTable = (props) => {
  const {
    notificationForm,
    removeRegister,
    attributes,
    list,
    loading,
  } = props;


  return (
    <DataTable
      add={notificationForm}
      edit={notificationForm}
      remove={removeRegister}
      list={list}
      loading={loading}
      attributes={attributes}
      keyField={'id'}
      confirmText={`Você está removendo a notificação <strong><field>title</field></strong>! </br>Realmente deseja fazer isso? `}
      headers={[
        {title: 'notificationType', field: 'title', dataSort: true},
        {title: 'notificationAlways', field: 'always', dataSort: true},
        {title: 'sharedAlarms', field: 'alarms', dataSort: false},
        {title: 'notificationNotificators', field: 'notificators', dataSort: false},
      ]}
    />
  );
};

export default NotificationTable;
