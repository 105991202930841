import {inject, observer} from 'mobx-react';
import qs from 'qs';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Container} from 'reactstrap';
import {from} from 'rxjs';
import {MapContext} from '../../apiContexts';
import {getStopReport} from '../../services/reportService';
import StopList from '../../views/reports/stop-list';
import useServices from "../../hooks/use-services";

const StopReportContainer = (props) => {

    const {
        match,
        location,
        listOnly = false,
        mapRef,
    } = props;

    const {positionService} = useServices();
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(true);
    const {devices, server} = props;
    const {timezone, distanceUnit, volumeUnit} = server.attributes;
    const search = useMemo(() => location.search, [location.search]);
    const {setData, setSelectedItem, setSelectedRow} = useContext(MapContext);
    const activeRef = useRef();

    const getReport = useCallback(async (params) => {
        setLoading(true);
        from(getStopReport(params)).subscribe((data) => {
            setReport(data);
            setLoading(false);
            setData({type: 'stop', data: null});
        });
    }, []);

    const onSelect = useCallback(
        async ({index, data: stop}) => {
            if (activeRef && activeRef.current === index) return;

            const item = report[index];
            let itemActive = null;

            if (activeRef && activeRef.current >= 0) {
                itemActive = report[activeRef.current];
            }

            const items = report.concat();

            items[index] = {...item, isActive: true};
            itemActive &&
            (items[activeRef.current] = {...itemActive, isActive: false});

            activeRef.current = index;

            if (stop && stop.positionId && stop.course === undefined) {
                const [position] = await positionService.getPositionsByIds([stop.positionId]);
                const {id, longitude, latitude, course} = position;

                items[index] = {
                    ...items[index],
                    longitude,
                    latitude,
                    id,
                    course,
                };

                // setCurrentEventPosition(position);
                setData({type: 'stop', data: items[index]});
            }

            setSelectedRow({id: items[index].id});
            setSelectedItem(items[index]);
            setReport(items);
        },
        [report],
    );

    useEffect(() => {
        if (search && devices.list.length && location.hash === '#stop') {
            const {markers, ...params} = qs.parse(search, {
                ignoreQueryPrefix: true,
                comma: true,
            });
            getReport(qs.stringify(params, {indices: false}));
        }
    }, [search, devices.list]);

    return (
        <Container fluid className="px-4 position-relative h-100">
            <StopList
                data={report}
                loading={loading}
                {...{timezone, distanceUnit, volumeUnit, onSelect}}
            />
        </Container>
    );
};

const mapping = ({store}) => ({
    auth: store.auth,
    session: store.session,
    map: store.map,
    server: store.server,
    devices: store.devices,
});

export default inject(mapping)(observer(StopReportContainer));
