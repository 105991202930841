import React from 'react';
import styled from 'styled-components';
import {colors} from '../../styleVariables';
import {Col, Container, Label, Row} from 'reactstrap';
import I18nMessage from '../../components/I18nMessage';

const Style = styled.div`
  line-height: 1rem;
  font-size: 0.8rem !important;

  strong {
    color: ${(props) => props.theme};
  }

  span {
    color: ${colors.gray} !important;
  }
`;

const CustomerView = ({customer, theme}) => {
    return (
        <Style theme={theme}>
            <Container>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerCpfCnpj" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.cpfCnpj}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerName" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.name}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerPhone" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.phone}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerCellphone" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.cellphone}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerNotificationEmail" />:</strong>
                    </Col>
                    <Col xs='8' className='p-0'>
                        {customer?.notificationEmail}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerSecondaryEmail" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.SecondaryEmail}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="customerPostalCode" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.postalCode}
                    </Col>
                </Row>
                <Row className='p-1'>
                    <Col xs='4' className='p-0'>
                        <strong><I18nMessage id="sharedAddress" />:</strong>
                    </Col>
                     <Col xs='8' className='p-0'>
                        {customer?.street}, {customer?.number} {customer?.complement}, {customer?.neighborhood}, {customer?.city} - {customer?.state}
                    </Col>
                </Row>
            </Container>
        </Style>
    );
};

export default CustomerView;
