import React, {useEffect, useMemo, useRef, useState, useContext} from 'react';
import {ButtomStyled, ReactSelectStyled} from '../../styles';
import {Form} from '@unform/web';
import useStore from '../../hooks/use-store';
import useServices from '../../hooks/use-services';
import * as yup from 'yup';
import LoadingBar from '../../components/loadingBar';
import {useCompany} from '../../providers/company';
import { ModalContext } from '../../apiContexts';
import useAlerts from '../../hooks/use-alerts';
import { useIntl } from 'react-intl';
import CommandModel from '../../models/command-model';

const COMMAND_TYPES = {
    1: 'engineStop',
    2: 'engineResume',
    3: 'rebootDevice'
}

const convertCommandTypeToCommandIntlId = (commandType) => 'command' + commandType.charAt(0).toUpperCase() + commandType.slice(1);

const buildCommandOptions = (commandTypes, format) => Object.values(commandTypes).map(commandType => ({id: commandType, title: format?.({id: convertCommandTypeToCommandIntlId(commandType)}) ?? '' }))

const parseFormDataToCommandBatchData = (data) => ({
    devicesId: data.devices,
    groupsId: data.groups,
    commandType: data.command ?? '',
});

const schema = yup.object().shape({
    devicesId: yup.array(yup.number())
        .required()
        .when('groupsId', {
            is: (groupsId) => groupsId?.length === 0,
            then: (_schema) => _schema.min(1),
        })
        .label('Dispositivos'),
    groupsId: yup.array(yup.number())
        .required()
        .when('devicesId', {
            is: (devicesId) => devicesId?.length === 0,
            then: (_schema) => _schema.min(1),
        })
        .label('Grupos'),
    commandType: yup.string().oneOf(buildCommandOptions(COMMAND_TYPES).map((cmd) => cmd.id))
        .label('Comando')
        .required()
}, [
    ['devicesId', 'groupsId'],
    ['groupsId', 'devicesId'],
]);

const NewCommandModal = () => {
    const intl = useIntl();
    const {store} = useStore();
    const {groupService, commandService} = useServices();
    const {theme} = useCompany();
    const {toast} = useAlerts();
    const {toggle} = useContext(ModalContext);
    const {devices} = store;

    const [groupOptions, setGroupOptions] = useState([]);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const formRef = useRef(null);

    const deviceOptions = useMemo(() => devices.list.map((device) => ({
        id: device.id,
        title: `${device.uniqueId} - ${device.name}`,
    })), [devices.list]);

    const onSubmit = async (data) => {
        try {
            setIsSubmiting(true);

            const parsedData = parseFormDataToCommandBatchData(data);
            await schema.validate(parsedData,
            {
                abortEarly: false
            });

            const command = CommandModel.create({
                id: 0,
                deviceId: 0,
                description: intl.formatMessage({id: 'sharedNew'}),
                attributes: {},
                type: parsedData.commandType
            })

            await commandService.batch({
                entity: command,
                groupsIds: parsedData.groupsId,
                devicesIds: parsedData.devicesId
            })

            toast.success('Comandos enviados para execução com sucesso!');
            toggle();
        } catch (error) {
            if(error instanceof yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                formRef.current.setErrors(validationErrors);
                toast.error('Ops! Verifique os campos e tente novamente.')
            } else {
                toast.errorStatus(error);
            }
        } finally {
            setIsSubmiting(false);
        }
    };

    useEffect(() => {
        let ignore = false;

        groupService.list().then((groups) => {
            if (ignore) return;

            setGroupOptions(groups.map((group) => ({
                id: group.id,
                title: group.name,
            })));
        });

        return () => {
            ignore = true;
        };
    }, []);

    return (
        <div>
            <Form ref={formRef} onSubmit={onSubmit} initialData={{
                devices: [],
                groups: [],
                command: {},
            }}>
                <ReactSelectStyled
                    radius="9px"
                    options={deviceOptions}
                    name="devices"
                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                    placeholder="Escolha dispositivo(s)"
                    className="w-100"
                    multiple
                />

                <ReactSelectStyled
                    radius="9px"
                    options={groupOptions}
                    name="groups"
                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                    placeholder="Escolha grupo(s)"
                    className="w-100 mt-3"
                    multiple
                />

                <ReactSelectStyled
                    radius="9px"
                    options={buildCommandOptions(COMMAND_TYPES, intl.formatMessage)}
                    name="command"
                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                    placeholder="Escolha o comando"
                    className="w-100 mt-3"
                />

                <div className="d-flex justify-content-end mt-3">
                    {!isSubmiting ? (
                        <ButtomStyled
                            theme={theme}
                            className="custom-shadow custom-border btn-theme"
                            outline
                            type="submit"
                        >
                            Executar
                        </ButtomStyled>
                    ) : (
                        <LoadingBar />
                    )}
                </div>

            </Form>

        </div>
    );
};

export default NewCommandModal;
