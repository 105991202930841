import React from 'react';
import DataTable from '../../components/dataTable';
import Button from '../../components/button';

const CustomerDeviceTable = (props) => {
    const {
        list,
        showList,
    } = props;

    return (
        <DataTable
            list={list}
            showGroupButtons={false}
            keyField={'id'}
            headers={[
                {title: 'sharedName', field: 'name', dataSort: true},
                {title: 'deviceIdentifier', field: 'uniqueId', dataSort: true},
                {title: 'deviceModel', field: 'model', dataSort: true},
                {title: 'groupDialog', field: 'groupName', dataSort: true},
                {title: 'sharedDisabled', field: 'disabled', dataSort: true},
            ]}
            extraHeaderEl={ (row, _) => <Button
                className={'outline'}
                color={'theme'}
                size={'sm'}
                onClick={() => showList()}
            >
                <i className="fas fa-arrow-left"/> Voltar
            </Button>}
        />);
};

export default CustomerDeviceTable;
