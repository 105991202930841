import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Form} from '@unform/web'
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from "../../components/fieldset";
import {GroupButtonForm} from "../../components/group-button-form";

const DriverForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        loading,
    } = props;

    const formRef = useRef(null);

    const schema = Yup.object().shape({
        name: Yup.string().required('Nome é necessário'),
        uniqueId: Yup.string().required('Identificador é necessário'),
        id: Yup.number(),
    });

    const handleSubmit = async (data) => {
        try {
            await schema.validate(data, {
                abortEarly: false,
            });
            data = schema.cast(data);
            edit ? updateRegister(data) : newRegister(data);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={data}>
            {!!edit && <UInput hidden type="number" name="id"/>}
            <FieldSet className="text-left border p-4" title={'Identificação'}>
                <FormGroup className="custom-formGroup">
                    <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                    <Input
                        tag={UInput}
                        type="text"
                        name="name"
                        id="name"
                    />
                </FormGroup>
                <FormGroup className="custom-formGroup">
                    <Label for="uniqueId"><I18nMessage id="deviceIdentifier"/>*</Label>
                    <Input
                        tag={UInput}
                        type="text"
                        name="uniqueId"
                        id="uniqueId"
                    />
                </FormGroup>
            </FieldSet>
            <br/>
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={() => formRef.current.submitForm()}
            />
        </Form>
    );
};

export default DriverForm;
