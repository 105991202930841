import {types} from 'mobx-state-tree';
import i18n from './translate-store';
import HourUnitModel from '../models/hour-unit-model';

const HoursUnitStore = types
    .model('HoursUnitStore', {
      units: types.optional(types.array(HourUnitModel), []),
    })
    .actions((self) => ({
      afterCreate() {
        self.setUnits([{
          key: 'h',
          name: i18n.strings.sharedHourAbbreviation,
          fullName: i18n.strings.sharedHour,
        }]);
      },
      setUnits( data ) {
        self.units = data;
      },
      convertValue(value, unit, back) {
        return back ? value * 3600000 : value / 3600000;
      },

      formatValue(value, unit, convert) {
        let hours; let minutes;
        if (convert) {
          hours = Math.floor(value / 3600000);
          minutes = Math.floor(value % 3600000 / 60000);
        } else {
          hours = Math.floor(value);
          minutes = Math.floor(value % 1 * 60);
        }
        return hours + ' ' + i18n.strings.sharedHourAbbreviation + ' ' + minutes + ' ' + i18n.strings.sharedMinuteAbbreviation;
      },
    }));

export default HoursUnitStore;

