import {inject, observer} from 'mobx-react';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useToasts} from 'react-toast-notifications';
import styled from 'styled-components';
import {ModalContext} from '../../apiContexts';
import {getEventsList} from '../../helpers/events';
import {
    getMemorizeReportForm,
    memorizeReportForm,
} from '../../helpers/report';
import history from '../../routes/history';
import {ReactSelectStyled} from '../../styles';
import EventConfig from '../../views/modals/newReport/eventConfig';
import RouteConfig from '../../views/modals/newReport/routeConfig';
import StopConfig from '../../views/modals/newReport/stopConfig';
import SummaryConfig from '../../views/modals/newReport/summaryConfig';
import TripConfig from '../../views/modals/newReport/tripConfig';
import useServices from '../../hooks/use-services';

const Styles = styled.div``;

const reportOptions = [
    {id: 1, title: 'Rota'},
    {id: 2, title: 'Eventos'},
    {id: 3, title: 'Viagens'},
    {id: 4, title: 'Paradas'},
    {id: 5, title: 'Resumo'},
    {id: 6, title: 'Gráfico'},
];

const NewReportContainer = (props) => {
    const {toggle} = useContext(ModalContext);
    const {groupService} = useServices();
    const [modalConfig, setModalConfig] = useState(null);
    const [groups, setGroups] = useState();
    const {addToast} = useToasts();
    const cacheReport = useMemo(() => getMemorizeReportForm('report'), []);

    const devices = useMemo(
        () =>
            props.devices.list.map(({id, uniqueId, name}) => ({
                id,
                title: `${uniqueId} - ${name}`,
            })),
        [props.devices],
    );

    const events = useMemo(() => getEventsList(), []);

    useEffect(() => {
        (async () => {
            const groups = await groupService.list();
            setGroups(groups.map(({id, name}) => ({id, title: name})));
        })();

        cacheReport && cacheReport.report && setModalConfig(cacheReport.report.id);
        return () => {
            console.log('Unmount NewReportContainer');
        };
    }, []);

    const setReport = (value) => {
        if (value != null) {
            setModalConfig(value.id);
            memorizeReportForm({report: value});
        } else {
            setModalConfig(null);
            memorizeReportForm({report: null});
        }
    };

    const redirectToReport = (url) => {
        const {
            location: {pathname},
        } = history;

        const [type, search] = url.split('?');

        if (pathname.startsWith('/admin')) {
            url = `/admin/reports?${search}#${type}`;
            toggle();
            history.push(url);
        } else {
            url = `${pathname}?${search}#${type}`;
            toggle();
            history.push(url);
        }
    };

    const notifyToast = useCallback((appearance) => {
        const msg =
            appearance === 'success' ? (
                <span>
          Você receberá seu relatório no e-mail{' '}
                    <strong>{props.auth.email}</strong>.
        </span>
            ) : (
                `Não foi possível realizar sua solicitação!`
            );
        addToast(msg, {appearance, autoDismiss: true});
    }, []);

    return (
        <Styles>
            <ReactSelectStyled
                radius="9px"
                options={reportOptions}
                name="report"
                scoped={false}
                noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                placeholder="Escolha um relatório"
                className="w-100 mb-3"
                onChange={setReport}
                defaultValue={cacheReport && cacheReport.report}
            />
            <div>
                {
                    {
                        1: (
                            <RouteConfig
                                {...{devices, groups, redirectToReport, notifyToast}}
                            />
                        ),
                        2: (
                            <EventConfig
                                {...{devices, groups, events, redirectToReport, notifyToast}}
                            />
                        ),
                        3: (
                            <TripConfig
                                {...{devices, groups, redirectToReport, notifyToast}}
                            />
                        ),
                        4: (
                            <StopConfig
                                {...{devices, groups, redirectToReport, notifyToast}}
                            />
                        ),
                        5: (
                            <SummaryConfig
                                {...{devices, groups, redirectToReport, notifyToast}}
                            />
                        ),
                    }[modalConfig]
                }
            </div>
        </Styles>
    );
};

const mapping = ({store}) => ({
    devices: store.devices,
    auth: store.auth,
});

export default inject(mapping)(observer(NewReportContainer));
