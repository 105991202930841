/* eslint-disable require-jsdoc */
import {useEffect, useReducer} from 'react';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

const createInitialState = (devices = []) => {
    return {
        filteredDevices: devices,
        text$: new BehaviorSubject(''),
        model$: new BehaviorSubject(''),
        status$: new BehaviorSubject(''),
    };
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_TEXT': {
            state.text$.next(action.payload.text);
            return state;
        }
        case 'SET_MODEL': {
            state.model$.next(action.payload.model);
            return state;
        }
        case 'CLEAR_MODEL': {
            state.model$.next('');
            return state;
        }
        case 'SET_STATUS': {
            state.status$.next(action.payload.status);
            return state;
        }
        case 'CLEAR_STATUS': {
            state.status$.next('');
            return state;
        }
        case 'FILTER_DEVICES': {
            return {
                ...state,
                filteredDevices: action.payload.filteredDevices,
            };
        }
        default:
            return state;
    }
}

export function useDeviceFilter(devices) {
    const [state, dispatch] = useReducer(reducer, devices, createInitialState);

    useEffect(() => {
        const filteredDevices$ = combineLatest([
            state.text$.pipe(distinctUntilChanged(), debounceTime(750)),
            state.model$.pipe(distinctUntilChanged()),
            state.status$.pipe(distinctUntilChanged()),
        ]).pipe(
            map(([text, model, status]) => devices.filter((device) => {
                    const nameMatch = text === '' || device.name.toUpperCase().includes(text.toUpperCase());
                    const modelMatch = model === '' || device.uniqueId === model;
                    const statusMatch = status === '' || device.status === status;
                    return nameMatch && modelMatch && statusMatch;
                }),
            ),
        ).subscribe((filteredDevices) => {
            dispatch({type: 'FILTER_DEVICES', payload: {filteredDevices}});
        });

        return () => {
            filteredDevices$.unsubscribe();
        };
    }, [devices]);

    return {
        filteredDevices: state.filteredDevices,
        dispatch,
    };
}
