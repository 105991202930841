import React from 'react';
import DataTable from '../../components/dataTable';

const GroupTable = (props) => {
  const {
    groupForm,
    removeRegister,
    attributes,
    list,
    loading,
    permissions
  } = props;

  return (
    <DataTable
      add={groupForm}
      edit={groupForm}
      remove={removeRegister}
      list={list}
      loading={loading}
      attributes={attributes}
      permissions={permissions}
      keyField={'id'}
      confirmText={`Você está removendo o grupo <strong><field>name</field></strong>! </br>Realmente deseja fazer isso? `}
      headers={[
        {title: 'sharedName', field: 'name', dataSort: true},
      ]}
    />
  );
};

export default GroupTable;
