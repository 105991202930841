import {observer} from 'mobx-react';
import moment from 'moment';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {from} from 'rxjs';
import {mapTo, share, tap, toArray} from 'rxjs/operators';
import styled from 'styled-components';
import HorizontalStackedBar from '../../components/charts/horizontalStackedBar';
import VerticalBar from '../../components/charts/verticalBar';
import {
    activeLabel,
    calculeDateGroup,
    communicationLabel,
    communicationTime,
    createLabelIcon,
    getDateGroup,
    getDeviceActiveGroup,
    getSinisterGroup,
    getStatusGroup,
    getTypeGroupPercent,
    sinisterDurationLabel,
    statusLabel,
} from '../../helpers/admin';
import useStore from '../../hooks/use-store';
import history from '../../routes/history';
import '../../views/admin/admin.sass';
import ChartCard from '../../views/admin/chartCard';
import '../../views/big-fleet/big-fleet.sass';

const StyledSmall = styled.small`
  color: #666666;
`;

const AdminDashboardContainer = ({children, ...props}) => {
    const [communicationGroup, setCommunicationGroup] = useState({});
    const [stateGroup, setStateGroup] = useState({});
    const [deviceGroup, setDeviceGroup] = useState({});
    const [sinisterGroup, setSinisterGroup] = useState({});

    const [typeStatusChartData, setTypeStatusChartData] = useState([]);

    const {store} = useStore();
    const {devices, sinisterStore} = store;

    useEffect(() => {
        if (devices.list.length) {
            const source$ = from(devices.list).pipe(share());

            getDeviceActiveGroup(source$).subscribe((data) => {
                setDeviceGroup(data);
            });

            getDateGroup(source$, communicationTime).subscribe((dateGroup) => {
                setCommunicationGroup(dateGroup);
            });

            getStatusGroup(source$).subscribe((dateGroup) => {
                setStateGroup(dateGroup);
            });

            getTypeGroupPercent(source$).subscribe((data) => {
                setTypeStatusChartData(data);
            });
        }
    }, [devices.list]);

    useEffect(()=>{
        if (sinisterStore.incidents.length > 0) {
            const source$ = from(sinisterStore.incidents.filter((s) => s.status === 'opened')).pipe(share());
            getSinisterGroup(source$).subscribe((data) => setSinisterGroup(data));
        }
    }, [sinisterStore.incidents.length]);

    const defaultColors = useMemo(
        () => ['#00b105', '#fe7f00', '#f03528', '#8a038c', '#abaaac'],
        [],
    );

    const squareLabel = useCallback(
        (color) => <span className="fas fa-square-full" style={{color}}/>,
        [],
    );

    const statusData = useMemo(
        () => ({
            colors: [defaultColors[0], defaultColors[4], '#FFD800'],
            data: typeStatusChartData.map(({name, ...rest}) => Object.values(rest)),
            labels: typeStatusChartData.map(({name}) => createLabelIcon(name)),
            labelsName: statusLabel,
        }),
        [typeStatusChartData],
    );

    const communicationData = useMemo(() => {
        const values = Object.values(communicationGroup);

        return {
            colors: defaultColors,
            data: calculeDateGroup(values),
            amount: values.map((d) => d.length),
            legends: communicationLabel.map((label, i) => ({
                element: squareLabel(defaultColors[i]),
                label,
            })),
        };
    }, [communicationGroup]);

    const sinisterData = useMemo(() => {
        const values = Object.values(sinisterGroup);
        const colors = ['#FDA700', '#8A008B', '#0031B0', '#6B6B6B'];

        return {
            colors: colors,
            data: calculeDateGroup(values),
            amount: values.map((d) => d.length),
            legends: sinisterDurationLabel.map((label, i) => ({
                element: squareLabel(colors[i]),
                label,
            })),
        };
    }, [sinisterGroup]);

    const stateData = useMemo(() => {
        const colors = [defaultColors[0], defaultColors[4], '#FFD800'];
        const values = Object.values(stateGroup);

        return {
            colors,
            data: calculeDateGroup(values),
            amount: values.map((d) => d.length),
            legends: statusLabel.map((label, i) => ({
                element: squareLabel(colors[i]),
                label,
            })),
        };
    }, [stateGroup]);

    const deviceData = useMemo(() => {
        const colors = [defaultColors[0], defaultColors[4]];
        const values = Object.values(deviceGroup);

        return {
            colors,
            data: calculeDateGroup(values),
            amount: values.map((d) => d.length),
            legends: activeLabel.map((label, i) => ({
                element: squareLabel(colors[i]),
                label,
            })),
        };
    }, [deviceGroup]);

    const {
        location: {pathname},
    } = history;

    const GoToDetailCommunication = (data) => {
        history.push(
            `${pathname}/chart/communication?group=${communicationTime[data] ||
            'never'}`,
        );
    };

    const GoToDetailSinister = (data) => {
        history.push(
            `${pathname}/chart/sinister?group=${sinisterData.legends[data].label}`,
        );
    };

    const GoToDetailState = (data) => {
        let group = stateData.legends[data].label;
        if (group === 'Desconhecido') group = 'unknown';
        history.push(`${pathname}/chart/state?group=${group}`);
    };

    const GoToDetailDevice = (data) => {
        const group = deviceData.legends[data].label;
        history.push(`${pathname}/chart/device?group=${group}`);
    };

    const GoToDetailStatus = ({index, status}) => {
        const data = {
            status: status === 'Desconhecido' ? 'unknown' : status,
            type: typeStatusChartData[index].name,
        };
        history.push(`${pathname}/chart/status?group=${data.type || 'default'}-${data.status}`);
    };

    return (
        <div className="">
            <Row className="mb-4">
                <Col md="6">
                    <ChartCard
                        header={<span>Dispositivos Cadastrados</span>}
                        style={{minHeight: 340}}>
                        <HorizontalStackedBar onClick={GoToDetailDevice} {...deviceData} />
                        <LastUpdate/>
                    </ChartCard>
                </Col>

                <Col md="6">
                    <ChartCard
                        header={<span>Status de Conexão dos Dispositivos</span>}
                        style={{minHeight: 340}}>
                        <HorizontalStackedBar onClick={GoToDetailState} {...stateData} />
                        <LastUpdate/>
                    </ChartCard>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <ChartCard
                        header={<span>Tempo da última comunicação</span>}
                        style={{minHeight: 340}}>
                        <HorizontalStackedBar
                            onClick={GoToDetailCommunication}
                            {...communicationData}
                        />
                        <LastUpdate/>
                    </ChartCard>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <ChartCard
                        header={<span>Status de conexão por categoria</span>}
                        style={{height: 340}}>
                        <VerticalBar onClick={GoToDetailStatus} {...statusData} />
                        <LastUpdate/>
                    </ChartCard>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ChartCard
                        header={<span>Sinistros Abertos</span>}
                        style={{minHeight: 340}}>
                        <HorizontalStackedBar
                            onClick={GoToDetailSinister}
                            {...sinisterData}
                        />
                        <LastUpdate/>
                    </ChartCard>
                </Col>
            </Row>
        </div>
    );
};

const LastUpdate = () => (
    <StyledSmall>
        {moment().format('[Atualizado em] DD/MM/YY [às] HH:mm')}
    </StyledSmall>
);

export default observer(AdminDashboardContainer);
