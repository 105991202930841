import {ButtomStyled} from '../../../styles';
import React from 'react';
import LoadingBar from '../../../components/loadingBar';

const FooterActions = ({theme, setOuter, loading}) => (
  <div className="d-flex justify-content-end mt-3">
    {loading ? (
      <LoadingBar />
    ) : (
      <>
        <ButtomStyled
          theme={theme}
          className="custom-shadow custom-border btn-theme"
          outline
          type="submit"
          onClick={(_) => setOuter('export')}
        >
          <span>Exportar</span>
        </ButtomStyled>
        <ButtomStyled
          theme={theme}
          className="custom-shadow custom-border btn-theme mx-3"
          outline
          type="submit"
          onClick={(_) => setOuter('mail')}
        >
          <span>Enviar por E-email</span>
        </ButtomStyled>
        <ButtomStyled
          theme={theme}
          className="custom-shadow custom-border btn-theme"
          outline
          type="submit"
        >
          <span>Ir para Relatório</span>
        </ButtomStyled>
      </>
    )}
  </div>
);

export default FooterActions;
