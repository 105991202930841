import React, {useMemo, useRef, useState, useEffect} from 'react';
import {Progress, Tooltip} from 'reactstrap';
import {fromEvent} from 'rxjs';
import styled from 'styled-components';
import {colors} from '../../styleVariables';
import uuid from 'uuid/v1';

const ProgressStyle = styled(Progress)`
  height: ${(props) => props.height};
  border-radius: 0;
`;

const ProgressWithCustomColor = styled(({customColor, ...rest}) => <Progress {...rest} />)`
  background-color: ${(props) => props.customColor || ''} !important;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
`;

const Legend = styled.div`
  margin-top: 1rem;
  color: ${colors.gray4};
`;

const Legends = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;

  ${Legend} {
    margin-left: 1rem;
  }
`;

const ChartStyle = styled.div`
  border-left: 1px solid ${colors.gray2} !important;
  border-right: 1px solid ${colors.gray2} !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const TooltipStyled = styled(Tooltip)`
  .tooltip-inner {
    background-color: ${colors.blue2} !important;
    color: ${'white'} !important;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  [x-placement="left"] {
    .arrow {
      &:before {
        border-left-color: ${colors.blue2} !important;
      }
    }
  }

  [x-placement="right"] {
    .arrow {
      &:before {
        border-right-color: ${colors.blue2} !important;
      }
    }
  }

  [x-placement="top"] {
    .arrow {
      &:before {
        border-top-color: ${colors.blue2} !important;
      }
    }
  }

  [x-placement="bottom"] {
    .arrow {
      &:before {
        border-bottom-color: ${colors.blue2} !important;
      }
    }
  }
`;

// HORIZONTALSTACKEDBAR

const HorizontalStackedBar = (props) => {
    console.log(props);
    const {
        colors,
        data,
        legends: legendsData,
        height,
        onClick,
        amount,
    } = props;
    const chartRef = useRef();
    const progress = useMemo(
        () =>
            data.map((value, i) => (
                <ProgressWithCustomColor
                    key={i}
                    bar
                    customColor={colors[i]}
                    value={value.toFixed(1)}>
                    <div hidden data-index={i}/>
                    {`${+(value || 0).toFixed()}%`}
                </ProgressWithCustomColor>
            )),
        [data, colors],
    );

    useEffect(() => {
        fromEvent(chartRef.current, 'click').subscribe((event) => {
            if (event.target.matches('.progress-bar')) {
                onClick(event.target.firstChild.getAttribute('data-index'));
            }
        });
    }, [onClick]);

    const tooltipRef = useMemo(() => '_' + uuid().split('-')[0], []);

    const tooltips = useMemo(
        () => (
            <TooltipItem target={`.${tooltipRef}`} className="d-flex flex-column">
                {data.map((v, i) => (
                    <span key={i}>
                        <strong>{legendsData[i].label}</strong> - {+v.toFixed()}%{' '}
                            {amount && ` (${amount[i]})`}
                    </span>
                ))}
            </TooltipItem>
        ),
        [data, amount, tooltipRef, legendsData],
    );

    const legends = useMemo(
        () => legendsData.map((l, i) => <LegendItem key={i} {...l} />),
        [legendsData],
    );

    return (
        <div ref={chartRef}>
            {tooltips}
            <ChartStyle className={`py-3 ${tooltipRef}`}>
                <ProgressStyle height={height} multi>
                    {progress}
                </ProgressStyle>
            </ChartStyle>
            <Legends>{legends}</Legends>
        </div>
    );
};

// LEGENDITEM

const LegendItem = ({label, element}) => (
    <Legend>
        {element} <span>{label}</span>
    </Legend>
);

// TOOLTIPITEM

const TooltipItem = ({children, target}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <TooltipStyled
            placement="auto"
            isOpen={tooltipOpen}
            target={target}
            toggle={toggle}>
            {children}
        </TooltipStyled>
    );
};

HorizontalStackedBar.defaultProps = {
    height: '120px',
    onClick: () => {
    },
};

export default HorizontalStackedBar;
