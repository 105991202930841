import React from 'react';
import {Button} from 'reactstrap';
import styled from 'styled-components';
import {colors} from '../../../../styleVariables';
import {DeviceInfoStyle} from '../../../../views/personal/styles';
import Header from '../../../../views/personal/device/device-detail/header';
import Location from '../../../../views/personal/device/device-detail/location';
import DeviceInfoAttributes from '../../../../components/device-info-attributes';
import PropTypes from 'prop-types';
import useTimezone from '../../../../hooks/use-timezone';
import {ThemeContext} from '../../../../apiContexts';
import useStore from '../../../../hooks/use-store';
import {useContext} from 'react';
import {observer} from 'mobx-react';

const Style = styled.div`
  background-color: white;
  box-shadow: $shadow;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -12px;
  margin-top: -10px;
`;

const ButtonStyled = styled(Button)`
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #eeeeee;
  color: ${colors.gray4} !important;
`;

const SelectedDeviceCardComponent = ({
    selectedDevice,
    unSelectDevice,
}) => {
  const {timezone} = useTimezone();

  const theme = useContext(ThemeContext);
  const {store} = useStore();
  const {server} = store;

  return (
    <>
      <ButtonStyled
        color="light"
        size="sm"
        className="custom-shadow pb-3 mt-2"
        onClick={unSelectDevice}
      >
                Mostrar todos os dispositivos
      </ButtonStyled>
      <Style className="bg-white w-100 custom-shadow">
        <Header {...{...selectedDevice, theme}} server={server}/>
        <Location
          theme={theme}
          currentPosition={selectedDevice.currentPosition}
          timezone={timezone}
        />
        <DeviceInfo {...{...selectedDevice, theme}} />
      </Style>
    </>
  );
};

const DeviceInfo = ({theme, ...props}) => (
  <DeviceInfoStyle id="deviceInfo" className="mt-3" theme={theme}>
    <span className="text-gray">Informações do Dispositivo</span>
    <DeviceInfoAttributes className="mt-3" {...props} />
  </DeviceInfoStyle>
);

export default observer(SelectedDeviceCardComponent);

SelectedDeviceCardComponent.propTypes = {
  selectedDevice: PropTypes.object,
  unSelectDevice: PropTypes.func,
};
