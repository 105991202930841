import React, {useCallback, useContext, useState} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {useIntl} from 'react-intl';
import {useHotkeys} from 'react-hotkeys-hook';
import {ModalBody, ModalFooter} from 'reactstrap';
import Button from '../button';
import ReactExport from 'react-export-excel';
import {ThemeContext} from '../../apiContexts';
import {ModalStyled} from '../../styles';
import I18nMessage from '../I18nMessage';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DataTable = (props) => {
    const {
        list,
        headers,
        add,
        edit,
        permissions,
        accumulatorForm,
        remove,
        confirmText,
        keyField,
        noDataText,
        searchPlaceholder,
        search,
        loading = false,
        excelConfig,
        auth,
        showGroupButtons = true,
        extraHeaderEl
    } = props;


    const intl = useIntl();
    const [row, setSelectedRow] = useState(null);
    const [modal, toggle] = useState(false);
    const isAdmin = auth ? auth.profile ? (auth.profile === 'admin' || auth.profile === 'monitor') : true : true;

    useHotkeys('F1', () => add({}));
    useHotkeys('F2', () => editAction(), {}, [row]);
    useHotkeys('F3', () => permissionAction(), {}, [row]);
    useHotkeys('F4', () => accumulatorAction(), {}, [row]);
    useHotkeys('ctrl+r', () => removeAction(), {}, [row]);

    const onRowSelect = (row, isSelected, e) => {
        if (isSelected && row.id === 0) {
            return false;
        }
        setSelectedRow(row);
        return true;
    };

    const bgColor = (row, isSelect) => {
        if (isSelect && row.id === 0) {
            return null;
        }
        return '#2497e9';
    };

    const selectRowProp = {
        mode: 'radio',
        bgColor: bgColor,
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: onRowSelect,
    };

    const editAction = useCallback(() => {
        if (row) {
            edit(row);
        }
    }, [row]);

    const permissionAction = useCallback(() => {
        if (row) {
            permissions(row);
        }
    }, [row]);

    const accumulatorAction = useCallback(() => {
        if (row) {
            accumulatorForm(row);
        }
    }, [row]);

    const removeAction = useCallback(() => {
        if (row) {
            toggle(!modal);
        }
    }, [row]);


    const deleteRow = (row) => {
        remove(row);
        toggle(!modal);
    };

    const formattConfirmText = (text) => {
        if (text && row) {
            const field = text.match(/<field>(.*?)<\/field>/g).map(function(val) {
                return val.replace(/<\/?field>/g, '');
            });

            return text.replace(field, row[field[0]]);
        }
        return '';
    };

    const theme = useContext(ThemeContext);

    const renderAlertConfirmation = () => {
        const msgRemove =
            formattConfirmText(confirmText) ||
            intl.formatMessage({id: 'sharedRemoveConfirm'});
        return (
            <ModalStyled
                theme={theme}
                isOpen={modal}
                toggle={toggle}
                className="customModal"
            >
                <ModalBody className="d-flex flex-column justify-content-center align-items-center">
                    <span className="fal fa-exclamation-triangle fa-5x text-danger mb-3"/>
                    <span dangerouslySetInnerHTML={{__html: msgRemove}}/>
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        color="secondary"
                        onClick={() => toggle()}
                        className=" custom-border"
                    >
                        <I18nMessage id="sharedCancel"/>
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => deleteRow(row)}
                        className="custom-shadow custom-border"
                    >
                        <I18nMessage id="sharedRemove"/>
                    </Button>
                </ModalFooter>
            </ModalStyled>
        );
    };

    const flattenObj = (arr) => {
        const newArray = [];

        const flatten = (obj, roots = [], sep = '.') => Object
            // find props of given object
            .keys(obj)
            // return an object by iterating props
            .reduce((memo, prop) => Object.assign(
                // create a new object
                {},
                // include previously returned object
                memo,
                Object.prototype.toString.call(obj[prop]) === '[object Object]' ?
                    // keep working if value is an object
                    flatten(obj[prop], roots.concat([prop]), sep) :
                    // include current prop and value and prefix prop with the roots
                    {[roots.concat([prop]).join(sep)]: obj[prop]},
            ), {});

        for (let i = 0; i <= arr.length; i++) {
            if (arr[i] !== undefined) {
                newArray.push(flatten(arr[i]));
            }
        }
        return newArray;
    };

    const renderExcelColumn = excelConfig && excelConfig.columns.map((item, idx) => {
        return (
            <ExcelColumn key={idx} label={`${intl.formatMessage({id: item.label})}`} value={item.field}/>
        );
    });


    const createCustomToolBar = (props) => {
        return (
            <>
                <div className={'rounded-group-button'}>
                    {extraHeaderEl && extraHeaderEl(row, (!row || loading))}
                    {showGroupButtons && <>
                    { isAdmin && <Button
                        className={'outline'}
                        onClick={() => add({})}
                        size={'sm'}
                        color={'theme'}>
                        <i className="fas fa-plus"/> Adicionar [F1]
                    </Button> }
                    { isAdmin && <Button
                        disabled={!row || loading}
                        className={'outline'}
                        size={'sm'}
                        color={'danger'}
                        onClick={() => removeAction()}
                    >
                        <i className="fas fa-minus"/> Remover [ctrl+r]
                    </Button> }
                    { isAdmin && <Button
                        disabled={!row || loading}
                        className={'outline'}
                        size={'sm'}
                        color={'theme'}
                        onClick={() => edit(row)}
                    >
                        <i className="fas fa-edit"/> Editar [F2]
                    </Button> }
                    {permissions && (
                        <Button
                            disabled={!row || loading}
                            className={'outline'}
                            color={'theme'}
                            size={'sm'}
                            onClick={() => permissions(row)}
                        >
                            <i className="fas fa-link"/> Associar [F3]
                        </Button>
                    )}
                    {accumulatorForm && (
                        <Button
                            disabled={!row || loading}
                            className={'outline'}
                            color={'theme'}
                            size={'sm'}
                            onClick={() => accumulatorForm(row)}
                        >
                            <i className="fas fa-link"/> Odometro [F4]
                        </Button>
                    )}
                    {excelConfig && (
                        <ExcelFile element={
                            <Button className={'outline'}
                                    size={'sm'}
                                    color={'warning'}>
                                <i className="fas fa-download"/>
                            </Button>
                        }>
                            <ExcelSheet data={flattenObj(list)}
                                        name={`${intl.formatMessage({id: excelConfig.fileName})}`}>
                                {renderExcelColumn}
                            </ExcelSheet>
                        </ExcelFile>
                    )}
                    </>}
                </div>
                <div className="search-toolbar">{props.components.searchPanel}</div>
            </>
        );
    };

    const options = {
        toolBar: createCustomToolBar,
        noDataText: noDataText || intl.formatMessage({id: 'sharedNoDataText'}),
        sizePerPageList: [{
            text: '10', value: 10,
        }, {
            text: '50', value: 50,
        }, {
            text: intl.formatMessage({id: 'sharedAll'}), value: list.length,
        }],
        sizePerPage: 10, // which size per page you want to locate as default
        prePage: intl.formatMessage({id: 'sharedPrev'}), // Previous page button text
        nextPage: intl.formatMessage({id: 'sharedNext'}), // Next page button text
        firstPage: intl.formatMessage({id: 'sharedFirst'}), // First page button text
        lastPage: intl.formatMessage({id: 'sharedLast'}), // Last page button text
    };

    const columnFormatter = (cell, row) => {
        if (typeof cell === 'boolean') {
            if (cell) {
                return intl.formatMessage({id: 'sharedYes'});
            }
            return intl.formatMessage({id: 'sharedNo'});
        }
        return cell;
    };

    const renderTableHeaderColumn = headers.map((item, idx) => {
        return (
            <TableHeaderColumn
                key={idx}
                dataField={item.field}
                dataSort={item.dataSort}
                dataFormat={columnFormatter}
                filter={item.filter}>
                <I18nMessage id={item.title}/>
            </TableHeaderColumn>
        );
    });


    const renderTable = () => {
        const data = list.length > 0 ? list : [];

        return (
            <BootstrapTable
                options={options}
                data={data}
                search={search || true}
                keyField={keyField}
                selectRow={selectRowProp}
                searchPlaceholder={
                    searchPlaceholder || intl.formatMessage({id: 'sharedSearch'})
                }
                striped
                hover
                pagination
            >
                {renderTableHeaderColumn}
            </BootstrapTable>
        );
    };

    return (
        <>
            {renderAlertConfirmation()}
            {renderTable()}
        </>
    );
};

export default DataTable;
