import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Button as BButton} from 'reactstrap';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';

const BButtonStyled = styled(BButton)`

     margin: 10px;
     border-radius: 12px;
     min-width: 119px;
     min-height: 45px;
     color: white;
     font-size: 16px;
     
     &.btn-sm {
         border-radius: 7px;
         min-width: auto;
         min-height: auto;
         font-size: 13px;
         padding: 5px 15px 5px 15px;
     }

     &.btn.btn-success:not(:hover) { 
          background-color: #009018;
          box-shadow: 0px 3px 6px #00000029;
     }
     
     &.btn.btn-warning:not(:hover) { 
          background-color: #F08100;
          box-shadow: 0px 3px 6px #00000029;
     }
     
     &.outline.btn.btn-warning:not(:hover) { 
          color: #F08100;
          border-color: #F08100;
          background-color: white;
     }
     
     &.outline.btn.btn-danger:not(:hover) { 
          color: #BF2A24;
          border-color: #BF2A24;
          background-color: white;
     }
     
     &.outline.btn.btn-danger:hover { 
          color: white;
          border-color: #BF2A24;
          background-color: #BF2A24; !important
     }
     
     &.outline.btn.btn-theme:not(:hover) { 
          color: ${(props) => props.theme};
          border-color: ${(props) => props.theme};
          background-color: white;
     }
     
     &.outline.btn.btn-theme:hover { 
          color: white;
          border-color: ${(props) => props.theme};
          background-color: ${(props) => props.theme} !important;
     }
        
`;

const Button = (props) => {
    const theme = useContext(ThemeContext);

    return (<React.Fragment>
        <BButtonStyled
            disabled={props.disabled ? props.disabled : false}
            onClick={props.type === 'submit' ? null : props.onClick}
            type={props.type || 'button'}
            color={props.color}
            size={props.size}
            theme={theme}
            className={`${props.className}`}>
            {props.children}
        </BButtonStyled>
    </React.Fragment>);
};

Button.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
    iconRight: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Button;
