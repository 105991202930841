import {observer} from 'mobx-react';
import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {Container} from 'reactstrap';
import styled from 'styled-components';
import {useMap} from '../../components/map/hooks';
import useMapDevice from '../../components/map/hooks/useMapDevice';
import useMapEvent from '../../components/map/hooks/useMapEvent';
import useMapGeofences from '../../components/map/hooks/useMapGeofences';
import useMapLabel from '../../components/map/hooks/useMapLabel';
import useMapPosition from '../../components/map/hooks/useMapPosition';
import useMapReport from '../../components/map/hooks/useMapReport';
import useMapTrace from '../../components/map/hooks/useMapTrace';
import config from '../../config';
import useDevices from '../../hooks/use-devices';
import useStore from '../../hooks/use-store';
import DeviceDetail from '../../views/event-map/device-detail';
import PopupEvent from '../../views/map/popupEvent';
import MapRightActions from '../../views/map-right-actions';
import {useIntl} from 'react-intl';
import useServices from '../../hooks/use-services';
import EventFormHandler from '../../views/event-map/event-form-handle';
import {ThemeContext} from '../../apiContexts';

const Map = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 12px;

  .ol-viewport {
    border-radius: 12px;
  }

  #right-actions {
    position: absolute;
    right: 1rem;
    z-index: 10;
    top: 1rem;
  }
`;

const AdminEventMapContainer = ({location, ...props}) => {
    const history = useHistory();
    const {eventId, deviceId} = useParams();
    const {store} = useStore();
    const {events, auth, customerStore} = store;
    const {eventService, geofenceService, permissionService} = useServices();
    const {devices} = useDevices();
    const {addToast} = useToasts();
    const mapRef = useRef();
    const intl = useIntl();
    const theme = useContext(ThemeContext);

    const {
        instance,
        state,
        setEvent,
        setTrace,
        follow,
        setFollow,
        trace,
       // toogleTrace: traceToggleCurrentDevice,
        label,
        setLabel,
        selectedMap,
        setSelectedMap,
        showDevices,
        setShowDevices,
        showGeofences,
        setShowGeofences,
    } = useMap(
        {
            target: mapRef,
            center: [0, 0],
            zoom: 4,
            tiers: config.mapConfig,
        },
        useMapDevice,
        useMapPosition,
        useMapLabel,
        useMapReport,
        useMapGeofences,
        useMapEvent,
        useMapTrace,
    );

    useEffect(() => {
        if (
            devices.updatedPositions &&
            devices.list.length &&
            state.initializedPositions
        ) {
            if (eventId) {
                instance.removeDevices();
                instance.removeFeaturesTrace();
                setTrace(false);
                (async () => {
                    let event = events.findById(eventId);
                    if (!event) event = await eventService.get(eventId);
                    if (event) {
                        setEvent(event);
                    }
                })();
            }

            if (deviceId && devices.list.length) {
                setEvent(null);
                const device = store.devices.findById(+deviceId);
                events.setCurrentDevice(+deviceId);
                instance.removeFeaturesTrace();
                instance.removeDevices();
                instance.initTraceLayer();
                instance.addFeatureDevice(device);
                setTrace(false);

                (async () => {
                    const position = device.currentPosition;

                    if (!position) return;

                    const {longitude, latitude} = position;
                    instance.goTo([longitude, latitude], 18);
                })();
            }
        }
    }, [instance, auth, events, eventId, deviceId, devices.list.length, state, devices.updatedPositions, setTrace, setEvent, store.devices]);

    const followToggle = () => {
        const status = !follow;
        setFollow(status);
        if (status) {
            devices.setSelected(events.currentDevice.id);
            const {longitude, latitude} = events.currentDevice.currentPosition;
            instance.goTo([longitude, latitude]);
        }
    };

    useEffect(() => {
        if (instance) {
            const map = instance.instance;
            instance.setI18n(intl);
            setSelectedMap('street');
            map.on('click', (event) => {
                const [feature, layer] =
                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    return [feature, layer];
                }) || [];

                if (!layer) return;

                const layerName = layer.get('name');

                if (layerName === 'devicesLayer') {
                    const features = feature.get('features');
                    if (features && features.length === 1) devices.setSelected(features[0].getId());
                }
            });

            map.on('pointermove', (event) => {
                map.getTargetElement().style.cursor = map.hasFeatureAtPixel(event.pixel) ?
                    'pointer' :
                    '';
            });

            map.on('pointerdrag', (event) => {
                setFollow(false);
            });

            let zoomCache;
            map.on('movestart', (event) => {
                zoomCache = event.map.getView().getZoom();
            });

            map.on('moveend', (event) => {
                const zoom = event.map.getView().getZoom();
                zoom !== zoomCache && setFollow(false);
            });
        }
    }, [auth, setSelectedMap, instance, store.devices]);

    const createGeofence = async () => {
        const currentPosition =
            events.currentDevice && events.currentDevice.currentPosition;

        const device = events.currentDevice;

        if (currentPosition) {
            const area = `CIRCLE (${currentPosition.latitude} ${
                currentPosition.longitude
            }, ${20})`;

            const data = {name: `Cerca_${device.name}`, area};

            const response = await geofenceService.save(data);

            if (response) {
                const permission = {deviceId: device.id, geofenceId: response.id};
                await permissionService.addPermission(permission);
                instance.addGeofence(response);
                geoFenceToastSuccess('success');
            } else {
                geoFenceToastSuccess('error');
            }
        }
    };

    const geoFenceToastSuccess = useCallback((appearance) => {
        const msg =
            appearance === 'success' ? (
                <span>Cerca criada com sucesso</span>
            ) : (
                `Não foi possível realizar sua solicitação!`
            );
        addToast(msg, {appearance, autoDismiss: true});
    }, []);

    const toggleTrace = () => {
        setTrace(!trace, devices.selectedDevice);
    };

    const toggleLabel = () => {
        setLabel(!label);
    };

    const toggleGeofences = () => {
        setShowGeofences(!showGeofences);
    };

    const onChangeMap = (value) => {
        setSelectedMap(value);
    };

    const toggleDevice = () => {
        setShowDevices(!showDevices);
    };

    const getDrive = () => {
        return events.currentDevice.currentPosition && events.currentDevice.currentPosition.attributes ?
            store.drivers.getDriverByUniqueId(events.currentDevice.currentPosition.attributes.driverUniqueId) : null;
    };

    const sendComment = async (comment) => {
        try {
            await eventService.sendComment({eventId, comment, attributes: {}});

            addToast('Tratamento de evento realizado com sucesso', {appearance: 'success', autoDismiss: true});
            events.remove(eventId);
            history.push('/admin');
        } catch (e) {
            console.log(e);
        }
    };

    const getCustomer = () => {
        return customerStore.findById(events.currentDevice.customerId);
    };

    return (
        <Container fluid className="px-4 pb-4 position-relative h-100">
            { devices.list.length > 0 && <PopupEvent isEvent={!!eventId}/> }

            {devices.list.length > 0 && events.currentDevice && events.currentDevice.currentPosition && (
                <DeviceDetail
                    toggleTrace={toggleTrace}
                    trace={trace}
                    createGeofence={createGeofence}
                    followToogle={followToggle}
                    follow={follow}
                    driver={getDrive()}
                    customer={getCustomer()}
                />
            )}
            <Map ref={mapRef} className="custom-shadow-2">
                <MapRightActions
                    map={instance}
                    toggleTrace={toggleTrace}
                    trace={trace}
                    toggleLabel={toggleLabel}
                    label={label}
                    onChangeMap={onChangeMap}
                    selectedMap={selectedMap}
                    toggleDevice={toggleDevice}
                    showDevices={showDevices}
                    toggleGeofences={toggleGeofences}
                    showGeofences={showGeofences}
                    close={'/admin'}
                />

                { eventId && <EventFormHandler
                    theme={theme}
                    submit={sendComment} /> }
            </Map>
        </Container>
    );
};

export default observer(AdminEventMapContainer);
