import React, {useRef, useState} from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import I18nMessage from '../../components/I18nMessage';
import ReactInputCpfCnpj from '../../components/react-mask-cpf-cnpj';
import ReactMaskPhone from '../../components/react-mask-phone';
import ReactMaskZipcode from '../../components/react-mask-zipcode';
import ReactSelect from '../../components/react-select';
import {TextArea} from '../../components/textarea';
import {FieldSet} from '../../components/fieldset';
import {Input as UInput} from '../../components/input';
import ToggleButton from '../../components/toggle-button';
import {GroupButtonForm} from '../../components/group-button-form';
import DatePicker from '../../components/react-datepicker';
import * as moment from 'moment';

const CustomerForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        states,
        loading,
        timezones,
    } = props;

    const formRef = useRef(null);
    const [type, setType] = useState(data.type === 'J' ? 'cnpj' : 'cpf');

    const schema = Yup.object().shape({
        id: Yup.number(),
        cpfCnpj: Yup.string().required('Informe o CPF ou CNPJ'),
        type: Yup.string().required('Selecione o tipo').default('F'),
        name: Yup.string().required('Informe o nome'),
        street: Yup.string().required('Informe a rua'),
        number: Yup.string().required('Informe o número'),
        complement: Yup.string(),
        neighborhood: Yup.string().required('Informe o bairro'),
        city: Yup.string().required('Informe a cidade'),
        state: Yup.string().required('Selecione o estado'),
        postalCode: Yup.string().required('Informe o código postal'),
        phone: Yup.string().required('Informe o telefone'),
        cellphone: Yup.string(),
        notificationEmail: Yup.string().required('Informe o e-mail'),
        secondaryEmail: Yup.string(),
        category: Yup.string().required('Nome é necessário'),
        status: Yup.string().required('Nome é necessário'),
        notes: Yup.string(),
        disabled: Yup.boolean(),
        registrationDate: Yup.string().required('Nome é necessário'), // "2019-10-31T03:00:00.000+0000"
        expirationDate: Yup.string().nullable(true), // "2019-10-31T03:00:00.000+0000"
        invoiceDay: Yup.number(),
    });

    const handleSubmit = async (customerData) => {
        let customer = Object.assign(data, customerData);
        try {
            await schema.validate(customer, {
                abortEarly: false,
            });
            customer = schema.cast(customerData);
            if (customer.expirationDate) {
                customer.expirationDate = moment(customer.expirationDate).toISOString();
            }
            if (customer.registrationDate) {
                customer.registrationDate = moment(customer.registrationDate).toISOString();
            }
            edit ? updateRegister(customer) : newRegister(customer);
        } catch (err) {
            console.log(err);
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const initialize = (src) => {
        const data = Object.assign({}, src);
        if (!data.hasOwnProperty('type')) {
            data.type = 'F';
        }
        return data;
    };

    const customerTypeOnChange = (_data) => {
        if (_data) {
            setType(_data.id === 'F' ? 'cpf' : 'cnpj');
        } else {
            setType(data.type === 'J' ? 'cnpj' : 'cpf');
        }
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={initialize(data)}>
            {!!edit && <UInput hidden type="number" name="id"/>}
            <FieldSet className="text-left border p-4" title={'Identificação'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="cpfCnpj"><I18nMessage id="customerCpfCnpj"/>*</Label>
                                <ReactInputCpfCnpj
                                    name="cpfCnpj"
                                    id="cpfCnpj"
                                    type={type}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="type"><I18nMessage id="customerType"/>*</Label>
                                <ReactSelect id={'type'}
                                             name="type"
                                             onChange={customerTypeOnChange}
                                             options={[
                                                 {id: 'F', title: 'Pessoa Física'},
                                                 {id: 'J', title: 'Pessoa Jurídica'},
                                             ]}/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="category"><I18nMessage id="customerCategory"/>*</Label>
                                <ReactSelect id={'category'}
                                             name="category"
                                             options={[
                                                 {id: 'client', title: 'Cliente'},
                                                 {id: 'partner', title: 'Parceiro'},
                                                 {id: 'monitoring', title: 'Central'},
                                             ]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br/>
            <FieldSet className="text-left border p-4" title={'Contato'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="phone"><I18nMessage id="customerPhone"/>*</Label>
                                <ReactMaskPhone
                                    tag={UInput}
                                    type="number"
                                    name="phone"
                                    id="phone"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="cellphone"><I18nMessage id="customerCellphone"/>*</Label>
                                <ReactMaskPhone
                                    tag={UInput}
                                    type="number"
                                    name="cellphone"
                                    id="cellphone"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="notificationEmail"><I18nMessage id="customerNotificationEmail"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="notificationEmail"
                                    id="notificationEmail"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="secondaryEmail"><I18nMessage id="customerSecondaryEmail"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="secondaryEmail"
                                    id="secondaryEmail"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br/>
            <FieldSet className="text-left border p-4" title={'Endereço'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="postalCode"><I18nMessage id="customerPostalCode"/>*</Label>
                                <ReactMaskZipcode
                                    name="postalCode"
                                    id="postalCode"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="street"><I18nMessage id="customerStreet"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="street"
                                    id="street"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="number"><I18nMessage id="customerNumber"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="number"
                                    id="number"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="complement"><I18nMessage id="customerComplement"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="complement"
                                    id="complement"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="neighborhood"><I18nMessage id="customerNeighborhood"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="neighborhood"
                                    id="neighborhood"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="city"><I18nMessage id="customerCity"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="city"
                                    id="city"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="state"><I18nMessage id="customerState"/>*</Label>
                                <ReactSelect id={'state'}
                                             name="state"
                                             options={states}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            </FieldSet>
            <br/>
            <FieldSet className="text-left border p-4" title={'Situação Cadastral'}>
                <>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="status"><I18nMessage id="customerStatus"/>*</Label>
                                <ReactSelect id={'status'}
                                             name="status"
                                             options={[
                                                 {id: 'active', title: 'Ativo'},
                                                 {id: 'frozen', title: 'Congelado'},
                                                 {id: 'inactive', title: 'Inativo'},
                                                 {id: 'trial', title: 'Teste'},
                                             ]}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="disabled"><I18nMessage id="customerDisabled"/></Label>
                                <ToggleButton
                                    name={'disabled'}
                                    id={'disabled'}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="invoiceDay"><I18nMessage id="customerInvoiceDay"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="invoiceDay"
                                    id="invoiceDay"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="registrationDate"><I18nMessage id="customerRegistrationDate"/>*</Label>
                                <DatePicker
                                    name={'registrationDate'}
                                    displayFormat={'dd/MM/yyyy'}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="expirationDate"><I18nMessage id="customerExpirationDate"/>*</Label>
                                <DatePicker
                                    name={'expirationDate'}
                                    displayFormat={'dd/MM/yyyy'}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="custom-formGroup">
                                <Label for="notes"><I18nMessage id="customerNotes"/>*</Label>
                                <TextArea
                                    type="text"
                                    name="notes"
                                    id="notes"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            </FieldSet>
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={() => formRef.current.submitForm()}
            />
        </Form>
    );
};

export default CustomerForm;
