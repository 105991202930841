import React, {useRef, useState} from 'react';
import {Input as UInput} from '../../components/input';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import moment from 'moment';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import I18nMessage from '../../components/I18nMessage';
import ReactSelect from '../../components/react-select';
import {TextArea} from '../../components/textarea';
import useServices from '../../hooks/use-services';
import useAlerts from '../../hooks/use-alerts';
import UInputMask from '../../components/react-mask';
import Button from '../../components/button';
import {InputFile} from '../../components/input-file';

const OccurrencesForm = (props) => {
    const {
        sinister,
        loadIncidents,
        close,
        setSinister,
        setOccurrences,
    } = props;

    const {toast} = useAlerts();
    const [loading] = useState(false);
    const [file, setFile] = useState(null);
    const [ext, setExt] = useState(null);
    const formRef = useRef(null);
    const fileRef = useRef(null);
    const {sinisterService} = useServices();

    const schema = Yup.object().shape({
        sinisterId: Yup.number().notRequired(),
        category: Yup.string().required(),
        date: Yup.string().required(),
        time: Yup.string().required(),
        address: Yup.string().notRequired(),
        description: Yup.string().required(),
    });

    const handleProgress = (event) => {
        const file = event.currentTarget.files[0];
        setExt(file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2));
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            setFile(reader.result);
        };
        reader.onerror = function(error) {
            console.log('Error: ', error);
        };
    };

    const handleSubmit = async (payload) => {
        try {
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            const
                {
                    sinisterId,
                    category,
                    address,
                    description,
                } = payload;

            sinisterService.addOccurrences( {
                sinisterId,
                attributes: {ext, file},
                category,
                occurrenceDate: moment(`${payload.date} ${payload.time}`, 'DD/MM/YYYY HH:mm').toDate().toISOString(),
                address,
                description,
            }).then((sinister) => {
                loadIncidents();
                setSinister(sinister);
                setOccurrences(sinister.occurrences);
                close();
                toast.success('Ocorrência Adicionada com Sucesso');
                formRef.current.reset();
                formRef.current.setFieldValue('sinisterId', sinister.id);
            });
        } catch (err) {
            toast.errorStatus('Erro ao tentar criar Ocorrência');
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            props.dispatchAccordionResize();
        }
    };

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
                sinisterId: sinister.id,
                date: moment().format('DD/MM/YYYY'),
                time: moment().format('HH:mm'),
            }}
            schema={schema}>

            <UInput hidden type="number" name="sinisterId"/>

            <FormGroup className="custom-formGroup">
                <Label for="name">
                    <I18nMessage id="deviceCategory"/>*
                </Label>
                <ReactSelect
                    id={'category'}
                    name={'category'}
                    options={[
                        {id: 'attemptToTriggerPromptResponse', title: 'Tentativa De Acionar A Resposta Imediata'},
                        {id: 'limitExceededInSearchConditions', title: 'Limite Excedido Nas Condições De Pesquisa'},
                        {id: 'policeArriveAtTheScene', title: 'A Polícia Chegou no Local'},
                        {id: 'promptResponseActivated', title: 'Resposta Ativada'},
                        {id: 'registerThePoliceReport', title: 'Registrou O Relatório Da Polícia'},
                        {
                            id: 'requestedVehicleForVehicleRecovery',
                            title: 'Veículo Solicitado Para Recuperação De Veículos',
                        },
                        {id: 'supportArrivesOnSite', title: ' Suporte Chega Ao Local'},
                        {id: 'supportDoesOnSitePreservation', title: ' Suporte Faz Preservação No Local'},
                        {id: 'supportOnTheWay', title: 'Apoio No Caminho'},
                        {id: 'supportStartsPatrolling', title: 'Suporte Começa A Patrulhar'},
                        {id: 'uninstalledEquipmentFound', title: 'Equipamento Desinstalado Encontrado'},
                    ]}/>
            </FormGroup>
            <Row>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label for="name">
                            Data*
                        </Label>
                        <UInputMask name={'date'} mask={'99/99/9999'}/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label for="name">
                            Horário*
                        </Label>
                        <UInputMask name={'time'} mask={'99:99'}/>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup className="custom-formGroup">
                <Label for="name">
                    <I18nMessage id="sharedAddress"/>
                </Label>
                <Input
                    tag={UInput}
                    type="text"
                    name={'address'}
                    id="address"
                />
            </FormGroup>
            <FormGroup className="custom-formGroup">
                <Label for="name">Adicionar Imagem</Label>
                <Button className={'outline'}
                        size={'sm'}
                        color={'theme'} onClick={ () => fileRef.current.click()}><i className="fas fa-arrow-up"/> Upload</Button>
                <input
                    style={{display: 'none'}}
                    type="file"
                    ref={fileRef}
                    onChange={handleProgress}
                />
            </FormGroup>
            <FormGroup className="custom-formGroup">
                <Label for="name">
                    <I18nMessage id="sharedDescription"/>
                </Label>
                <TextArea
                    type="text"
                    name={'description'}
                    id="description"
                />
            </FormGroup>

            <Row>
                <Col> <Button
                    color={'warning'}
                    disabled={loading}
                    className={'outline'}
                    size={'sm'}
                    onClick={() => close()}>
                    Cancelar
                </Button></Col>
                <Col>
                    <Button
                        color={'success'}
                        disabled={loading}
                        className={'outline'}
                        size={'sm'}
                        onClick={() => formRef.current.submitForm()}>
                        Salvar
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default OccurrencesForm;
