import React from 'react';
import CalendarContainer from '../../containers/calendar-container';
import DeviceContainer from '../../containers/device-container';
import DriverContainer from '../../containers/driver-container';
import GeofenceContainer from '../../containers/geofence-container';
import GroupContainer from '../../containers/group-container';
import MaintenanceContainer from '../../containers/maintenance-container';
import NotificationContainer from '../../containers/notification-container';
import NewReportContainer from '../../containers/report-container/NewReportContainer';
import UserContainer from '../../containers/user-container';
import ModalEnum from './modalEnum';
import AccountContainer from '../../containers/account-container';
import SinisterContainer from '../../containers/sinister-container';
import NewCommandModal from '../../containers/command-container/new-command-modal';

export const modalDetail = {
  [ModalEnum.NEW_REPORT]: {
    title: 'reportNew',
    type: ModalEnum.NEW_REPORT,
  },
  [ModalEnum.ACCOUNT]: {
    title: 'settingsUser',
    type: ModalEnum.ACCOUNT,
  },
  [ModalEnum.USERS]: {
    title: 'settingsUsers',
    type: ModalEnum.USERS,
  },
  [ModalEnum.GROUP]: {
    title: 'groupDialog',
    type: ModalEnum.GROUP,
  },
  [ModalEnum.GEOFENCE]: {
    title: 'sharedGeofence',
    type: ModalEnum.GEOFENCE,
  },
  [ModalEnum.SINISTER]: {
    title: 'sharedSinister',
    type: ModalEnum.SINISTER,
  },
  [ModalEnum.DEVICE]: {
    title: 'sharedDevice',
    type: ModalEnum.DEVICE,
  },
  [ModalEnum.DRIVER]: {
    title: 'sharedDriver',
    type: ModalEnum.DRIVER,
  },
  [ModalEnum.NOTIFICATION]: {
    title: 'sharedNotification',
    type: ModalEnum.NOTIFICATION,
  },
  [ModalEnum.CALENDAR]: {
    title: 'sharedCalendar',
    type: ModalEnum.CALENDAR,
  },
  [ModalEnum.MAINTENANCE]: {
    title: 'sharedMaintenance',
    type: ModalEnum.MAINTENANCE,
  },
  [ModalEnum.SERVER]: {
    title: 'sharedCalendar',
    type: ModalEnum.SERVER,
  },
  [ModalEnum.COMMAND]: {
    title: 'commandNew',
    type: ModalEnum.COMMAND,
  },
};

export const modalList = {
  [ModalEnum.NEW_REPORT]: <NewReportContainer />,
  [ModalEnum.ACCOUNT]: <AccountContainer />,
  [ModalEnum.DRIVER]: <DriverContainer />,
  [ModalEnum.CALENDAR]: <CalendarContainer />,
  [ModalEnum.USERS]: <UserContainer />,
  [ModalEnum.GROUP]: <GroupContainer />,
  [ModalEnum.DEVICE]: <DeviceContainer />,
  [ModalEnum.GEOFENCE]: <GeofenceContainer />,
  [ModalEnum.SINISTER]: <SinisterContainer />,
  [ModalEnum.NOTIFICATION]: <NotificationContainer />,
  [ModalEnum.MAINTENANCE]: <MaintenanceContainer />,
  [ModalEnum.COMMAND]: <NewCommandModal />,
};
