import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import ReactSelect from '../../components/react-select';
import {Form} from '@unform/web'
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';

import './group.sass';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from "../../components/fieldset";
import {GroupButtonForm} from "../../components/group-button-form";

const GroupForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        groups,
        loading,
    } = props;

    const formRef = useRef(null);

    const schema = Yup.object().shape({
        name: Yup.string().required('Nome é necessário'),
        groupId: Yup.string().notRequired(),
        id: Yup.number(),
    });

    const handleSubmit = async (payload) => {

        try {
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);
            edit ? updateRegister(payload) : newRegister(payload);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    return (
        <div>
            <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={data}>
                {!!edit && <UInput hidden type="number" name="id"/>}
                <FieldSet className="text-left border p-4" title={'Identificação'}>
                    <>
                        <FormGroup className="custom-formGroup">
                            <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                            <Input
                                tag={UInput}
                                type="text"
                                name="name"
                                id="name"
                            />
                        </FormGroup>
                        <FormGroup className="custom-formGroup">
                            <Label for="groupId"><I18nMessage id="groupParent"/></Label>
                            <ReactSelect id={'groupId'} name="groupId" options={groups}/>
                        </FormGroup>
                    </>
                </FieldSet>

                <br/>
                <GroupButtonForm
                    loading={loading}
                    cancel={cancel}
                    submit={() => formRef.current.submitForm()}
                />

            </Form>
        </div>
    );
};

export default GroupForm;
