import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import useStore from './use-store';

const useNotificationType = () => {

    const intl = useIntl();
    const {store} = useStore();
    const [types, seTypes] = useState([]);
    const {allNotificationTypes} = store;

    useEffect(() => {
        const data = [];
        allNotificationTypes.types.map((type) => {
            data.push(
                {
                    id: type.type,
                    title: intl.formatMessage({id: type.name})
                });
        });

        seTypes(data);
    }, [allNotificationTypes.types.length]);

    return {types};
};

export default useNotificationType;
