import {types} from 'mobx-state-tree';
import MapTypeModel from '../models/map-type-model';
import i18n from './translate-store';

const MapTypeStore = types

    .model('MapTypeStore', {
      types: types.optional(types.array(MapTypeModel), []),
    })
    .actions((self) => ({
      afterCreate() {
        self.setTypes([{
          key: 'carto',
          name: i18n.strings.mapCarto,
        }, {
          key: 'osm',
          name: i18n.strings.mapOsm,
        }, {
          key: 'bingRoad',
          name: i18n.strings.mapBingRoad,
        }, {
          key: 'bingAerial',
          name: i18n.strings.mapBingAerial,
        }, {
          key: 'bingHybrid',
          name: i18n.strings.mapBingHybrid,
        }, {
          key: 'baidu',
          name: i18n.strings.mapBaidu,
        }, {
          key: 'yandexMap',
          name: i18n.strings.mapYandexMap,
        }, {
          key: 'yandexSat',
          name: i18n.strings.mapYandexSat,
        }, {
          key: 'wikimedia',
          name: i18n.strings.mapWikimedia,
        }, {
          key: 'custom',
          name: i18n.strings.mapCustom,
        }]);
      },
      setTypes( data ) {
        self.types = data;
      },
    }));

export default MapTypeStore;
