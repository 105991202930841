import DeviceService from '../services/device-service';
import CalendarService from '../services/calendar-service';
import GroupService from '../services/group-service';
import CommandService from '../services/command-service';
import DriverService from '../services/driver-service';
import GeofenceService from '../services/geofence-service';
import MaintenanceService from '../services/maintenance-service';
import NotificationService from '../services/notification-service';
import PermissionService from '../services/permission-service';
import PositionService from '../services/position-service';
import ReportService from '../services/report-service';
import ServerService from '../services/server-service';
import UserService from '../services/user-service';
import AttributesComputedService from '../services/attributes-computed-service';
import EventService from '../services/event-service';
import CustomerService from '../services/customer-service';
import SinisterService from '../services/sinister-service';
import LocationService from '../services/location-service';
import CompanyConfigurationService from '../services/company-configuration-service';

const useServices = () => {
    return {
        attributesComputedService: new AttributesComputedService(),
        calendarService: new CalendarService(),
        commandService: new CommandService(),
        customerService: new CustomerService(),
        deviceService: new DeviceService(),
        driverService: new DriverService(),
        eventService: new EventService(),
        geofenceService: new GeofenceService(),
        groupService: new GroupService(),
        maintenanceService: new MaintenanceService(),
        notificationService: new NotificationService(),
        permissionService: new PermissionService(),
        positionService: new PositionService(),
        reportService: new ReportService(),
        serverService: new ServerService(),
        userService: new UserService(),
        sinisterService: new SinisterService(),
        locationService: new LocationService(),
        companyConfigurationService: new CompanyConfigurationService(),
    };
};

export default useServices;
