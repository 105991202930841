import {useEffect, useState} from 'react';
import useStore from './use-store';

const useNotificatiorType = () => {

    const {store} = useStore();
    const [allNotificator, setAllNotificator] = useState([]);
    const {allNotificators} = store;

    useEffect(() => {
        const data = [];
        allNotificators.types.map((type) => {
            data.push(
                {
                    id: type.type,
                    title: type.name
                });
        });

        setAllNotificator(data);
    }, [allNotificators.types.length]);

    return {allNotificator};
};

export default useNotificatiorType;
