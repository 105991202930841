import request from './http-request';

export const getServer = (_) =>
  request({
    method: 'get',
    url: `server`,
    noAuth: true,
  });

export const updateServer = ({email, password}, data) =>
  request({
    method: 'put',
    url: `server/${data.id}`,
    noAuth: true,
    auth: {username: email, password},
    data,
  });
