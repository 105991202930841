import {inject, observer} from 'mobx-react';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';
import I18nMessage from '../../components/I18nMessage';
import {timezoneFormat} from '../../helpers/date';
import useTimezone from '../../hooks/use-timezone';
import {colors, shadows} from '../../styleVariables';

const EventType = styled.h6`
  font-weight: bold;
  margin-bottom: 0.2rem;
`;

const Date = styled.span``;

const Address = styled.small`
  color: ${colors.gray};
`;

const Popup = styled.div`
  background-color: white;
  padding: 0.7rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: ${shadows.shadow};
  flex-direction: column;
  display: ${({show}) => (!show ? 'none' : 'flex')};

  ${EventType} {
    color: ${({theme}) => theme};
  }

  ${Date} {
    color: ${({theme}) => theme};
  }
`;

const getEvent = (type, attributes) => {
  return attributes.alarm ?
    `alarm${String(attributes.alarm)
        .charAt(0)
        .toUpperCase()}${String(attributes.alarm).slice(1)}` :
    `event${String(type)
        .charAt(0)
        .toUpperCase()}${String(type).slice(1)}`;
};

const getDate = (timezone, date) =>
  moment(timezoneFormat(timezone, date)).format('DD/MM/YYYY [|] HH:MM:ss');

const PopupEvent = ({server, events, isEvent, ...props}) => {
  const theme = useContext(ThemeContext);
  const {timezone} = useTimezone();

  const [show, setShow] = useState(false);

  const {current, currentDevice, position} = events || {};
  const {type} = current || {};
  const {attributes} = current || {};

  const {currentPosition} = currentDevice || {};

  useEffect(() => {}, [JSON.stringify(currentPosition), current]);

  useEffect(() => {
    current && setShow(true);
  }, [current]);

  return (
    <Popup id="popupEvent" theme={theme} show={show && isEvent}>
      {current && position && (
        <>
          <EventType>
            <I18nMessage id={getEvent(type, attributes)} />
          </EventType>
          <Date>{getDate(timezone, current.serverTime)}</Date>
          <Address>{position.address}</Address>
          <span>{attributes.result}</span>
        </>
      )}
    </Popup>
  );
};

const mapping = ({store}) => ({
  server: store.server,
  events: store.events,
});

export default inject(mapping)(observer(PopupEvent));
