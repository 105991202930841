import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import ReactSelect from '../../components/react-select';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';

import './geofence.sass';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from '../../components/fieldset';
import {TextArea} from '../../components/textarea';
import MapDraw from './map-draw';
import {GroupButtonForm} from '../../components/group-button-form';
import {ReactColor} from '../../components/react-color';
import {kmhToKnots, knotsToKmH} from '../../helpers/conversions';

const GeofenceForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        calendars,
        loading,
    } = props;

    const formRef = useRef(null);

    const schema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().required('Nome é necessário'),
        description: Yup.string().notRequired(),
        calendarId: Yup.string().notRequired(),
        area: Yup.string().required('É preciso definir a área da Cerca'),
        attributes: Yup.object().shape({
            color: Yup.string().notRequired(),
            speedLimit: Yup.string().notRequired(),
            polylineDistance: Yup.string().notRequired(),
        }),
    });

    const handleSubmit = async (payload) => {
        try {
            await schema.validate(payload, {
                abortEarly: false,
            });

            payload = schema.cast(payload);

            if (payload.attributes) {
                const attributes = Object.assign({}, payload.attributes);
                if (attributes.hasOwnProperty('speedLimit')) {
                    if (attributes.speedLimit.length > 0) {
                        attributes.speedLimit = parseFloat(kmhToKnots(payload.attributes.speedLimit));
                    } else {
                        delete attributes.speedLimit;
                    }
                }
                if (attributes.hasOwnProperty('polylineDistance')) {
                    if (attributes.polylineDistance.length > 0) {
                        attributes.polylineDistance = parseFloat(payload.attributes.polylineDistance);
                    } else {
                        delete attributes.polylineDistance;
                    }
                }
                if (attributes.hasOwnProperty('color')) {
                    if (attributes.color.length === 0) {
                        delete attributes.color;
                    }
                }
                payload.attributes = attributes;
            }
            edit ? updateRegister(payload) : newRegister(payload);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const cast = (src) => {
        const data = Object.assign({}, src);
        if (data.attributes) {
            const attributes = Object.assign({}, data.attributes);
            if (attributes.hasOwnProperty('speedLimit')) {
                attributes.speedLimit = knotsToKmH(attributes.speedLimit);
            }

            data.attributes = attributes;
        }
        return data;
    };

    const setMapArea = (area) => {
        formRef.current.setFieldValue('area', area);
    };

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            schema={schema}
            initialData={cast(data)}>
                {!!edit && <UInput hidden type="number" name="id"/>}
                <FieldSet className="text-left border p-4" title={'Identificação'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            {!!edit && <UInput hidden type="number" name="id"/>}
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="groupId"><I18nMessage id="sharedCalendar"/></Label>
                                <ReactSelect
                                    id={'calendarId'}
                                    name="calendarId"
                                    options={calendars}/>
                            </FormGroup>
                        </div>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedDescription"/></Label>
                                <TextArea
                                    type="text"
                                    name="description"
                                    id="description"
                                />
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>

                <FieldSet className="text-left border p-4" title={'Mapa'}>
                    <>
                        <MapDraw
                            key={`map__draw`}
                            data={data}
                            setData={(data) => setMapArea(data)}
                        />
                        <UInput hidden type="text" name="area"/>
                    </>
                </FieldSet>

                <FieldSet className="text-left border p-4" title={'Configurações'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name">Cor</Label>
                                <ReactColor
                                    name="attributes.color"
                                    id="color"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="groupId">Distancia da Linha (m)</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.polylineDistance"
                                    id="polylineDistance"
                                />
                            </FormGroup>
                        </div>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name">Limite de Velocidade (km/h)</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="attributes.speedLimit"
                                    id="speedLimit"
                                />
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>

                <br/>
                <GroupButtonForm
                    loading={loading}
                    cancel={cancel}
                    submit={() => formRef.current.submitForm()}
                />

            </Form>
    );
};

export default GeofenceForm;
