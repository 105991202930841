import {inject, observer} from 'mobx-react';
import qs from 'qs';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Container} from 'reactstrap';
import {from} from 'rxjs';
import {getSummaryReport} from '../../services/reportService';
import SummaryList from '../../views/reports/summary-list';

const SummaryReportContainer = ({match, location, ...props}) => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(true);

  const {server} = props;
  const {timezone, distanceUnit, volumeUnit} = server.attributes;

  const search = useMemo(() => location.search, [location.search]);

  const activeRef = useRef();

  const getReport = useCallback(async (params) => {
    setLoading(true);
    from(getSummaryReport(params)).subscribe((data) => {
      setReport(data);
      setLoading(false);
    });
  }, []);

  const onSelect = useCallback(
      ({index}) => {
        if (activeRef && activeRef.current === index) return;

        const item = report[index];
        let itemActive = null;

        if (activeRef && activeRef.current >= 0) {
          itemActive = report[activeRef.current];
        }

        const items = report.concat();

        items[index] = {...item, isActive: true};
        itemActive &&
        (items[activeRef.current] = {...itemActive, isActive: false});

        activeRef.current = index;

        setReport(items);
      },
      [report],
  );

  useEffect(() => {
    if (search) {
      const params = qs.parse(search, {
        ignoreQueryPrefix: true,
        comma: true,
      });

      getReport(qs.stringify(params, {indices: false}));
    }
  }, [search]);

  return (
    <Container fluid className="px-4 position-relative h-100">
      <SummaryList
        data={report}
        loading={loading}
        speedUnit={server.attributes && server.attributes.speedUnit}
        {...{timezone, distanceUnit, volumeUnit, onSelect}}
      />
    </Container>
  );
};

const mapping = ({store}) => ({
  auth: store.auth,
  session: store.session,
  server: store.server,
});

export default inject(mapping)(observer(SummaryReportContainer));
