import React, {useState} from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import I18nMessage from '../../components/I18nMessage';
import {useIntl} from 'react-intl';
import 'moment/locale/pt-br';
import moment from 'moment';
import qs from 'qs';
import styled from 'styled-components';
import './sinister.sass';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Accordion from '../../components/accordion';
import {getColor, getSinisterTypeI18N} from '../../helpers/sinister-help';
import Button from '../../components/button';
import history from '../../routes/history';
import {sinisterReport} from '../../services/reportService';
import { ButtomStyled } from '../../styles';
import FileSaver from 'file-saver';

const ModalStyled = styled(Modal)`
   top: 20%;
   .modal-dialog{
      overflow:visible;
      min-height:100%!important;
      height:600px !important;
   }
   .modal-content{
        background: #FFFFFF;
        height: 60%;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
    }
`;

const ModalHeaderStyled = styled(ModalHeader)`
    background: #FFFFFF;
    border-bottom: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #15A6DA;
    font-size: 16px;
`;

const ModalBodyStyled = styled(ModalBody)`
    height:100%;
`;

const AccordionStyled = styled(Accordion)`
  button.accordion{
    background-color: white;
  }
`;

const ModalFooterStyled = styled(ModalFooter)`
 border-top: 0px;
`;

const SinisterTable = (props) => {
    const {
        list,
    } = props;

    const intl = useIntl();
    const [modal, setModal] = useState(false);
    const [rowSelected, setSelectedRow] = useState(null);

    const toggle = () => setModal(!modal);

    const selectRowProp = {
        mode: 'radio',
        bgColor: (row, isSelect) => {
            if (isSelect && row.id === 0) {
                return null;
            }
            // return '#2497e9';
            return null;
        },
        hideSelectColumn: true,
        clickToSelect: true,
        onSelect: (row, isSelected, e) => {
            if (!row) {
                if (isSelected && row.id === 0) {
                    return false;
                }
                setSelectedRow(row);
            }
            return true;
        },
    };

    const showSinterDetail = () => {
        if (rowSelected) {
            toggle();
        }
    };

    const exportReport = async (data) => {
        try {
            const response = await sinisterReport(data);

            if (response) {
                const blob = new Blob([response], {
                    type:
                        'application/pdf',
                });
                FileSaver.saveAs(blob, 'relatório.pdf');
            };
        } catch (err) {
            console.log(err);
        }
        return;
    };

    const showOccurrenceDetail = (occurrence) => {
        return <>
         <span className={'occurrence-modal-item'}>
                        <span
                            className={'occurrence-modal-label'}>Data: </span> {moment(occurrence.occurrenceDate).format('MM.DD.YYYY')}
                    </span>
            <span className={'occurrence-modal-item'}>
                        <span
                            className={'occurrence-modal-label'}>Horário: </span> {moment(occurrence.occurrenceDate).format('hh:mm')}
                    </span>
            <span className={'occurrence-modal-item'}>
                        <span
                            className={'occurrence-modal-label'}>Categoria: </span> {intl.formatMessage({id: getSinisterTypeI18N(occurrence.category)})}
                    </span>
            <span className={'occurrence-modal-item'}>
                        <span className={'occurrence-modal-label'}>Endereço: </span> {occurrence.address}
                    </span>
            <span className={'occurrence-modal-item'}>
                        <span className={'occurrence-modal-label'}>Ocorrência: </span> {occurrence.description}
                    </span>
        </>;
    };

    return (
        <>
            <ModalStyled isOpen={modal} toggle={toggle} className={'className'}>
                <ModalHeaderStyled toggle={toggle}>Resumo do Sinistro</ModalHeaderStyled>
                <ModalBodyStyled>
                    <AccordionStyled
                        title="Informações Essenciais"
                        component={<div>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Placa:</span> {rowSelected && rowSelected.devicePlate ? rowSelected.devicePlate : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Modelo:</span> {rowSelected && rowSelected.deviceModel ? rowSelected.deviceModel : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Cor:</span> {rowSelected && rowSelected.deviceColor ? rowSelected.deviceColor : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Endereço:</span> {rowSelected && rowSelected.openingAddress ? rowSelected.openingAddress : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Latitude:</span> {rowSelected && rowSelected.openingLatitude ? rowSelected.openingLatitude : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Longitude:</span> {rowSelected && rowSelected.openingLongitude ? rowSelected.openingLongitude : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Contato:</span> {rowSelected && rowSelected.customerName ? rowSelected.customerName : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Celular:</span> {rowSelected && rowSelected.customerPhone ? rowSelected.customerPhone : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Tipo de Sinistro:</span> {rowSelected && rowSelected.openingType ? rowSelected.openingType : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Informações:</span> {rowSelected && rowSelected.openingDescription ? rowSelected.openingDescription : ''}
                            </p>
                        </div>}
                    />
                    {rowSelected && rowSelected.occurrences && rowSelected.occurrences.map((occurrence) =>
                        <AccordionStyled
                            key={rowSelected.id + occurrence.id}
                            title={`Ocorrência - ${intl.formatMessage({id: getSinisterTypeI18N(occurrence.category)})}`}
                            component={showOccurrenceDetail(occurrence)}
                        />)
                    }
                    {rowSelected && rowSelected.status !== 'opened' &&
                    <AccordionStyled
                        title="Fechamento"
                        component={<div>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Endereço:</span> {rowSelected && rowSelected.closingAddress ? rowSelected.closingAddress : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Latitude:</span> {rowSelected && rowSelected.closingLatitude ? rowSelected.closingLatitude : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Longitude:</span> {rowSelected && rowSelected.closingLongitude ? rowSelected.closingLongitude : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Tipo de Fechamento:</span> {rowSelected && rowSelected.closingType ?
                                intl.formatMessage({id: 'sinisterType' + rowSelected.closingType.charAt(0).toUpperCase() + rowSelected.closingType.slice(1)}) : ''}
                            </p>
                            <p className={'info-text'}><span
                                className={'info-text-label'}>Informações:</span> {rowSelected && rowSelected.closingDescription ? rowSelected.closingDescription : ''}
                            </p>
                        </div>}
                    />}
                </ModalBodyStyled>
                <ModalFooterStyled>
                    <Button className={'outline'}
                        size={'sm'}
                        color={'theme'}
                        onClick={(_) => exportReport(rowSelected.id)}>
                            <i className="fas fa-arrow-down"/> Baixar Relatório
                    </Button>
                </ModalFooterStyled>
            </ModalStyled>
            <BootstrapTable
                options={{
                    onRowMouseOver: (row) => {
                        if (row !== rowSelected) {
                            setSelectedRow(row);
                        }
                    },
                    noDataText: intl.formatMessage({id: 'sharedNoDataText'}),
                    sizePerPageList: [{
                        text: '10', value: 10,
                    }, {
                        text: '50', value: 50,
                    }, {
                        text: intl.formatMessage({id: 'sharedAll'}), value: list.length,
                    }],
                    sizePerPage: 10, // which size per page you want to locate as default
                    prePage: intl.formatMessage({id: 'sharedPrev'}), // Previous page button text
                    nextPage: intl.formatMessage({id: 'sharedNext'}), // Next page button text
                    firstPage: intl.formatMessage({id: 'sharedFirst'}), // First page button text
                    lastPage: intl.formatMessage({id: 'sharedLast'}), // Last page button text
                }}
                data={list}
                search={true}
                keyField={'id'}
                selectRow={selectRowProp}
                striped
                hover
                pagination
            >
                <TableHeaderColumn dataField='number'><I18nMessage id={'sinisterNumber'}/></TableHeaderColumn>
                <TableHeaderColumn dataField='devicePlate'><I18nMessage id={'sinisterDevicePlate'}/></TableHeaderColumn>
                <TableHeaderColumn dataField='deviceModel'><I18nMessage id={'sinisterDeviceModel'}/></TableHeaderColumn>
                <TableHeaderColumn dataField='openingDate'
                                   dataFormat={(cell, row) => moment(cell).format('MM.DD.YYYY - hh:mm')}>
                    <I18nMessage id={'sinisterOpeningDate'}/>
                </TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataFormat={(cell, row) => <span
                    className={`status sinister-color-${getColor(row)}`}>
                <I18nMessage id={'sinisterStatus' + cell.charAt(0).toUpperCase() + cell.slice(1)}/></span>}>
                    <I18nMessage id={'sinisterStatus'}/>
                </TableHeaderColumn>
                <TableHeaderColumn export={false} dataField='deviceId'
                                   dataFormat={(cell, row) => <div className="buttons">
                                       <div className="action_btn">
                                           <button name={'btnShowDetail'} onClick={() => showSinterDetail()}
                                                   className="btn btn-outline" type="button"><i
                                               className={'fad fa-eye'}/></button>
                                           {row.status === 'opened' ?
                                               <button
                                                   onClick={() => history.push(`/admin/events/devices/${row.deviceId}`)}
                                                   className="btn btn-outline" type="button"><i
                                                   className={'fad fa-shield'}/></button> :
                                               <button
                                                    onClick={(_) => exportReport(row.id)}
                                                    className="btn btn-outline" type="button"><i
                                                    className={'fad fa-arrow-down'}/></button>
                                           }
                                       </div>
                                   </div>}><I18nMessage id={'sinisterActions'}/></TableHeaderColumn>
            </BootstrapTable>
        </>
    );
};

export default SinisterTable;
