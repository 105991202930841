import {types} from 'mobx-state-tree';
import FCClientModel from "../models/full-control/client-model.js";
import FullControlClienteService from "../services/full-control/full-control-cliente-service";
import FullControlVeiculoService from "../services/full-control/full-control-veiculo-service";
import FullControlRastreadorService from "../services/full-control/full-control-rastreador-service";
import FCEquipmentModel from "../models/full-control/equipment-model";
import FullControlOperadoraService from "../services/full-control/full-control-operadora-service";
import FullControlSimCardService from "../services/full-control/full-control-sim-card-service";

const FullControlStore = types
    .model('FullControlStore', {
        clientId: types.optional(types.string, ''),
        secretId: types.optional(types.string, ''),
        clients: types.optional(types.array(FCClientModel), []),
        equipments: types.optional(types.array(FCEquipmentModel), []),
    })
    .views((self) => {
        return {
            getClients(){
                return self.clients;
            },
            getEquipments(){
                return self.equipments;
            },
            getClientById(id){
               
                const client = self.clients.find( c => c.id === id);

                console.log(client);

                return client;
                
                if(client){
                    return {
                        id: client.id,
                        cc_email: client.contatoPrincipal.email,
                        email: client.contatoPrincipal.email,
                        full_name: client.nome,
                        cpf_cnpj: client.cnpJ_CPF,
                        postal_code: client.enderecoPrincipal.cep,
                        number: client.enderecoPrincipal.numero,
                        neighborhood: client.enderecoPrincipal.bairro,
                        complement: client.enderecoPrincipal.complemento,
                        street: client.enderecoPrincipal.endereco,
                        city: client.enderecoPrincipal.cidade,
                        state:  client.enderecoPrincipal.uf,
                        type:  client.id,
                        notes: null,
                    }
                }
                
                return null;
            }
        }
    })
    .actions((self => {

        return {
            setClientId( data ){
                self.clientId = data;
            },
            setSecretId( data ){
                self.secretId = data;
            },
            setClients( data ){
                self.clients = data;
            },
            setEquipments( data ){
                self.equipments = data;
            },
            async getSimCardById( simCardId ){
                const FCSimCardApi = new FullControlSimCardService(self.clientId, self.secretId);
                const response =  await FCSimCardApi.ObterPorId(simCardId);

                return response.data;
            },
            async getOperadoraById( operadoraId ){
                const FCOperadoraApi = new FullControlOperadoraService(self.clientId, self.secretId);
                const response =  await FCOperadoraApi.ObterPorId(operadoraId);

                return response.data;
            },
            async loadEquipments() {

                const FCVeiculosApi = new FullControlVeiculoService(self.clientId, self.secretId);
                const FCRastreadoresApi = new FullControlRastreadorService(self.clientId, self.secretId);

                const response = await FCRastreadoresApi.ObterRastreadores();
                const responseVeiculos = await FCVeiculosApi.ObterVeiculos();

                const rastreadores = response.data;
                const veiculos = responseVeiculos.data;

                rastreadores.map( async (rastreador) => {

                    rastreador.veiculo = veiculos.find( veiculo => veiculo.id === rastreador.veiculoId);
                });

                self.setEquipments(rastreadores);
            },
            async loadClients(skip = 0, take = 100, search = '') {

                const FCClientesApi = new FullControlClienteService(self.clientId, self.secretId);

                let page = skip;
                let response = await FCClientesApi.ObterClientes(page, take, search);
                let clientes = response.data.lista
                let contador = response.data.sumario.contador;

                while( clientes.length < contador){
                    page = page + 100;
                    response = await FCClientesApi.ObterClientes(page, take, search);
                    clientes = clientes.concat(response.data.lista);
                    contador = response.data.sumario.contador;
                }

                self.setClients(clientes);
            }
        }
    }));

export default FullControlStore;

