import React from 'react';
import styled from 'styled-components';
import FleetDeviceListContainer from '../../containers/fleet-device-list-container';
import MapRightActions from '../map-right-actions';
import DeviceBigFleet from './device-big-fleet';

const RightStyle = styled(MapRightActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 2rem;
  bottom: 9rem;
`;


const Bottom = ({
                    map,
                    selectedDevice,
                    setSelected,
                    toggleTrace,
                    trace,
                    toggleLabel,
                    label,
                    onChangeMap,
                    selectedMap,
                    toggleDevice,
                    showDevices,
                    createGeofence,
                    traceToggleCurrent,
                    follow,
                    followToogle,
                    devices,
                    theme,
                    updatedPositions,
                    showGeofences,
                    toggleGeofences,
                    toast,
                    intl,
                    auth,
                    server,
                    drivers,
                    isOpenModalCommand,
                    toggleModalCommand,
                    block,
                    sendCommand,
                    commandData,
                    close,
                    isAlarmsVisible,
                    toggleAlarmsVisibility,
                }) => {
    return (
        <div id="bottom">
            <div className="d-flex flex-column w-100">
                <div id="center-actions" className="d-flex justify-content-between">
                    {selectedDevice && (
                        <DeviceBigFleet
                            toast={toast}
                            intl={intl}
                            auth={auth}
                            devices={devices}
                            drivers={drivers}
                            server={server}
                            trace={selectedDevice ? selectedDevice.withTrace : false}
                            traceToggle={traceToggleCurrent}
                            follow={follow}
                            block={block}
                            sendCommand={sendCommand}
                            commandData={commandData}
                            followToogle={followToogle}
                            createGeofence={createGeofence}
                            isOpenModalCommand={isOpenModalCommand}
                            toggleModalCommand={toggleModalCommand}
                            close={close}
                        />
                    )}
                    <FleetDeviceListContainer
                        devices={devices}
                        theme={theme}
                        setSelected={setSelected}
                        selectedDevice={selectedDevice}
                        updatedPositions={updatedPositions}
                    />
                </div>

                <RightStyle
                    map={map}
                    toggleTrace={toggleTrace}
                    trace={trace}
                    toggleLabel={toggleLabel}
                    label={label}
                    onChangeMap={onChangeMap}
                    selectedMap={selectedMap}
                    toggleDevice={toggleDevice}
                    showDevices={showDevices}
                    toggleGeofences={toggleGeofences}
                    showGeofences={showGeofences}
                    isAlarmsVisible={isAlarmsVisible}
                    toggleAlarmsVisibility={toggleAlarmsVisibility}
                />
            </div>
        </div>
    );
};

export default Bottom;
