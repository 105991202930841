import FullControlService from "./full-control-service";
import Veiculo from "./model/veiculo";

export default class FullControlVeiculoService extends FullControlService {

    constructor(clientId, secretId) {
        super(clientId, secretId);
    }


    ObterTipoEMarca() {
        return this.request({
            method: 'get',
            url: `Veiculo/ObterTipoEMarca`
        });
    }


    ObterVeiculos() {
        return this.request({
            method: 'post',
            url: `Veiculo/ObterVeiculos`,
            data: {}
        });
    }

    Incluir(veiculo) {

        if (!(veiculo instanceof Veiculo)) {
            throw new Error("Must to be a Veiculo Instance");
        }

        return this.request({
            method: 'post',
            url: `Veiculo/Incluir`,
            data: veiculo
        });
    }

    Atualizar(veiculo) {

        if (!(veiculo instanceof Veiculo)) {
            throw new Error("Must to be a Veiculo Instance");
        }

        return this.request({
            method: 'put',
            url: `Veiculo/Atualizar`,
            data: veiculo
        });
    }

}
