import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../styleVariables';
import DatePicker from './datePiker';

const ErrorDate = styled.div`
  width: 100%;
  span {
    font-size: 80%;
    font-weight: 400;
    color: ${colors.red};
  }
`;

const PeriodDate = ({setStartDate, setEndDate, startDate, endDate}) => (
    <div className="d-flex mt-3">
        <ErrorDate>
            <DatePicker
                onSelect={setStartDate}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
                className=""
                placeholderText="Selecione"
                maxDate={endDate || new Date()}
                name="startDate"
                selected={startDate}
                onChange={setStartDate}
            />
        </ErrorDate>
        <span className="text-gray mx-2">até</span>
        <ErrorDate>
            <DatePicker
                onSelect={setEndDate}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
                className=""
                placeholderText="Selecione"
                minDate={startDate}
                maxDate={new Date()}
                name="endDate"
                selected={endDate}
                onChange={setEndDate}
            />
        </ErrorDate>
    </div>
);

export default PeriodDate;
