import BaseService from "./base-service";

export default class ReportService extends BaseService {

    constructor() {
        super('reports');
    }

    getRouteReport = (params) => {
        return this.__get(`reports/route${params ? '?' + params : ''}`);
    }

    getEventReport = (params) => {
        return this.__get(`reports/events${params ? '?' + params : ''}`);
    }

    getTripReport = (params) => {
        return this.__get(`reports/trips${params ? '?' + params : ''}`);
    }

    getStopReport = (params) => {
        return this.__get(`reports/stops${params ? '?' + params : ''}`);
    }

    getSummaryReport = (params) => {
        return this.__get(`reports/summary${params ? '?' + params : ''}`);
    }

    externalReport = (type, params, asExport) => {
        const configs = {
            headers: {
                Accept:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
        };

        if (asExport) configs.responseType = 'blob';

        return this.__get(`reports/${type}?${params}`, configs);
    }

    list(query) {
        throw new Error("method not implemented");
    }

    update(data) {
        throw new Error("method not implemented");
    }

    save(data) {
        throw new Error("method not implemented");
    }

    remove(id) {
        throw new Error("method not implemented");
    }
}
