import React from 'react';
import DataTable from '../../components/dataTable';

const MaintenanceTable = (props) => {
    const {
        maintenanceForm,
        removeRegister,
        attributes,
        list,
        loading
    } = props;

    return (
        <DataTable
            add={maintenanceForm}
            edit={maintenanceForm}
            remove={removeRegister}
            list={list}
            loading={loading}
            attributes={attributes}
            keyField={'id'}
            confirmText={`Você está removendo a configuração <strong><field>name</field></strong>! </br>Realmente deseja fazer isso? `}
            headers={[
                {title: 'sharedName', field: 'name', dataSort: true},
                {title: 'sharedType', field: 'typeFmt', dataSort: true},
                {title: 'maintenanceStart', field: 'startFmt', dataSort: false},
                {title: 'maintenancePeriod', field: 'periodFmt', dataSort: false},
            ]}
        />
    );
};

export default MaintenanceTable;
