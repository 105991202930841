import {types} from 'mobx-state-tree';

const FCEnderecoPrincipal = types
    .model('FCEnderecoPrincipal', {
        cep : types.maybeNull(types.string),
        codigoIBGE : types.maybeNull(types.string),
        endereco : types.maybeNull(types.string),
        numero : types.maybeNull(types.string),
        bairro : types.maybeNull(types.string),
        complemento : types.maybeNull(types.string),
        cidade : types.maybeNull(types.string),
        uf : types.maybeNull(types.string),
        cidadeEstrangeiroId : types.maybeNull(types.number)
    })

const FCTelefone = types
    .model('FCTelefone', {
        ddd: types.maybeNull(types.string),
        ddi: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
    })

const FCContatoPrincipal = types
    .model('FCContatoPrincipal', {
        email : types.maybeNull(types.string),
        contatoPrincipalId : types.maybeNull(types.number),
        dataCadastro : types.maybeNull(types.string),
        telefone : types.maybeNull(FCTelefone),
        telefone2 : types.maybeNull(FCTelefone),
        celular : types.maybeNull(FCTelefone),
    })

const FCClientModel = types
    .model('CustomerModel', {
        id: types.maybeNull(types.number),
        pessoa: types.maybeNull(types.string),
        rg: types.maybeNull(types.string),
        cnpJ_CPF: types.maybeNull(types.string),
        im: types.maybeNull(types.string),
        ie: types.maybeNull(types.string),
        nome: types.maybeNull(types.string),
        nomeFantasia: types.maybeNull(types.string),
        codigoCliente: types.maybeNull(types.string),
        situacaoClienteId: types.maybeNull(types.number),
        tipoPessoa: types.maybeNull(types.number),
        dataCadastro: types.maybeNull(types.string),
        enderecoPrincipal : types.maybeNull(FCEnderecoPrincipal),
        contatoPrincipal : types.maybeNull(FCContatoPrincipal),
    })
    .views((self) => {
        return {
            getId() {
                return self.id;
            },
            getPessoa() {
                return self.pessoa;
            },
            getRg() {
                return self.rg;
            },
            getIm() {
                return self.im;
            },
            getIe() {
                return self.ie;
            },
            getNome() {
                return self.nome;
            },
            getNomeFantasia() {
                return self.nomeFantasia;
            },
            getTipoPessoa(){
                if(self.tipoPessoa){
                    switch (self.tipoPessoa){
                        case 1: return "PF"
                        case 2: return "PJ"
                        case 3: return "Estrangeiro"
                        case 4: return "Rural"
                    }
                }
                return null;
            }
        };
    })
    .actions((self) => ({
        setId(data) {
            self.id = data;
        },
        setPessoa(data) {
            self.pessoa = data;
        },
        setRg(data) {
            self.rg = data;
        },
        setIm(data) {
            self.im = data;
        },
        setIe(data) {
            self.ie = data;
        },
        setNome(data) {
            self.nome = data;
        },
        setNomeFantasia(data) {
            self.nomeFantasia = data;
        },
        setCodigo(data) {
            self.codigo = data;
        },
        setDataCadastro(data) {
            self.dataCadastro = data;
        }
    }));

export default FCClientModel;
