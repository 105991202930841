import qs from 'qs';

export const dataAsForm = (data) => ({
  data: qs.stringify(data),
  headers: {'content-type': 'application/x-www-form-urlencoded'},
});

export const Headers = {
  'application/x-www-form-urlencoded': () =>
    ({
      headers: {'content-type': 'application/x-www-form-urlencoded'},
    }()),
};

export const getToken = () => {
  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');

  if (token) return token;
  return null;
};

export const serialize = (obj) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};

export const serializeArray = (obj) => qs.stringify(obj, {indices: false});
