
export const getExternalMapURL = (type, position) => {
    const baseURL = 'https://www.google.com/maps/';
    const point = position.latitude + ',' + position.longitude;
    let URL = '';
    switch (type) {
        case 'street':
            URL = baseURL + '@?api=1&map_action=pano&viewpoint=' + point;
            break;
        case 'route':
            URL = baseURL + 'dir/?api=1&travelmode=walking&destination=' + point;
            break;
        default:
            break;
    }
    return URL;
};
