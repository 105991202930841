import {types} from 'mobx-state-tree';
import AlarmTypesModel from '../models/alarm-types-model';
import i18n from './translate-store';

const AllNotificationTypesModel = types

    .model('AlarmTypesStore', {
        types: types.optional(types.array(AlarmTypesModel), []),
    })
    .actions((self) => ({
        afterCreate() {
            let key;
            const items = [];
            for (key in i18n.strings) {
                if (i18n.strings.hasOwnProperty(key) && key.lastIndexOf('alarm', 0) === 0) {
                    items.push({
                        key: key.charAt(5).toLowerCase() + key.slice(6),
                        name: i18n.strings[key],
                    });
                }
            }
            self.setTypes(items);
        },
        setTypes(data) {
            self.types = data;
        },
    }));

export default AllNotificationTypesModel;

