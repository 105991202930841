import moment from 'moment';
import randomColor from 'randomcolor';
import {useCallback, useEffect, useState} from 'react';
import {mergeAll} from 'rxjs/operators';
import useSocket from '../../../hooks/use-socket';
import useStore from '../../../hooks/use-store';
import useServices from '../../../hooks/use-services';

const useMapEvent = (state, dispatch, instance) => {
    const {instance: map, deviceMap} = state;
    const [event, setEvent] = useState();
    const {positionService} = useServices();
    const {store} = useStore();
    const {events} = store;
    const {positions: positionsSocket} = useSocket();

    useEffect(() => {
        if (map) {
            map.initTraceEventLayer();
        }
    }, [map]);

    const doBuffer = useCallback(
        (positions) =>
            positions.map(({longitude, latitude, course}) => [
                longitude,
                latitude,
                course,
            ]),
        [],
    );

    useEffect(() => {
        let unsubscribe$;
        if (map && event && events.currentDevice) {
            unsubscribe$ = positionsSocket.pipe(mergeAll()).subscribe((position) => {
                if (event.deviceId === position.deviceId) {
                    events.addPositionInBuffer(position);
                    map.updateEventTrace(
                        +position.deviceId,
                        doBuffer(events.positionBuffer.toJSON()),
                    );
                }
            });
        }

        return () => {
            if (map && event) {
                map.removeFeaturesEventTrace();
                events.setBuffer([]);
                unsubscribe$ && unsubscribe$.unsubscribe();
            }
        };
    }, [map, event, events]);

    useEffect(() => {
        if (event && map && deviceMap) {
            map.removeFeaturesEventTrace();
            map.removeDevices();

            const {deviceId, serverTime} = event;
            const device = store.devices.findById(+deviceId);

            (async () => {
                let eventPosition = await positionService.getPositionsByIds([+event.positionId]);
                eventPosition = eventPosition[0];

                let positions = await positionService.getPositionsFromTo(
                    +deviceId,
                    moment(serverTime).toISOString(),
                    moment(device.lastUpdate).toISOString(),
                );

                positions = positions || [];
                if (!positions.length) {
                    positions = await positionService.getPositionsByIds([device.positionId]);
                }

                events.setCurrentDevice(+deviceId);
                events.setCurrent({...event});

                positions = positions.reverse();

                events.setBuffer([...positions, eventPosition]);
                events.setPosition(eventPosition);
                events.currentDevice.setCurrentPosition(positions[0]);

                // CREATE OVERLAY
                const {longitude, latitude} = eventPosition;
                const coord = [longitude, latitude];

                const overlay = map.instance.getOverlayById('overlayEvent');

                if (!overlay) {
                    map.addEventOverlay('popupEvent', coord);
                } else {
                    map.updateOverlayPosition(null, coord, overlay);
                }

                // ADD TRACE
                const color = randomColor({luminosity: 'dark'});

                map.addFeatureDevice(deviceMap.get(deviceId));
                map.addFeatureEventTrace(
                    deviceId,
                    doBuffer(events.positionBuffer.toJSON()),
                    color,
                );

                map.goTo(coord, 18);
            })();
        }
    }, [map, deviceMap, event]);

    return {setEvent};
};

export default useMapEvent;
