import {types} from 'mobx-state-tree';

const AuthModel = types
    .model('AuthModel', {
      isLogged: types.maybe(types.boolean),
      email: types.maybe(types.string),
      password: types.maybe(types.string),
      sessionToken: types.maybe(types.string),
      user: types.frozen({}),
      profile: types.maybe(types.enumeration(['admin', 'monitor', 'fleet', 'client'])),
    });

export default AuthModel;
