import React from 'react';
import {icons} from '../../helpers/conversions';
import {getBatteryPercentage} from '../../helpers/device';
import styled from 'styled-components';
import {colors} from '../../styleVariables';
import './index.sass';
import useFormatter from '../../hooks/user-formatter';
import I18nMessage from '../I18nMessage';

const DeviceFooterStyle = styled.div`
  margin-top: 5px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;

  * {
    opacity: 1;
  }

  .red * {
    color: ${colors.red1};
  }

  .yellow * {
    color: ${colors.orange1};
  }

  .green * {
    color: ${colors.green2};
  }

  .red * {
    color: ${colors.red};
  }

  .fal {
    font-size: 1.3rem;

    + small {
      font-size: 0.8rem !important;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1rem;

    :not(:last-child) {
      border-right: 1px solid ${colors.gray1};
    }

    span {
      color: ${colors.gray1};

      :first-child {
        margin-bottom: 0.1rem;
      }
    }

    small {
      color: ${colors.gray1};
      font-size: 0.8rem !important;
    }
  }
`;

const Battery = ({value}) => {
  const colors = [
    [70, 'green'],
    [40, 'yellow'],
    [0, 'red'],
  ];

  const icon = icons.find(([k]) => value >= k)[1];
  const color = colors.find(([k]) => value >= k)[1];

  return (
    <div className={`attribute-item battery flex-fill ${color}`}>
      <span className={icon} />
      <small><I18nMessage id={'positionBattery'} /></small>
      <small>
        <strong>{value}%</strong>
      </small>
    </div>
  );
};

const Blocked = ({status}) => (
  <div className={`attribute-item blocked flex-fill ${!status ? 'green' : ''}`}>
    <span className={`${status ? 'fal fa-lock' : 'fal fa-unlock'} fa-2x`} />
    <small><I18nMessage id={'positionBlocked'} /></small>
    <small>
      <strong>{!status ? <I18nMessage id={'sharedNotActivated'} /> : <I18nMessage id={'sharedActivated'} />}</strong>
    </small>
  </div>
);

const Alarm = ({status}) => (
  <div className={`attribute-item panic flex-fill ${!status ? undefined : 'red'}`}>
    <span className={`${status ? 'fal fa-siren-on' : 'fal fa-siren'} fa-2x`} />
    <small><I18nMessage id={'positionAlarm'} /></small>
    <small><strong>{status ? <I18nMessage id={'sharedActivated'} /> : <I18nMessage id={'sharedNotActivated'} />}</strong></small>
  </div>
);

const Ignition = ({status}) => (
  <div className={`attribute-item ignition flex-fill ${status ? 'green' : undefined}`}>
    <span className="fal fa-key fa-2x" />
    <small><I18nMessage id={'positionIgnition'} /></small>
    <small><strong>{status ? <I18nMessage id={'deviceStatusOnline'} /> : <I18nMessage id={'deviceStatusOffline'} />}</strong></small>
  </div>
);

const Odometer = ({value}) => (
  <div className={`attribute-item odometer flex-fill ${value ? 'green' : undefined}`}>
    <span className="fal fa-digital-tachograph fa-2x" />
    <small><I18nMessage id={'positionOdometer'} /></small>
    <small><strong>{value}</strong></small>
  </div>
);

const Runtime = ({value}) => (
  <div className={`attribute-item runtime flex-fill ${value ? 'green' : undefined}`}>
    <span className="fal fa-clock fa-2x" />
    <small><I18nMessage id={'positionHours'} /></small>
    <small><strong>{value}</strong></small>
  </div>
);


const Power = ({value}) => (
  <div className={`attribute-item network flex-fill ${value ? 'green' : undefined}`}>
    <span className="fal fal fa-car-battery fa-2x" />
    <small><I18nMessage id={'positionPower'} /></small>
    <small><strong>{value}</strong></small>
  </div>
);


const DeviceInfoAttributes = (props) => {
  const {formatValue} = useFormatter();
  const {currentPosition, theme, className} = props;
  const attributes =
        currentPosition && currentPosition.attributes ?
            currentPosition.attributes :
            {
              batteryLevel: 0,
              battery: 0,
              blocked: undefined,
              ignition: undefined,
              alarm: undefined,
              odometer: undefined,
              runtime: undefined,
              hours: undefined,
              power: undefined,
            };

  const {
    batteryLevel,
    battery,
    blocked,
    ignition,
    alarm,
    odometer,
    runtime,
    hours,
    power,
  } = attributes;

  const batteryValue = batteryLevel ?
        batteryLevel :
        battery ?
            getBatteryPercentage(battery).toFixed(0) :
            null;

  return (
    <DeviceFooterStyle theme={theme} className={`row ${className}`}>
      {ignition !== undefined && <Ignition status={ignition} />}
      {blocked !== undefined && <Blocked status={blocked} />}
      {batteryValue && <Battery value={batteryValue} />}
      {odometer !== undefined && <Odometer value={formatValue('odometer', odometer)} />}
      {runtime !== undefined || hours !== undefined && <Runtime value={formatValue('hours', runtime || hours)} />}
      {power !== undefined && <Power value={formatValue('power', power)} />}
      <Alarm status={alarm} />
    </DeviceFooterStyle>
  );
};

export default DeviceInfoAttributes;
