/* eslint-disable require-jsdoc */
import {useMemo} from 'react';

export function useDeviceModels(devices) {
    return useMemo(() => devices.reduce((accu, device) => {
        const isModelAlreadyMapped = accu.find((model) => model.id === device.uniqueId);

        if (isModelAlreadyMapped) return accu;

        accu.push({
            id: device.uniqueId,
            title: device.name,
        });

        return accu;
    }, []), [devices]);
}
