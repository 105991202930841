import {observer} from 'mobx-react';
import momentTimezone from 'moment-timezone';
import qs from 'qs';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Link, useParams} from 'react-router-dom';
import {Container} from 'reactstrap';
import {from, fromEvent} from 'rxjs';
import {debounceTime, share} from 'rxjs/operators';
import {ThemeContext} from '../../apiContexts';
import InputSearchWithRef from '../../components/inputSearch/inputSearchWithRef';
import LoadingBar from '../../components/loadingBar';
import ReactTable from '../../components/reactTable';
import ReactExport from 'react-export-excel';
import RoundButton from '../../components/roundButton';
import {
  activeLabel,
  communicationLabel,
  communicationTime,
  createLabelIcon,
  getCurrentCategories,
  getDateGroup,
  getDeviceActiveGroup,
  getGroupByCategoryStatus,
  getSinisterGroup,
  getStatusGroup,
  sinisterDurationLabel,
  statusLabel,
} from '../../helpers/admin';
import {getOffsetTo} from '../../helpers/DOM';
import {getColumnWidth} from '../../helpers/report';
import useStore from '../../hooks/use-store';
import history from '../../routes/history';
import {ReactSelectStyled} from '../../styles';
import {Header, ListStyle} from '../../views/reports/styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const activeOptions = activeLabel.map((l) => ({
  id: l,
  title: l,
}));

const communicationOptions = communicationLabel.map((l, i) => ({
  id: communicationTime[i] || 'never',
  title: l,
}));

const stateOptions = statusLabel.map((l) => ({
  id: l === 'Desconhecido' ? 'unknown' : l,
  title: l,
}));

const sinisterOptions = sinisterDurationLabel.map((l) => ({
  id: l,
  title: l,
}));

const typeOptions = [
  {id: 'device', title: 'Dispositivos Cadastrados'},
  {id: 'communication', title: 'Última Comunicação'},
  {id: 'state', title: 'Status de Conexão'},
  {id: 'status', title: 'Conexão dos Dispositivos'},
  {id: 'sinister', title: 'Sinistro dos Dispositivos'},
];

const AdminDetailChartContainer = ({location, ...props}) => {
  const {store} = useStore();
  const {server, session, devices, sinisterStore} = store;
  const theme = useContext(ThemeContext);
  const {type} = useParams();
  const intl = useIntl();

  const memoizedCategoryOptions = useRef();
  const isPrepared = useRef(false);
  const chartTypeRef = useRef(null);
  const chartTypeGroupRef = useRef(null);
  const inputRef = useRef();
  const containerRef = useRef();
  const tableRef = useRef();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState();
  const [typeOption, setTypeOption] = useState();
  const [statusOption, setStatusOption] = useState();
  const [offset, setOffset] = useState();

  const options = useMemo(() => {
    if (!(typeOption && devices.list.length)) return;
    switch (typeOption.id) {
      case 'device':
        return activeOptions;
      case 'communication':
        return communicationOptions;
      case 'state':
        return stateOptions;
      case 'sinister':
        return sinisterOptions;
      case 'status':
        memoizedCategoryOptions.current = getCurrentCategories(devices.list).reduce(
          (acc, cur) => [
            ...acc,
            ...statusLabel.map((l) => ({
              id: `${cur}-${l === 'Desconhecido' ? 'unknown' : l}`,
              title: `${intl.formatMessage({
                id: `category_${cur}`,
              })} - ${l}`,
            })),
          ],
          [],
        );
        return memoizedCategoryOptions.current;
    }
  }, [typeOption?.id, devices.list]);

  const query = useMemo(
      () =>
          qs.parse(location.search, {
            ignoreQueryPrefix: true,
            comma: true,
          }),
      [location.search],
  );

  const timezone = useMemo(
      () =>
          session.attributes.hasOwnProperty('timezone') ?
              session.attributes.timezone :
              server.attributes.hasOwnProperty('timezone') ?
                  server.attributes.timezone :
                  'America/Fortaleza',
      [],
  );

  const columns = useMemo(
      () => [
        {
          Header: '#',
          accessor: 'category',
          Cell: ({cell: {value}}) => createLabelIcon(value),
          width: 60,
        },
        {
          Header: 'Nome',
          accessor: 'name',
          minWidth: 200,
          width: getColumnWidth(data, 'name', 'Nome'),
        },
        {
          Header: 'Data do servidor',
          accessor: 'lastUpdate',
          Cell: ({cell: {value}}) =>
              !value ?
                  'D/N' :
                  momentTimezone(value)
                      .tz(timezone)
                      .format('DD/MM/YYYY [-] HH:mm:ss'),
          minWidth: 200,
        },
        {
          Header: 'Data do dispositivo',
          accessor: (d) => d.currentPosition,
          Cell: ({cell: {value}}) =>
              !value ?
                  'D/N' :
                  momentTimezone(value.deviceTime)
                      .tz(timezone)
                      .format('DD/MM/YYYY [-] HH:mm:ss'),
          minWidth: 200,
        },
        {
          Header: 'Data do GPS',
          accessor: (d) => d.currentPosition,
          Cell: ({cell: {value}}) => !value ?
                  'D/N' :
                  momentTimezone(value.fixTime)
                      .tz(timezone)
                      .format('DD/MM/YYYY [-] HH:mm:ss'),
          minWidth: 200,
        },
        {
          Header: 'Endereço',
          accessor: (d) => d.currentPosition,
          Cell: ({cell: {value}}) => (value ? value.address || '-' : '-'),
          minWidth: 150,
          width: getColumnWidth(
              data,
              ({currentPosition}) =>
                  currentPosition ? currentPosition.address : '',
              'Endereço',
          ),
        },
      ],
      [data],
  );

  useEffect(() => {
    const _typeOption = typeOptions.find((e) => e.id === type);
    setTypeOption(_typeOption);
  }, []);

  useEffect(() => {
      const initialType = type;
      const initialQuery = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        comma: true,
      });

      if (options) {
        const selectedTypeOption = typeOptions.find((to) => to.id === initialType);
        const selectedGroup = options.find((o) => o.id === initialQuery.group);
        chartTypeRef.current.setValue(selectedTypeOption);
        chartTypeGroupRef.current.setValue(selectedGroup);
        isPrepared.current = true;
      }
  }, [options !== undefined]); // trick to trigger effect again on options defined

  useEffect(() => {
    if(isPrepared.current) {
      chartTypeGroupRef.current.setValue(options[0]);
    }
  }, [options]);

  useEffect(() => {
    fromEvent(inputRef.current, 'input')
        .pipe(debounceTime(500))
        .subscribe((value) => {
          value = value.target.value;
          if (!value) setFiltered(null);
          else {
            setFiltered(
                data.filter(({name}) =>
                    String(name)
                        .toUpperCase()
                        .includes(String(value).toUpperCase()),
                ),
            );
          }
        });
  }, [data]);

  useEffect(() => {
    setLoading(true);
    if (devices.updatedPositions) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [devices.updatedPositions]);

  const headerTitle = useMemo(() => {
    if (type) {
      switch (type) {
        case 'communication':
          return 'Última Comunicação';
        case 'state':
          return 'Conexão dos Dispositivos';
        case 'device':
          return 'Dispositivos Cadastrados';
        case 'sinister':
          return 'Sinistros abertos';
        default:
          return 'Status de Conexão';
      }
    }
  }, [type]);

  const sortByLastUpdate = (data) => {
    return data.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.lastUpdate) - new Date(a.lastUpdate);
    });
  };

  const sortByCreatedAt = (data) => {
    return data.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  useEffect(() => {
    if (statusOption && devices.list.length) {
      const source$ = from(devices.list).pipe(share());
      setLoading(true);
      switch (type) {
        case 'device':
          getDeviceActiveGroup(source$).subscribe((dateGroup) => {
            const data = sortByLastUpdate(dateGroup[String(statusOption.id).toLowerCase()]);
            setData(data);
            setLoading(false);
          });
          break;
        case 'communication':
          getDateGroup(source$, communicationTime).subscribe((dateGroup) => {
            const data = sortByLastUpdate(dateGroup[statusOption.id]);
            setData(data);
            setLoading(false);
          });
          break;
        case 'state':
          getStatusGroup(source$).subscribe((dateGroup) => {
            const data = sortByLastUpdate(dateGroup[String(statusOption.id).toLowerCase()]);
            setData(data);
            setLoading(false);
          });
          break;
        case 'status':
          const [category, status] = statusOption.id.split('-');
          getGroupByCategoryStatus(source$, category, status).subscribe(
              (dateGroup) => {
                setData(sortByLastUpdate(dateGroup));
                setLoading(false);
              },
          );
          break;
        case 'sinister':
          if (sinisterStore.incidents.length > 0 && devices.list.length) {
            getSinisterGroup(from(sinisterStore.incidents.filter((s) => s.status === 'opened')).pipe(share()))
                .subscribe((incidents) => {
                  const data = sortByCreatedAt(incidents[statusOption.id]);

                  const newDevices = data.map(( sinister )=> devices.findById(sinister.deviceId));

                  setData(newDevices);
                  setLoading(false);
                });
          }
          break;
        default:
          break;
      }
    }
  }, [statusOption, devices.list, devices.updatedPositions, sinisterStore.incidents.length]);

  const selectItem = ({data}) => {
    if (['unknown', 'never'].includes(query.group)) return;
    history.push(`/admin/events/devices/${data.id}`);
  };

  const changeType = (value) => {
    if(value !== null) {
      setTypeOption(value);
    }
  };

  const changeOption = (value) => {
    if(value !== null) {
      setStatusOption(value);
      if(!chartTypeRef.current?.getValue()) return;
      history.push(`/admin/chart/${chartTypeRef.current.getValue().id}?group=${value.id}`);
    }
  };

  useEffect(() => {
    setOffset(getOffsetTo(tableRef.current, containerRef.current));
  });

  const getlistHeight = useCallback(() => {
    if (!containerRef.current) return 0;
    return containerRef.current.clientHeight - 32 - offset;
  }, [tableRef.current]);

  return (
      <Container fluid className="px-4 pb-4 position-relative h-100">
        <ListStyle
            ref={containerRef}
            theme={theme}
            className="custom-shadow-2 position-relative w-100 h-100"
            style={{bottom: 0}}
        >
          <Header className="mb-4">
            <h5>{headerTitle}</h5>
            <RoundButton className="mt-3 mb-3" tag={Link} to={'/admin'}>
              <span className="fas fa-times text-secondary"/>
            </RoundButton>
          </Header>

          <div className="d-flex">
            <ReactSelectStyled
                ref={chartTypeRef}
                radius="9px"
                scoped={false}
                options={typeOptions}
                name="chartType"
                noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                placeholder="Escolha um tipo"
                className="w-100 mb-3"
                onChange={changeType}
                isClearable={false}
                value={typeOption}
            />

            <div className="px-2"/>

            <ReactSelectStyled
                ref={chartTypeGroupRef}
                radius="9px"
                scoped={false}
                options={options}
                name="chartTypeGroup"
                noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                placeholder="Escolha um status"
                className="w-100 mb-3"
                onChange={changeOption}
                isClearable={false}
                value={statusOption}
            />
          </div>

          <div className="row py-4">
            <div style={{width: '90%', padding: 10}}>
              <InputSearchWithRef
                  placeholder="Filtre por dispositivos"
                  inputRef={inputRef}
              />
            </div>
            <ExcelFile element={
              <RoundButton className="mt-3 mb-3 float-right"
                           style={{right: '-20px', top: '-12px', position: 'relative'}}>
                <span className="fas fa-download"/>
              </RoundButton>
            }>
              <ExcelSheet data={filtered || data} name={`${intl.formatMessage({id: 'deviceTitle'})}`}>
                <ExcelColumn label={`${intl.formatMessage({id: 'sharedName'})}`} value="name"/>
                <ExcelColumn label={`${intl.formatMessage({id: 'sharedDate'})}`}
                             value={(col) => !col.lastUpdate ?
                                 'D/N' :
                                 momentTimezone(col.lastUpdate)
                                     .tz(timezone)
                                     .format('DD/MM/YYYY [-] HH:mm:ss')}/>
                <ExcelColumn label={`${intl.formatMessage({id: 'positionAddress'})}`}
                             value={(col) => col.currentPosition ? (col.currentPosition.address || '-') : '-'}/>
              </ExcelSheet>
            </ExcelFile>
          </div>

          {loading ? (
              <LoadingBar/>
          ) : (
              <div ref={tableRef}>
                <ReactTable
                    data={filtered || data}
                    columns={columns}
                    selectItem={selectItem}
                    listHeight={getlistHeight()}
                />
              </div>
          )}
        </ListStyle>
      </Container>
  );
};

export default observer(AdminDetailChartContainer);
