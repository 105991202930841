import {View} from 'ol';
import {defaults as defaultControls} from 'ol/control.js';
import {Tile as TileLayer} from 'ol/layer';
import OSM from 'ol/source/OSM';
import {useEffect, useReducer, useRef, useState} from 'react';
import useDevices from '../../../hooks/use-devices';
import MapInstance from '../MapInstance';
import {actions, initialState, reducer} from '../reducers';

const useMap = (props = {}, ...plugins) => {
    const [selectedMap, setSelectedMap] = useState();

    const instance = useRef({
        props: {
            center: [0, 0],
            zoom: 4,
            tiers: [],
            initAllDevices: false,
            cluster: {distance: 0},
            ...props,
        },
    });

    const {devices} = useDevices();

    const [state, dispatch] = useReducer(reducer, initialState);

    const {target, center, zoom, tiers} = instance.current.props;

    const pluginActions = plugins.reduce(
        (actions, plugin) => ({
            ...actions,
            ...plugin(state, dispatch, instance),
        }),
        {},
    );

    useEffect(() => {
        if (selectedMap) state.instance.setMap(selectedMap);
    }, [selectedMap]);

    useEffect(() => {
        if (target && target.current) {
            const view = new View({center, zoom});

            const osm = new TileLayer({
                source: new OSM(),
                visible: false,
                name: 'osm',
            });

            const tiles = Object.entries(tiers).reduce(
                (acc, [name, url]) => [...acc, MapInstance.createTileXYZ(url, name)],
                [],
            );

            const options = {
                view,
                layers: [osm, ...tiles],
                loadTilesWhileInteracting: true,
                loadTilesWhileAnimating: true,
                controls: defaultControls({
                    attribution: false,
                    zoom: false,
                    rotate: false,
                }),
                target: target.current,
            };

            const map = new MapInstance(options);

            dispatch({
                type: actions.SET_INSTANCE,
                payload: map,
            });

            setSelectedMap('street');

            instance.current = {
                ...instance.current,
                instance: map,
                pluginActions,
            };
        }
    }, [target]);

    useEffect(() => {
        if (devices.list.length && !state.deviceMap) {
            const deviceMap = new Map();

            [...devices.devices.values()].forEach((d) => {
                deviceMap.set(d.id, d);
            });

            dispatch({
                type: actions.SET_DEVICE_MAP,
                payload: deviceMap,
            });
        }
    }, [devices.list.length]);

    return {
        ...instance.current,
        ...pluginActions,
        selectedMap,
        setSelectedMap,
        state,
    };
};

export default useMap;
