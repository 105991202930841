/* eslint-disable require-jsdoc */
import React, {useEffect, useState, useContext, useRef} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {Col, Row} from 'reactstrap';
import InputSearch from '../../components/inputSearch';
import ReactSelect from '../../components/react-select';
import DeviceListItem from './device-list-item';
import {ThemeContext} from '../../apiContexts';
import {useDeviceModels} from './hooks/useDeviceModels';
import {useDeviceStatuses} from './hooks/useDeviceStatuses';
import {useDeviceFilter} from './hooks/useDeviceFilter';

const DeviceListExpanded = ({list = [], selected, onSelect, ...props}) => {
    const [currentList, setCurrentList] = useState(list);

    const theme = useContext(ThemeContext);

    function onFilter(value) {
        setCurrentList(value);
    }

    return (
        <div id="deviceListExpanded">
            <div className="shadow h-100">
                <Bar onFilter={onFilter} list={list}/>
                <Scrollbars style={{height: 400}}>
                    <Row id="items" className="py-2">
                        {currentList.map(({name, currentPosition, id, category, status}) => (
                            <Col key={id} md="3">
                                <DeviceListItem
                                    theme={theme}
                                    category={category}
                                    status={status}
                                    id={id}
                                    name={name}
                                    currentPosition={currentPosition}
                                    onSelect={onSelect}
                                    active={selected && selected.id === id}
                                />
                            </Col>
                        ))}
                    </Row>
                </Scrollbars>
            </div>
        </div>
    );
};

const Bar = ({onFilter, list}) => {
    const isMounted = useRef(false);
    const deviceModels = useDeviceModels(list);
    const deviceStatuses = useDeviceStatuses(list);
    const {filteredDevices, dispatch} = useDeviceFilter(list);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            onFilter(filteredDevices);
        }
    }, [filteredDevices]);

    return (
        <Row id="bar" className="ml-0 mr-0">
            <Col md="4" style={{borderRight: '2px solid #b8b8b8'}}>
                <InputSearch
                    placeholder="Pesquise para filtrar"
                    onChange={({target}) => {
                        dispatch({type: 'SET_TEXT', payload: {text: target.value}});
                    }}
                />
            </Col>
            <Col className="d-flex align-items-center">
                <strong className="w-50 text-left mr-1">Filtar por: </strong>
                <ReactSelect
                    options={deviceModels}
                    scoped={false}
                    name="typeDevice"
                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                    placeholder="Dispositivo"
                    className="w-100 mr-1"
                    isClearable
                    onChange={(item) => {
                        if(item === null) {
                            dispatch({type: 'CLEAR_MODEL'});
                            return;
                        }
                        dispatch({type: 'SET_MODEL', payload: {model: item.id}});
                    }}
                />
                <ReactSelect
                    options={deviceStatuses}
                    name="typeDevice"
                    scoped={false}
                    noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                    placeholder="Estado"
                    className="w-100 ml-1"
                    isClearable
                    onChange={(item) => {
                        if(item === null) {
                            dispatch({type: 'CLEAR_STATUS'});
                            return;
                        }
                        dispatch({type: 'SET_STATUS', payload: {status: item.id}});
                    }}
                />
            </Col>
        </Row>
    );
};

export default DeviceListExpanded;
