import React from 'react';
import styled from 'styled-components';
import {colors} from '../../../styleVariables';

const Style = styled.div`
  display: flex;
  padding: 0 1rem;
  line-height: 1rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;

  > div {
    display: flex;
    flex-direction: column;
    text-align: left;

    > strong {
      color: ${(props) => props.theme};
    }

    span {
      color: ${colors.gray} !important;
    }
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

const Driver = ({theme, driver}) => {
  return (
    <Style className="mt-1" theme={theme}>
      <div>
        <strong className="mb-1">Motorista</strong>
        <span>{ driver ? driver.name : 'Não identificado'}</span>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/default.jpg`}
        alt="profile"
        className="rounded-circle"
      />
    </Style>
  );
};

export default Driver;
