import React, {useEffect, useRef, useState} from 'react';
import {useField} from '@unform/core';
import Switch from 'react-switch';

import './style.sass';
import PropTypes from 'prop-types';

const ToggleButton = (props) => {
  const {
    name,
    onChange,
    disabled,
  } = props;

  const ref = useRef(null);
  const {fieldName, registerField, defaultValue} = useField(name);
  const [checked, onChangeChecked] = useState(defaultValue || false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.checked',
    });
    }, [ref.current, fieldName]); // eslint-disable-line

  const handleOnChange = (checked) =>{
    onChangeChecked(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <Switch
      name={fieldName}
      disabled={disabled}
      ref={ref}
      onChange={ (checked) => handleOnChange(checked)}
      checked={checked}
      onColor="#86d3ff"
      onHandleColor="#2693e6"
      handleDiameter={30}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      className="switch-button" />
  );
};

ToggleButton.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ToggleButton.defaultProps = {
  disabled: false,
};

export default ToggleButton;
