import {types} from 'mobx-state-tree';
import KnownCommandModel from '../models/known-command-model';
import i18n from './translate-store';

const KnownCommandsStore = types
    .model('KnownCommandsStore', {
      commands: types.optional(types.array(KnownCommandModel), []),
    })
    .actions((self) => ({
      afterCreate() {
        self.setCommands([{
          type: 'custom',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'positionPeriodic',
          parameters: [{
            key: 'frequency',
            name: i18n.strings.commandFrequency,
            valueType: 'number',
            allowDecimals: false,
            minValue: 0,
            dataType: 'frequency',
          }],
        }, {
          type: 'setTimezone',
          parameters: [{
            key: 'timezoneName',
            name: i18n.strings.commandTimezone,
            valueType: 'string',
            dataType: 'timezone',
          }],
        }, {
          type: 'sendSms',
          parameters: [{
            key: 'phone',
            name: i18n.strings.commandPhone,
            valueType: 'string',
          }, {
            key: 'message',
            name: i18n.strings.commandMessage,
            valueType: 'string',
          }],
        }, {
          type: 'sendUssd',
          parameters: [{
            key: 'phone',
            name: i18n.strings.commandPhone,
            valueType: 'string',
          }],
        }, {
          type: 'sosNumber',
          parameters: [{
            key: 'index',
            name: i18n.strings.commandIndex,
            valueType: 'number',
            allowDecimals: false,
            minValue: 0,
          }, {
            key: 'phone',
            name: i18n.strings.commandPhone,
            valueType: 'string',
          }],
        }, {
          type: 'silenceTime',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'setPhonebook',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'voiceMessage',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'outputControl',
          parameters: [{
            key: 'index',
            name: i18n.strings.commandIndex,
            valueType: 'number',
            allowDecimals: false,
            minValue: 0,
          }, {
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'voiceMonitoring',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'setAgps',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'setIndicator',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'configuration',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'setConnection',
          parameters: [{
            key: 'server',
            name: i18n.strings.commandServer,
            valueType: 'string',
          }, {
            key: 'port',
            name: i18n.strings.commandPort,
            valueType: 'number',
            allowDecimals: false,
            minValue: 1,
            maxValue: 65535,
          }],
        }, {
          type: 'setOdometer',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'modePowerSaving',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'modeDeepSleep',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'movementAlarm',
          parameters: [{
            key: 'radius',
            name: i18n.strings.commandRadius,
            valueType: 'number',
            allowDecimals: false,
            minValue: 0,
          }],
        }, {
          type: 'alarmBattery',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'alarmSos',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'alarmRemove',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'alarmClock',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'alarmSpeed',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'alarmFall',
          parameters: [{
            key: 'enable',
            name: i18n.strings.commandEnable,
            valueType: 'boolean',
          }],
        }, {
          type: 'alarmVibration',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'alarmIdle',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'motionInterval',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }, {
          type: 'stopInterval',
          parameters: [{
            key: 'data',
            name: i18n.strings.commandData,
            valueType: 'string',
          }],
        }]);
      },
      setCommands( data ) {
        self.commands = data;
      },
      getCommand( type ) {
        return self.commands.find( (c) => c.type === type);
      },
    }));

export default KnownCommandsStore;

