import FullControlService from "./full-control-service";
import SIMCard from "./model/veiculo";
import {serialize} from '../../helpers/request';

export default class FullControlSimCardService extends FullControlService {

    constructor(clientId, secretId) {
        super(clientId, secretId);
    }


    ObterPorId(simCardId) {
        return this.request({
            method: 'get',
            url: `SIMCard/ObterPorId?${serialize({ simCardId })}`
        });
    }


    ObterSIMCards(numeroChip, numeroLinha, operadoraId, skip, take) {
        return this.request({
            method: 'post',
            url: `SIMCard/ObterSIMCards?${serialize({ numeroChip, numeroLinha, operadoraId, skip, take  })}`,
            data: {}
        });
    }

    CadastrarOuAtualizar(simCard) {

        if (!(simCard instanceof SIMCard)) {
            throw new Error("Must to be a SIMCard Instance");
        }

        return this.request({
            method: 'post',
            url: `SIMCard/CadastrarOuAtualizar`,
            data: simCard
        });
    }

    Atualizar(simCard) {

        if (!(simCard instanceof simCard)) {
            throw new Error("Must to be a SIMCard Instance");
        }

        return this.request({
            method: 'put',
            url: `/SIMCard/Atualizar`,
            data: simCard
        });
    }

}
