import React, {useEffect, useRef, useState} from 'react';
import mStyles from './image.module.sass';
import Skeleton from '../skeleton/skeleton';

const ImageComponent = ({
    src,
    className,
    skeletonProps,
    kind = 'rectangular', // rectangular | circular
    ...rest
}) => {
    const imagePreload = useRef(new Image());
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        imagePreload.current.onload = function() {
            setIsImageLoaded(true);
        };
        imagePreload.current.onerror = function() {
            setIsImageLoaded(false);
            console.log('error on load image from: ', src);
        };
        imagePreload.current.src = src;
    }, [src]);

    if (!isImageLoaded) {
        return {
            rectangular: <Skeleton.Rectangular {...skeletonProps} />,
            circular: <Skeleton.Circular {...skeletonProps} />,
        }[kind];
    };

    return <img {...rest} className={`image ${className}`} src={src} />;
};


export default ImageComponent;
