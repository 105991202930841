import {useEffect, useState} from 'react';
import {from} from 'rxjs';
import {last, mergeAll, tap} from 'rxjs/operators';
import useDevices from '../../../hooks/use-devices';
import useSocket from '../../../hooks/use-socket';
import {actions} from '../../../components/map/reducers';

let unsubscribe$ = null;
let followValue;

const useMapPosition = (state, dispatch, instance) => {
  const {positions} = useSocket();
  const [showDevices, setShowDevices] = useState(true);
  const [follow, setFollow] = useState(false);
  const {devices} = useDevices();
  const {instance: map, deviceMap} = state;

  useEffect(() => {
    followValue = follow;
  }, [follow]);

  useEffect(() => {
    return () => {
      unsubscribe$ && unsubscribe$.unsubscribe();
    };
  }, []);

  /**
   *
   *
   */
  useEffect(() => {
    if (map && deviceMap) {
      map.initDevicesLayer();
      if (devices.updatedPositions) {
        from(devices.list)
            .pipe(
                tap((device) => {
                  if (instance.current.props.initAllDevices) {
                    map.addFeatureDevice(device);
                  }
                }),
                last(),
            )
            .subscribe((_) => {
              dispatch({
                type: actions.SET_INITIALIZED_POSITIONS,
                payload: devices.updatedPositions ? devices.updatedPositions : false,
              });
              const distance = instance.current.props.cluster.distance;
              const centerOnCluster = instance.current.props.centerOnCluster;
              if (distance) map.defineDeviceCluster(distance, centerOnCluster);
            });
      }
    }
  }, [map, deviceMap, devices.updatedPositions]);

  useEffect(() => {
    if (map && deviceMap) {
      if (showDevices && instance.current.props.initAllDevices) {
        for (const device of deviceMap.values()) {
          map.addFeatureDevice(device);
        }
      }
      unsubscribe$ = positions.pipe(mergeAll()).subscribe((position) => {
        devices.updatePosition(position);
        try {
          const device = deviceMap.get(+position.deviceId);
          const selected = devices.selectedDevice;
          map.updateDevicePosition(device);
          if (followValue && selected && +device.id === selected.id) {
            const {longitude, latitude} = device.currentPosition;
            map.goTo([longitude, latitude]);
          }
        } catch (error) {}
      });
    } else {
      if (unsubscribe$) unsubscribe$.unsubscribe();

      if (map) map.removeDevices();
    }
  }, [map, deviceMap, showDevices]);

  return {showDevices, setShowDevices, follow, setFollow};
};

export default useMapPosition;
