import React from 'react';
import DeviceListItem from './device-list-item';
import {DeviceListStyle} from '../styles.js';
import PropTypes from 'prop-types';

const DeviceList = (props) => {
  const {
    devices,
    onSelect,
  } = props;

  return (
    <DeviceListStyle id="device-list">
      {devices.list.map((e) => (
        <DeviceListItem key={e.id} {...e} onSelect={onSelect} />
      ))}
    </DeviceListStyle>
  );
};


export default DeviceList;

DeviceList.propTypes = {
  devices: PropTypes.object,
  onSelect: PropTypes.func,
};
