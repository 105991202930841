import React, {Component, useContext, useRef} from 'react';
import {Button} from 'reactstrap';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';
import {Typeahead as T} from 'react-bootstrap-typeahead';

export const initSearchData = {
  emptyLabel: 'Nada encontrado!',
  minLength: 2,
  align: 'right',
  caseSensitive: false,
  ignoreDiacritics: false,
  labelKey: 'name',
  filterBy: ['uniqueId', 'name'],
  options: [],
  renderMenuItemChildren: ({uniqueId, name}) => (
    <div>
      {name}
      <div>
        <small>Id: {uniqueId}</small>
      </div>
    </div>
  ),
};

const animationStyle = {
  transition: 'width 0.75s cubic-bezier(0.000, 0.795, 0.000, 1.000)'
};

const makeExpanding = (Target) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {isOpen: false};
    }

    onClick = () => {
      this.setState({isOpen: !this.state.isOpen});
    };

    render() {
      return (
          <Target {...this.props}
                  isOpen={this.state.isOpen}
                  onClick={this.onClick}
                  additionalStyles={{text: animationStyle, frame: animationStyle}}/>
      );
    }
  }
};

const Typeahead = styled(T)`
  .rbt-input-hint-container {
    width: 300px;
    display: ${(props) => props.display}
  }
  
  .rbt-input-main {
    border-radius: 50px;
    height: 3rem;
    width: 300px
  }
  
 .rbt-menu > li a {
   width: 300px;
  }
  
  ul.rbt-menu {
    z-index: -2 !important;
    padding-top: 40px !important;
    top: -27px !important;
    left: 0px !important;
    max-height: 340px !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  div.rbt-input-hint {
    display: none !important;
  }
  
  
`;

const StyledButton = styled(Button)`
  position: absolute;
  height: 3rem;
  width: 3rem;
  display: flex;
  z-index:2;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.4) !important;
  color: ${(props) => props.theme} !important;

  &.active {
    background-color: ${(props) => props.theme} !important;
    color: white !important;
  }

  :hover {
    color: white !important;
    background-color: ${(props) => props.theme} !important;

    & > span {
      color: white !important;
    }
  }

  > span {
    font-size: 1.5rem;
  }
`;

const SearchBox = ({isOpen, onClick, additionalStyles, className, ...props}) => {
  const typeaheadRef = useRef(null);
  const theme = useContext(ThemeContext);
  const display = isOpen ? 'initial' : 'none';

  const onHandleClick = (onClick) => {
    typeaheadRef.current.clear();
    onClick();
  };

  return (
    <div
      style={{pointerEvents: 'all', height: '3rem', minWidth: '3rem'}}
      className={`${className} d-flex flex-column justify-content-end align-items-end`}
    >
      <StyledButton
        outline
        className={`rounded-circle shadow-sm`}
        onClick={ () => onHandleClick(onClick)}
        theme={theme}
        withborder={0}>
        <span className="fal fa-search" />
      </StyledButton>
      <Typeahead
        id={'device-search'}
        ref={typeaheadRef}
        {...props} display={display} />
    </div>
  );
};

export default makeExpanding(SearchBox);
