import React, {forwardRef, useImperativeHandle, useEffect, useRef, useState} from 'react';
import Chevron from './chevron';

import './accordion.css';

const Accordion = forwardRef((props, ref) => {
    const [setActive, setActiveState] = useState('');
    const [setHeight, setHeightState] = useState('0px');
    const [setRotate, setRotateState] = useState('accordion__icon');

    const content = useRef(null);

    const toggle = () => {
        setActiveState(setActive === '' ? 'active' : '');
        setHeightState(
            setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
        );
        setRotateState(
            setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate',
        );
    };

    useImperativeHandle(ref, () => ({

        toggleAccordion() {
            toggle();
        },
        dispatchAccordionResize() {
            content.current.dispatchEvent(new Event('resize'));
        },

    }));

    useEffect(() => {
        content.current.addEventListener('resize', function(event) {
            setHeightState(`${content.current.scrollHeight}px`);
        });

        return () => {
            content.current.removeEventListener('resize');
        };
    }, []);

    return (
        <div className="accordion__section">
            <button className={`accordion ${setActive}`} onClick={toggle}>
                <p className="accordion__title">{props.title}</p>
                {props.chevron ? props.chevron(setRotate, 13, '#777') :
                    <Chevron className={`${setRotate}`} width={13} fill={'#777'}/>}
            </button>
            <div
                ref={content}
                style={{maxHeight: `${setHeight}`}}
                className="accordion__content"
            >
                {props.content ? <div className="accordion__text" dangerouslySetInnerHTML={{__html: props.content}}>
                </div> : <div className="accordion__text">
                    {props.component}
                </div>}

            </div>
        </div>
    );
});

export default Accordion;
