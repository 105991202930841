import React from 'react';
import DataTable from '../../components/dataTable';

const UserTable = (props) => {
    const {
        userForm,
        removeRegister,
        attributes,
        list,
        permissions,
        loading,
        intl
    } = props;

    return (
        <DataTable
            add={userForm}
            edit={userForm}
            remove={removeRegister}
            list={list}
            loading={loading}
            attributes={attributes}
            permissions={permissions}
            keyField={'id'}
            confirmText={`Você está removendo o usuário <strong><field>name</field></strong>! </br>Realmente deseja fazer isso? `}
            headers={[
                {title: 'sharedName', field: 'name', dataSort: true},
                {title: 'userEmail', field: 'email', dataSort: true},
                {title: 'sharedDisabled', field: 'disabled', dataSort: false},
                {title: 'sharedCustomer', field: 'customerName', dataSort: true},
            ]}
            excelConfig={{
                fileName: 'deviceTitle',
                columns: [
                    {label: 'sharedName', field: 'name'},
                    {label: 'userEmail', field: 'email'},
                    {
                        label: 'sharedDisabled',
                        field: (col) => col.disabled ? intl.formatMessage({id: 'sharedYes'}) : intl.formatMessage({id: 'sharedNo'})
                    },
                    {label: 'sharedPhone', field: 'phone'},
                ]
            }}
        />);
};

export default UserTable;
