import request from './http-request';
import {serialize} from '../helpers/request';

export const getDevices = ( data, query) => {
  const options = {
    method: 'get',
    url: 'devices',
  };

  if (data) {
    const {email, password} = data;
    options.auth = {username: email, password};
  }

  if (query) {
    options.url = `${options.url}?${serialize( Object.assign({_dc: new Date().getTime()}, query))}`;
  }

  return request(options);
};

export const saveDevice = ({email, password}, data) =>
  request({
    method: 'post',
    url: `devices`,
    noAuth: true,
    auth: {username: email, password},
    data,
  });

export const updateDevice = ({email, password}, data) =>
  request({
    method: 'put',
    url: `devices/${data.id}`,
    noAuth: true,
    auth: {username: email, password},
    data,
  });

export const removeDevice = ({email, password}, id) =>
  request({
    method: 'delete',
    url: `devices/${id}`,
    noAuth: true,
    auth: {username: email, password},
  });
