import React, {useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import Button from '../../components/button';
import * as Yup from 'yup';
import * as moment from 'moment';
import ReactSelect from '../../components/react-select';
import DatePicker from '../../components/react-datepicker';

import './account.sass';
import ToggleButton from '../../components/toggle-button';
import {generateToken} from '../../helpers/auth';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from '../../components/fieldset';
import {GroupButtonForm} from '../../components/group-button-form';

const AccountForm = (props) => {
    const {
        data,
        updateRegister,
        coordinateFormats,
        profile,
        loading,
        showCompanyConfigurationForm,
    } = props;

    const formRef = useRef(null);

    const isAdmin = profile === 'admin';

    const schema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        email: Yup.string(),
        password: Yup.string().nullable().notRequired(),
        phone: Yup.string().notRequired(),
        map: Yup.string().nullable().notRequired(),
        latitude: Yup.number().notRequired(),
        longitude: Yup.number().notRequired(),
        zoom: Yup.number().notRequired(),
        twelveHourFormat: Yup.boolean().notRequired(),
        coordinateFormat: Yup.string().nullable().notRequired(),
        poiLayer: Yup.string().notRequired(),
        disabled: Yup.boolean().notRequired(),
        administrator: Yup.boolean().notRequired(),
        readonly: Yup.boolean().notRequired(),
        deviceReadonly: Yup.boolean().notRequired(),
        limitCommands: Yup.boolean().notRequired(),
        expirationTime: Yup.date().notRequired().nullable(),
        deviceLimit: Yup.number().notRequired(),
        userLimit: Yup.number().notRequired(),
        token: Yup.string().nullable().notRequired(),
    });

    const handleSubmit = async (payload) => {
        const tmp = Object.assign({}, data);
        let userData = Object.assign(tmp, payload);

        Object.keys(userData).forEach((key) =>
            (userData[key] === undefined || userData[key] === null) && delete userData[key]);

        try {
            if (userData.expirationTime) {
                userData.expirationTime = moment(userData.expirationTime).toISOString();
            }
            await schema.validate(userData, {
                abortEarly: false,
            });
            userData = schema.cast(userData);
            updateRegister(userData);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const cast = (src) => {
        const data = Object.assign({}, src);
        if (!data.userLimit) {
            data.userLimit = 0;
        }
        if (!data.zoom) {
            data.zoom = 0;
        }
        if (!data.expirationTime) {
            data.expirationTime = undefined;
        }
        if (!data.deviceLimit) {
            data.deviceLimit = 0;
        }
        if (!data.latitude) {
            data.latitude = 0;
        }
        if (!data.longitude) {
            data.longitude = 0;
        }
        return data;
    };

    const setToken = () => {
        formRef.current.setFieldValue('token', generateToken());
    };

    return (<>
            {isAdmin && (
                <div className={'rounded-group-button'}>
                    <Button
                        className={'outline'}
                        onClick={showCompanyConfigurationForm}
                        size={'sm'}
                        color={'theme'}>
                        <i className="fas fa-plus"/>
                        <I18nMessage id="sharedCompanyConfiguration"/>
                    </Button>
                </div>
            )}
            <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={cast(data)}>
                <UInput hidden type="number" name="id"/>
                <FieldSet className="text-left border p-4" title={'Identificação'}>
                    <div className="row">
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="sharedName"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userEmail"/>*</Label>
                                <Input
                                    readOnly={true}
                                    tag={UInput}
                                    type="email"
                                    name="email"
                                    id="email"
                                />
                            </FormGroup>
                        </div>

                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="phone"><I18nMessage id="sharedPhone"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="phone"
                                    id="phone"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userPassword"/>*</Label>
                                <Input
                                    tag={UInput}
                                    type="password"
                                    name="password"
                                    id="password"
                                    autoComplete={'new-password'}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>
                <FieldSet className="text-left border p-4" title={'Permissões'}>
                    <div className="row">
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="sharedDisabled"/></Label>
                                <ToggleButton disabled={true} name={'disabled'} id={'disabled'}/>
                            </FormGroup>
                            {isAdmin &&
                                <FormGroup className="custom-formGroup">
                                    <Label><I18nMessage id="userAdmin"/></Label>
                                    <ToggleButton disabled={true} name={'administrator'} id={'administrator'}/>
                                </FormGroup>
                            }
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="serverReadonly"/></Label>
                                <ToggleButton disabled={true} name={'readonly'} id={'readonly'}/>
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="userDeviceReadonly"/></Label>
                                <ToggleButton disabled={true} name={'deviceReadonly'} id={'deviceReadonly'}/>
                            </FormGroup>
                        </div>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="userLimitCommands"/></Label>
                                <ToggleButton disabled={true} name={'limitCommands'} id={'limitCommands'}/>
                            </FormGroup>

                            <FormGroup className="custom-formGroup">
                                <Label><I18nMessage id="settingsTwelveHourFormat"/></Label>
                                <ToggleButton name={'twelveHourFormat'} id={'twelveHourFormat'}/>
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>
                <br/>
                <FieldSet className="text-left border p-4" title={'Configurações'}>
                    <div className="row">
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="positionLatitude"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="latitude"
                                    id="latitude"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="positionLongitude"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="longitude"
                                    id="longitude"
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="serverZoom"/></Label>
                                <Input
                                    tag={UInput}
                                    type="number"
                                    name="zoom"
                                    id="zoom"
                                />
                            </FormGroup>
                        </div>
                        <div className={'col'}>
                            <FormGroup className="custom-formGroup">
                                <Label for="coordinateFormat"><I18nMessage id="settingsCoordinateFormat"/></Label>
                                <ReactSelect
                                    id={'coordinateFormat'}
                                    name="coordinateFormat"
                                    options={coordinateFormats}/>
                            </FormGroup>
                            {isAdmin &&
                                <FormGroup className="custom-formGroup">
                                    <Label for="name"><I18nMessage id="userDeviceLimit"/></Label>
                                    <Input
                                        readOnly={true}
                                        tag={UInput}
                                        type="number"
                                        name="deviceLimit"
                                        id="deviceLimit"
                                    />
                                </FormGroup>
                            }
                            {isAdmin &&
                                <FormGroup className="custom-formGroup">
                                    <Label for="name"><I18nMessage id="userUserLimit"/></Label>
                                    <Input
                                        readOnly={true}
                                        tag={UInput}
                                        type="number"
                                        name="userLimit"
                                        id="userLimit"
                                    />
                                </FormGroup>
                            }
                            <FormGroup className="custom-formGroup">
                                <Label for="name"><I18nMessage id="userExpirationTime"/></Label>
                                <DatePicker
                                    disabled={true}
                                    name={'expirationTime'}
                                    displayFormat={'dd/MM/yyyy'}
                                />
                            </FormGroup>
                            <FormGroup className="custom-formGroup">
                                <Label for="token"><I18nMessage id="userToken"/></Label>
                                <div className="input-group mb-2">
                                    <Input
                                        tag={UInput}
                                        type="text"
                                        name="token"
                                        id="token"
                                    />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text token">
                                                <span color="primary" onClick={() => setToken()}>
                                                    <i className="fas fa-sync-alt"/>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </FieldSet>
                <br/>
                <GroupButtonForm
                    loading={loading}
                    submit={() => formRef.current.submitForm()}
                />
            </Form>
        </>

    );
};

export default AccountForm;
