import momentTimezone from 'moment-timezone';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

export const timezoneFormat = (timeZone, date) =>
  momentTimezone(date)
      .tz(timeZone)
      .format();

export const dateToDate = (start, end) => ({
  from: moment.utc(moment(start)).format(),
  to: moment.utc(moment(end)).format(),
});

export const nowSofar = () => ({
  from: moment.utc(moment().startOf('day')).format(),
  to: moment.utc().format(),
});

export const currentWeekSoFar = () => ({
  from: moment.utc(moment().startOf('week')).format(),
  to: moment.utc().format(),
});

export const yesterday = () => ({
  from: moment
      .utc(
          moment()
              .add(-1, 'day')
              .startOf('day'),
      )
      .format(),
  to: moment
      .utc(
          moment()
              .add(-1, 'day')
              .endOf('day'),
      )
      .format(),
});

export const lastWeek = () => ({
  from: moment
      .utc(
          moment()
              .day(-7)
              .startOf('week'),
      )
      .format(),
  to: moment
      .utc(
          moment()
              .day(-7)
              .endOf('week'),
      )
      .format(),
});

export const currentMonthSoFar = () => ({
  from: moment.utc(moment().startOf('month')).format(),
  to: moment.utc().format(),
});

export const lastMonth = () => ({
  from: moment
      .utc(
          moment()
              .subtract(1, 'month')
              .startOf('month'),
      )
      .format(),
  to: moment
      .utc(
          moment()
              .subtract(1, 'month')
              .endOf('month'),
      )
      .format(),
});

export const durationFormat = (milliseconds, format) =>
  moment.duration(milliseconds, 'milliseconds').format(format, {trim: false});
