import React, {useContext, useRef} from 'react';
import {Button, ButtonGroup} from 'reactstrap';
import {ThemeContext} from '../../apiContexts';
import './zoomButton.sass';

const ZoomButton = (props) => {
  const {
    map
  } = props;

  const theme = useContext(ThemeContext);
  const btn1 = useRef();
  const btn2 = useRef();

  const hover = (e) => {
    e.current.style.backgroundColor = theme;
    e.current.style.borderColor = theme;
    e.current.style.color = 'white';
  };

  const noHover = (e) => {
    e.current.style.backgroundColor = 'white';
    e.current.style.color = theme;
  };

  return (
    <ButtonGroup vertical className="zoom-button">
      <Button
        innerRef={btn1}
        onClick={ () => map.increaseZoom() }
        onMouseEnter={() => hover(btn1)}
        onMouseLeave={() => noHover(btn1)}
        style={{color: theme, borderColor: theme}}
      >
        <span className="fas fa-plus" />
      </Button>
      <Button
        innerRef={btn2}
        onClick={ () => map.decreaseZoom() }
        onMouseEnter={() => hover(btn2)}
        onMouseLeave={() => noHover(btn2)}
        style={{color: theme, borderColor: theme}}
      >
        <span className="fas fa-minus" />
      </Button>
    </ButtonGroup>
  );
};

export default ZoomButton;
