import React, {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {ModalBody, ModalHeader} from 'reactstrap';
import styled from 'styled-components';
import {ThemeContext} from '../../../apiContexts';
import RoundButton from '../../../components/roundButton';
import CommandContainer from '../../../containers/command-container';
import {ModalStyled} from '../../../styles';
import {colors} from '../../../styleVariables';
import useServices from '../../../hooks/use-services';
import useAlerts from '../../../hooks/use-alerts';

const Style = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;

  * {
    opacity: 1;
  }

  > strong {
    display: flex;
    justify-content: center;
    font-size: 0.8rem !important;
    color: ${(props) => props.theme};
  }

  .badge {
    color: ${colors.gray};
    font-size: 0.8rem !important;
  }
`;

const ActionsContainer = styled.div`
  flex: 0 0 30%;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
`;

const ActionContainer = styled.div`
  display: flex;
  width: 33%;
  min-width: 4rem;
  justify-content: center;

  .badge {
    /* max-width: 4rem; */
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Actions = ({trace, traceToggle, followToogle, follow, createGeofence, deviceUniqueId}) => {
  const theme = useContext(ThemeContext);
  const intl = useIntl();
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({title: 'Título', type: ''});

  const {locationService} = useServices();
  const {toast} = useAlerts();


  const toggle = () => setModal(!modal);

  const openSendCommand = () => {
    setModalInfo({
      title: intl.formatMessage({id: 'commandTitle'}),
      type: 'group',
    });
    toggle();
  };

  return (
    <Style theme={theme} id="deviceActions">
      <strong>Ações para o Dispositivo</strong>
      <ActionsContainer className="mt-2 d-flex">
        <ActionContainer>
          <RoundButton
            withBorder
            noBgBadge
            labelDirection="bottom"
            label="Rota"
            active={trace}
            onClick={traceToggle}
          >
            <span className="fad fa-route" />
          </RoundButton>
        </ActionContainer>

        <ActionContainer>
          <RoundButton
            withBorder
            noBgBadge
            labelDirection="bottom"
            label={intl.formatMessage({id: 'commandTitle'})}
            onClick={() => openSendCommand()}
          >
            <span className="fad fa-upload" />
          </RoundButton>
        </ActionContainer>

        <ActionContainer>
          <RoundButton
            withBorder
            noBgBadge
            labelDirection="bottom"
            label="Seguir"
            active={follow}
            onClick={followToogle}
          >
            <span className="fad fa-location-arrow" />
          </RoundButton>
        </ActionContainer>

        <ActionContainer>
          <RoundButton
            withBorder
            noBgBadge
            labelDirection="bottom"
            onClick={createGeofence}
            label="Cerca">
            <span
              style={{fontSize: '2rem'}}
              className="fad fa-location-circle fa-2x"
            />
          </RoundButton>
        </ActionContainer>

        <ActionContainer>
          <RoundButton
            withBorder
            noBgBadge
            labelDirection="bottom"
            label="Compartilhar"
            onClick={async () => {
              try {
                const token = await locationService.shareLocation([deviceUniqueId]);
                navigator.clipboard.writeText(window.location.origin + '/shared/' + token);
                toast.success('Link de compartilhamento copiado para a área de transferência.');
              } catch (err) {
                toast.error('Ops! Houve um erro ao compartilhar o dispositivo.');
              }
            }}
          >
            <span className="fad fa-share" />
          </RoundButton>
        </ActionContainer>

        <ModalStyled
          theme={theme}
          size="sm"
          centered
          unmountOnClose
          className={`customModal`}
          isOpen={modal}
          toggle={toggle}
        >
          <ModalHeader toggle={toggle}>{modalInfo.title}</ModalHeader>
          <ModalBody>
            <CommandContainer toggle={toggle} />
          </ModalBody>
        </ModalStyled>
      </ActionsContainer>
    </Style>
  );
};

export default Actions;
