import {types} from 'mobx-state-tree';

// `{
// "id":172026538,
// "attributes":{"alarm":"sos"},
// "deviceId":8834,
// "type":"alarm",
// "eventTime":"2021-11-29T10:08:20.000+00:00",
// "positionId":1092217905,
// "geofenceId":0,
// "maintenanceId":0}`;

const EventModel = types.model('Event', {
  id: types.identifierNumber,
  deviceId: types.number,
  name: types.maybe(types.string),
  type: types.string,
  attributes: types.frozen({}),
  geofenceId: types.number,
  positionId: types.number,
  maintenanceId: types.number,
  serverTime: types.maybe(types.string),
  eventTime: types.maybe(types.string)
});

export default EventModel;
