import React, {Fragment, useContext} from 'react';
import {Typeahead as T} from 'react-bootstrap-typeahead';
import styled from 'styled-components';
import {ThemeContext} from '../../apiContexts';
import {shadows} from '../../styleVariables';
import './style.sass';

const Typeahead = styled(T)`
  .rbt-input-main {
    box-shadow: ${shadows.shadow};
    border-radius: 10px;
    padding-right: 3rem !important;
  }
  .rbt-input-hint {
    width: calc(100% - 3rem);
    overflow-x: hidden !important;
  }
`;

const Icon = styled.span`
  color: ${({theme}) => theme};
  position: absolute;
  right: 1rem;
  font-size: 1.3rem;
`;

const Style = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;


const groupBy = (xs, key) =>{
    if (!xs) {
        return [];
    }
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


const renderMenu = (results, menuProps, state) => {
    let index = 0;
    const regions = groupBy(results, 'region');
    const items = Object.keys(regions).sort().map((region) => (
        <Fragment key={region}>
            {index !== 0 && <hr />}
            <h3>{region}</h3>
            {regions[region].map((i) => {
                const item =
                    <h4 key={index} option={i} position={index}>
                        <p search={state.text}>
                            {i.name}
                        </p>
                    </h4>;

                index += 1;
                return item;
            })}
        </Fragment>
    ));

    return <div {...menuProps}>{items}</div>;
};

const SearchAutocomplete = ({className, ...props}) => {
    const theme = useContext(ThemeContext);

    return (
        <Fragment>
            <Style className={className}>
                <Typeahead {...props} />
                <Icon theme={theme} className="far fa-search"/>
            </Style>
        </Fragment>
    );
};

export default SearchAutocomplete;
