import React from 'react';
import mStyles from './skeleton.module.sass';

const Skeleton = ({
    className,
    width,
    height,
    radius,
    kind = 'rectangular', // rectangular | circular
    ...rest
}) => {
    const kindMap = {
        'rectangular': mStyles.skeleton__rectangle,
        'circular': mStyles.skeleton__circle,
    };

    return (
        <div
            {...rest}
            className={`${mStyles.skeleton} ${kindMap[kind]} ${className}`}
            style={{
                width,
                height,
                borderRadius: radius,
            }}
        />
    );
};

export default {
    Rectangular: ({width, height, ...rest}) =>
        <Skeleton {...rest} width={width} height={height} kind="rectangular" />,
    Circular: ({size, radius, ...rest}) =>
        <Skeleton {...rest} width={size} height={size} radius={radius} kind="circular" />,
};
