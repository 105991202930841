import {types} from 'mobx-state-tree';
import DriverModel from '../models/driver-model';

const DriverStore = types
    .model('DriverStore', {
        drivers: types.optional(types.array(DriverModel), []),
    })
    .actions((self) => ({
        setDrivers(drivers) {
            self.drivers = drivers;
        }
    }))
    .views((self) => ({
        getDriverByUniqueId: (id) => self.drivers.find((e) => e.uniqueId === id),
        getDrivers() {
            return self.drivers;
        }
    }));

export default DriverStore;

