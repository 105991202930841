import React, {useRef, useState} from 'react';
import {Input as UInput} from '../../components/input';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import I18nMessage from '../../components/I18nMessage';
import ReactSelect from '../../components/react-select';
import {TextArea} from '../../components/textarea';
import ToggleButton from '../../components/toggle-button';
import useServices from '../../hooks/use-services';
import Button from '../../components/button';
import UInputMask from '../../components/react-mask';
import moment from 'moment';
import useAlerts from '../../hooks/use-alerts';

const SinisterCloseForm = (props) => {
    const {
        sinister,
        loadIncidents,
        close,
        setHideClass,
    } = props;
    const schema = Yup.object().shape({
        id: Yup.number().notRequired(),
        date: Yup.string().required(),
        time: Yup.string().required(),
        closingType: Yup.string().required(),
        vehicleDamaged: Yup.boolean().required(),
        closingAddress: Yup.string().required(),
        closingDescription: Yup.string().required(),
    });

    const {toast} = useAlerts();
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);
    const {sinisterService} = useServices();


    const handleSubmit = async (payload) => {
        try {
            setLoading(true);
            await schema.validate(payload, {
                abortEarly: false,
            });
            payload = schema.cast(payload);

            const
                {
                    id,
                    closingType,
                    vehicleDamaged,
                    closingAddress,
                    closingDescription,
                } = payload;

            sinisterService.close({
                id,
                closingDate: moment(`${payload.date} ${payload.time}`, 'DD/MM/YYYY HH:mm').toDate().toISOString(),
                closingType,
                vehicleDamaged,
                closingAddress,
                closingDescription,
            }).then((sinister) => {
                loadIncidents();
                close();
                toast.success('Sinistro Fechado com Sucesso');
                setHideClass('hide-card');
            }).finally(() => {
                setLoading(false);
            });
        } catch (err) {
            toast.errorStatus('Erro ao tentar Fechar o Sinistro');
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
            props.dispatchAccordionResize();
        } finally {
            setLoading(false);
        }
    };

    return (<Form
            ref={formRef}
            initialData={{
                id: sinister.id,
                date: moment().format('DD/MM/YYYY'),
                time: moment().format('HH:mm'),
            }}
            onSubmit={handleSubmit}
            schema={schema}>

            <UInput hidden type="number" name="id"/>

            <FormGroup className="custom-formGroup">
                <Label for="name"><I18nMessage id="sinisterClosingType"/>*</Label>
                <ReactSelect
                    id={'closingType'}
                    name="closingType"
                    options={[
                        {id: 'vehicleFound', title: 'Veículo Encontrado'},
                        {id: 'vehicleNotFound', title: 'Veículo Não Encontrado'},
                        {id: 'falsePositive', title: 'Falso Positivo'},
                    ]}/>
            </FormGroup>

            <Row>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label for="name">
                            Data*
                        </Label>
                        <UInputMask name={'date'} mask={'99/99/9999'}/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="custom-formGroup">
                        <Label for="name">
                            Horário*
                        </Label>
                        <UInputMask name={'time'} mask={'99:99'}/>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup className="custom-formGroup">
                <Label for="name"><I18nMessage id="sinisterVehicleDamaged"/>*</Label>
                <ToggleButton name={'vehicleDamaged'} id={'vehicleDamaged'}/>
            </FormGroup>

            <FormGroup className="custom-formGroup">
                <Label for="name"><I18nMessage id="sinisterClosingAddress"/></Label>
                <Input
                    tag={UInput}
                    type="text"
                    name="closingAddress"
                    id="closingAddress"
                />
            </FormGroup>

            <FormGroup className="custom-formGroup">
                <Label for="name"><I18nMessage id="sinisterClosingDescription"/></Label>
                <TextArea
                    type="text"
                    name="closingDescription"
                    id="closingDescription"
                />
            </FormGroup>

            <Row>
                <Col> <Button
                    color={'warning'}
                    disabled={loading}
                    className={'outline'}
                    size={'sm'}
                    onClick={() => close()}>
                    Cancelar
                </Button></Col>
                <Col>
                    <Button
                        color={'success'}
                        disabled={loading}
                        className={'outline'}
                        size={'sm'}
                        onClick={() => formRef.current.submitForm()}>
                        Salvar
                    </Button>
                </Col>
            </Row>

        </Form>
    );
};

export default SinisterCloseForm;
