import {types} from 'mobx-state-tree';

const FCSimCardModel = types
    .model('FCSimCardModel', {
        id: types.maybeNull(types.number),
        numeroChip: types.maybeNull(types.number),
        numeroLinha: types.maybeNull(types.number),
        operadoraId: types.maybeNull(types.number),

    })
    .views((self) => {
        return {
            getId() {
                return self.id;
            },
            getNumeroChip() {
                return self.numeroChip;
            },
            getNumeroLinha() {
                return self.numeroLinha;
            },
            getOperadorId() {
                return self.operadoraId;
            }
        };
    })
    .actions((self) => ({
        setId( data ) {
            self.id = data;
        },
        getNumeroChip( data ) {
            self.numeroChip = data;
        },
        getNumeroLinha( data ) {
            self.numeroLinha = data;
        },
        getOperadorId( data ) {
            self.operadoraId = data;
        }

    }));

export default FCSimCardModel;
