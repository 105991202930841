import React from 'react';
import DataTable from '../../components/dataTable';

const CalendarTable = (props) => {
  const {
    calendarForm,
    removeRegister,
    attributes,
    list,
    loading,
  } = props;

  return (
    <DataTable
      add={calendarForm}
      edit={calendarForm}
      remove={removeRegister}
      list={list}
      loading={loading}
      attributes={attributes}
      keyField={'id'}
      confirmText={`Você está removendo o calendário <strong><field>name</field></strong>! </br>Realmente deseja fazer isso? `}
      headers={[
        {title: 'sharedName', field: 'name', dataSort: true},
      ]}
    />
  );
};

export default CalendarTable;
