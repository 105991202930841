import {useCallback, useEffect} from 'react';
import useStore from './use-store';

let started = false;

const usePayProvider = () => {

    const {store} = useStore();
    const {session, azulPayStore, fullControlStore} = store;

    const syncExternalApi = useCallback(async () => {

        if (session.attributes && session.attributes.hasOwnProperty('payment_provider')) {

            const paymentProvider = session.attributes.payment_provider;

            if(paymentProvider.toUpperCase() === 'FULL_CONTROL'){

                if(session.attributes.full_control_client_id && session.attributes.full_control_secret_id){

                    if(fullControlStore.clients.length === 0 && !started){
                        fullControlStore.setClientId(session.attributes.full_control_client_id);
                        fullControlStore.setSecretId(session.attributes.full_control_secret_id);
                        await fullControlStore.loadClients();
                        await fullControlStore.loadEquipments();
                    }
                }
            }
        } else {
            if (session.attributes && session.attributes.hasOwnProperty('apiKeySeller')) {
                if(azulPayStore.customers.length === 0 && !started){
                    await azulPayStore.setApiKeySelle(session.attributes.apiKeySeller);
                    await azulPayStore.loadCustomers();
                }
            }
        }


    }, []);

    return {
        azulPayStore,
        fullControlStore,
        syncExternalApi
    };
};

export default usePayProvider;
