export default {
  'sharedLoading': 'Carregando...',
  'sharedHide': 'Esconder',
  'sharedSave': 'Salvar',
  'sharedSet': 'Aplicar',
  'sharedCancel': 'Cancelar',
  'sharedAdd': 'Adicionar',
  'sharedEdit': 'Editar',
  'sharedRemove': 'Remover',
  'sharedRegister': 'Cadastro',
  'sharedOf': 'de',
  'sharedLogout': 'Sair',
  'sharedYes': 'Sim',
  'sharedNo': 'Não',
  'sharedLogin': 'Login',
  'sharedComment': 'Comentário',
  'sharedNext': 'Próximo',
  'sharedPrev': 'Anterior',
  'sharedAll': 'Todos',
  'sharedFirst': 'Primeiro',
  'sharedLast': 'Último',
  'sharedNoDataText': 'Não há dados para exibir',
  'sharedEditAttributeFrom': 'Editando atributo de {name}',
  'sharedCreateAttributeFrom': 'Criando atributo em {name}',
  'sharedRemoveConfirm': 'Remover item?',
  'sharedKm': 'Km',
  'sharedMi': 'Mi',
  'sharedNmi': 'Milhas Náuticas',
  'sharedKn': 'Kn',
  'sharedKmh': 'Km/h',
  'sharedMph': 'Mph',
  'sharedHour': 'Hora',
  'sharedMinute': 'Minuto',
  'sharedSecond': 'Segundo',
  'sharedDays': 'dias',
  'sharedHours': 'horas',
  'sharedMinutes': 'minutos',
  'sharedDecimalDegrees': 'Graus Decimais',
  'sharedDegreesDecimalMinutes': 'Graus Minutos Decimais',
  'sharedDegreesMinutesSeconds': 'Graus Minutos Segundos',
  'sharedName': 'Nome',
  'sharedDescription': 'Descrição',
  'sharedSearch': 'Busca',
  'sharedGeofence': 'Geocerca',
  'sharedGeofences': 'Geocercas',
  'sharedSinister': 'Sinistro',
  'sharedCompanyConfiguration': 'Configuração da central',
  'sinisterOpen': 'Sinistro Aberto',
  'sinisterCreate': 'Abrir Sinistro',
  'sinisterOccurrenceDate': 'Data da Ocorrência',
  'sinisterOccurrenceShowMore': 'Exibir Mais',
  'sinisterNumber': 'Número',
  'sinisterTypeAttemptToTriggerPromptResponse': 'Tentativa de acionar a resposta imediata',
  'sinisterTypeLimitExceededInSearchConditions': 'Limite excedido nas condições de pesquisa',
  'sinisterTypePoliceArriveAtTheScene': 'A polícia chega ao Local',
  'sinisterTypePromptResponseActivated': 'Resppsta Ativada',
  'sinisterTypeRegisterThePoliceReport': 'Registrar O Relatório da Polícia',
  'sinisterTypeRequestedVehicleForVehicleRecovery': 'Veículo solicitado Para Recuperação de Veículos',
  'sinisterTypeSupportArrivesOnSite': 'Suporte chega ao local',
  'sinisterTypeSupportDoesOnSitePreservation': 'Suporte Faz Preservação no Local',
  'sinisterTypeSupportOnTheWay': 'Apoio No Caminho',
  'sinisterTypeSupportStartsPatrolling': 'Suporte Começa a patrulhar',
  'sinisterTypeUninstalledEquipmentFound': 'Equipamento desinstalado encontrado',
  'sinisterTypeUndefined': 'ERRO!',
  'sinisterTypeVehicleNotFound': 'Veículo Não Encontrado',
  'sinisterTypeVehicleFound': 'Veículo Encontrado',
  'sinisterTypeFalsePositive': 'Falso Positivo',
  'sinisterClosingDate': 'Data de Fechamento',
  'sinisterClosingType': 'Tipo de Fechamento',
  'sinisterVehicleDamaged': 'Veículo Danificado',
  'sinisterClosingAddress': 'Endereço de Fechamento',
  'sinisterClosingDescription': 'Descrição do Fechamento',
  'sinisterDevicePlate': 'Placa',
  'sinisterDeviceModel': 'Modelo',
  'sinisterOpeningDate': 'Data de Abertura',
  'sinisterStatus': 'Status',
  'sinisterActions': 'Ações',
  'sinisterStatusOpened': 'Aberto',
  'sinisterStatusClosedWin': 'Fechado',
  'sinisterStatusClosedLost': 'Fechado',
  'sinisterTypeRobbery': 'Roubo',
  'sinisterTypeTheft': 'Furto',
  'sinisterTypeMisappropriation': 'Apropriação Indébita',
  'sinisterTypeAccident': 'Acidente',
  'sinisterTypeOther': 'Outro',
  'sharedKeyword': 'Palavra-chave',
  'sharedNotifications': 'Notificações',
  'sharedNotification': 'Notificação',
  'sharedAttributes': 'Atributos',
  'sharedAttribute': 'Atributo',
  'sharedDrivers': 'Motoristas',
  'sharedDriver': 'Motorista',
  'sharedArea': 'Área',
  'sharedSound': 'Som de notificação',
  'sharedType': 'Tipo',
  'sharedDistance': 'Distância',
  'sharedHourAbbreviation': 'h',
  'sharedMinuteAbbreviation': 'm',
  'sharedSecondAbbreviation': 's',
  'sharedVoltAbbreviation': 'V',
  'sharedLiterAbbreviation': 'L',
  'sharedGallonAbbreviation': 'Galão',
  'sharedLiter': 'Litro',
  'sharedImpGallon': 'Imp. Galão',
  'sharedUsGallon': 'U.S. Galão',
  'sharedLiterPerHourAbbreviation': 'L/h',
  'sharedLabel': 'Etiqueta',
  'sharedGetMapState': 'Obter estado do mapa',
  'sharedComputedAttribute': 'Atributo Calculado',
  'sharedComputedAttributes': 'Atributos Calculados',
  'sharedCheckComputedAttribute': 'Verificar Atributo Calculado',
  'sharedExpression': 'Expressão',
  'sharedDevice': 'Dispositivo',
  'sharedTestNotification': 'Enviar notificação de teste',
  'sharedCalendar': 'Calendário',
  'sharedCalendars': 'Calendários',
  'sharedFile': 'Arquivo',
  'sharedSelectFile': 'Selecionar Arquivo',
  'sharedPhone': 'Telefone',
  'sharedRequired': 'Necessário',
  'sharedPreferences': 'Preferências',
  'sharedPermissions': 'Permissões',
  'sharedExtra': 'Adicional',
  'sharedTypeString': 'Texto',
  'sharedTypeNumber': 'Número',
  'sharedTypeBoolean': 'Lógico',
  'sharedTimezone': 'Fuso horário',
  'sharedInfoTitle': 'Informação',
  'sharedSavedCommand': 'Comando Salvo',
  'sharedSavedCommands': 'Comandos Salvos',
  'sharedNew': 'Novo...',
  'sharedShowAddress': 'Mostrar Endereço',
  'sharedDisabled': 'Desativado',
  'sharedMaintenance': 'Manutenção',
  'sharedMaintenances': 'Manutenções',
  'sharedDeviceAccumulators': 'Bateria',
  'sharedAlarms': 'Alarmes',
  'sharedSavedSuccess': 'Seus dados foram salvos',
  'sharedRemovedSuccess': 'Seus dados foram removidos',
  'sharedUpdatedSuccess': 'Seus dados foram atualizados',
  'sharedError': 'Ops! Erro interno',
  'sharedBadRequest': 'Operação não autorizada',
  'sharedForbidden': 'Não possui direitos de acesso',
  'sharedUnauthorized': 'Usuário não autenticado',
  'sharedNotFound': 'Não encontrado',
  'sharedActivated': 'Ativado',
  'sharedNotActivated': 'Desativado',
  'sharedDate': 'Data',
  'sharedUserPaymentDetail': 'Dados para Pagamento',
  'sharedUser': 'Usuário',
  'sharedCustomers': 'Clientes',
  'sharedCustomer': 'Cliente',
  'sharedAddress': 'Endereço',
  'attributeSpeedLimit': 'Limite de Velocidade',
  'attributePolylineDistance': 'Distância da Polilinha',
  'attributeReportIgnoreOdometer': 'Relatório: Ignorar Odômetro',
  'attributeWebReportColor': 'Web: Cor do Relatório',
  'attributeDevicePassword': 'Senha do Dispositivo',
  'attributeProcessingCopyAttributes': 'Processamento: Copiar Atributos',
  'attributeColor': 'Cor',
  'attributeWebLiveRouteLength': 'Web: Comprimento da Rota ao Vivo',
  'attributeWebSelectZoom': 'Web: Zoom Selecionado',
  'attributeWebMaxZoom': 'Web: Zoom Máximo',
  'attributeMailSmtpHost': 'Email: Host SMTP',
  'attributeMailSmtpPort': 'Email: Porta SMTP',
  'attributeMailSmtpStarttlsEnable': 'Email: Ativar STARTTLS (SMTP)',
  'attributeMailSmtpStarttlsRequired': 'Email: SMTP START TLS Obrigatório',
  'attributeMailSmtpSslEnable': 'Email: Ativar SSL (SMTP)',
  'attributeMailSmtpSslTrust': 'Email: Certificado SSL (SMTP)',
  'attributeMailSmtpSslProtocols': 'Email: Protocolo SSL (SMTP)',
  'attributeMailSmtpFrom': 'Email: Remetente',
  'attributeMailSmtpAuth': 'Email: Ativar Autenticação (SMTP)',
  'attributeMailSmtpUsername': 'Email: Nome de Usuário (SMTP)',
  'attributeMailSmtpPassword': 'Email: Senha (SMTP)',
  'attributeUiDisableReport': 'UI: Disabilita Relatório',
  'attributeUiDisableEvents': 'UI: Eventos Desativados',
  'attributeUiDisableVehicleFetures': 'UI: Desativar Características do Veículo',
  'attributeUiDisableDrivers': 'UI: Desativar Motoristas',
  'attributeUiDisableComputedAttributes': 'UI: Desativar Atributos Calculados',
  'attributeUiDisableCalendars': 'UI: Desativar Calendários',
  'attributeUiDisableMaintenances': 'Desativar Manutenções',
  'attributeUiHidePositionAttributes': 'UI: Ocultar os atributos de posição',
  'errorAuthorization': 'Autorização de credencial inválida, verifique e-mail e senha.',
  'errorTitle': 'Erro',
  'errorGeneral': 'Parâmetros inválidos ou violação de restrições',
  'errorConnection': 'Erro de conexão',
  'errorSocket': 'Erro na conexão com Web Socket',
  'errorZero': 'Não pode ser zero',
  'userEmail': 'Email',
  'userPassword': 'Senha',
  'userAdmin': 'Admin',
  'userRemember': 'Lembrar',
  'userExpirationTime': 'Expiração',
  'userDeviceLimit': 'Limite de dispositivos',
  'userUserLimit': 'Limite de Usuários',
  'userDeviceReadonly': 'Dispositivos somente leitura',
  'userLimitCommands': 'Limite de Comandos',
  'userToken': 'Token',
  'userKeyword': 'Palavra-chave',
  'loginTitle': 'Acessar',
  'loginLanguage': 'Idioma',
  'loginRegister': 'Registrar',
  'loginLogin': 'Entrar',
  'loginFailed': 'Email ou senha incorretos',
  'loginCreated': 'Novo usuário registrado',
  'loginLogout': 'Sair',
  'loginLogo': 'Logotipo',
  'devicesAndState': 'Dispositivo e Estado',
  'deviceTitle': 'Dispositivos',
  'deviceIdentifier': 'Identificador',
  'deviceModel': 'Modelo',
  'deviceContact': 'Contato',
  'deviceCategory': 'Categoria',
  'deviceLastUpdate': 'Última Atualização',
  'deviceLimit': 'Limite de Dispositivos',
  'deviceCommand': 'Comando',
  'deviceFollow': 'Seguir',
  'deviceTotalDistance': 'Distância total',
  'deviceTrail': 'Rastro',
  'deviceStatus': 'Estado',
  'deviceStatusOnline': 'Conectado',
  'deviceStatusOffline': 'Desconectado',
  'deviceStatusUnknown': 'Desconhecido',

  'deviceM2mProvider': 'Operadora do Chip',
  'deviceIccid': 'ICCID',
  'devicePlate': 'Placa',
  'deviceYear': 'Ano',
  'deviceVehicleModel': 'Modelo do Veículo',
  'deviceFuelType': 'Combustível',
  'deviceFuelConsumption': 'Consumo',
  'deviceManufacturer': 'Marca',
  'deviceColor': 'Cor',
  'deviceRenavam': 'Renavam',
  'deviceChassi': 'Chassi',
  'deviceInstallationLocation': 'Local de instalação',
  'deviceTechnician': 'Instalador',
  'devicePassword': 'Senha',
  'deviceSpeedLimit': 'Limite de Velocidade',
  'deviceIgnoreOdometer': 'Ignorar odômetro no relatório',
  'deviceSelectedNeverPositioned': 'O dispositivo selecionado nunca posicionou.',

  'groupDialog': 'Grupo',
  'groupParent': 'Grupo',
  'groupNoGroup': 'Sem Grupo',
  'settingsTitle': 'Configurações',
  'settingsUser': 'Conta',
  'settingsGroups': 'Grupos',
  'settingsServer': 'Servidor',
  'settingsUsers': 'Usuários',
  'settingsDistanceUnit': 'Unidade de Distância',
  'settingsSpeedUnit': 'Unidade de Velocidade',
  'settingsVolumeUnit': 'Unidade de Volume',
  'settingsTwelveHourFormat': 'Formato de 12 Horas',
  'settingsCoordinateFormat': 'Formato de coordenadas',
  'reportTitle': 'Relatórios',
  'reportDevice': 'Dispositivo',
  'reportGroup': 'Grupo',
  'reportFrom': 'De',
  'reportTo': 'Para',
  'reportShow': 'Mostrar',
  'reportClear': 'Limpar',
  'positionFixTime': 'Tempo',
  'positionValid': 'Válido',
  'positionAccuracy': 'Precisão',
  'positionLatitude': 'Latitude',
  'positionLongitude': 'Longitude',
  'positionAltitude': 'Altitude',
  'positionSpeed': 'Velocidade',
  'positionCourse': 'Direção',
  'positionAddress': 'Endereço',
  'positionProtocol': 'Protocolo',
  'positionDistance': 'Distância',
  'positionRpm': 'RPM',
  'positionFuel': 'Combustível',
  'positionPower': 'Tensão',
  'positionBattery': 'Bateria',
  'positionRaw': 'Bruto',
  'positionIndex': 'Índice',
  'positionHdop': 'HDOP',
  'positionVdop': 'VDOP',
  'positionPdop': 'PDOP',
  'positionSat': 'Sátelites',
  'positionSatVisible': 'Satélites Visíveis',
  'positionRssi': 'RSSI',
  'positionGps': 'GPS',
  'positionRoaming': 'Roaming',
  'positionEvent': 'Evento',
  'positionAlarm': 'Alarme',
  'positionStatus': 'Estado',
  'positionOdometer': 'Odômetro',
  'positionServiceOdometer': 'Odômetro de Serviço',
  'positionTripOdometer': 'Odômetro de Viagem',
  'positionHours': 'Horas',
  'positionSteps': 'Passos',
  'positionInput': 'Entrada',
  'positionOutput': 'Saída',
  'positionBatteryLevel': 'Nível de Bateria',
  'positionFuelConsumption': 'Consumo de Combustível',
  'positionRfid': 'RFID',
  'positionVersionFw': 'Versão de Firmware',
  'positionVersionHw': 'Versão de Hardware',
  'positionIgnition': 'Ignição',
  'positionFlags': 'Sinais',
  'positionCharge': 'Carga',
  'positionIp': 'IP',
  'positionArchive': 'Arquivar',
  'positionVin': 'VIN',
  'positionApproximate': 'Estimado',
  'positionThrottle': 'Acelerador',
  'positionMotion': 'Movimento',
  'positionArmed': 'Alarme Ativado',
  'positionAcceleration': 'Aceleração',
  'positionDeviceTemp': 'Temperatura do Dispositivo',
  'positionOperator': 'Operadora',
  'positionCommand': 'Comando',
  'positionBlocked': 'Bloqueado',
  'positionDtcs': 'DTCs',
  'positionObdSpeed': 'Velocidade OBD',
  'positionObdOdometer': 'Odômetro OBD',
  'positionDriverUniqueId': 'Identificador Único Motorista',
  'positionImage': 'Imagem',
  'positionAudio': 'Áudio',
  'serverTitle': 'Configurações do Servidor',
  'serverZoom': 'Zoom',
  'serverRegistration': 'Registro',
  'serverReadonly': 'Somente leitura',
  'serverForceSettings': 'Forçar configurações',
  'mapTitle': 'Mapa',
  'mapLayer': 'Camada de Mapa',
  'mapCustom': 'Mapa Personalizado',
  'mapCarto': 'Carto Basemaps',
  'mapOsm': 'Open Street Map',
  'mapBingKey': 'Bing Mapas API Key',
  'mapBingRoad': 'Bing Mapas Estradas',
  'mapBingAerial': 'Bing Mapas Aéreo',
  'mapBingHybrid': 'Bing Mapas Híbrido',
  'mapBaidu': 'Baidu',
  'mapYandexMap': 'Mapa Yandex',
  'mapYandexSat': 'Satélite Yandex',
  'mapWikimedia': 'Wikimedia',
  'mapShapePolygon': 'Polígono',
  'mapShapeCircle': 'Círculo',
  'mapShapePolyline': 'Polilinha',
  'mapLiveRoutes': 'Rotas ao Vivo',
  'mapPoiLayer': 'Camada POI',
  'mapKindStreet': 'Rua',
  'mapKindSatellite': 'Satélite',
  'stateTitle': 'Estado',
  'stateName': 'Atributo',
  'stateValue': 'Valor',
  'commandTitle': 'Comando',
  'commandSend': 'Enviar',
  'commandSent': 'Comando enviado',
  'commandQueued': 'Comando na fila',
  'commandUnit': 'Unidade',
  'commandMotionInterval': 'Intervalo de movimento',
  'commandStopInterval': 'Intervalo parado',
  'commandResetOdometer': 'Zerar Odometro',
  'commandAlarmIdle': 'Alarme de Ociosidade',
  'commandAlarmRemove': 'Remover Alarme',
  'commandCustom': 'Comando personalizado',
  'commandDeviceIdentification': 'Identificação do dispositivo',
  'commandPositionSingle': 'Relatório avulso',
  'commandPositionPeriodic': 'Atualização Periódica',
  'commandPositionStop': 'Parar Atualização',
  'commandEngineStop': 'Desligar Motor',
  'commandEngineResume': 'Religar Motor',
  'commandAlarmArm': 'Ativar Alarme',
  'commandAlarmDisarm': 'Desativar Alarme',
  'commandSetTimezone': 'Definir fuso horário',
  'commandRequestPhoto': 'Pegar foto',
  'commandRebootDevice': 'Reiniciar dispositivo',
  'commandSendSms': 'Enviar SMS',
  'commandSendUssd': 'Enviar USSD',
  'commandSosNumber': 'Definir numero SOS',
  'commandSilenceTime': 'Definir Tempo no Silencioso',
  'commandSetPhonebook': 'Definir lista telefônica',
  'commandVoiceMessage': 'Mensagem de voz',
  'commandOutputControl': 'Controle de saída',
  'commandVoiceMonitoring': 'Monitoramento de Voz',
  'commandSetAgps': 'Definir AGPS',
  'commandSetIndicator': 'Definir Indicador',
  'commandConfiguration': 'Configuração',
  'commandGetVersion': 'Obter Versão',
  'commandFirmwareUpdate': 'Atualizar Firmware',
  'commandSetConnection': 'Definir Conexão',
  'commandSetOdometer': 'Definir Odômetro',
  'commandGetModemStatus': 'Obter Status do Modem',
  'commandGetDeviceStatus': 'Obter status do dispositivo',
  'commandModePowerSaving': 'Modificar a Economia de Energia',
  'commandModeDeepSleep': 'Modifique o Sono Profundo',
  'commandMovementAlarm': 'Alarme de Movimento',
  'commandFrequency': 'Frequência',
  'commandTimezone': 'Ajuste de Fuso horário',
  'commandMessage': 'Mensagem',
  'commandRadius': 'Raio',
  'commandEnable': 'Habilitar',
  'commandData': 'Valor',
  'commandIndex': 'Índice',
  'commandPhone': 'Número de Telefone',
  'commandServer': 'Servidor',
  'commandPort': 'Porta',
  'eventAll': 'Todos Eventos',
  'eventDeviceOnline': 'Status online',
  'eventDeviceUnknown': 'Status desconhecido',
  'eventDeviceOffline': 'Status offline',
  'eventDeviceMoving': 'Dispositivo movendo',
  'eventDeviceStopped': 'Dispositivo parado',
  'eventDeviceInactive': 'Dispositivo Inativo',
  'eventDeviceOverspeed': 'Excedido o limite de velocidade',
  'eventDeviceFuelDrop': 'Queda de combustível',
  'eventCommandResult': 'Resultado do comando',
  'eventGeofenceEnter': 'Entrada na cerca virtual',
  'eventGeofenceExit': 'Saída da cerca virtual',
  'eventAlarm': 'Alarme',
  'eventIgnitionOn': 'Ignição ligada',
  'eventIgnitionOff': 'Ignição desligada',
  'eventMaintenance': 'Manutenção necessária',
  'eventTextMessage': 'Mensagem de texto recebida',
  'eventDriverChanged': 'Condutor alterado',
  'eventQueuedCommandSent': 'Comando na fila enviado',
  'eventDeviceFuelIncrease': 'Aumento de combustível',
  'eventMedia': 'Mídia',
  'eventsScrollToLast': 'Rolar para o último',
  'alarmGeneral': 'Genérico',
  'alarmSos': 'SOS',
  'alarmVibration': 'Vibrando',
  'alarmMovement': 'Movendo',
  'alarmLowspeed': 'Baixa velocidade',
  'alarmOverspeed': 'Excesso de velocidade',
  'alarmFallDown': 'Queda',
  'alarmLowPower': 'Baixa potência',
  'alarmLowBattery': 'Bateria Fraca',
  'alarmFault': 'Falha',
  'alarmPowerOff': 'Alimentação desligada',
  'alarmPowerOn': 'Alimentação ligada',
  'alarmDoor': 'Porta',
  'alarmLock': 'Bloqueio',
  'alarmUnlock': 'Desbloqueio',
  'alarmGeofence': 'Cerca virtual',
  'alarmGeofenceEnter': 'Entrando na cerca virtual',
  'alarmGeofenceExit': 'Saiu da Cerca virtual',
  'alarmGpsAntennaCut': 'Antena de GPS cortada',
  'alarmAccident': 'Acidente',
  'alarmTow': 'Rebocar',
  'alarmIdle': 'Ocioso',
  'alarmHighRpm': 'Alto RPM',
  'alarmHardAcceleration': 'Aceleração difícil',
  'alarmHardBraking': 'Frenagem brusca',
  'alarmHardCornering': 'Curva perigosa',
  'alarmLaneChange': 'Mudança de faixa',
  'alarmFatigueDriving': 'Condutor Cansado',
  'alarmPowerCut': 'Alimentação cortada',
  'alarmPowerRestored': 'Alimentação restaurada',
  'alarmJamming': 'Interferência',
  'alarmTemperature': 'Temperatura',
  'alarmParking': 'Estacionamento',
  'alarmShock': 'Choque',
  'alarmBonnet': 'Capô',
  'alarmFootBrake': 'Freio de mão',
  'alarmFuelLeak': 'Vazamento de combustível',
  'alarmTampering': 'Manipulando',
  'alarmRemoving': 'Removendo',
  'notificationType': 'Tipo de Notificação',
  'notificationAlways': 'Todos os Dispositivos',
  'notificationNotificators': 'Canais',
  'notificatorWeb': 'Web',
  'notificatorMail': 'E-Mail',
  'notificatorSms': 'SMS',
  'notificatorFirebase': 'Móvel',
  'reportNew': 'Novo Relatório',
  'commandNew': 'Novo Comando',
  'reportRoute': 'Rota',
  'reportEvents': 'Eventos',
  'reportTrips': 'Viagens',
  'reportStops': 'Paradas',
  'reportSummary': 'Resumo',
  'reportChart': 'Gráfico',
  'reportConfigure': 'Configurar',
  'reportEventTypes': 'Tipos de Eventos',
  'reportChartType': 'Tipo do Gráfico',
  'reportShowMarkers': 'Mostrar Marcadores',
  'reportExport': 'Exportar',
  'reportEmail': 'Relatório de E-mail',
  'reportPeriod': 'Período',
  'reportCustom': 'Personalizadas',
  'reportToday': 'Hoje',
  'reportYesterday': 'Ontem',
  'reportThisWeek': 'Esta Semana',
  'reportPreviousWeek': 'Semana Anterior',
  'reportThisMonth': 'Este Mês',
  'reportPreviousMonth': 'Mês Anterior',
  'reportDeviceName': 'Nome do Dispositivo',
  'reportAverageSpeed': 'Velocidade Média',
  'reportMaximumSpeed': 'Velocidade Máxima',
  'reportEngineHours': 'Horas ligado',
  'reportDuration': 'Duração',
  'reportStartTime': 'Hora inicial',
  'reportStartAddress': 'Endereço inicial',
  'reportEndTime': 'Hora final',
  'reportEndAddress': 'Endereço final',
  'reportSpentFuel': 'Gasto de Combustível',
  'reportStartOdometer': 'Início do odômetro',
  'reportEndOdometer': 'Fim do odômetro',
  'statisticsTitle': 'Estatísticas',
  'statisticsCaptureTime': 'Tempo de Captura',
  'statisticsActiveUsers': 'Usuários ativos',
  'statisticsActiveDevices': 'Dispositivos ativos',
  'statisticsRequests': 'Pedidos',
  'statisticsMessagesReceived': 'Mensagens Recebidas',
  'statisticsMessagesStored': 'Mensagens armazenadas',
  'statisticsGeocoder': 'Solicitações de Geocódigo',
  'statisticsGeolocation': 'Solicitações de Geolocalização',

  'maintenanceStart': 'Começar',
  'maintenancePeriod': 'Período',

  'customerCpfCnpj': 'CPF/CNPJ',
  'customerType': 'Tipo',
  'customerName': 'Nome',
  'customerStreet': 'Rua',
  'customerNumber': 'Número',
  'customerComplement': 'Complemento',
  'customerNeighborhood': 'Bairro',
  'customerCity': 'Cidade',
  'customerState': 'Estado',
  'customerPostalCode': 'CEP',
  'customerPhone': 'Telefone',
  'customerCellphone': 'Celular',
  'customerNotificationEmail': 'E-mail principal',
  'customerSecondaryEmail': 'E-mail secundário',
  'customerCategory': 'Categoria',
  'customerStatus': 'Status',
  'customerNotes': 'Notas',
  'customerDisabled': 'Desabilitado',
  'customerRegistrationDate': 'Data de Cadastro',
  'customerExpirationDate': 'Data de Expiração',
  'customerInvoiceDay': 'Dia de Cobrança',
  'customerAttributes': 'Atributos',

  'categoryArrow': 'Seta',
  'categoryAnimal': 'Animal',
  'categoryBicycle': 'Bicicleta',
  'categoryBoat': 'Barco',
  'categoryBus': 'Ônibus',
  'categoryCar': 'Carro',
  'categoryCrane': 'Guindaste',
  'categoryHelicopter': 'Helicóptero',
  'categoryMotorcycle': 'Motocicleta',
  'categoryOffroad': 'Offroad',
  'categoryPerson': 'Pessoa',
  'categoryPickup': 'Pick-up',
  'categoryPlane': 'Avião',
  'categoryShip': 'Navio',
  'categoryTractor': 'Trator',
  'categoryTrain': 'Trem',
  'categoryTram': 'Bonde',
  'categoryTrolleybus': 'Ônibus Elétrico',
  'categoryTruck': 'Caminhão',
  'categoryVan': 'Van',
  'categoryDefault': 'Padrão',
  'categoryDrone': 'Drone',
  'category_20ft_container': 'Container de 20 pés',
  'category_40ft_container': 'Container de 40 pés',
  'category_airliner': 'Avião de passageiros',
  'category_ambulance': 'Ambulância',
  'category_backhoe_loader': 'Retroescavadora',
  'category_bicycle': 'Bicicleta',
  'category_box_trailer': 'Reboque de Caixa',
  'category_bulldozer': 'Escavadeira',
  'category_bus': 'Ônibus',
  'category_cabriolet': 'Cabriolé',
  'category_camper_van': 'Van de Campista',
  'category_car': 'Carro',
  'category_car_trailer': 'Reboque de Carro',
  'category_caravan': 'Caravana',
  'category_concrete_pump': 'Caminhão Bomba de Concreto',
  'category_containerized_generator_trailer': 'Reboque de Gerador Container',
  'category_cruiser_motorcycle': 'Motocicleta cruzadora',
  'category_excavator': 'Escavadora',
  'category_executive_car': 'Carro Executivo',
  'category_farm_tractor': 'Trator Agrícola',
  'category_fire_truck': 'Caminhão de Bombeiros',
  'category_flatbed_trailer_bulkhead': 'Plataforma de Reboque de Mesa',
  'category_forklift_truck': 'Empilhadeira',
  'category_fuel_tank_truck': 'Caminhão Tanque de Combustível',
  'category_gully_emptier': 'Caminhão Vácuo',
  'category_hatchback': 'Carro Hatchback',
  'category_haul_truck': 'Caminhão de Transporte',
  'category_helicopter': 'Helicóptero',
  'category_luxury_car': 'Carro de Luxo',
  'category_mixer_truck': 'Caminhão Misturador (Betoneira)',
  'category_motorcycle': 'Motocicleta',
  'category_pedicab': 'Pedicab',
  'category_pickup': 'Pickup',
  'category_police': 'Viatura',
  'category_recovery_truck': 'Caminhão de Recuperação',
  'category_reefer_trailer': 'Reboque Reefer',
  'category_roadside_assistance_car': 'Carro de Assistência na Estrada',
  'category_van': 'Van',
  'category_sewer_cleaning_truck': 'Caminhão de Limpeza de Esgoto',
  'category_skip': 'Caixa Estacionária',
  'category_steamroller': 'Rolo Compressor',
  'category_swap_tank_container': 'Container de Tanque de Troca',
  'category_tanker_trailer': 'Reboque Tanque',
  'category_taxi': 'Táxi',
  'category_tow_rig': 'Pickup Reboque',
  'category_tow_truck': 'Caminhão Reboque',
  'category_tractor_flatbed_trailer': 'Caminhão Reboque Estrado',
  'category_tractor_trailer': 'Caminhão',
  'category_tractor_unit': 'Caminhão (Unidade Trator)',
  'category_truck': 'Caminhão Baú',
  'category_ultra_silent_generator_trailer': 'Reboque Gerador Ultra Silencioso',
  'category_waste_tanker_trailer': 'Reboque Tanque de Resíduos',
  'category_water_tank_truck': 'Caminhão Tanque de Água (Pipa)',
  'category_default': 'Padrão',
  'category_drone': 'Drone',
};

