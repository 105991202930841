import BaseService from './base-service';

export default class EventService extends BaseService {
    constructor() {
        super('events');
    }

    get(eventId) {
        return this.__get(`events/${eventId}`);
    }

    list(query) {
        throw new Error('method not implemented');
    }

    sendComment({eventId, comment, attributes}) {
        return this.__post(`events/${eventId}/comment`, {eventId, comment, attributes});
    }
}
