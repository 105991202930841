import React from 'react';
import styled from 'styled-components';

const Style = styled.div`
  display: flex;
  padding: 0 1rem;
  line-height: 1rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;

  > div {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;

const Driver = ({theme, driver}) => {
  return (
    <Style className="mt-1">
      <div>
        <strong className="mb-1" style={{color: theme}}>
          Motorista
        </strong>
        <span className="text-gray">{driver}</span>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/default.jpg`}
        alt="profile"
        className="rounded-circle"
      />
    </Style>
  );
};

export default Driver;
