import {useEffect, useState} from 'react';
import {mergeAll} from 'rxjs/operators';
import useSocket from '../../../hooks/use-socket';
import useDevices from '../../../hooks/use-devices';
import useStore from '../../../hooks/use-store';

let unsubscribe$ = null;

const useMapLabel = (state, dispatch, instance) => {
  const [label, setLabel] = useState(false);
  const {positions} = useSocket();
  const {store} = useStore();
  const {events, devices} = store;
  const {instance: map, deviceMap} = state;

  useEffect(() => {
    return () => unsubscribe$ && unsubscribe$.unsubscribe();
  });

  useEffect(() => {
    if (map && deviceMap) {
      if (label) {
        if (events.currentDevice || devices.selectedDevice) {
          const device = events.currentDevice || devices.selectedDevice;
          const {deviceId, name, currentPosition} = device;
          if (currentPosition) {
            const {longitude, latitude} = currentPosition;
            map.addLabelOverlay(deviceId, name, [longitude, latitude]);
          }
        } else {
          for (const [deviceId, {name, currentPosition}] of deviceMap) {
            if (currentPosition) {
              const {longitude, latitude} = currentPosition;
              map.addLabelOverlay(deviceId, name, [longitude, latitude]);
            }
          }
        }

        map.instance.render();

        unsubscribe$ = positions
            .pipe(mergeAll())
            .subscribe(({deviceId, longitude, latitude}) => {
              if (events.currentDevice || devices.selectedDevice) {
                const device = events.currentDevice || devices.selectedDevice;
                if (device.deviceId !== deviceId) {
                  return;
                }
              }
              map.updateOverlayPosition(`label-${deviceId}`, [
                longitude,
                latitude,
              ]);
            });
      } else {
        if (unsubscribe$) {
          unsubscribe$.unsubscribe();
          map.removeOverlays('label');
        }
      }
    }
  }, [map, deviceMap, label]);

  return {label, setLabel};
};

export default useMapLabel;
