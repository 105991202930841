import styled from 'styled-components';
import {colors, shadows} from '../../styleVariables';

export const BottomStyle = styled.div`
  z-index: 1 !important;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  width: 100%;
  padding-bottom: 2rem !important;
`;

export const RightStyle = styled.div`
  position: absolute;
  top: 0;
  right: 2.5rem;
`;

export const CenterStyle = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;

  .badge {
    background-color: ${colors.gray5};
    color: ${colors.gray};
  }
`;

export const DevicesStyle = styled.div`
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
`;

export const DeviceInfoStyle = styled.div`
  > span {
    :first-child {
      font-size: 0.9rem;
      color: ${colors.gray};
      display: flex;
      justify-content: center;
    }
  }
`;

export const DeviceFooterStyle = styled.div`
  margin-top: 5px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;

  * {
    opacity: 1;
  }

  .red * {
    color: ${colors.red1};
  }

  .yellow * {
    color: ${colors.orange1};
  }

  .green * {
    color: ${colors.green2};
  }

  .red * {
    color: ${colors.red};
  }

  .fal {
    font-size: 1.3rem;

    + small {
      font-size: 0.8rem !important;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1rem;

    :not(:last-child) {
      border-right: 1px solid ${colors.gray1};
    }

    span {
      color: ${colors.gray1};

      :first-child {
        margin-bottom: 0.1rem;
      }
    }

    small {
      color: ${colors.gray1};
      font-size: 0.8rem !important;
    }
  }
`;
