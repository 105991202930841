import BaseService from "./base-service";

export default class ServerService extends BaseService {

    constructor() {
        super('server');
    }

    save(data) {
        throw new Error("method not implemented");
    }

    remove(id) {
        throw new Error("method not implemented");
    }
}
