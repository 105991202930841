import React from 'react';
import RoundButton from '../../../components/roundButton';
import {CenterStyle} from '../styles';

const CenterActions = ({
                           trace,
                           traceToggle,
                           followToogle,
                           follow,
                           onBlock,
                           createGeofence,
                       }) => {
    return (
        <CenterStyle id="center-actions">
            <RoundButton
                labelDirection="bottom"
                withBorder
                label="Rota"
                active={trace}
                onClick={traceToggle}
            >
                <span className="fal fa-route"/>
            </RoundButton>

            <RoundButton
                labelDirection="bottom"
                withBorder
                onClick={() => onBlock(true)}
                className="mx-2"
                label="Bloquear"
            >
                <span className="fas fa-lock-alt text-danger"/>
            </RoundButton>

            <RoundButton
                labelDirection="bottom"
                withBorder
                onClick={() => onBlock(false)}
                label="Liberar"
            >
                <span className="fas fa-unlock-alt text-green"/>
            </RoundButton>

            <RoundButton
                withBorder
                labelDirection="bottom"
                className="mx-2"
                label="Seguir"
                active={follow}
                onClick={followToogle}
            >
                <span className="fas fa-location-arrow"/>
            </RoundButton>

            <RoundButton
                label="Cerca"
                withBorder
                labelDirection="bottom"
                className=""
                onClick={createGeofence}
            >
        <span
            style={{fontSize: '2rem'}}
            className="fal fa-location-circle fa-2x"
        />
            </RoundButton>
        </CenterStyle>
    );
};

export default CenterActions;
