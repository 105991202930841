import React, {useRef, useState} from 'react';
import {FormGroup, Label} from 'reactstrap';
import {Form} from '@unform/web';
import {Input as UInput} from '../../components/input';
import * as Yup from 'yup';
import ReactSelect from '../../components/react-select';
import './notification.sass';
import ToggleButton from '../../components/toggle-button';
import I18nMessage from '../../components/I18nMessage';
import {FieldSet} from '../../components/fieldset';
import {GroupButtonForm} from '../../components/group-button-form';

const NotificationForm = (props) => {
    const {
        cancel,
        data,
        edit,
        newRegister,
        updateRegister,
        alarmTypes,
        allNotificationTypes,
        allNotificator,
        calendars,
        i18n,
        loading,
    } = props;

    const formRef = useRef(null);

    const [showFieldAlarms, showAlarms] = useState(edit ? data.type === 'alarm' : false);

    const schema = Yup.object().shape({
        type: Yup.string().required('Nome é necessário'),
        always: Yup.string().notRequired(),
        alarms: Yup.array().notRequired(),
        notificators: Yup.array().notRequired(),
        id: Yup.number().transform((value) => (isNaN(value) ? undefined : parseInt(value))),
    });

    const handleSubmit = async (data) => {
        try {
            await schema.validate(data, {
                abortEarly: false,
            });

            data = schema.cast(data);

            data.notificators = data.notificators.toString();

            if (data.type === 'alarm') {
                data.attributes = {alarms: data.alarms.toString()};
                delete data.alarms;
            }

            console.log(data);

            edit ? updateRegister(data) : newRegister(data);
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const onChange = (selectedOption) => {
        if (selectedOption !== null) {
            showAlarms(selectedOption.id === 'alarm');
        } else {
            showAlarms(edit ? data.type === 'alarm' : false);
        }
    };

    const renderAlarm = () => {
        if (showFieldAlarms) {
            return (
                <FormGroup className="custom-formGroup">
                    <Label for="name">{i18n.sharedAlarms}</Label>
                    <ReactSelect id={'alarms'} name="alarms" options={alarmTypes} multiple={true}/>
                </FormGroup>);
        }
        return null;
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit} schema={schema} initialData={data}>
            {!!edit && <UInput hidden type="number" name="id"/>}

            <FieldSet className="text-left border p-4" title={'Identificação'}>
               <>
                   <FormGroup className="custom-formGroup">
                       <Label for="name"><I18nMessage id="notificationType"/>*</Label>
                       <ReactSelect id={'type'} name="type"
                                    onChange={onChange}
                                    options={allNotificationTypes}/>
                   </FormGroup>
                   <FormGroup className="custom-formGroup">
                       <Label><I18nMessage id="notificationAlways"/></Label>
                       <ToggleButton name={'always'} id={'always'}/>
                   </FormGroup>
                   {renderAlarm()}
                   <FormGroup className="custom-formGroup">
                       <Label for="name"><I18nMessage id="notificationNotificators"/>*</Label>
                       <ReactSelect id={'notificators'} name="notificators" options={allNotificator}
                                    multiple={true}/>
                   </FormGroup>
                   <FormGroup className="custom-formGroup">
                       <Label for="groupId"><I18nMessage id="sharedCalendar"/></Label>
                       <ReactSelect id={'calendarId'} name="calendarId" options={calendars}/>
                   </FormGroup>
               </>
            </FieldSet>
            <br/>
            <GroupButtonForm
                loading={loading}
                cancel={cancel}
                submit={() => formRef.current.submitForm()}
            />
        </Form>
    );
};

export default NotificationForm;
