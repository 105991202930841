export const knotsToKmH = (knots) => knots * 1.852;

export const kmhToKnots = (kmh) => kmh * 0.5399568034557235;

export const knotsToMph = (knots) => knots * 1.150779;

export const mToKm = (m) => m * 0.001;

export const mToMi = (m) => m * 0.000621371;

export const mToNmi = (m) => m * 0.000539957;

export const ltrToImpGal = (ltr) => ltr * 4.546;

export const ltrToUsGal = (ltr) => ltr * 3.785;

export const convertSpeedTo = (knots, type) => {
  if (!knots) return false;

  switch (type) {
    case 'kmh':
      return knotsToKmH(knots);
    case 'mph':
      return knotsToKmH(knots);
    default:
      return knots;
  }
};

export const nomenclatureSpeedOf = (speedUnit) => {
  switch (speedUnit) {
    case 'kmh':
      return 'Km/h';
    case 'mph':
      return 'Mph';
    default:
      return 'Kn';
  }
};

export const convertDistanceTo = (m, type) => {
  if (!m) return false;

  switch (type) {
    case 'km':
      return mToKm(m);
    case 'mi':
      return mToMi(m);
    case 'nmi':
      return mToNmi(m);
    default:
      return m;
  }
};

export const nomenclatureDistance = (distanceUnit) => {
  switch (distanceUnit) {
    case 'km':
      return 'Km';
    case 'mi':
      return 'Milha';
    case 'nmi':
      return 'Milhas Náuticas';
    default:
      return 'Metros';
  }
};

export const convertVolumeTo = (ltr, type) => {
  if (!ltr) return false;

  switch (type) {
    case 'impGal':
      return ltrToImpGal(ltr);
    case 'usGal':
      return ltrToUsGal(ltr);
    default:
      return ltr;
  }
};

export const nomenclatureVolume = (volumeUnit) => {
  switch (volumeUnit) {
    case 'impGal':
      return 'Imp. Galão';
    case 'usGal':
      return 'U.S. Galão';
    default:
      return 'L';
  }
};

export const icons = [
  [90, 'fal fa-battery-full fa-2x'],
  [70, 'fal fa-battery-three-quarters fa-2x'],
  [40, 'fal fa-battery-half fa-2x'],
  [10, 'fal fa-battery-quarter fa-2x'],
  [0, 'fal fa-battery-empty fa-2x'],
];
