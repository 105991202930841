import {useCallback, useEffect} from 'react';
import useStore from './use-store';
import useServices from './use-services';

let started = false;

const useUsers = () => {
    const {store} = useStore();
    const {userService} = useServices();

    const getUsers = useCallback(async () => {
        if (store.userStore.list.length === 0 && !started) {
            started = true;
            const users = await userService.list();
            users.sort((a, b) => a.name.localeCompare(b.name));
            store.userStore.addAll(users);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await getUsers();
        })();
    }, []);

    return {users: store.userStore};
};

export default useUsers;
