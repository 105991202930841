import styled from 'styled-components';
import {shadows, colors} from '../../styleVariables';

export const ResultLength = styled.small`
  color: ${colors.gray1};
  position: absolute;
  right: 16px;
`;
export const Styles = styled.div`
  min-width: 100%;

  .header {
    border: 1px solid ${colors.gray5};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: white;
    box-shadow: ${shadows.shadow};
    color: ${(props) => props.theme};
    font-weight: bold;
    overflow: hidden;
    height: 3.125rem;
    padding: 0.75rem 1.25rem;
    text-align: left;
  }

  .body {
    .tr {
      font-size: 0.9rem;
      color: ${colors.black};
      cursor: pointer;

      &.active {
        color: ${(props) => props.theme};
        border-left: 4px solid;
        font-weight: bold;
      }

      &:not(:first-child) {
        border-top: 2px solid white;
      }

      &:last-child {
      }

      &:nth-of-type(odd) {
        background-color: ${colors.gray5};
        .td {
          background-color: ${colors.gray5};
        }
      }

      &:nth-of-type(even) {
        background-color: ${colors.gray6};
        .td {
          background-color: ${colors.gray6};
        }
      }

      .td {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 1.25rem;
      }
    }
  }

  .table {
    border-spacing: 0;
    border: 1px solid black;

    .tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;
