import React, {useState, useEffect} from 'react';
import {Button} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import I18nMessage from '../../components/I18nMessage';
import {useIntl} from 'react-intl';

const AttributesTable = (props) => {
  const {
    cancel,
    attributeForm,
    removeAttribute,
    data,
  } = props;

  const intl = useIntl();
  const [attributes, setAttributes] = useState([]);
  const [row, setSelectedRow] = useState(null);

  useEffect(() => {
    if (data && data.hasOwnProperty('attributes')) {
      const attributes = Object.entries(data.attributes);
      const items = [];
      attributes.map( (a, i) => {
        items.push({id: i+1, name: a[0], value: a[1]});
      });
      setAttributes(items);
    }
  }, []);

  const onRowSelect = (row, isSelected, e) => {
    if (isSelected && row.id === 0) {
      return false;
    }
    setSelectedRow(row);
    return true;
  };

  const bgColor = (row, isSelect) => {
    if (isSelect && row.id === 0) {
      return null;
    }
    return '#2497e9';
  };

  const selectRowProp = {
    mode: 'radio',
    bgColor: bgColor,
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const remove = () =>{
    removeAttribute(data, row.name);
    attributes.splice(attributes.findIndex((item) => item.name === row.name), 1);
  };

  const createCustomToolBar = (props) => {
    return (
      <>
        <div className={'rounded-group-button'}>
          <Button className={'btn-circle btn-lg'} onClick={cancel} type="button">
            <i className="fas fa-arrow-left"></i>
          </Button>
          <Button className={'btn-circle btn-lg'} onClick={() => attributeForm({})}>
            <i className="fas fa-plus"></i>
          </Button>
          <Button
            disabled={!row}
            className={'btn-circle btn-lg'}
            onClick={() => attributeForm(row)}
          >
            <i className="fas fa-edit"></i>
          </Button>
          <Button
            disabled={!row}
            className={'btn-circle btn-lg'}
            onClick={() => remove()}
          >
            <i className="fas fa-minus"></i>
          </Button>
        </div>
        <div className="search-toolbar">{props.components.searchPanel}</div>
      </>
    );
  };

  const options = {
    toolBar: createCustomToolBar,
    noDataText: intl.formatMessage({id: 'sharedNoDataText'}),
  };

  return (
    <BootstrapTable
      options={options}
      data={attributes}
      search={true}
      selectRow={selectRowProp}
      searchPlaceholder={intl.formatMessage({id: 'sharedSearch'})} striped hover>
      <TableHeaderColumn dataField='id' isKey={ true } hidden>ID</TableHeaderColumn>
      <TableHeaderColumn dataField='name'><I18nMessage id="sharedName"/></TableHeaderColumn>
      <TableHeaderColumn dataField='value'><I18nMessage id="stateValue"/></TableHeaderColumn>
    </BootstrapTable>
  );
};

export default AttributesTable;
