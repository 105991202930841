import {types} from 'mobx-state-tree';
import i18n from './translate-store';
import UnitModel from '../models/unit-model';

const DistanceUnitStore = types
    .model('DistanceUnitStore', {
        units: types.optional(types.array(UnitModel), []),
    })
    .actions((self) => ({
        afterCreate() {
            self.setUnits([{
                key: 'km',
                name: i18n.strings.sharedKm,
                factor: 0.001,
            }, {
                key: 'mi',
                name: i18n.strings.sharedMi,
                factor: 0.000621371,
            }, {
                key: 'nmi',
                name: i18n.strings.sharedNmi,
                factor: 0.000539957,
            }]);
        },
        setUnits(data) {
            self.units = data;
        },
        findRecord(key, unit) {
            return self.units.find((u) => u[key] === unit);
        },
        convertValue(value, unit, back) {
            if (!unit) {
                unit = 'km';
            }
            const model = self.findRecord('key', unit);
            return back ? value / model.factor : value * model.factor;
        },

        formatValue(value, unit, convert) {
            if (!unit) {
                unit = 'km';
            }
            const model = self.findRecord('key', unit);
            return (convert ? self.convertValue(value, unit) : value).toFixed(2) + ' ' + model.name;
        },
    }));

export default DistanceUnitStore;

