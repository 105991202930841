import {useCallback, useEffect, useState} from 'react';
import useStore from '../../../hooks/use-store';
import useServices from "../../../hooks/use-services";

let geofences = null;

const useMapGeofences = (state, dispatch, instance) => {

    const {store} = useStore();
    const { geofenceService } = useServices();
    const [showGeofences, setShowGeofences] = useState(true);
    const {instance: map} = state;

    const fetchGeofences = useCallback(async () => {
        geofences = await geofenceService.list();
        drawGeofences();
    }, [map]);

    const drawGeofences = () => {
        if (geofences) {
            geofences.forEach((geofence) => {
                map.addGeofence(geofence);
            });
        }
    };

    useEffect(() => {
        if (map) {
            if (!geofences && showGeofences) {
                map.initGeofencesLayer();
                fetchGeofences();
            } else if (geofences && !showGeofences) {
                map.removeGeofences();
            } else if (geofences && showGeofences) {
                drawGeofences();
            }
        }
    }, [map, showGeofences]);

    return {devices: store.devices, showGeofences, setShowGeofences};
};

export default useMapGeofences;
