import React from 'react';
import PropTypes from 'prop-types';
import SharedMapView from './views/Map';
import HashValidatingView from './views/HashValidating';
import HashNotValidView from './views/HashNotValid';
import {useSharedMapViewModel} from './useSharedMapViewModel';

/** */
function SharedMap(props) {
    const sharedId = props.match.params.sharedId;

    const {isLoading, error} = useSharedMapViewModel(sharedId);

    if (isLoading) {
        return <HashValidatingView />;
    }

    if (error !== null) {
        return <HashNotValidView />;
    };

    return <SharedMapView />;
};

export default SharedMap;

SharedMap.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            sharedId: PropTypes.string,
        }),
    }),
};
