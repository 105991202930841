import {observer} from 'mobx-react';
import 'moment/locale/pt-br';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {mergeAll, tap} from 'rxjs/operators';
import {AdminContext} from '../../apiContexts';
import NavVertical from '../../components/navVertical';
import useSocket from '../../hooks/use-socket';
import useStore from '../../hooks/use-store';
import {logOut} from '../../services/authService';
import NavBar from '../../views/admin/NavBar';
import EventContainer from '../event-container';
import {AdminGlobalStyle, AdminStyle} from '../../views/admin/styles';
import ModalEnum from '../../views/modals/modalEnum';
import history from '../../routes/history';
import PropTypes from 'prop-types';
import useServices from '../../hooks/use-services';
import useDevices from '../../hooks/use-devices';
import useCustomers from '../../hooks/use-customers';
import useUsers from '../../hooks/use-users';
import usePayProvider from '../../hooks/use-pay-provider';
import {getSnapshot} from 'mobx-state-tree';

let positionsCache;

const AdminContainer = (props) => {
    const {
        children,
    } = props;

    const intl = useIntl();
    const {positionService, driverService} = useServices();
    const {store} = useStore();
    const {devices: devicesStore} = useDevices();
    const {users} = useUsers();
    const {customers} = useCustomers({all: true});
    const {auth} = store;
    const {positions: positionsSocket} = useSocket();
    const [devicesCustomer, setDevicesCustomer] = useState([]);

    useEffect(() => {
        driverService.list().then((drivers) => {
            store.drivers.setDrivers(drivers);
        });
    }, []);

    // TODO: [MON-34] Refatorar lógica de associação de devices com customer
    useEffect(() => {
        (async () => {
            const _devices = getSnapshot(devicesStore.devices).map((device) => {
                const obj = Object.assign({}, device);
                if (customers.list && customers.list.length > 0) {
                    const customer = customers.list.find((customer) => customer.id === device.customerId);
                    if (customer) {
                        obj.customerName = customer.name;
                        obj.customerCpfCnpj = customer.cpfCnpj;
                    }
                }
                return obj;
            });
            setDevicesCustomer(_devices);
        })();
    }, [customers.list, devicesStore.list]);

    useEffect(() => {
        if (devicesStore.list.length) {
            (async () => {
                if (!positionsCache && !devicesStore.updatedPositions) {
                    positionsCache = await positionService.list();
                    devicesStore.updatePositions([...positionsCache]);
                }
                positionsSocket
                    .pipe(
                        tap((positions) => {
                            if (positions.length > 0) {
                                devicesStore.updatePositions(positions);
                                positionsCache = positions;
                            }
                        }),
                        mergeAll(),
                    )
                    .subscribe((position) => {
                        devicesStore.updatePosition(position);
                    });
            })();
        }
    }, [devicesStore.list, devicesStore.updatedPositions]);

    const links = useMemo(
        () => [
            {
                label: intl.formatMessage({id: 'mapTitle'}),
                icon: 'fad fa-map-marked-alt',
                type: ModalEnum.MAP,
                fn: () => history.push('/admin/devices'),
            },
            {
                label: intl.formatMessage({id: 'sharedSinister'}),
                icon: 'fad fa-shield',
                type: ModalEnum.SINISTER,
                fn: () => history.push('/admin/manage/sinister'),
            },
            {
                label: intl.formatMessage({id: 'sharedCustomers'}),
                icon: 'fad fa-book-user',
                type: ModalEnum.CUSTOMERS,
                fn: () => history.push('/admin/manage/customers'),
            },
            {
                label: intl.formatMessage({id: 'sharedDevice'}),
                icon: 'fad fa-car',
                type: ModalEnum.DEVICE,
                fn: () => history.push('/admin/manage/device'),
            },
            {
                label: intl.formatMessage({id: 'settingsUsers'}),
                icon: 'fad fa-users',
                type: ModalEnum.USERS,
                fn: () => history.push('/admin/manage/users'),
            },
            {
                label: intl.formatMessage({id: 'groupDialog'}),
                icon: 'fad fa-object-group',
                type: ModalEnum.GROUP,
                fn: () => history.push('/admin/manage/groups'),
            },
            {
                label: intl.formatMessage({id: 'sharedGeofence'}),
                icon: 'fad fa-location-circle',
                type: ModalEnum.GEOFENCE,
                fn: () => history.push('/admin/manage/geofences'),
            },
            {
                label: intl.formatMessage({id: 'sharedDriver'}),
                icon: 'fad fa-steering-wheel',
                type: ModalEnum.DRIVER,
                fn: () => history.push('/admin/manage/drivers'),
            },
            {
                label: intl.formatMessage({id: 'sharedNotification'}),
                icon: 'fad fa-envelope-open-text',
                type: ModalEnum.NOTIFICATION,
                fn: () => history.push('/admin/manage/notifications'),
            },
            {
                label: intl.formatMessage({id: 'sharedCalendar'}),
                icon: 'fad fa-calendar-alt',
                type: ModalEnum.CALENDAR,
                fn: () => history.push('/admin/manage/calendars'),
            },
            {
                label: intl.formatMessage({id: 'sharedMaintenance'}),
                icon: 'fad fa-tools',
                type: ModalEnum.MAINTENANCE,
                fn: () => history.push('/admin/manage/maintenance'),
            },
            {
                label: intl.formatMessage({id: 'settingsServer'}),
                icon: 'fad fa-server',
                type: ModalEnum.SERVER,
                fn: () => history.push('/admin/manage/server'),
            },
            // {
            //     label: intl.formatMessage({id: 'settingsUser'}),
            //     icon: 'fad fa-user-tie',
            //     type: ModalEnum.ACCOUNT,
            //     fn: () => history.push('/admin/manage/account'),
            // },
            {
                label: intl.formatMessage({id: 'sharedLogout'}),
                icon: 'fad fa-door-open',
                fn: logOut,
            },
        ],
        [],
    );

    const filterLinks = useCallback(() => {
        const profile = auth.profile;
        if (profile === 'monitor') {
            return links.filter((l) => l.type !== ModalEnum.SERVER);
        }
        return links;
    }, [links]);

    return (
        <>
            <AdminGlobalStyle/>
            <AdminStyle>
                <AdminContext.Provider>
                    <NavBar devices={devicesCustomer} users={users} customers={customers}/>
                    <EventContainer/>
                    <NavVertical links={filterLinks()}/>
                    <div id="children">{children}</div>
                </AdminContext.Provider>
            </AdminStyle>
        </>
    );
};

export default observer(AdminContainer);

AdminContainer.propTypes = {
    children: PropTypes.any,
};
