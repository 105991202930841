import React from 'react';
import styled from 'styled-components';

export const MapStatus = styled.div`
      width: 10px;
      height: 10px;
      margin-right: 30px;
      display: inline-block;
      padding: 0.7rem 0.7rem;
      float: right;
      border-radius: 50%;
`;

const TextInfo = styled.span`
    color: black;
    position: absolute;
    font-size: 0.6rem;
    margin-left: -15px;
    padding-top: 12px;
`;

const MapStatusGreen = styled(MapStatus)`
  background-color: #008B00;
`;

const MapStatusRed= styled(MapStatus)`
  background-color: #CE3834;
`;

const MapStatusGray = styled(MapStatus)`
  background-color: #9CA8B4;
`;

const MapStatusYellow = styled(MapStatus)`
  background-color: #FEA100;
`;

const MapStatusBlue = styled(MapStatus)`
  background-color: #356DD5;
`;


export const LegendContainer = styled.div`
      position: absolute;
      bottom: 10px;
      right: 0;
      padding: 30px;
      z-index: 900
`;

const MapLegend = () => {
    return <LegendContainer className="justify-content-end">
        <MapStatusGreen><TextInfo>Online</TextInfo></MapStatusGreen>
        <MapStatusBlue><TextInfo>Ligado</TextInfo></MapStatusBlue>
        <MapStatusYellow><TextInfo>Unknown</TextInfo></MapStatusYellow>
        <MapStatusRed><TextInfo>Alarme</TextInfo></MapStatusRed>
        <MapStatusGray><TextInfo>Offline</TextInfo></MapStatusGray>
    </LegendContainer>;
};

export default MapLegend;
