import React, {useContext, useEffect, useState} from 'react';
import GeofenceForm from '../../views/geofence/geofence-form';
import GeofenceTable from '../../views/geofence/geofence-table';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {MapContext, ModalContext} from '../../apiContexts';
import useServices from "../../hooks/use-services";


const GeofenceContainer = (props) => {

    const intl = useIntl();
    const {toast} = useAlerts();
    const {geofenceService, calendarService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);

    const [area] = useState(null);
    const [view, setView] = useState('geofenceList');
    const [calendars, setCalendars] = useState([]);
    const [geofences, setGeofences] = useState([]);
    const [data, setData] = useState({});

    const mapInstance = useContext(MapContext).map;

    useEffect(() => {
        (async () => {
            const geofences = await geofenceService.list();
            setGeofences(geofences);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const items = [];
            const calendars = await calendarService.list();
            calendars.map((calendar) => {
                items.push({id: calendar.id, title: calendar.name});
            });
            setCalendars(items);
        })();
    }, []);

    const geofenceForm = (data) => {
        setData(Object.keys(data).length ? data : {});
        setView('geofenceForm');
    };

    const newRegister = async (data) => {
        try {
            showModalLoading(true);
            if (area) {
                data['area'] = area;
            }
            const result = await geofenceService.save(data);
            setGeofences([...geofences, result]);
            if (mapInstance) {
                mapInstance.addGeofence(result);
            }
            setView('geofenceList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            console.log(e);
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'geofenceList') => {
        try {
            showModalLoading(true);
            if (area) {
                data['area'] = area;
            }
            const index = geofences.findIndex(({id}) => id === data.id);
            const result = await geofenceService.update({
                ...geofences[index],
                ...data,
            });
            geofences.splice(index, 1, result);
            setGeofences([...geofences]);
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await geofenceService.remove(data.id);
            setGeofences(geofences.filter(({id}) => id !== data.id));
            setView('geofenceList');
            if (mapInstance) {
                mapInstance.removeGeofence(data.id);
            }
            showModalLoading(false);
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    return (
        <>
            {
                {
                    geofenceList: (
                        <GeofenceTable
                            list={geofences}
                            geofenceForm={geofenceForm}
                            removeRegister={removeRegister}
                            loading={isModalLoading}
                        />
                    ),
                    geofenceForm: (
                        <GeofenceForm
                            cancel={() => setView('geofenceList')}
                            data={Object.assign({}, data)}
                            calendars={calendars}
                            edit={Object.keys(data).length && data.id > 0}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                        />
                    )
                }[view]
            }
        </>
    );
};

export default GeofenceContainer;
