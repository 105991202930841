import React from 'react';
import Button from '../button';
import PropTypes from 'prop-types';
import {useHotkeys} from 'react-hotkeys-hook';

const GroupButtonForm = (props) => {
    useHotkeys('F8', () => props.submit());
    useHotkeys('ESC', () => props.cancel ? props.cancel() : console.log('not implemented'));

    return (
        <>
            <div className={'rounded-group-button-form-bottom'}>
                {props.cancel && <Button
                    color={'warning'}
                    disabled={props.loading}
                    className={'outline'}
                    size={'lg'}
                    onClick={props.cancel}>
                    Voltar [ESC]
                </Button> }

                <Button
                    color={'success'}
                    disabled={props.loading}
                    type="submit"
                    size={'lg'}>
                    {props.edit ? 'Atualizar [F8]' : 'Salvar [F8]'}
                </Button>
            </div>
        </>
    );
};

GroupButtonForm.propTypes = {
    loading: PropTypes.bool,
    cancel: PropTypes.func,
};

export {
    GroupButtonForm,
};
