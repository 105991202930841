import {useEffect, useState} from 'react';
import useStore from './use-store';

const useAlarmType = () => {

    const {store} = useStore();
    const [alarmTypes, seAlarmTypes] = useState([]);
    const {alarmTypes: alarms} = store;

    useEffect(() => {
        const data = [];
        alarms.types.map((alarm) => {
            data.push(
                {
                    id: alarm.key,
                    title: alarm.name
                });
        });

        seAlarmTypes(data);
    }, [alarms.types.length]);

    return {alarmTypes};
};

export default useAlarmType;
