import React, {useContext, useMemo, useState} from 'react';
import {Form} from '@unform/web';
import FileSaver from 'file-saver';
import qs from 'qs';
import * as Yup from 'yup';
import {ThemeContext} from '../../../apiContexts';
import * as dateUtil from '../../../helpers/date';
import {getDate, getMemorizeReportForm, memorizeReportForm} from '../../../helpers/report';
import {externalReport} from '../../../services/reportService';
import {ReactSelectStyled} from '../../../styles';
import FooterActions from './footerActions';
import PeriodDate from './periodDate';
import {defaultReportValidation} from './validation';
import {Input as UInput} from '../../../components/input';

Yup.setLocale({
    mixed: {
        required: 'Este campo é obrigatório!',
        notType: 'Dados inválidos para tipo ${type}',
    },
});

const periodOptions = [
    {id: 'dateToDate', title: 'Personalizado'},
    {id: 'nowSofar', title: 'Hoje'},
    {id: 'yesterday', title: 'Ontem'},
    {id: 'currentWeekSoFar', title: 'Esta Semana'},
    {id: 'lastWeek', title: 'Semana Anterior'},
    {id: 'currentMonthSoFar', title: 'Este mês'},
    {id: 'lastMonth', title: 'Mês Anterior'},
];

const schema = defaultReportValidation(Yup);

const StopConfig = ({
                        devices,
                        redirectToReport,
                        groups,
                        notifyToast,
                        ...props
                    }) => {
    const theme = useContext(ThemeContext);

    const initialData = useMemo(() => getMemorizeReportForm(), []);

    const [startDate, setStartDate] = useState(getDate(initialData.startDate));
    const [endDate, setEndDate] = useState(getDate(initialData.endDate));
    const [period, setPeriod] = useState(initialData.period);
    const [outer, setOuter] = useState();
    const [loading, setLoading] = useState();

    const toReport = async (data) => {
        const date = (data.period === 'dateToDate') ? dateUtil['dateToDate'](data.startDate, data.endDate) : dateUtil[data.period]();
        data.startDate = date.from;
        data.endDate = date.to;

        await schema.validate(data, {
            abortEarly: false,
            recursive: true,
        });

        const query = new URLSearchParams();
        const params = {...date};

        query.append('_dc', new Date().getTime().toString());
        query.append('from', date.from);
        query.append('to', date.to);

        if (data.device) {
            data.device.map((id) => query.append('deviceId', id));
            params.deviceId = data.device.map(Number);
        }

        if (data.group) {
            data.group.map((id) => query.append('groupId', id));
            params.groupId = data.group.map(Number);
        }

        memorizeReportForm(data);

        if (outer) {
            try {
                params.mail = outer === 'mail';
                setLoading(true);

                const response = await externalReport(
                    'stops',
                    qs.stringify(params, {indices: false}),
                    !params.mail,
                );

                if (response) {
                    const blob = new Blob([response], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                    });
                    FileSaver.saveAs(blob, 'relatório.xlsx');
                }
                setLoading(false);
                params.mail && notifyToast('success');
            } catch (err) {
                notifyToast('error');
                setLoading(false);
            }
            setOuter(null);
            return;
        }

        const url = `stop?${query.toString()}`;
        redirectToReport(url);
    };

    return (
        <div>
            {devices && groups && (
                <Form schema={schema} initialData={initialData} onSubmit={toReport}>

                    <UInput hidden name="startDate" value={startDate}/>
                    <UInput hidden name="endDate" value={endDate} />

                    <ReactSelectStyled
                        radius="9px"
                        options={devices}
                        name="device"
                        noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                        placeholder="Escolha dispositivo(s)"
                        className="w-100"
                        multiple
                    />

                    <ReactSelectStyled
                        radius="9px"
                        options={groups}
                        name="group"
                        noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                        placeholder="Escolha grupo(s)"
                        className="w-100 mt-3"
                        multiple
                    />

                    <ReactSelectStyled
                        radius="9px"
                        options={periodOptions}
                        name="period"
                        noOptionsMessage={() => 'Nenhum resultado encontrado!'}
                        placeholder="Escolha o período"
                        className="w-100 mt-3"
                        onChange={(_data) => {
                            if (_data) {
                                setPeriod(_data.id);
                            } else {
                                setPeriod(initialData.period);
                            }
                        }}
                    />

                    {period === 'dateToDate' && (
                        <PeriodDate {...{setStartDate, startDate, setEndDate, endDate}} />
                    )}

                    <FooterActions theme={theme} setOuter={setOuter} loading={loading}/>
                </Form>
            )}
        </div>
    );
};

export default StopConfig;
