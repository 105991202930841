import styled from 'styled-components';
import {colors, shadows} from '../../styleVariables';

export const BottomStyle = styled.div`
  z-index: 1 !important;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  width: 100%;
  padding-bottom: 2rem !important;
`;

export const RightStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const CenterStyle = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;

  .badge {
    background-color: ${colors.gray5};
    color: ${colors.gray};
  }
`;

export const DeviceListItemStyle = styled.div`
  padding: 0.8rem 1rem;
  padding-bottom: 1.4rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: ${shadows.shadow};
  background-color: white;
  margin-bottom: -12px;
  cursor: pointer;
  color: ${colors.gray};
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    font-weight: bold;
    background-color: ${colors.gray5};
  }

  .fa-circle {
    font-size: 0.7rem !important;
    color: $green-3;
  }
`;

export const DeviceListStyle = styled.div`
  ${DeviceListItemStyle} {
    :last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-bottom: 0.8rem;
    }
  }
`;

export const DevicesStyle = styled.div`
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;

  ${DeviceListItemStyle} {
    width: 12.5vmax;
  }
`;

export const DeviceInfoStyle = styled.div`
  > span {
    :first-child {
      font-size: 0.9rem;
      color: ${colors.gray};
    }
  }
`;

export const DeviceFooterStyle = styled.div`
  margin-top: 5px;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;

  .red * {
    color: ${colors.red1};
  }

  .yellow * {
    color: ${colors.orange1};
  }

  .green * {
    color: ${colors.green2};
  }

  .fal {
    font-size: 1.3rem;

    + small {
      font-size: 0.8rem !important;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1rem;

    :not(:last-child) {
      border-right: 1px solid ${colors.gray1};
    }

    span {
      color: ${colors.gray1};

      :first-child {
        margin-bottom: 0.1rem;
      }
    }

    small {
      color: ${colors.gray1};
      font-size: 0.8rem !important;
    }
  }
`;
