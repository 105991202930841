import {useEffect} from 'react';

const useRemoveMinWidthRestrictionWhileMounted = () => {
    useEffect(() => {
        document.getElementById('root').style.setProperty('min-width', 'unset', 'important');

        return () => {
            document.getElementById('root').style.setProperty('min-width', '1024px', 'important');
        };
    }, []);
};

export default useRemoveMinWidthRestrictionWhileMounted;
