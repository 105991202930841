import {types} from 'mobx-state-tree';
import i18n from '../stores/translate-store';

const KnownNotificatorModel = types

    .model('KnownNotificatorModel', {
      type: types.string,
    })
    .views( (self) => {
      return {
        get name() {
          const key = 'notificator' + self.type.charAt(0).toUpperCase() + self.type.slice(1);
          return i18n.strings[key] || key;
        },
      };
    });

export default KnownNotificatorModel;
