import {types} from 'mobx-state-tree';

const HourUnitModel = types
    .model('HourUnitModel', {
      key: types.string,
      name: types.string,
      fullName: types.string,
    });

export default HourUnitModel;
