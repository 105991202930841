import React from 'react';
import moment from 'moment';
import {timezoneFormat} from '../../../../helpers/date';
import styled from 'styled-components';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  text-align: left;
  line-height: 1rem;
  font-size: 0.8rem !important;
`;

const Location = ({currentPosition, theme, timezone}) => {
  return (
    <Style className="mt-3">
      <strong className="mb-1" style={{color: theme}}>
        Última atualização timezone
      </strong>
      <span className="text-gray">
        {moment(currentPosition.fixTime).tz(timezone).format(
            'YYYY-MM-DD HH:mm:ss',
        )}
      </span>
      <strong className="mt-1 mb-1" style={{color: theme}}>
        Endereço
      </strong>
      <span className="text-gray">{currentPosition.address}</span>
    </Style>
  );
};

export default Location;
