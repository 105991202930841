import {useToasts} from 'react-toast-notifications';
import {useIntl} from 'react-intl';

/**
 *
 * @return {{warn: warn, success: success, error: error}}
 */
const useAlerts = () => {
  const intl = useIntl();
  const {addToast} = useToasts();

  const success = (msg) => {
    addToast(msg, {appearance: 'success', autoDismiss: true});
  };

  const error = (msg) => {
    addToast(msg, {appearance: 'error', autoDismiss: true});
  };

  const errorStatus = (e) => {
    let errorMessage = intl.formatMessage({id: 'sharedError'});
    switch (e.status) {
      case 400: {
        errorMessage = intl.formatMessage({id: 'sharedBadRequest'});
        break;
      }
      case 401: {
        errorMessage = intl.formatMessage({id: 'sharedUnauthorized'});
        break;
      }
      case 403: {
        errorMessage = intl.formatMessage({id: 'sharedForbidden'});
        break;
      }
      case 404: {
        errorMessage = intl.formatMessage({id: 'sharedNotFound'});
        break;
      }
    }
    error(errorMessage);
  };

  const warn = (msg) => {
    addToast(msg, {appearance: 'warning', autoDismiss: true});
  };

  const info = (msg) => {
    addToast(msg, {appearance: 'info', autoDismiss: true});
  };

  return {
    toast: {
      success,
      warn,
      error,
      info,
      errorStatus,
    },
  };
};

export default useAlerts;
