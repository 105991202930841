import React from 'react';
import DataTable from '../../components/dataTable';
import {formatDateTimeColumn} from '../../helpers/excel-helper';


const DeviceTable = (props) => {
    const {
        deviceForm,
        accumulatorForm,
        removeRegister,
        attributes,
        permissions,
        list,
        loading,
        timezone,
        intl,
        auth
    } = props;

    return (
        <DataTable
            auth={auth}
            add={deviceForm}
            edit={deviceForm}
            remove={removeRegister}
            list={list}
            loading={loading}
            attributes={attributes}
            permissions={permissions}
            accumulatorForm={accumulatorForm}
            keyField={'id'}
            confirmText={`Você está removendo o device <strong><field>name</field></strong>! </br>Realmente deseja fazer isso? `}
            headers={[
                {title: 'sharedName', field: 'name', dataSort: true},
                {title: 'deviceIdentifier', field: 'uniqueId', dataSort: true},
                {title: 'deviceModel', field: 'model', dataSort: true},
                {title: 'groupDialog', field: 'groupName', dataSort: true},
                {title: 'sharedDisabled', field: 'disabled', dataSort: true},
                {title: 'sharedCustomer', field: 'customerName', dataSort: true},
            ]}
            excelConfig={{
                fileName: 'deviceTitle',
                columns: [
                    {label: 'sharedName', field: 'name'},
                    {label: 'deviceIdentifier', field: 'uniqueId'},
                    {label: 'deviceModel', field: 'model'},
                    {label: 'groupDialog', field: 'groupName'},
                    {label: 'deviceCategory', field: 'category'},
                    {label: 'deviceContact', field: 'contact'},
                    {label: 'sharedDisabled', field: (col) => col.disabled ? intl.formatMessage({id: 'sharedYes'}) : intl.formatMessage({id: 'sharedNo'})},
                    {label: 'sharedPhone', field: 'phone'},
                    {label: 'devicePlate', field: 'attributes.plate'},
                    {label: 'deviceManufacturer', field: 'attributes.manufacturer'},
                    {label: 'deviceVehicleModel', field: 'attributes.vehicleModel'},
                    {label: 'deviceFuelType', field: 'attributes.fuelType'},
                    {label: 'deviceFuelConsumption', field: 'attributes.fuelConsumption'},
                    {label: 'deviceIccid', field: 'attributes.iccid'},
                    {label: 'deviceM2mProvider', field: 'attributes.m2mProvider'},
                    {label: 'deviceColor', field: 'attributes.color'},
                    {label: 'deviceYear', field: 'attributes.year'},
                    {label: 'deviceRenavam', field: (col) => col.hasOwnProperty('attributes.renavan') ? col['attributes.renavan'] : col['attributes.renavam']},
                    {label: 'deviceChassi', field: 'attributes.chassi'},
                    {label: 'deviceInstallationLocation', field: 'attributes.installationLocation'},
                    {label: 'deviceTechnician', field: 'attributes.technician'},
                    {label: 'devicePassword', field: 'attributes.devicePassword'},
                    {label: 'deviceLastUpdate', field: (col) => formatDateTimeColumn(col, timezone)},
                ],
            }}
        />
    );
};

export default DeviceTable;
