import {inject, observer} from 'mobx-react';
import qs from 'qs';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Container} from 'reactstrap';
import {from, of, zip} from 'rxjs';
import {groupBy, mergeAll, mergeMap, reduce, tap, toArray} from 'rxjs/operators';
import {MapContext} from '../../apiContexts';
import useTimezone from '../../hooks/use-timezone';
import {getRouteReport} from '../../services/reportService';
import RouteList from '../../views/reports/route-list';

const RouteReportContainer = ({
  match,
  location,
  listOnly = false,
  mapRef,
  ...props
}) => {
  const [report, setReport] = useState([]);
  const [positions, setPositions] = useState();
  const [selectedPosition, setSelectedPosition] = useState();
  const [markers, setMarkers] = useState();
  const [loading, setLoading] = useState(true);

  const {map, devices, server} = props;
  const {timezone} = useTimezone();

  const search = useMemo(() => location.search, [location.search]);

  const {setData, setSelectedItem, setSelectedRow} = useContext(MapContext);

  const activeRef = useRef();

  const getReport = useCallback(async (params, markers) => {
    setLoading(true);
    from(getRouteReport(params))
        .pipe(
            tap((report) => {
              const cache = new Map();
              setReport(
                  report.map((e) => {
                    const {deviceId} = e;
                    if (!cache.has(deviceId)) {
                      cache.set(deviceId, devices.findById(deviceId).name);
                    }
                    e.name = cache.get(deviceId);
                    return e;
                  }),
              );
            }),
            mergeAll(),
            groupBy(
                (position) => position.deviceId,
                (p) => p,
            ),
            mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
            reduce(
                (acc, [deviceId, positions]) => (
                  acc.set(deviceId, {
                    device: devices.findById(+deviceId),
                    positions,
                  }),
                  acc
                ),
                new Map(),
            ),
        )
        .subscribe((data) => {
          data = [...data.values()];
          setPositions(data);
          setData({type: 'route', data, markers: markers === 'true'});
          setLoading(false);
        });
  }, []);

  const onSelect = useCallback(
      ({index, data: position}) => {
        if (activeRef && activeRef.current === index) return;

        const item = report[index];
        let itemActive = null;

        if (activeRef && activeRef.current >= 0) {
          itemActive = report[activeRef.current];
        }

        const items = report.concat();

        items[index] = {...item, isActive: true};
        itemActive &&
        (items[activeRef.current] = {...itemActive, isActive: false});

        activeRef.current = index;

        setReport(items);
        setSelectedItem(item);
        if (position) {
          setSelectedRow({id: position.id});
        // setSelectedPosition(position);
        }
      },
      [report],
  );

  useEffect(() => {
    if (search && devices.list.length && location.hash === '#route') {
      const {markers, ...params} = qs.parse(search, {
        ignoreQueryPrefix: true,
        comma: true,
      });

      setMarkers(markers);

      getReport(qs.stringify(params, {indices: false}), markers);
    }
  }, [search, devices.list]);

  return (
    <Container fluid className="px-4 position-relative h-100">
      <RouteList
        data={report}
        loading={loading}
        speedUnit={server.attributes && server.attributes.speedUnit}
        {...{timezone, onSelect}}
      />
    </Container>
  );
};

const mapping = ({store}) => ({
  auth: store.auth,
  session: store.session,
  map: store.map,
  server: store.server,
  devices: store.devices,
});

export default inject(mapping)(observer(RouteReportContainer));
