import axios from "axios";

const API_URL = "https://customers.api.fullcontrol.com.br:8086/";

const getAccountToken = async (clientId, secretId) => {
    const response = await axios.get(`${API_URL}/Account/Token?clientId=${clientId}&secretId=${secretId}`);

    return response.data.data.token;
}

export default class FullControlService {

    constructor(clientId, secretId) {
       // if (!FullControlService._instance) {
            this.instance = axios.create({baseURL: API_URL});
            this.instance.interceptors.request.use(
                async (config) => {
                    const token = await getAccountToken(clientId, secretId);
                    if (token) config.headers.Authorization = `Bearer ${token}`;
                    return config;
                },
                (error) => Promise.reject(error),
            );
            this.instance.interceptors.response.use(
                (response) => {
                    if (response.data.hasOwnProperty('errorcode')) {
                        return Promise.reject(response.data);
                    }
                    return response;
                },
                (error) => Promise.reject(error),
            );
           // FullControlService._instance = this
       // }

       // return FullControlService._instance
    }

    async request(config) {
        try {
            const response = await this.instance(Object.assign(config));
            return response.data;
        } catch (err) {
            throw err.response;
        }
    }
}
