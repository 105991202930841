import {inject, observer} from 'mobx-react';
import qs from 'qs';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Container} from 'reactstrap';
import {concat, from} from 'rxjs';
import {map, mergeMap, toArray} from 'rxjs/operators';
import {MapContext} from '../../apiContexts';
import {getAlarmEventsList, getEventsList} from '../../helpers/events';
import {getEventReport} from '../../services/reportService';
import EventList from '../../views/reports/event-list';
import useServices from '../../hooks/use-services';
import useTimezone from '../../hooks/use-timezone';

const eventsType = getEventsList()
    .map((e) => Object.values(e))
    .reduce((acc, [type, value]) => ({...acc, [type]: value}), {});

const alarmType = getAlarmEventsList()
    .map((e) => Object.values(e))
    .reduce(
        (acc, [type, value]) => ({...acc, [type]: value}),

        {},
    );

const EventReportContainer = (props) => {
    const {
        location,
    } = props;

    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(true);
    const {devices, server} = props;
    const {timezone} = useTimezone();
    const {positionService, geofenceService, maintenanceService} = useServices();
    const search = useMemo(() => location.search, [location.search]);
    const {setData, setSelectedItem, setSelectedRow} = useContext(MapContext);
    const activeRef = useRef();

    const getReport = useCallback(async (params) => {
        setLoading(true);
        from(getEventReport(params))
            .pipe(
                mergeMap(
                    (_) =>
                        concat(
                            from(geofenceService.list()).pipe(
                                map((g) =>
                                    g.reduce((acc, {id, name}) => ({...acc, [id]: name}), {}),
                                ),
                            ),
                            from(maintenanceService.list()).pipe(
                                map((g) =>
                                    g.reduce((acc, {id, name}) => ({...acc, [id]: name}), {}),
                                ),
                            ),
                        ).pipe(toArray()),
                    (events, [geofences, maintenances]) => {
                        const cache = new Map();
                        return events.map((e) => {
                            const {deviceId} = e;
                            if (!cache.has(deviceId)) {
                                cache.set(deviceId, devices.findById(deviceId).name);
                            }
                            e.name = cache.get(deviceId);

                            const detail = [];

                            e.type === 'alarm' &&
                            detail.push(`Tipo do alarme: ${alarmType[e.attributes.alarm]}`);

                            e.geofenceId > 0 &&
                            detail.push(
                                `Cerca: ${geofences[e.geofenceId] || e.geofenceId}`,
                            );
                            e.maintenanceId > 0 &&
                            detail.push(
                                `Manutenção: ${maintenances[e.geofenceId] || e.maintenanceId}`,
                            );

                            detail && (e.detail = detail.join(', '));

                            e.type = eventsType[e.type] || e.type;

                            return e;
                        });
                    },
                ),
            )
            .subscribe((data) => {
                setReport(data);
                setLoading(false);
                setData({type: 'event', data: null});
            });
    }, []);

    const onSelect = useCallback(
        async ({index, data: event}) => {
            if (activeRef && activeRef.current === index) return;

            const item = report[index];
            let itemActive = null;

            if (activeRef && activeRef.current >= 0) {
                itemActive = report[activeRef.current];
            }

            const items = report.concat();

            items[index] = {...item, isActive: true};
            itemActive &&
            (items[activeRef.current] = {...itemActive, isActive: false});

            activeRef.current = index;

            if (event && event.positionId && !event.loaded) {
                const [position] = await positionService.getPositionsByIds([event.positionId]);
                const {longitude, latitude, course} = position;

                items[index] = {
                    ...items[index],
                    longitude,
                    latitude,
                    course,
                    loaded: true,
                };
                // setCurrentEventPosition(position);
                setData({type: 'event', data: items[index]});
            }

            setSelectedRow({id: event ? event.id : items[index].id || 0});
            setSelectedItem(items[index]);
            setReport(items);
        },
        [report],
    );

    useEffect(() => {
        if (search && devices.list.length && location.hash === '#event') {
            const {markers, ...params} = qs.parse(search, {
                ignoreQueryPrefix: true,
                comma: true,
            });
            getReport(qs.stringify(params, {indices: false}));
        }
    }, [search, devices.list]);

    return (
        <Container fluid className="px-4 position-relative h-100">
            <EventList data={report} {...{timezone, onSelect, loading}} />
        </Container>
    );
};

const mapping = ({store}) => ({
    auth: store.auth,
    session: store.session,
    map: store.map,
    server: store.server,
    devices: store.devices,
});

export default inject(mapping)(observer(EventReportContainer));
