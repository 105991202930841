import {useEffect} from 'react';
import {map as mapRx, mergeAll} from 'rxjs/operators';
import {sharedMapSubjects$} from '../helpers/subjects';
import useStore from '../../../hooks/use-store';

const useSharedMapDevice = (state, dispatch, instance) => {
  const {devices: socketDevices} = sharedMapSubjects$;
  const {store} = useStore();
  const {devices} = store;

  useEffect(() => {
    if (state.instance && devices.list.length) {
      const {instance: map, deviceMap} = state;

      socketDevices
          .pipe(
              mergeAll(),
              mapRx((device) => {
                devices.updateDevice(device);
                return device;
              }),
          )
          .subscribe((device) => {
            map.updateDevice(deviceMap.get(+device.id));
          });
    }
  }, [state.instance, state.deviceMap]);

  return {};
};

export default useSharedMapDevice;
