import React from 'react';
import SinisterTable from '../../views/sinister/sinister-table';
import useStore from '../../hooks/use-store';
import {observer} from 'mobx-react';
import {getSnapshot} from 'mobx-state-tree';


const SinisterContainer = (props) => {
    const {store} = useStore();
    const {sinisterStore} = store;

    return (
        <SinisterTable
            list={getSnapshot(sinisterStore.incidents)}
        />
    );
};

export default observer(SinisterContainer);
