import BaseService from "./base-service";
import {serialize, serializeArray} from "../helpers/request";

export default class PositionService extends BaseService {

    constructor() {
        super('positions');
    }

    getPositionsByIds = (ids) => {
        return this.__get(`positions?${serializeArray({id: ids})}`);
    }

    getPositionsFromTo = (deviceId, from, to) => {
        return this.__get( `positions?${serialize(Object.assign({_dc: new Date().getTime()}, {deviceId, from, to}))}`);
    }
}
