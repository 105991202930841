import {types} from 'mobx-state-tree';
import i18n from '../stores/translate-store';
import KnownAttributeModel from './known-attribute-model';

const KnownCommandModel = types.model('KnownCommandModel', {
  type: types.string,
  parameters: types.optional(types.array(KnownAttributeModel), []),
}) .views( (self) => {
  return {
    get name() {
      const name = i18n.strings['command' + self.type.charAt(0).toUpperCase() + self.type.slice(1)];
      return name ? name : self.type;
    },
  };
});

export default KnownCommandModel;
