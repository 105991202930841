import React, {useContext, useEffect, useState} from 'react';

import CustomerTable from '../../views/customer/customer-table';
import CustomerForm from '../../views/customer/customer-form';
import {useIntl} from 'react-intl';
import useAlerts from '../../hooks/use-alerts';
import {ModalContext} from '../../apiContexts';
import useServices from '../../hooks/use-services';
import useCustomers from '../../hooks/use-customers';
import {observer} from 'mobx-react';
import CustomerDeviceTable from '../../views/customer/customer-device-table';

const CustomerContainer = (props) => {
    const intl = useIntl();
    const {toast} = useAlerts();
    const {deviceService} = useServices();
    const {customers: customerStore} = useCustomers();
    const {customerService} = useServices();
    const {showModalLoading, isModalLoading} = useContext(ModalContext);
    const [view, setView] = useState('customerList');
    const [customers, setCustomers] = useState([]);
    const [devices, setDevices] = useState([]);
    const [data, setData] = useState({});

    let started = false;

    const getStates = () => {
        const data = [];
        const states = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

        states.map( (state) => {
            data.push({id: state, title: state});
        });

        return data;
    };

    useEffect(() => {
        if (customers.length === 0 && !started) {
            (async () => {
                started = true;
                const customers = await customerStore.list;
                setCustomers(customers);
            })();
        }
    }, [customerStore.list]);

    const customerForm = (data) => {
        setData(Object.keys(data).length ? Object.assign({}, data) : {});
        setView('customerForm');
    };

    const newRegister = async (data) => {
        try {
            data.registrationDate = new Date().toISOString();
            showModalLoading(true);
            const result = await customerService.save(data);
            setCustomers((prevCustomers) => {
                const newCustomers = [...prevCustomers, result];
                customerStore.setCustomers(newCustomers);
                return newCustomers;
            });
            setView('customerList');
            toast.success(intl.formatMessage({id: 'sharedSavedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const updateRegister = async (data, afterTo = 'customerList') => {
        try {
            showModalLoading(true);
            const index = customers.findIndex(({id}) => id === data.id);
            const payload = {...customers[index], ...data};
            delete payload.expirationTime;
            const result = await customerService.update(payload);
            setCustomers((prevCustomers) => {
                const newCustomers = [...prevCustomers];
                newCustomers[index] = {...newCustomers[index], ...result};
                customerStore.setCustomers(newCustomers);
                return newCustomers;
            });
            setView(afterTo);
            toast.success(intl.formatMessage({id: 'sharedUpdatedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const removeRegister = async (data) => {
        try {
            showModalLoading(true);
            await customerService.remove(data.id);
            setCustomers(customers.filter(({id}) => id !== data.id));
            setView('customerList');
            toast.success(intl.formatMessage({id: 'sharedRemovedSuccess'}));
        } catch (e) {
            toast.errorStatus(e);
        } finally {
            showModalLoading(false);
        }
    };

    const getDevicesByCustomer = async (row) => {
        const devices = await deviceService.getDevicesByCustomerId(row.id);
        setDevices(devices);
        setView('customerDeviceTable');
    };


    return (
        <>
            {
                {
                    customerList: (
                        <CustomerTable
                            list={customers}
                            customerForm={customerForm}
                            removeRegister={removeRegister}
                            showDeviceList={(row) => getDevicesByCustomer(row)}
                        />
                    ),
                    customerForm: (
                        <CustomerForm
                            cancel={() => setView('customerList')}
                            data={Object.assign({}, data)}
                            edit={Object.keys(data).length}
                            newRegister={newRegister}
                            updateRegister={updateRegister}
                            loading={isModalLoading}
                            states={getStates()}
                        />
                    ),
                    customerDeviceTable: (
                        <CustomerDeviceTable
                            cancel={() => getDevicesByCustomer}
                            list={devices}
                            showList={() => setView('customerList')}
                        />
                    ),
                }[view]
            }
        </>
    );
};

export default observer(CustomerContainer);
