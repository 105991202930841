import React from 'react';

export default function HashValidatingView() {
    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <h1>Carregando...</h1>
        </div>
    )
}