import React, {useContext, useState} from 'react';
import {DropdownToggle} from 'reactstrap';
import {ThemeContext} from '../../apiContexts';
import {ButtonStyled, DropdownItemStyled, DropdownMenuStyled, DropdownStyled} from './style';

const RoundButtonDropdown = ({className, icon, items = [], ...props}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const theme = useContext(ThemeContext);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
        <DropdownStyled
            isOpen={dropdownOpen}
            toggle={toggle}
            className={`roundButtonDropdown ${className}`}
            theme={theme}
        >
            <DropdownToggle
                tag={() => (
                    <ButtonStyled
                        theme={theme}
                        className="rounded-circle shadow-sm border-0"
                        onClick={toggle}
                    >
                        <span className={icon}/>
                    </ButtonStyled>
                )}
            />
            <DropdownMenuStyled right className="shadow border-0" style={{}}>
                {items.map(({icon, label, callback}, i) => (
                    <DropdownItemStyled
                        className="mb-1 py-2"
                        key={`Dropdown_menu__styled__${i}`}
                        onClick={callback}
                        theme={theme}
                    >
                        <span className="mr-3">{label}</span>
                        <span className={icon}/>
                    </DropdownItemStyled>
                ))}
            </DropdownMenuStyled>
        </DropdownStyled>
    );
};

export default RoundButtonDropdown;
