import BaseService from "./base-service";
import {serialize} from "../helpers/request";
import axios from "axios";

export default class DeviceService extends BaseService {

    constructor() {
        super('devices');
    }

    static removeUnnecessaryFields = (data) => {
        const obj = Object.assign({}, data);

        delete obj.withTrace;
        delete obj.groupName;
        delete obj.positionBuffer;
        delete obj.currentPosition;
        delete obj.customerName;
        delete obj.customerCpfCnpj;

        return obj;
    };

    static processCustomerAndGroupData = (responses) => {

        const device = responses[0];
        const groups = responses[1];

        let groupName = null;

        if (device.groupId) {
            const group = groups.find(g => g.id === device.groupId);
            groupName = group && group.name ? group.name : null;
        }

        device.groupName = groupName;

        return device;
    };

    /**
     * Method to process the list devices
     *
     * obs.: The method needs get information from the groups API
     *
     * @param query
     * @returns {Promise<Device>}
     */
    list(query) {

        return new Promise((resolve, reject) => {
            const getDevices = this.__get(query ?
                `${this.url}?${serialize(Object.assign({_dc: new Date().getTime()}, query))}` : this.url);
            const getGroups = this.__get(`groups`);

            axios.all([getDevices, getGroups])
                .then(axios.spread((...responses) => {

                    const data = [];
                    const devices = responses[0];
                    const groups = responses[1];

                    devices.sort((a, b) => a.name.localeCompare(b.name));

                    devices.forEach((device) => {
                        let groupName = null;
                        if (device.groupId) {
                            const group = groups.find(g => g.id === device.groupId);
                            groupName = group && group.name ? group.name : null;
                        }
                        data.push({groupName, ...device});
                    });

                    resolve(data);

                })).catch(errors => reject(errors))
        });
    }

    save(data) {

        const device = DeviceService.removeUnnecessaryFields(data);

        return new Promise((resolve, reject) => {

            const saveDevice = super.save(device);
            const getGroups = this.__get(`groups`);

            axios.all([saveDevice, getGroups])
                .then(axios.spread((...responses) => {
                    resolve(DeviceService.processCustomerAndGroupData(responses));
                })).catch(errors => reject(errors))
        });
    }


    update(data) {

        const device = DeviceService.removeUnnecessaryFields(data);

        return new Promise((resolve, reject) => {

            const updateDevice = super.update(device);
            const getGroups = this.__get(`groups`);

            axios.all([updateDevice, getGroups])
                .then(axios.spread((...responses) => {
                    resolve(DeviceService.processCustomerAndGroupData(responses));
                })).catch(errors => reject(errors))
        });
    }

    accumulator( deviceId, totalDistance, hours ){
        this.__put(`devices/${deviceId}/accumulators`, {
            deviceId,
            totalDistance: parseFloat(totalDistance) * 1000,
            hours: parseFloat(hours) * 3600000
        })
    }

    getDevicesByCustomerId = (customerId) => {
        return this.__get(`${this.url}?${serialize(Object.assign({_dc: new Date().getTime()}, {customerId}))}`)
    }
}
