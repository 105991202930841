import PropTypes from 'prop-types';
import React, {createContext, useState} from 'react';

export const ContextProviderComposer = ({contextProviders, children}) => {
    return contextProviders.reduceRight(
        (children, parent, index) => React.cloneElement(parent, {children}),
        children,
    );
};

ContextProviderComposer.propTypes = {
    contextProviders: PropTypes.arrayOf(PropTypes.element).isRequired,
    children: PropTypes.node.isRequired,
};

export const ModalContext = createContext();

export const ThemeContext = createContext('claro');

export const AdminContext = createContext();

export const MapContext = createContext();

export const MapProvider = ({children}) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [props, setProps] = useState({});

    const setProp = (key, value) => setProps({...props, [key]: value});

    return (
        <MapContext.Provider
            value={{
                selectedItem,
                setSelectedItem,
                data,
                setData,
                selectedRow,
                setSelectedRow,
                setProps,
                setProp,
                ...props,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
