import {types} from 'mobx-state-tree';
import CustomerModel from '../models/customer-model';

const CustomerStore = types
    .model('CustomerStore', {
        customers: types.optional(types.array(CustomerModel), []),
    })
    .actions((self) => ({
        setCustomers(customers) {
            self.customers = customers;
        },
        addAll: (customers) => self.customers.push(...customers),
    }))
    .views((self) => ({
        findById: (id) => self.customers.find((e) => e.id === id),
        getCustomers() {
            return self.customers;
        },
        get list() {
            return self.customers.toJSON();
        },
    }));

export default CustomerStore;

