import {Button, Dropdown, DropdownMenu} from 'reactstrap';
import styled from 'styled-components';
import {shadows} from '../../styleVariables';

export const ButtonStyled = styled(Button)`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${shadows.shadow} !important;
  background-color: white;
  border-color: ${(props) => props.theme} !important;
  color: ${(props) => props.theme};
  :active,
  :hover {
    background-color: ${(props) => props.theme} !important;
  }
  span {
    font-size: 1.5rem;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  &.dropleft {
    .dropdown-menu {
      transform: translate3d(-170px, -8px, 0px) !important;
    }
  }
  &.show {
    .dropdown-menu {
      display: flex;
      background: transparent;
      border: 0;
    }
    ${ButtonStyled} {
      color: white;
      background-color: ${(props) => props.theme} !important;
    }
  }
`;

export const DropdownMenuStyled = styled(DropdownMenu)`
  position: absolute !important;
    opacity: inherit !important;
  pointer-events: all !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  transform: translate3d(-162px, 0px, 0px) !important;
  justify-content: flex-end;
`;
