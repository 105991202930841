import React from 'react';
import DeviceList from '../../../personal/device/device-list';
import {BottomStyle, DevicesStyle} from '../../../personal/styles';
import SelectedDeviceCardComponent from './SelectedDeviceCard.component';
import DeviceActionsComponent from './DeviceActions.component';
import MapControlsComponent from './MapControls.component';

const DevicesList = ({
    devices,
    selectDevice,
    unSelectDevice,
}) => {
    return (
        <DevicesStyle id="devices">
            {!!devices.selectedDevice ? (
                <SelectedDeviceCardComponent
                    selectedDevice={devices.selectedDevice}
                    unSelectDevice={unSelectDevice}/>
            ) : (
                <DeviceList devices={devices} onSelect={selectDevice}/>
            )}
        </DevicesStyle>
    );
};

export default {
    Root: (props) => <BottomStyle id="bottom" {...props} />,
    DevicesList: ({
        devices,
        selectDevice,
        unSelectDevice,
    }) => <DevicesList devices={devices} selectDevice={selectDevice} unSelectDevice={unSelectDevice} />,
    DeviceActionsComponent,
    MapControlsComponent,
};
