import {useCallback, useEffect} from 'react';
import useStore from './use-store';
import useServices from './use-services';

let started = false;

const useCustomers = (query) => {
    const {store} = useStore();
    const {customerService} = useServices();

    const getCustomers = useCallback(async () => {
        if (store.customerStore.list.length === 0 && !started) {
            started = true;
            const customers = await customerService.list(query);
            customers.sort((a, b) => a.name.localeCompare(b.name));
            store.customerStore.addAll(customers);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await getCustomers();
        })();
    }, []);

    return {customers: store.customerStore};
};

export default useCustomers;
