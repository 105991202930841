import FullControlService from "./full-control-service";
import {serialize} from '../../helpers/request';

export default class FullControlOperadoraService extends FullControlService {

    constructor(clientId, secretId) {
        super(clientId, secretId);
    }

    /**
     *
     * @param operadoraId
     * @returns {Promise<any|undefined>}
     * @constructor
     */
    ObterPorId(operadoraId) {
        return this.request({
            method: 'get',
            url: `Operadora/ObterPorId?${serialize({ operadoraId })}`
        });
    }

    ObterOperadoras(descricao, skip, take) {
        return this.request({
            method: 'post',
            url: `Operadora/ObterOperadoras?parcelaId=${descricao, skip, take}`,
            data: {}
        });
    }

    CadastrarOuAtualizar( id, descricao) {
        return this.request({
            method: 'post',
            url: `Operadora/CadastrarOuAtualizar`,
            data: { id, descricao }
        });
    }

    Atualizar( id, descricao ) {
        return this.request({
            method: 'put',
            url: `Operadora/Atualizar`,
            data: { id, descricao }
        });
    }

}
