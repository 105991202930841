import {types} from 'mobx-state-tree';

const DriverModel = types
    .model('DriverModel', {
      id: types.number,
      name: types.string,
      uniqueId: types.string,
      atributes: types.frozen({}),
    });

export default DriverModel;

