import {flow} from 'mobx-state-tree';
import AuthModel from '../models/auth-model';
import {logIn} from '../services/authService';

const AuthStore = AuthModel.views((self) => ({
    getUser() {
        return self.user;
    },
    get token() {
        return btoa(`${self.email}:${self.password}`);
    },
})).actions((self) => ({
    setLogged: (value) => (self.isLogged = value),

    setProfile: (profile) => (self.profile = profile),

    setAuthInfo: (email, password) => {
        self.email = email;
        self.password = password;
    },

    setUser: (data) => {
        self.user = data;
    },
    signIn: flow(function* (data) {
        const response = yield logIn(data);
        self.setUser(response);
        return response;
    }),
}));

export default AuthStore;
