import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input as UInput} from '@rocketseat/unform';
import {Spinner, Button, Input} from 'reactstrap';
import I18nMessage from '../../components/I18nMessage';
import styled from 'styled-components';
import {useCompany} from '../../providers/company';
import {Skeleton} from '@mui/material';
import {observer} from 'mobx-react';

const LoginForm = (props) => {
    const {
        error,
        clear,
        schema,
        loading,
    } = props;

    const {
        logoUrl,
        theme,
        androidAppUrl,
        iosAppUrl,
    } = useCompany();

    const LoginButtonStyled = styled(Button)`
    color: #FFFFFF;
    background-color: ${(props) => props.theme} !important;
    border-color: ${(props) => props.theme};
    `;
    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">

                <div className="logo-wrapper fadeIn first">
                    {logoUrl ?
                        (
                            <img
                                src={logoUrl}
                                alt="logo"
                                className='logo'
                            />
                        ) :
                        (
                            <Skeleton
                                variant='rectangular'
                                className="logo-placeholder"
                            />
                        )}
                </div>

                <Form schema={schema}
                      className="login100-form validate-form"
                      onSubmit={props.onLogin}
                >
                    <br/>
                    <div className={'form-item'}>
                        <Input
                            onChange={() => clear()}
                            className="form__input"
                            tag={UInput}
                            type="text"
                            name="email"
                            placeholder={'E-mail'}
                            id="email"
                        />
                    </div>
                    <br/>
                    <div className={'form-item'}>
                        <Input
                            onChange={() => clear()}
                            className="form__input"
                            tag={UInput}
                            type="password"
                            name="password"
                            id="password"
                            placeholder={'Senha'}
                            autoComplete={'new-password'}
                        />
                    </div>

                    <div style={{padding: 20}}>
                        <LoginButtonStyled className="btn btn-primary btn-lg btn-block" theme={theme}>
                            {loading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> :
                                <I18nMessage id="loginTitle"/>}
                        </LoginButtonStyled>
                    </div>

                    <div className="text-center p-t-46 p-b-20">
                        {error && <span className="txt-error">
                            <I18nMessage id="errorAuthorization"/>
                        </span>}
                    </div>

                    <div className={'row'}>
                        <div className={'col'}>
                            <a
                               href={iosAppUrl}>
                                <img
                                    className='mobile-badge'
                                    alt="Disponível na App Store"
                                    src={`${process.env.PUBLIC_URL}/images/app-store-badge.svg`}
                                />
                            </a>
                        </div>
                        <div className={'col'}>
                            <a
                                href={androidAppUrl}>
                                <img
                                    className='mobile-badge'
                                    alt='Disponível no Google Play'
                                    src={`${process.env.PUBLIC_URL}/images/google-play-badge.png`}
                                    style={{
                                        margin: '-3px 0',
                                    }}
                                />
                            </a>
                        </div>
                    </div>

                </Form>
            </div>

            <div className='my-2' />

            <footer>
                <a href="https://statuspage.freshping.io/17052-AzulTecnologia"><img
                    id="uptime-badge"
                    src="https://statuspage.freshping.io/badge/533e82de-c259-47ab-a7d5-42ae273af966?0.5757067805614287"/>
                </a>
                <div className='my-2' />
                <p className="copyright">© Azul Tecnologia - All rights reserved
                    <br/>Versão 2.0</p>
            </footer>
        </div>
    );
};

LoginForm.propTypes = {
    onLogin: PropTypes.func.isRequired,
    error: PropTypes.bool,
    clear: PropTypes.func,
    schema: PropTypes.object,
    loading: PropTypes.bool,
};

export default observer(LoginForm);
