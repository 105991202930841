import BaseService from "./base-service";
import {serialize, serializeArray} from "../helpers/request";

export default class CommandService extends BaseService {

    constructor() {
        super('commands');
    }

    getCommandTypes = (query) => {
        return this.__get(`commands/types?${serialize(Object.assign({_dc: new Date().getTime()}, query))}`);
    }

    send = (data) => {
        return this.__post(`commands/send`, data);
    }

    batch = (data) => {
        return this.__post(`commands/batch?${serializeArray({groupsIds: data.groupsIds, devicesIds: data.devicesIds})}`, data.entity);
    }
}
