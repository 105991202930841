import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {useIntl} from 'react-intl';
import I18nMessage from '../../components/I18nMessage';

const UserShortTable = ({users, theme}) => {
    console.log(users);
    const intl = useIntl();
    const options = {
        noDataText: intl.formatMessage({id: 'sharedNoDataText'}),
    };
    return (
    <BootstrapTable
        options={options}
        data={users}>
        <TableHeaderColumn dataField='id' isKey={ true } hidden>ID</TableHeaderColumn>
        <TableHeaderColumn dataField='name'><I18nMessage id="sharedName"/></TableHeaderColumn>
        <TableHeaderColumn dataField='email'><I18nMessage id="userEmail"/></TableHeaderColumn>
        <TableHeaderColumn dataField='phone'><I18nMessage id="sharedPhone"/></TableHeaderColumn>
        <TableHeaderColumn dataField='attributes.keyword'><I18nMessage id="userKeyword"/></TableHeaderColumn>
    </BootstrapTable>);
};

export default UserShortTable;
