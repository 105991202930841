import './map.sass';
import '../../../../views/personal/client.sass';
import '../../../../views/personal/device/device.sass';

import React, {useContext, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {observer} from 'mobx-react';
import {useRef} from 'react';
import styled from 'styled-components';
import config from '../../../../config';
import {useMap} from '../../../../components/map/hooks';
import {MapContext} from '../../../../apiContexts';
import {useIntl} from 'react-intl';
import Header from '../../components/Header';
import useStore from '../../../../hooks/use-store';
import useAlerts from '../../../../hooks/use-alerts';
import useSharedMapPosition from '../../hooks/useSharedMapPosition';
import FooterComponent from '../../components/Footer/Footer.component';
import useSharedMapLabel from '../../hooks/useSharedMapLabel';
import useSharedMapTrace from '../../hooks/useSharedMapTrace';
import useSharedMapDevice from '../../hooks/useSharedMapDevice';
import {useCompany} from '../../../../providers/company';

const Map = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

/** */
function SharedMapView() {
    const mapRef = useRef();
    const intl = useIntl();

    const {store} = useStore();
    const {toast} = useAlerts();
    const {devices, events} = store;
    const company = useCompany();

    const {
        instance,
        trace,
        setTrace,
        toogleTrace: toggleDeviceTrace,
        label,
        setLabel,
        selectedMap,
        setSelectedMap,
        follow,
        setFollow,
    } = useMap(
        {
            target: mapRef,
            center: [0, 0],
            zoom: 4,
            tiers: config.mapConfig,
            initAllDevices: true,
            centerOnCluster: true,
            cluster: {
                distance: 25,
            },
        },
        useSharedMapPosition,
        useSharedMapDevice,
        useSharedMapTrace,
        useSharedMapLabel,
    );

    const {
        setSelectedRow,
        setProps,
    } = useContext(MapContext);

    useEffect(() => {
        if (instance) {
            setProps({map: instance});
            const map = instance.instance;
            instance.setI18n(intl);
            setSelectedMap('osm');
            map.on('click', (event) => {
                if (events.currentDevice !== null) {
                    devices.setSelected(null);
                    events.setCurrentDevice(null);

                    return;
                }

                const [feature, layer] =
                map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                    return [feature, layer];
                }) || [];


                if (!layer) return;
                const layerName = layer.get('name');

                if (layerName === 'reportLayer') {
                    const featureName = feature.get('name');
                    if (featureName !== 'line') {
                        setSelectedRow({
                            id: feature.get('id') || feature.getId(),
                            scroll: true,
                        });
                    }
                }

                if (layerName === 'devicesLayer') {
                    const features = feature.get('features');
                    if (features.length === 1) {
                        const deviceId = features[0].getId();
                        const device = store.devices.findById(+deviceId);
                        devices.setSelected(deviceId);
                        events.setCurrentDevice(+deviceId);

                        (async () => {
                            const position = device.currentPosition;

                            if (!position) return;

                            const {longitude, latitude} = position;
                            instance.goTo([longitude, latitude], 18);
                        })();
                    }
                }
            });

            map.on('pointermove', (event) => {
                map.getTargetElement().style.cursor = map.hasFeatureAtPixel(event.pixel) ?
                    'pointer' :
                    '';
            });

            map.on('pointerdrag', (event) => {
                setFollow(false);
            });

            let zoomCache;
            map.on('movestart', (event) => {
                zoomCache = event.map.getView().getZoom();
            });

            map.on('moveend', (event) => {
                const zoom = event.map.getView().getZoom();
                zoom !== zoomCache && setFollow(false);
            });
        }
    }, [instance, devices.list.length]);

    const toggleLabel = () => {
        setLabel((prev) => !prev);
    };

    const onChangeMap = (value) => {
        setSelectedMap(value);
    };

    const toggleTrace = () => {
        if (!!devices.selectedDevice === false) return;

        toggleDeviceTrace(devices.selectedDevice);
        setTrace((prev) => !prev);
    };

    const toggleFollow = () => {
        setFollow((prev) => {
            const status = !prev;

            if (status) {
                const {longitude, latitude} = devices.selectedDevice.currentPosition;
                instance.goTo([longitude, latitude]);
            }

            return status;
        });
    };

    const selectDevice = (id) => {
        const device = devices.findById(id);
        if (!!device.currentPosition == false) {
            toast.info(intl.formatMessage({id: 'deviceSelectedNeverPositioned'}));
            return;
        }

        devices.setSelected(id);
        const {currentPosition} = devices.selectedDevice;
        if (currentPosition) {
            const {longitude, latitude} = currentPosition;
            instance.goTo([longitude, latitude], 18);
        }
    };

    const unSelectDevice = (_) => devices.setSelected(null);

    return (
        <div id="client">
            <Map id="shared-map" ref={mapRef} />

            <Header.Root>
                <Header.Logo src={company.logoUrl} />
            </Header.Root>

            <div className="content"/>

            <FooterComponent.Root>
                <FooterComponent.DevicesList
                    devices={devices}
                    selectDevice={selectDevice}
                    unSelectDevice={unSelectDevice}
                />

                {createPortal(
                    <FooterComponent.MapControlsComponent.Root>
                        <FooterComponent.MapControlsComponent.ActionGroup icon="fas fa-layer-group">
                            <FooterComponent.MapControlsComponent.ActionItem
                                label={intl.formatMessage({id: 'sharedLabel'}) + 's'}
                                iconClassName="fal fa-comment-alt"
                                active={label}
                                onPress={toggleLabel}
                            />

                            <div className='mr-2' />

                            <FooterComponent.MapControlsComponent.ActionItem
                                label={intl.formatMessage({id: 'deviceTrail'})}
                                iconClassName="fal fa-route"
                                active={trace}
                                onPress={toggleTrace}
                            />
                        </FooterComponent.MapControlsComponent.ActionGroup>

                        <div className='my-2' />

                        <FooterComponent.MapControlsComponent.ActionGroup icon="fal fa-map">
                            <FooterComponent.MapControlsComponent.ActionItem
                                label={intl.formatMessage({id: 'mapKindStreet'}) + 's'}
                                iconClassName="fal fa-globe-americas"
                                active={selectedMap === 'street'}
                                onPress={() => onChangeMap('street')}
                            />

                            <div className='mr-2' />

                            <FooterComponent.MapControlsComponent.ActionItem
                                label={intl.formatMessage({id: 'mapKindSatellite'})}
                                iconClassName="fal fa-satellite"
                                active={selectedMap === 'satellite'}
                                onPress={() => onChangeMap('satellite')}
                            />

                            <div className='mr-2' />

                            <FooterComponent.MapControlsComponent.ActionItem
                                label="OSM"
                                active={selectedMap === 'osm'}
                                onPress={() => onChangeMap('osm')}

                            >
                                <strong>OSM</strong>
                            </FooterComponent.MapControlsComponent.ActionItem>
                        </FooterComponent.MapControlsComponent.ActionGroup>

                        <div className='my-2' />

                        <FooterComponent.MapControlsComponent.ZoomControls map={instance} />

                        {!!devices.selectedDevice && (
                            <>
                                <div className='my-2'/>

                                <FooterComponent.DeviceActionsComponent.Item
                                    label={intl.formatMessage({id: 'deviceTrail'})}
                                    active={trace}
                                    iconClassName="fal fa-route"
                                    onPress={toggleTrace}
                                />

                                <div className='my-2'/>

                                <FooterComponent.DeviceActionsComponent.Item
                                    label={intl.formatMessage({id: 'deviceFollow'})}
                                    active={follow}
                                    iconClassName="fas fa-location-arrow"
                                    onPress={toggleFollow}
                                />
                            </>
                        )}

                    </FooterComponent.MapControlsComponent.Root>,
                    document.body,
                )}
            </FooterComponent.Root>
        </div>
    );
}

export default observer(SharedMapView);
