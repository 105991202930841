import BaseService from "./base-service";

export default class AttributesComputedService extends BaseService {

    constructor() {
        super('attributes/computed?');
    }

    update(data) {
        throw new Error("method not implemented");
    }

    save(data) {
        throw new Error("method not implemented");
    }

    remove(id) {
        throw new Error("method not implemented");
    }
}
