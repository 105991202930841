import {types} from 'mobx-state-tree';
import KnownAttributeModel from '../models/known-attribute-model';
import i18n from './translate-store';

const data = [{
    key: 'raw',
    name: i18n.strings.positionRaw,
    valueType: 'string',
}, {
    key: 'index',
    name: i18n.strings.positionIndex,
    valueType: 'number',
}, {
    key: 'hdop',
    name: i18n.strings.positionHdop,
    valueType: 'number',
}, {
    key: 'vdop',
    name: i18n.strings.positionVdop,
    valueType: 'number',
}, {
    key: 'pdop',
    name: i18n.strings.positionPdop,
    valueType: 'number',
}, {
    key: 'sat',
    name: i18n.strings.positionSat,
    valueType: 'number',
}, {
    key: 'satVisible',
    name: i18n.strings.positionSatVisible,
    valueType: 'number',
}, {
    key: 'rssi',
    name: i18n.strings.positionRssi,
    valueType: 'number',
}, {
    key: 'gps',
    name: i18n.strings.positionGps,
    valueType: 'number',
}, {
    key: 'roaming',
    name: i18n.strings.positionRoaming,
    valueType: 'boolean',
}, {
    key: 'event',
    name: i18n.strings.positionEvent,
    valueType: 'string',
}, {
    key: 'alarm',
    name: i18n.strings.positionAlarm,
    valueType: 'string',
}, {
    key: 'status',
    name: i18n.strings.positionStatus,
    valueType: 'string',
}, {
    key: 'odometer',
    name: i18n.strings.positionOdometer,
    valueType: 'number',
    dataType: 'distance',
}, {
    key: 'serviceOdometer',
    name: i18n.strings.positionServiceOdometer,
    valueType: 'number',
    dataType: 'distance',
}, {
    key: 'tripOdometer',
    name: i18n.strings.positionTripOdometer,
    valueType: 'number',
    dataType: 'distance',
}, {
    key: 'hours',
    name: i18n.strings.positionHours,
    valueType: 'number',
    dataType: 'hours',
}, {
    key: 'steps',
    name: i18n.strings.positionSteps,
    valueType: 'number',
}, {
    key: 'input',
    name: i18n.strings.positionInput,
    valueType: 'string',
}, {
    key: 'output',
    name: i18n.strings.positionOutput,
    valueType: 'string',
}, {
    key: 'power',
    name: i18n.strings.positionPower,
    valueType: 'number',
    dataType: 'voltage',
}, {
    key: 'battery',
    name: i18n.strings.positionBattery,
    valueType: 'number',
    dataType: 'voltage',
}, {
    key: 'batteryLevel',
    name: i18n.strings.positionBatteryLevel,
    valueType: 'number',
    dataType: 'percentage',
}, {
    key: 'fuel',
    name: i18n.strings.positionFuel,
    valueType: 'number',
    dataType: 'volume',
}, {
    key: 'fuelConsumption',
    name: i18n.strings.positionFuelConsumption,
    valueType: 'number',
    dataType: 'consumption',
}, {
    key: 'versionFw',
    name: i18n.strings.positionVersionFw,
    valueType: 'string',
}, {
    key: 'versionHw',
    name: i18n.strings.positionVersionHw,
    valueType: 'string',
}, {
    key: 'type',
    name: i18n.strings.sharedType,
    valueType: 'string',
}, {
    key: 'ignition',
    name: i18n.strings.positionIgnition,
    valueType: 'boolean',
}, {
    key: 'flags',
    name: i18n.strings.positionFlags,
    valueType: 'string',
}, {
    key: 'charge',
    name: i18n.strings.positionCharge,
    valueType: 'boolean',
}, {
    key: 'ip',
    name: i18n.strings.positionIp,
    valueType: 'string',
}, {
    key: 'archive',
    name: i18n.strings.positionArchive,
    valueType: 'boolean',
}, {
    key: 'distance',
    name: i18n.strings.positionDistance,
    valueType: 'number',
    dataType: 'distance',
}, {
    key: 'totalDistance',
    name: i18n.strings.deviceTotalDistance,
    valueType: 'number',
    dataType: 'distance',
}, {
    key: 'rpm',
    name: i18n.strings.positionRpm,
    valueType: 'number',
}, {
    key: 'vin',
    name: i18n.strings.positionVin,
    valueType: 'string',
}, {
    key: 'approximate',
    name: i18n.strings.positionApproximate,
    valueType: 'boolean',
}, {
    key: 'throttle',
    name: i18n.strings.positionThrottle,
    valueType: 'number',
}, {
    key: 'motion',
    name: i18n.strings.positionMotion,
    valueType: 'boolean',
}, {
    key: 'armed',
    name: i18n.strings.positionArmed,
    valueType: 'number',
}, {
    key: 'geofence',
    name: i18n.strings.sharedGeofence,
    valueType: 'string',
}, {
    key: 'acceleration',
    name: i18n.strings.positionAcceleration,
    valueType: 'number',
}, {
    key: 'deviceTemp',
    name: i18n.strings.positionDeviceTemp,
    valueType: 'number',
    dataType: 'temperature',
}, {
    key: 'operator',
    name: i18n.strings.positionOperator,
    valueType: 'string',
}, {
    key: 'command',
    name: i18n.strings.deviceCommand,
    valueType: 'string',
}, {
    key: 'blocked',
    name: i18n.strings.positionBlocked,
    valueType: 'boolean',
}, {
    key: 'dtcs',
    name: i18n.strings.positionDtcs,
    valueType: 'string',
}, {
    key: 'obdSpeed',
    name: i18n.strings.positionObdSpeed,
    valueType: 'number',
    dataType: 'speed',
}, {
    key: 'obdOdometer',
    name: i18n.strings.positionObdOdometer,
    valueType: 'number',
    dataType: 'distance',
}, {
    key: 'result',
    name: i18n.strings.eventCommandResult,
    valueType: 'string',
}, {
    key: 'driverUniqueId',
    name: i18n.strings.positionDriverUniqueId,
    valueType: 'string',
    dataType: 'driverUniqueId',
}];

const PositionAttributesStore = types
    .model('PositionAttributesStore', {
        items: types.optional(types.array(KnownAttributeModel), []),
    })
    .actions((self) => ({
        afterCreate() {
            self.setItems(data);
        },
        setItems(data) {
            self.items = data;
        },
        findRecord(key, unit) {
            return self.items.find((u) => u.key === key);
        },
        getAttributeName(key, capitalize) {
            const model = self.findRecord(key);
            if (model) {
                return model.name;
            } else if (capitalize) {
                return key.replace(/^./, function (match) {
                    return match.toUpperCase();
                });
            } else {
                return key;
            }
        },
        getAttributeDataType(key) {
            const model = self.findRecord(key);
            if (model && model.dataType) {
                return model.dataType;
            } else {
                return null;
            }
        }
    }));

export default PositionAttributesStore;

