import React, {useState} from 'react';
import {Button, Nav, NavItem, NavLink, TabContent} from 'reactstrap';
import './style.sass';
import {inject, observer} from 'mobx-react';
import PermissionTab from './permission-tab';
import {useIntl} from 'react-intl';

const PermissionTabs = (props) => {
    const {
        options = [],
        data,
        cancel,
    } = props;

    const intl = useIntl();
    const [activeTab, setActiveTab] = useState(
        options.length > 0 ? options[0] : '');

    const permissions = [
        {
            name: 'device',
            icon: 'fal fa-car',
            label: intl.formatMessage({id: 'sharedDevice'}),
            table: {
                keyField: 'id',
                headers: [
                    {title: 'sharedName', field: 'name', dataSort: true},
                    {title: 'deviceIdentifier', field: 'uniqueId', dataSort: true},
                    {title: 'sharedDisabled', field: 'disabled', dataSort: true},
                ],
            },
        },
        {
            name: 'notification',
            icon: 'fal fa-envelope',
            label: intl.formatMessage({id: 'sharedNotification'}),
            table: {
                keyField: 'id',
                headers: [{
                    title: intl.formatMessage({id: 'notificationType'}),
                    field: 'title',
                    dataSort: true
                }],
            },
        },
        {
            name: 'geofence',
            icon: 'fal fa-location-circle',
            label: 'Geocercas',
            table: {
                keyField: 'id',
                headers: [{title: 'sharedName', field: 'name', dataSort: true}],
            },
        },
        {
            name: 'calendar',
            icon: 'fal fa-calendar-alt',
            label: intl.formatMessage({id: 'sharedCalendar'}),
            table: {
                keyField: 'id',
                headers: [{title: 'sharedName', field: 'name', dataSort: true}],
            },
        },
        {
            name: 'group',
            icon: 'fal fa-object-group',
            label: intl.formatMessage({id: 'groupDialog'}),
            table: {
                keyField: 'id',
                headers: [{title: 'sharedName', field: 'name', dataSort: true}],
            },
        },
        {
            name: 'driver',
            icon: 'fal fa-steering-wheel',
            label: intl.formatMessage({id: 'sharedDriver'}),
            table: {
                keyField: 'id',
                headers: [{title: 'sharedName', field: 'name', dataSort: true}],
            },
        },
        {
            name: 'user',
            icon: 'fal fa-users',
            label: intl.formatMessage({id: 'settingsUsers'}),
            table: {
                keyField: 'id',
                headers: [{title: 'sharedName', field: 'name', dataSort: true}],
            },
        },
        {
            name: 'command',
            icon: 'fal fa-file-import',
            label: intl.formatMessage({id: 'sharedCalendar'}),
            table: {
                keyField: 'id',
                headers: [{
                    title: 'sharedDescription',
                    field: 'description',
                    dataSort: true,
                }],
            },
        },
        {
            name: 'maintenance',
            icon: 'fal fa-tools',
            label: intl.formatMessage({id: 'sharedMaintenance'}),
            table: {
                keyField: 'id',
                headers: [{
                    title: 'sharedName',
                    field: 'name',
                    dataSort: true,
                }],
            },
        },
        {
            name: 'attribute',
            icon: 'fad fa-server',
            label: intl.formatMessage({id: 'sharedComputedAttribute'}),
            table: {
                keyField: 'id',
                headers: [{
                    title: 'sharedDescription',
                    field: 'description',
                    dataSort: true,
                },
                    {
                        title: 'sharedComputedAttribute',
                        field: 'attribute',
                        dataSort: true,
                    }],
            },
        },
    ];

    const items = permissions.filter((p) => options.includes(p.name));

    return (
        <div className={'permission-content'}>
            <div className={'rounded-group-button-form'}>
                <Button className={'btn-circle btn-lg'} onClick={cancel} type="button">
                    <i className="fas fa-arrow-left"/></Button>
            </div>
            <Nav tabs>
                {items.map(({name, icon, label}, idx) => (
                    <NavItem key={idx}>
                        <NavLink
                            className={activeTab === name ? 'active' : ''}
                            onClick={() => {
                                setActiveTab(name);
                            }}>
                            {<i className={icon}/>}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {items.map((item, idx) => (
                    <PermissionTab
                        key={idx}
                        auth={props.auth}
                        data={data} {...item} />
                ))}
            </TabContent>
        </div>
    );
};


const mapping = ({store}) => ({auth: store.auth, devices: store.devices});

export default inject(mapping)(observer(PermissionTabs));
