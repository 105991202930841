import {types} from 'mobx-state-tree';

const MapModel = types
    .model('Map', {
      selectedMap: types.enumeration(['osm', 'hybrid', 'street', 'satellite']),
      center: types.frozen([0, 0]),
      zoom: types.frozen({value: 4}),
      trace: types.optional(types.boolean, false),
      follow: types.optional(types.boolean, false),
      labels: types.optional(types.boolean, false),
      geofences: types.frozen([]),
    })
    .actions((self) => ({
      setMap: (value) => (self.selectedMap = value),
      setInfoBase: ({zoom, center}) => {
        self.center = center;
        self.zoom = zoom;
      },
      setFollow: (value) => (self.follow = value),
      showLabels: (value) => (self.labels = value),
      addGeofence: (geofence) => (self.geofences = [...self.geofences, geofence]),
    }));

export default MapModel;
