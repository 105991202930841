import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'

const InputFile = ({ name, label, onChange, ...rest }) =>{
    const inputRef = useRef(null)

    const { fieldName, registerField, error } = useField(name)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            getValue: ref => {
                return ref.value
            },
            setValue: (ref, value) => {
                ref.value = value
            },
            clearValue: ref => {
                ref.value = ''
            },
        })
    }, [fieldName, registerField])

    return (
        <>
            <input
                type="file"
                id={fieldName}
                ref={inputRef}
                onChange={onChange}
                {...rest}
            />
            {error && <span className="error">{error}</span>}
        </>
    )
}

export {
    InputFile
}
