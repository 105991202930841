import {useEffect, useState} from 'react';
import {from} from 'rxjs';
import {last, mergeAll, tap} from 'rxjs/operators';
import {actions} from '../../../components/map/reducers';
import {sharedMapSubjects$} from '../helpers/subjects';
import useStore from '../../../hooks/use-store';

let unsubscribe$ = null;
let followValue;

const useSharedMapPosition = (state, dispatch, instance) => {
  const {positions} = sharedMapSubjects$;
  const {store} = useStore();
  const {devices} = store;
  const {instance: map, deviceMap} = state;

  const [follow, setFollow] = useState(false);
  const [showDevices, setShowDevices] = useState(true);

  useEffect(() => {
    if (map) {
      map.initDevicesLayer();
    }
  }, [map]);

  useEffect(() => {
    followValue = follow;
  }, [follow]);

  useEffect(() => {
    if (map?.devicesLayer && devices.updatedPositions) {
      from(devices.list)
          .pipe(
              tap((device) => {
                if (instance.current.props.initAllDevices) {
                  map.addFeatureDevice(device);
                }
              }),
              last(),
          )
          .subscribe((_) => {
            dispatch({
              type: actions.SET_INITIALIZED_POSITIONS,
              payload: devices.updatedPositions ? devices.updatedPositions : false,
            });

            const distance = instance.current.props.cluster.distance;
            const centerOnCluster = instance.current.props.centerOnCluster;

            if (distance) map.defineDeviceCluster(distance, centerOnCluster);
          });
    }
  }, [map, devices.updatedPositions, deviceMap]);


    useEffect(() => {
      if (map?.devicesLayer && deviceMap) {
        if (showDevices && instance.current.props.initAllDevices) {
          for (const device of deviceMap.values()) {
            map.addFeatureDevice(device);
          }
        }
        unsubscribe$ = positions.pipe(mergeAll()).subscribe((position) => {
          devices.updatePosition(position);
          try {
            const device = deviceMap.get(+position.deviceId);
            const selected = devices.selectedDevice;
            map.updateDevicePosition(device);
            if (followValue && selected && +device.id === selected.id) {
              const {longitude, latitude} = device.currentPosition;
              map.goTo([longitude, latitude]);
            }
          } catch (error) {}
        });
      } else {
        if (unsubscribe$) unsubscribe$.unsubscribe();

        if (map) map.removeDevices();
      }
    }, [map, deviceMap, showDevices]);

    return {showDevices, setShowDevices, follow, setFollow};
};

export default useSharedMapPosition;
