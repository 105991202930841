import React from 'react';
import styled from 'styled-components';
import RoundButton from '../../../../components/roundButton';
import {colors} from '../../../../styleVariables';

const Container = styled.div.attrs({id: 'center-actions'})`
  align-items: flex-end;
  display: flex;
  justify-content: center;

  position: absolute;
  z-index: 10;
  bottom: 1rem;
  left: 50%;
  right: 50%;

  .badge {
    background-color: ${colors.gray5};
    color: ${colors.gray};
  }
`;

const ActionItem = ({
    label,
    active,
    onPress,
    iconClassName,
}) => {
    return (
        <RoundButton
            withBorder
            labelDirection='bottom'
            label={label}
            active={active}
            onClick={onPress}
            className="mx-1"
        >
            <span className={iconClassName} />
        </RoundButton>
    );
};

export default {
    Root: Container,
    Item: ({
        label,
        active,
        onPress,
        iconClassName,
    }) => <ActionItem
        label={label}
        active={active}
        onPress={onPress}
        iconClassName={iconClassName}
    />,
};
