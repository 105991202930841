import HttpInstance from "./http-instance";
import {serialize} from "../helpers/request";

export default class BaseService {

    constructor(api) {
        this.instance = HttpInstance.instance;
        this.url = api;
    }

    async request(config) {
        try {
            const response = await this.instance(Object.assign(config));
            return response.data;
        } catch (err) {
            throw err.response;
        }
    }

    __get( url, configs = {}) {

        return this.request({
            method: 'get',
            url,
            configs
        });
    }

    __post( url, data) {
        return this.request({
            method: 'post',
            url,
            data,
        });
    }

    __put( url, data) {
        return this.request({
            method: 'put',
            url,
            data,
        });
    }

    __delete( url, data) {
        if(data){
            return this.request({
                method: 'delete',
                url,
                data
            });
        }
        return this.request({
            method: 'delete',
            url
        });
    }

    list(query) {
        return this.__get(`${this.url}?${serialize(Object.assign({_dc: new Date().getTime()}, query))}`);
    }

    save(data) {
        return this.__post(this.url, data);
    }

    update(data) {
        return this.__put(`${this.url}/${data.id}`, data);
    }

    remove(id) {
        return this.__delete(`${this.url}/${id}`)
    }

}
