import React from 'react';
import {Button} from 'reactstrap';
import styled from 'styled-components';
import {colors} from '../../../../styleVariables';
import {DeviceInfoStyle} from '../../styles';
import Driver from './driver';
import Header from './header';
import Location from './location';
import DeviceInfoAttributes from '../../../../components/device-info-attributes';
import PropTypes from 'prop-types';
import CommandConfirmationModal from '../../../modals/CommandConfirmationModal';
import useTimezone from '../../../../hooks/use-timezone';
import {observer} from 'mobx-react';

const Style = styled.div`
  background-color: white;
  box-shadow: $shadow;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -12px;
  margin-top: -10px;
`;

const ButtonStyled = styled(Button)`
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #eeeeee;
  color: ${colors.gray4} !important;
`;

const DeviceDetail = (props) => {
  const {
    selectedDevice,
    drivers,
    server,
    theme,
    unSelectDevice,
    isOpenModalCommand,
    toggleModalCommand,
    block,
    sendCommand,
    commandData,
  } = props;

  const selected = selectedDevice || {};
  const {
    currentPosition = {},
  } = selectedDevice;

  const {timezone} = useTimezone();

  const driver = currentPosition && currentPosition.attributes ?
        drivers.getDriverByUniqueId(currentPosition.attributes.driverUniqueId) : null;

  const driverName = driver ? driver.name : '';

  return (
    <>
      <ButtonStyled
        color="light"
        size="sm"
        className="custom-shadow pb-3 mt-2"
        onClick={unSelectDevice}
      >
                Mostrar todos os dispositivos
      </ButtonStyled>
      <Style className="bg-white w-100 custom-shadow">
        <Header {...{...selected, theme}} server={server}/>
        <Location
          theme={theme}
          currentPosition={currentPosition}
          timezone={timezone}
        />
        <Driver theme={theme} driver={driverName}/>
        {/* <Pictures theme={theme} /> */}
        <DeviceInfo {...{...selected, theme}} />
      </Style>
      <CommandConfirmationModal
        theme={theme}
        name={selectedDevice.name}
        isOpenModalCommand={isOpenModalCommand}
        toggleModalCommand={toggleModalCommand}
        block={block}
        sendCommand={sendCommand}
        commandData={commandData}
      />
    </>
  );
};

const DeviceInfo = ({theme, ...props}) => (
  <DeviceInfoStyle id="deviceInfo" className="mt-3" theme={theme}>
    <span className="text-gray">Informações do Dispositivo</span>
    <DeviceInfoAttributes className="mt-3" {...props} />
  </DeviceInfoStyle>
);

export default observer(DeviceDetail);

DeviceDetail.propTypes = {
  selectedDevice: PropTypes.object,
  drivers: PropTypes.object,
  server: PropTypes.object,
  theme: PropTypes.object,
  unSelectDevice: PropTypes.func,
  commandData: PropTypes.any,
  isOpenModalCommand: PropTypes.bool,
  toggleModalCommand: PropTypes.func,
  sendCommand: PropTypes.func,
  block: PropTypes.func,
};
