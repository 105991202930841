import {useEffect, useState} from 'react';
import useStore from './use-store';

const useMaintenanceTypes = () => {

    const {store} = useStore();
    const [maintenanceTypes, setMaintenanceTypes] = useState([]);
    const {maintenanceTypes: maintenanceTypesStore} = store;

    useEffect(() => {
        const data = [];
        maintenanceTypesStore.types.map((type) => {
            data.push(
                {
                    id: type.key,
                    title: type.name,
                    type
                });
        });

        setMaintenanceTypes(data);
    }, [maintenanceTypesStore.types.length]);

    return {maintenanceTypes};
};

export default useMaintenanceTypes;
