import React from 'react';
import {icons} from '../../helpers/conversions.js';
import {getBatteryPercentage} from '../../helpers/device';
import styled from 'styled-components';
import {getDeviceIcon, getDeviceIconColor} from '../../helpers/deviceIcons';

const Div = styled.div`
  &.active,
  :hover {
    border-top: ${(props) => `3px solid ${props.theme}`};
    border-bottom: ${(props) => `3px solid ${props.theme}`};
  }
`;

const Battery = ({value}) => {
  const colors = [[70, 'green'], [40, 'yellow'], [0, 'red']];

  const icon = icons.find(([k]) => value >= k)[1];
  const color = colors.find(([k]) => value >= k)[1];

  return (
    <div className={`${color}`}>
      <span className={icon} />
    </div>
  );
};

const Ignition = ({status}) => (
  <div className={`${status ? 'green' : undefined}`}>
    <span className="fal fa-key fa-2x" />
  </div>
);

const Blocked = ({status}) => (
  <div className={`${!status ? 'green' : undefined}`}>
    <span className={`${status ? 'fal fa-lock' : 'fal fa-unlock'} fa-2x`} />
  </div>
);

const Alarm = ({status}) => (
  <div className={`${!status ? undefined : 'red'}`}>
    <span className={`${status ? 'fal fa-siren-on' : 'fal fa-siren'} fa-2x`} />
  </div>
);

const DeviceListItem = (props) => {
  const {
    name,
    currentPosition,
    onSelect,
    id,
    active,
    theme,
    category,
    status,
  } = props;

  const attributes =
      currentPosition && currentPosition.attributes ?
          currentPosition.attributes :
          {
            batteryLevel: 0,
            battery: 0,
            blocked: undefined,
            ignition: undefined,
            alarm: undefined,
            odometer: undefined,
            runtime: undefined,
          };

  const {batteryLevel, battery, blocked, ignition, alarm} = attributes;

  const batteryValue = batteryLevel ?
    batteryLevel :
    battery ?
    getBatteryPercentage(battery).toFixed(0) :
    null;

  return (
    <Div
      theme={theme}
      className={`d-flex flex-column deviceListItem ${active && 'active'}`}
      onClick={() => onSelect(id)}
    >
      <div className="status">
        <img
          className={'device-fleet-icon'}
          width={35}
          height={35}
          src={getDeviceIcon(
              category,
              'right',
              getDeviceIconColor({status, ignition, alarm}),
          )} />
        {ignition !== undefined && <Ignition status={ignition} />}
        {blocked !== undefined && <Blocked status={blocked} />}
        {batteryValue && <Battery value={batteryValue} />}
        <Alarm status={alarm} />
      </div>
      <div className="marquee">
        <span>
          <small>{name} - {currentPosition?.address}</small>
        </span>
      </div>
    </Div>
  );
};

export default DeviceListItem;
