import {useEffect, useRef, useState} from 'react';
import useServices from '../../hooks/use-services';
import {sharedMapSubjects$} from './helpers/subjects';
import useStore from '../../hooks/use-store';
import useRemoveMinWidthRestrictionWhileMounted from 'src/hooks/use-remove-min-width-restriction-while-mounted';

const POOLING_TIME_MILLISECONDS = 5000;

export const useSharedMapViewModel = (token) => {
    const intervalRef = useRef(null);

    const {store} = useStore();
    const {devices} = store;
    const {locationService} = useServices();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const onFetchLocationSuccess = (_devices, shouldUpdateDevicesPositions = false) => {
        const {__devices, positions} = _devices.reduce((accu, device) => {
            const {currentPosition, positionBuffer, withTrace, ...rest} = device;

            accu.__devices.push(rest);
            if (currentPosition !== null) {
                accu.positions.push(currentPosition);
            }

            return accu;
        }, {__devices: [], positions: []});

        sharedMapSubjects$.devices.next(__devices);
        sharedMapSubjects$.positions.next(positions);
        if (shouldUpdateDevicesPositions) {
            devices.updatePositions(positions);
        }
    };

    const fetchLocation = async (token) => {
        try {
            const _devices = await locationService.getLocation(token);
            return _devices;
        } catch (error) {
            setError(error);
            return [];
        }
    };

    useEffect(() => {
        fetchLocation(token)
            .then((_devices) => {
                devices.addAll(_devices);
                onFetchLocationSuccess(_devices, true);
            })
            .finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!isLoading && !error) {
            intervalRef.current = setInterval(async () => {
                const _devices = await fetchLocation(token);
                onFetchLocationSuccess(_devices);
            }, POOLING_TIME_MILLISECONDS);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [isLoading, error]);

    // workaround to allow shared route to not have 1024px as min width
    // because of box sizing it stretches the whole page
    useRemoveMinWidthRestrictionWhileMounted();

    return {
        isLoading,
        error,
    };
};
